import axios from "axios";

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const getBig3Players = (user) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    };
    return axios.get(
      BALLPARK_BACKEND_BASE_URL + "big3",
      { headers }
    );
};

export const updateBig3Players = (user, athletes) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
    };
    return axios.post(
        BALLPARK_BACKEND_BASE_URL + "big3", athletes,
        { headers }
    );
}