import { useSnackbar } from "notistack";
import { useEffect, useState, useMemo } from "react";
import { useUserContext } from "../contexts/ProviderProvider";

const useNotification = () => {
  const [conf, setConf] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const user = useUserContext();

  useEffect(() => {
    if (!user) return;
    const now = new Date().getTime();
    const expiresIn = user?.stsTokenManager?.expirationTime - now;
    //console.log("expires in :", expiresIn);
    if (expiresIn < 2 * 60 * 1000) {
      //console.log("expires too soon, force login");
      window.location.path = "/login"
    }
  }, [user]);
  useEffect(() => {
    if (conf?.msg) {
      const variant = conf.variant || "info";
      enqueueSnackbar(conf.msg, {
        variant: variant,
        autoHideDuration: 5000,
      });
    }
  }, [conf, enqueueSnackbar]);
  return useMemo(() => {
    return (v) =>
      typeof v === "string" || v instanceof String
        ? setConf({ msg: v, variant: "error" })
        : setConf(v);
  }, []);
};

export default useNotification;
