import { useState, useEffect } from "react";
import AreYouSure from "../../common/AreYouSure";
import { getNonVerifiedGraphics, postGraphicVerification } from "../../../API/equipmentManager";
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import MUIDataTable from "mui-datatables";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Checkbox, IconButton } from "@mui/material";

const MediaDaySuccess = () => {
    const user = useUserContext();
    const notify = useNotification();
    const [smGraphics, setSMGraphics] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [actionType, setActionType] = useState("");
    const [rosterId, setRosterId] = useState(-1);

    useEffect(() => {
        setIsLoading(true);

        getNonVerifiedGraphics(user).then((res) => {
            console.log(res)
            setSMGraphics(res.data.obj ? res.data.obj : []);
            setIsLoading(false);
        }).catch((err) => {
            notify("Error getting NonVerified Graphics");
            setIsLoading(false);
        });

    }, [refresh, user, notify]);

    const nullifyState = () => {
        setActionType("");
        setRosterId(-1);
        setIsModalOpen(false);
    }

    const submitAreYouSure = () => {

        var payload = {
            "roster_id": rosterId,
        }
        var notify_message = "Graphic "
        var error_message = "Error "
        if (actionType === "approve") {
            payload["is_approved"] = true;
            notify_message += "Approved Successfully"
            error_message += "approving Graphic"
        } else {
            payload["is_approved"] = false;
            notify_message += "Denied Successfully"
            error_message += "denying Graphic"
        }

        postGraphicVerification(user, payload).then(() => {
            setRefresh(refresh + 1);
            notify({ msg: notify_message, variant: "success" });
            nullifyState();
        }).catch((err) => {
            notify(error_message);
            nullifyState();
        });
    }

    const columns = [
        { name: "full_name", label: " Athlete Name" },
        { name: "university", label: "University" },
        { name: "sport", label: "Sport" },
        {
            name: "is_international", label: "International",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (<Checkbox checked={value} disabled={true} />)
                },
            }
        },
        {
            name: "image_link",
            label: "Graphic",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <img
                            src={value}
                            alt="Graphic"
                            style={{ maxHeight: "100px" }}
                            onClick={() => {
                                window.open(value, "_blank");
                            }} />
                    );
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <IconButton onClick={() => {
                                setActionType("approve")
                                setRosterId(smGraphics[tableMeta.rowIndex].roster_id)
                                setIsModalOpen(true)
                            }}>
                                <CheckIcon color="success" />
                            </IconButton>
                            <IconButton onClick={() => {
                                setActionType("deny")
                                setRosterId(smGraphics[tableMeta.rowIndex].roster_id)
                                setIsModalOpen(true)
                            }}>
                                <ClearIcon color="error" />
                            </IconButton>

                        </div>
                    );
                }
            }
        }
    ];

    return (
        <div style={{ width: "100%", marginTop: "10px", paddingLeft: "20px", paddingRight: "20px" }}>

            <MUIDataTable
                options={{
                    rowsPerPage: window.rowsPerPage,
                    scrollX: true,
                    selectableRows: "none",
                    filter: false,
                    download: false,
                    print: false,
                    viewColumns: false,
                    search: false,
                }}
                columns={columns}
                data={smGraphics}
            />
            <AreYouSure
                style={{
                    backgroundColor: "background.default",
                    margin: "auto",
                    marginTop: "10vh",
                    padding: "20px",
                    width: "30vw",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRadius: "5px",
                }}
                open={isModalOpen}
                message={"Do you want to " + actionType + " the graphic"}
                handleClose={() => nullifyState()}
                yes={() => {
                    submitAreYouSure();
                }}
                no={() => nullifyState()}
            />
        </div>
    );

}

export default MediaDaySuccess;