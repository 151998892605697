import * as React from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import AthleteProfileWrapper from "./AthleteProfileWrapper";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.ATHLETES]} els={<Navigate to="/ballpark" />}>
      <AthleteProfileWrapper />
    </Can>
  );
}

export const icon = <DirectionsRunIcon />;
export const text = "Athletes";
export const componentName = "Athletes";
export const path = "athletes/:athleteIdEncoded?";
