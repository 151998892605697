import * as React from 'react';
import { useUserContext } from '../../../../contexts/ProviderProvider';
import useNotification from "../../../../hooks/useNotification";

import { CircularProgress, Typography } from "@mui/material";
import MUIDataTable from 'mui-datatables';
import { getEquipmentManagerConfig } from '../../../../API/equipmentManager';
import { decompressData } from '../../../../utilities/utils';

const ConfigureEquipmentZeroOut = ({ addToCardArray, setLoading }) => {

    const [loadingData, setLoadingData] = React.useState(true);
    const [emConfigurations, setEMConfigurations] = React.useState([]);

    const notify = useNotification();

    const user = useUserContext();


    React.useEffect(() => {
        setLoadingData(true);
        setLoading(true);
        getEquipmentManagerConfig(user)
            .then((response) => {
                setLoadingData(false);
                setLoading(false);
                const responseData = decompressData(response);
                setEMConfigurations(responseData.missingConfigRecords);
                addToCardArray({
                    id: 'Equip Mang Config',
                    panel: 'EquipMangConfig',
                    count: responseData.missingConfigRecords.length
                })
            })
            .catch((error) => {
                setLoadingData(false);
                setLoading(false);
                console.log(error);
                notify("Failed to get equipment manager configurations");
            })

    }, [user, notify]);

    const columns = [
        {
            name: "product_count",
            label: "Product Count",
        },
        {
            name: "sku_name",
            label: "Sku Name",
        },
        {
            name: "university",
            label: "University",
        },
    ];

    const options = {
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: loadingData ? (
                    <CircularProgress />
                ) : (
                    "No Records"
                ),
            },
        },
    }

    return (
        <div
            style={{
                margin: "20px 10px"
            }}>
            {emConfigurations ?
                <MUIDataTable
                    data={emConfigurations}
                    columns={columns}
                    options={options}
                />
                :
                <Typography variant="h6">Data not found for Grand Slam</Typography>
            }
        </div>
    );

}

export default ConfigureEquipmentZeroOut;