import * as React from "react";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import AssetUpload from "./AssetUpload";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.UPLOADASSETS]} els={<Navigate to="/ballpark" />}>
      <AssetUpload />
    </Can>
  );
}
export const icon = <LibraryAddIcon />;
export const text = "Upload Assets";
export const componentName = "Upload Assets";
export const path = "uploadAssets";
