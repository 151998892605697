import { useState, useEffect } from "react";
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import * as Yup from "yup";
import {
  getAllProductConfigurations,
  postProductConfiguration,
} from "../../../API/productConfiguration";
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { VENDORS } from "../../../utilities/utils";

export const ManufacturingData = ({ color, size, style }) => {
  const sizeList = size.split(":");
  const colorList = color.split(":");
  const styleList = style.split(":");

  return (
    <table>
      <thead>
        <tr>
          <th style={{ borderBottom: "solid gray 1px" }}>Size</th>
          <th style={{ borderBottom: "solid gray 1px" }}>Style</th>
          <th style={{ borderBottom: "solid gray 1px" }}>Color</th>
        </tr>
      </thead>
      <tbody>
        {sizeList.map((size, i) => {
          return (
            <tr key={size}>
              <td style={{ borderRight: "solid gray 1px" }}>{sizeList[i]}</td>
              <td style={{ borderRight: "solid gray 1px" }}>{styleList[i]}</td>
              <td style={{ borderRight: "solid gray 1px" }}>{colorList[i]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const dfsFrom = (tree) => {
  if (tree === undefined) {
    return [];
  }

  let vals = [];

  if (!(Object.prototype.toString.call(tree) === "[object Array]")) {
    let keys = Object.keys(tree);
    keys.forEach((key) => {
      const subtree = dfsFrom(tree[key]);
      vals = vals.concat(...subtree);
    });
    return vals;
  }
  return tree;
};

const buildListSubtree = (tree, l1, l2) => {
  if (!tree) {
    return [];
  }

  if (l1 === "*") {
    return dfsFrom(tree);
  } else if (l2 === "*") {
    return dfsFrom(tree[l1]);
  } else {
    if (tree[l1] === undefined) {
      return [];
    }
    return dfsFrom(tree[l1][l2]);
  }
};

const Matches = ({ productConfigurations, formik }) => {
  console.log("Trying to match with:", formik.values);
  const type = formik.values.product_type || "*";
  const publicColor = formik.values.public_color || "*";

  // Ensure that each level of the tree exists
  if (productConfigurations) {
    // Map over the matching results
    const matchingResults = buildListSubtree(
      productConfigurations,
      publicColor,
      type
    ).map((matching) => {
      return (
        <div key={matching.product_config_id} style={{ padding: "auto" }}>
          <div>
            {matching.vendor} {matching.product_category}
          </div>
          <div>
            {matching.public_color} {matching.product_type}
          </div>
          <div>
            <ManufacturingData
              color={matching.color_reference}
              size={matching.size_range}
              style={matching.style_reference}
            />
          </div>
          <Divider style={{ margin: "1rem 0rem" }} />
        </div>
      );
    });

    if (matchingResults.length === 0) {
      return "No Matching Products";
    }

    // Render the matching results
    return matchingResults;
  } else {
    // Handle the case where one or more properties are missing
    return <div>No matching results found.</div>;
  }
};

const ErrorText = ({ message }) => {
  return <p style={{ color: "#f44336", fontSize: "0.75rem" }}>{message}</p>;
};

const ConfigureNewProduct = () => {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(0);
  const [productConfigurations, setProductConfigurations] = useState();

  const user = useUserContext();
  const notify = useNotification();

  const handleSubmit = (form) => {
    setLoading(true);
    postProductConfiguration(form, user)
      .then((response) => {
        notify({ msg: "Configured New Product", variant: "success" });
        setLoading(false);
        setReload((p) => p + 1);
      })
      .catch((e) => {
        notify("Failed to configure product");
        setReload((p) => p + 1);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllProductConfigurations(user)
      .then((response) => {
        setProductConfigurations(response.data);
        setLoading(false);
      })
      .catch((err) => {
        notify("Failed to get product configurations");
        setLoading(false);
      });
  }, [user, notify, reload]);

  const validationSchema = Yup.object().shape({
    product_type: Yup.string().required("Product type is required"),
    public_color: Yup.string().required("Public color is required"),
    product_category: Yup.string().required("Product category is required"),

    requires_manufacture_info: Yup.boolean(),

    color_reference: Yup.string().when("requires_manufacture_info", {
      is: (rmi) => rmi,
      then: () => Yup.string().required("Color reference is required"),
      otherwise: () => Yup.string(),
    }),
    size_range: Yup.string().when("requires_manufacture_info", {
      is: (rmi) => rmi,
      then: () => Yup.string().required("Size range is required"),
      otherwise: () => Yup.string(),
    }),
    style_reference: Yup.string().when("requires_manufacture_info", {
      is: (rmi) => {
        console.log("is", rmi);
        return rmi;
      },
      then: () => Yup.string().required("Style reference is required"),
      otherwise: () => Yup.string(),
    }),

    vendor: Yup.string().required("Vendor is required"),
    needs_license: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      product_type: "",
      public_color: "",
      color_reference: "",
      product_category: "",
      size_range: "",
      style_reference: "",
      vendor: "Influxer Merch",
      needs_license: true,
      requires_manufacture_info: false,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const fieldStyle = { marginTop: "1rem" };

  console.log(formik.values, formik.errors);

  return (
    <Grid container direction={"row"} gap={2} style={{ width: "100%" }}>
      {loading && <LinearProgress />}
      <Grid item lg={5} xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Autocomplete
            freeSolo
            name="product_category"
            fullWidth
            style={fieldStyle}
            value={formik.values.product_category}
            onChange={(event, newValue) => {
              console.log("category", newValue);
              formik.setFieldValue("product_category", newValue);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              formik.setTouched({ ...formik.touched, product_category: true });
              formik.setFieldValue("product_category", e.target.value);
            }}
            options={Array.from(
              new Set(
                dfsFrom(productConfigurations).map((pc) => pc.product_category)
              )
            )}
            renderInput={(params) => {
              return (
                <TextField
                  id="product_category"
                  {...params}
                  required
                  label="Product Category"
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputProps={{ ...params.InputProps }}
                  error={
                    formik.touched.product_category &&
                    Boolean(formik.errors.product_category)
                  }
                  helperText={
                    formik.touched.product_category &&
                    formik.errors.product_category
                  }
                />
              );
            }}
          />
          <Autocomplete
            freeSolo
            name="public_color"
            fullWidth
            style={fieldStyle}
            value={formik.values.public_color}
            onChange={(event, newValue) => {
              console.log("category", newValue);
              formik.setFieldValue("public_color", newValue);
            }}
            onBlur={(a, b, c) => {
              formik.handleBlur(a);
              formik.setTouched({ ...formik.touched, public_color: true });
            }}
            options={Array.from(
              new Set(
                dfsFrom(productConfigurations).map((pc) => pc.public_color)
              )
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  required
                  label="Public Color"
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputProps={{ ...params.InputProps }}
                  error={
                    formik.touched.public_color &&
                    Boolean(formik.errors.public_color)
                  }
                  helperText={
                    formik.touched.public_color && formik.errors.public_color
                  }
                />
              );
            }}
          />
          <Autocomplete
            freeSolo
            name="product_type"
            fullWidth
            style={fieldStyle}
            value={formik.values.product_type}
            onChange={(event, newValue) => {
              console.log("category", newValue);
              formik.setFieldValue("product_type", newValue);
            }}
            onBlur={(a) => {
              formik.handleBlur(a);
              formik.setTouched({ ...formik.touched, product_type: true });
              formik.setFieldValue("product_type", a.target.value);
            }}
            options={Array.from(
              new Set(
                dfsFrom(productConfigurations).map((pc) => pc.product_type)
              )
            )}
            renderInput={(params) => {
              return (
                <TextField
                  id="product_type"
                  {...params}
                  required
                  label="Product Type"
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputProps={{ ...params.InputProps }}
                  error={
                    formik.touched.product_type &&
                    Boolean(formik.errors.product_type)
                  }
                  helperText={
                    formik.touched.product_type && formik.errors.product_type
                  }
                />
              );
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.requires_manufacture_info}
                id="requires_manufacture_info"
              />
            }
            label="Requires Manufacturing Data"
          />
          <div
            style={{
              display: formik.values.requires_manufacture_info
                ? "block"
                : "none",
              visibility: formik.values.requires_manufacture_info
                ? "none"
                : "visible",
            }}
          >
            <TextField
              id="color_reference"
              name="color_reference"
              label="Color Reference"
              variant="outlined"
              style={{ marginTop: "20px" }}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.color_reference}
              error={
                formik.touched.color_reference &&
                Boolean(formik.errors.color_reference)
              }
              helperText={
                formik.touched.color_reference && formik.errors.color_reference
              }
            />
            <TextField
              id="size_range"
              name="size_range"
              label="Size Range"
              variant="outlined"
              style={{ marginTop: "20px" }}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.size_range}
              error={
                formik.touched.size_range && Boolean(formik.errors.size_range)
              }
              helperText={formik.touched.size_range && formik.errors.size_range}
            />
            <TextField
              id="style_reference"
              name="style_reference"
              label="Style Reference"
              variant="outlined"
              style={{ marginTop: "20px" }}
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.style_reference}
              error={
                formik.touched.style_reference &&
                Boolean(formik.errors.style_reference)
              }
              helperText={
                formik.touched.style_reference && formik.errors.style_reference
              }
            />
          </div>
          <Autocomplete
            freeSolo
            fullWidth
            style={fieldStyle}
            value={formik.values.vendor}
            onChange={(event, newValue) => {
              formik.setFieldValue("vendor", newValue);
            }}
            onBlur={(a, b, c) => {
              console.log(a, b, c);
              formik.handleBlur(a, b, c);
            }}
            options={VENDORS}
            renderInput={(params) => {
              return (
                <TextField
                  id="vendor"
                  {...params}
                  InputProps={{ ...params.InputProps }}
                  required
                  label="Vendor"
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={formik.touched.vendor && Boolean(formik.errors.vendor)}
                  helperText={formik.touched.vendor && formik.errors.vendor}
                />
              );
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.needs_license}
                id="needs_license"
              />
            }
            label="Needs License"
          />
          <Button
            style={{ width: "100%" }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Save
          </Button>
        </form>
      </Grid>
      <Grid item lg={5} xs={12}>
        {productConfigurations && (
          <div>
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: ".5rem",
                width: "100%",
              }}
            >
              Similar Configurations
            </Typography>
            <div style={{ overflowY: "scroll", height: "70vh" }}>
              {loading && <Divider />}
              <Matches
                productConfigurations={productConfigurations}
                formik={formik}
              />
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default ConfigureNewProduct;
