import * as React from "react";
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { getSchoolsMeta, updateSchool } from "../../../API/school";
import MUIDataTable from "mui-datatables";
import { Checkbox } from "@mui/material";

const format = (data) => {
  return data.map((s) => {
    s.bdrAssigned = s.bdrAssigned
      ? s.bdrAssigned.name + "~" + s.bdrAssigned.id
      : "";
    return s;
  });
};

// a bunch of tables for viewing the current state of the biz dev system
// columns
// uni name
// license status
// BDR assigned
// roster size
// signups size
const Overview = () => {
  const [schoolData, setSchoolData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const user = useUserContext();
  const notify = useNotification();

  React.useEffect(() => {
    getSchoolsMeta(user)
      .then((response) => {
        setSchoolData(format(response.data));
      })
      .catch((error) => {
        setSchoolData([]);
        notify("Failed to get school metadata:" + error);
      });
  }, [notify, user]);

  const columns = [
    { label: "Universtiy", name: "universityName", options: {} },
    {
      label: "License",
      name: "licenseStatus",
      options: {
        customFilterListOptions: {
          render: (v) => (v ? "License Obtained" : "Missing License"),
        },
        filterOptions: {
          renderValue: (v) => (v ? "License Obtained" : "Missing License"),
        },
      },
    },
    {
      label: "BDR",
      name: "bdrAssigned",
      options: {
        customBodyRender: (value) => {
          return <div>{value.split("~")[0]}</div>;
        },
        customFilterListOptions: {
          render: (v) => (v.length > 0 ? v.split("~")[0] : "No BDR"),
        },
        filterOptions: {
          renderValue: (v) => {
            return v.split("~")[0];
          },
        },
      },
    },
    {
      label: "Art Approved",
      name: "artworkApproved",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(value);
          return (
            <Checkbox
              checked={value}
              onClick={(a) => {
                updateValue(a.target.checked);
                updateSchool(
                  tableMeta.rowData[0],
                  "artwork_approved",
                  a.target.checked ? 1 : 0,
                  user
                );
              }}
            />
          );
        },
      },
    },
    {
      label: "Bulk Created",
      name: "bulkCreated",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Checkbox
              checked={value}
              onClick={(a) => {
                updateValue(a.target.checked);
                updateSchool(
                  tableMeta.rowData[0],
                  "bulk_created_art",
                  a.target.checked ? 1 : 0,
                  user
                );
              }}
            />
          );
        },
      },
    },
    {
      label: "Products Uploaded",
      name: "productsUploaded",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox disabled checked={value} />;
        },
      },
    },
    {
      label: "Instagram Made",
      name: "instagramMade",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox disabled checked={value} />;
        },
      },
    },
    {
      label: "Roster",
      name: "rosterSize",
      options: {
        customFilterListOptions: {
          render: (v) => (v && v > 0 ? v : "No Roster"),
        },
        filterOptions: {
          renderValue: (v) => (v && v > 0 ? v : "No Roster"),
        },
      },
    },
    {
      label: "Signed Up",
      name: "signedUpCount",
      options: {
        customFilterListOptions: {
          render: (v) => (v > 0 ? v : "No Signups"),
        },
        filterOptions: {
          renderValue: (v) => (v > 0 ? v : "No Signups"),
        },
      },
    },
  ];

  const options = { rowsPerPage: window.rowsPerPage, selectableRows: "none" };

  return (
    <>
      <MUIDataTable
        title={"Schools Overview"}
        columns={columns}
        options={options}
        data={schoolData}
      />
    </>
  );
};

export default Overview;
