import * as React from "react";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import InfluxerVendor from "./InfluxerVendor";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
    const { views } = usePermissionContext();
    return (
        <Can action={views.VIEW} items={[views.FLUXVENDOR]} els={<Navigate to="/ballpark" />}>
            <InfluxerVendor />
        </Can>
    );
}
export const icon = <LocalShippingIcon />;
export const text = "Influxer Vendor";
export const componentName = "Influxer Vendor";
export const path = "influxervendor";