import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  ListItem,
  InputAdornment,
  IconButton
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import {
  useSchoolContext,
  useUserContext,
} from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { getBizDevs, getInterns } from "../../../API/employee";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { createSocialInternRecord } from "../../../API/roster";


const CreateSocialIntern = () => {
  const [schoolName, setSchoolName] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useUserContext();
  const notify = useNotification();
  const schools = useSchoolContext().schools;
  const [employeeName, setEmployeeName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeePassword, setEmployeePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [existingInterns, setExistingInterns] = useState([])
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const schoolNames = useMemo(
    () => schools.map((s) => s.schoolName),
    [useSchoolContext()]
  );

  useEffect(() => {
    getInterns(user).then((resp) => {
      setExistingInterns(resp.data.interns.map(i => i.university))
    }).catch(e => {
      notify(e)
    })
  }, [user, notify])
  const submit = () => {
    setLoading(true);
    createSocialInternRecord(schoolName, employeeName, employeeEmail, employeePassword, user)
      .then((response) => {
        notify({
          msg: "Successfully created social account",
          variant: "success",
        });
        setSchoolName("");
        setEmployeeName("");
        setEmployeeEmail("");
        setEmployeePassword("");
        setLoading(false);
      })
      .catch((error) => {
        notify("Failed to create social account with error: " + error);
        setLoading(false);
      });
  };

  return (
    <>
      <Paper sx={{ padding: "20px" }}>
        <Grid container spacing={2} direction={"row"}>
          <Grid item lg={12} xs={12}>
            <Typography sx={{ paddingBottom: "5px" }} variant="h5">
              Create Social Intern
            </Typography>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Autocomplete
              id="tags-standard"
              options={schoolNames.filter(f => !existingInterns.includes(f))}
              value={schoolName}
              onChange={(event, newValue) => {
                setSchoolName(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="School"
                />
              )}

            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              name="email"
              label="Employee Name"
              type="text"
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              name="email"
              label="App Email"
              type="email"
              value={employeeEmail}
              onChange={(e) => setEmployeeEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              name="password"
              label="App Password"
              value={employeePassword}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setEmployeePassword(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" onClick={submit}>
                  Configure New Social Intern
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CreateSocialIntern;
