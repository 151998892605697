import axios from "axios";

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const getHandleById = (id, user, variant) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  const queryParam = "?" + (variant ? variant : "id") + "=" + id;

  return axios.get(BALLPARK_BACKEND_BASE_URL + "getHandleById" + queryParam, {
    headers,
  });
};
