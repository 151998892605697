import { Collapse, TableRow, Box, responsiveFontSizes } from "@mui/material";
import { useEffect } from "react";
import FileDropZone from "../../../common/FileDropZone";
import { useUserContext } from "../../../../contexts/ProviderProvider";
import { uploadCollectionAssets } from "../../../../API/filesystem";
import useNotification from "../../../../hooks/useNotification";

const EditableCollection = ({ isOpen, rosterId, refresh }) => {
  const user = useUserContext();
  const notify = useNotification();

  const handleImageChange = async (files, callback) => {
    uploadCollectionAssets(files[0], { rosterId: rosterId }, user)
      .then((response) => {
        notify({ msg: "Success", variant: "success" });
        callback();
        refresh();
      })
      .catch((e) => {
        console.log(JSON.stringify(e.response.data));
        notify(e.response.data.err);
        callback();
        refresh();
      });
  };

  if (isOpen) {
    return (
      <Collapse sx={{ width: "100%" }} in={isOpen} unmountOnExit>
        <Box sx={{ margin: 1, width: "100%" }}>
          <FileDropZone
            single
            sx={{
              width: "100%",
            }}
            text={"Drag and drop new collection photo"}
            uploadFiles={(files, callback) => {
              handleImageChange(files, callback);
            }}
          />
        </Box>
      </Collapse>
    );
  } else {
    return "";
  }
};

export default EditableCollection;
