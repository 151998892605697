import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useNotification from "../../hooks/useNotification";
import useWebSocket from 'react-use-websocket';

const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;

const ScoutTeam = ({ scoutData, refresh, user }) => {

    const [loading, setLoading] = useState(false);
    const notify = useNotification();
    const [socketUrl, setSocketUrl] = useState(BASE_HATTRICK_URL + 'scoutTeam/run');
    const [isRunClicked, setIsRunClicked] = useState(false);
    
    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl, {
        onOpen: () => {
            console.log('Websocket connection established')
            sendMessage(user.accessToken); // Send token as the first message
            if(!isRunClicked){sendMessage('status');} // Check if a script is already running
        },
        onClose: () => {
            console.log('Websocket connection closed')
            const ws = getWebSocket();
            if (ws) {
                ws.close(1000, 'Client closing connection'); // Send close message
            }
        },
        onError: (event) => console.error('WebSocket error:', event),
        shouldReconnect: () => false, // Prevent reconnection
    });

    const closeWebSocket = () => {
        const ws = getWebSocket();
        if (ws) {
            ws.close(1000, 'Client closing connection');
        }
        setSocketUrl(null);
    }

    useEffect(() => {
        if (lastMessage !== null) {
            const data = lastMessage.data;
            if (data === "Script Completed") {
                notify({
                    msg: "Scout team run completed",
                    variant: "success"
                });
                refresh();
                closeWebSocket();
                setLoading(false);
            } else if (data === "Script Errored") {
                notify({
                    msg: "Scout team script encountered an error",
                    variant: "error"
                });
                closeWebSocket();
                setLoading(false);
            } else if (data === "Script already running") {
                notify({
                    msg: "Scout team script is already running",
                    variant: "warning"
                });
                setLoading(true);
            } else if (data === "No script running") {
                setLoading(false);
                closeWebSocket();
            }
        }
    }, [lastMessage, notify, user]);

    const handleRunScoutTeam = async () => {
        setLoading(true);
        setIsRunClicked(true);

        const dataToSend = Array.isArray(scoutData) ? scoutData : [scoutData];
        console.log("Data to send", { schools: dataToSend }, JSON.stringify({ schools: dataToSend }))

        setSocketUrl(BASE_HATTRICK_URL + 'scoutTeam/run');
        sendMessage(JSON.stringify({ schools: dataToSend })); // Send the school data as the second message
    };

    return (
        <div
            style={{
                margin: "10px",
                justifyContent: "flex-start",
                width: "100%",
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
            }}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={handleRunScoutTeam}
                style={{
                    margin: "5px",
                    flex: '0 1 auto',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                }}
                disabled={loading}
            >
                {loading ? (
                    <>
                        Scout Team run in progress
                        <CircularProgress size={24} color="inherit" />
                    </>
                ) : (
                    'Run Scout Team On Selected Schools'
                )}
            </Button>
        </div>
    );
};

// Define prop types
ScoutTeam.propTypes = {
    scoutData: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]).isRequired,
    refresh: PropTypes.func.isRequired,
};

export default ScoutTeam;
