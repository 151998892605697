import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, CircularProgress, LinearProgress, Modal, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import AreYouSure from "../../common/AreYouSure";
import useNotification from "../../../hooks/useNotification";
import { getAllOrders, updateOutfielderOrderStatus, getOrderStatus, TestfulfillmentStatus } from '../../../API/orderHistory';
import { convertSortedIndexNumber } from '../../../utilities/utils';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from "dayjs";
import LineItemsViewModal from "./LineItemsViewModal";
import { useUserContext } from '../../../contexts/ProviderProvider';
import { useMemo } from 'react';


const AllOrdersTable = () => {
    const notify = useNotification();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [isTestFulfillmentModalOpen, setIsTestFulfillmentModalOpen] = useState(false);
    const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState([]);
    const [selectedOrderName, setSelectedOrderName] = useState("");
    const today = dayjs();
    const threeWeeksAgo = today.subtract(3, 'week');
    const tomorrow = today.add(1, 'day');
    const [startDate, setStartDate] = useState(threeWeeksAgo);
    const [endDate, setEndDate] = useState(tomorrow);
    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const [dateChanged, setDateChanged] = useState(false);
    const [lineItemData, setLineItemData] = useState({});
    const [isLineItemsViewOpen, setIsLineItemsViewOpen] = useState(false);
    const user = useUserContext();


    const uniqueStatusList = useMemo(() => {
        return orders?.reduce((acc, order) => {
            if (!acc.includes(order.production_status)) {
                acc.push(order.production_status);
            }
            return acc;
        }, []);
    }, [orders]);


    useEffect(() => {
        startDateRef.current = startDate;
    }, [startDate]);

    useEffect(() => {
        endDateRef.current = endDate;
    }, [endDate]);

    useEffect(() => {
        fetchOrders();
    }, []);

    useEffect(() => {
        if (dateChanged) {
            fetchOrders();
        }
    }, [startDate, endDate, dateChanged]);

    const onLineItemsViewopen = (line_items) => {
        setLineItemData(line_items);
        setIsLineItemsViewOpen(true);
    }

    const onLineItemsViewClose = () => {
        console.log("Closing Modal")
        setIsLineItemsViewOpen(false);
        setLineItemData({});
    }

    useEffect(() => {
        const getAlerts = () => {
            setLoading(true);
            getAllOrders(startDateRef.current, endDateRef.current, user).then((response) => {
                if (response.data && response.data.obj) {
                    setOrders(response.data.obj);
                } else {
                    setOrders([]);
                }
                setLoading(false);
            }).catch((error) => {
                notify("Failed to get orders");
                setLoading(false);
            });
        }
        getAlerts()
        const interval = setInterval(() => getAlerts(), 60000)
        return () => {
            clearInterval(interval);
        }
    }, [])


    // Fetch orders from API
    const fetchOrders = () => {
        setLoading(true);
        getAllOrders(startDateRef.current, endDateRef.current, user).then((response) => {
            if (response.data && response.data.obj) {
                setOrders(response.data.obj);
            } else {
                setOrders([]);
            }
            setLoading(false);
        }).catch((error) => {
            notify("Failed to get orders");
            setLoading(false);
        });
    };


    // Refresh Ninja status for an order
    const refreshNinjaStatus = (orderName) => {
        setLoading(true);
        getOrderStatus(orderName, user).then((response) => {
            notify({ msg: "Updated order status", variant: "success" });
            const updatedOrders = orders.map(order => {
                if (order.order_name === orderName) {
                    return {
                        ...order, production_status: response.data.obj.status,
                        production_error: response.data.obj.error || ''
                    };
                }
                return order;
            });
            setOrders(updatedOrders);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            notify("Failed to get order status");
            setLoading(false);
        });
    };

    //Test fulfillment for an order
    const testFulfillment = (orderId) => {
        setLoading(true);
        TestfulfillmentStatus(orderId, user).then((response) => {
            const products = response.data.obj.products ? response.data.obj.products : {};
            const not_found = "❌"
            const content = Object.entries(products).map(([key, item]) => ({
                id: key,
                ...item,
                frontAsset: item.frontAsset === "" ? not_found : <img style={{ maxHeight: "50px" }} src={item.frontAsset}></img>,
                backAsset: item.backAsset === "" ? not_found : <img style={{ maxHeight: "50px" }} src={item.backAsset}></img>,
                productMockup: item.productMockup === "" ? not_found : <img style={{ maxHeight: "50px" }} src={item.productMockup}></img>,
                style: item.style === "" ? not_found : item.style,
                color: item.color === "" ? not_found : item.color,
                size: item.size === "" ? not_found : item.size,
                sku: item.sku === "" ? not_found : item.sku,
                title: item.title === "" ? not_found : item.title,
            }));
            setModalContent(content);
            setIsTestFulfillmentModalOpen(true);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            notify("Failed Test Fulfillment");
            setLoading(false);
        });
    };


    // Send an order to Ninja
    const handleSendToNinja = (order_name) => {
        updateOutfielderOrderStatus(order_name, user).then(() => {
            notify({ msg: "Order sent to Ninja", variant: "success" });
        }).catch((error) => {
            console.error(error);
            notify("Failed to send order to Ninja");
            setLoading(false);
        });
    };

    const submitAreYouSure = () => {
        handleSendToNinja(selectedOrderName);
        nullifyState();
    };

    const nullifyState = () => {
        setSelectedOrderName("");
        setIsAreYouSureModalOpen(false);
    };

    // Action Items for Production status
    const actionMapping = {
        SHIPPED: ['status'],
        INTERNATIONAL: ['submit', 'status', 'test'],
        THIRDPARTY: [],
        CANCELLED: [],
        READY_TO_SUBMIT: ['submit', 'test'],
        SUBMITTED: ['status', 'test'],
        WACO: ['submit', 'status', 'test'],
        GATESVILLE: ['submit', 'status', 'test'],
        IMPORT_ERROR: ['submit', 'status', 'test'],
        IN_PRODUCTION: ['status'],
        GIFT_CARD: []
    };

    const getActionButtons = (productionStatus, orderName, orderId) => {

        if (!productionStatus) {
            return null;
        }
        const actions = actionMapping[productionStatus.toUpperCase()] || [];
        if (actions.length === 0) {
            return null;
        }
        return (
            <ButtonGroup sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {actions.includes('submit') && (
                    <Button
                        variant="contained"
                        sx={{
                            padding: '9px', minWidth: '60px',
                            height: '80px', bgcolor: "#F5C542"
                        }}
                        onClick={() => {
                            setSelectedOrderName(orderName);
                            setIsAreYouSureModalOpen(true)
                        }}
                    >
                        Send To Ninja
                    </Button>
                )}
                {actions.includes('status') && (
                    <Button
                        variant="contained"
                        sx={{
                            padding: '9px', minWidth: '60px',
                            height: '80px', bgcolor: "#5DAE8B"
                        }}
                        onClick={() => refreshNinjaStatus(orderName)}
                    >
                        Ninja Status
                    </Button>
                )}
                {actions.includes('test') && (
                    <Button
                        variant="contained"
                        sx={{
                            padding: '9px', minWidth: '60px',
                            height: '80px', bgcolor: "#4A90E2"
                        }}
                        onClick={() => testFulfillment(orderId)}
                    >
                        Test Fulfillment
                    </Button>
                )}
            </ButtonGroup>
        );
    };


    const columns = [
        {
            label: "Order Name", name: "order_name",
            options: {
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Button sx={{ textTransform: 'unset' }}
                            onClick={() => {
                                const lineItems = orders[convertSortedIndexNumber(tableMeta)]?.line_items ? orders[convertSortedIndexNumber(tableMeta)].line_items : [];
                                onLineItemsViewopen(lineItems);
                            }}
                        >
                            {value}
                        </Button>
                    );
                }
            },
        },
        {
            label: "University", name: "university"
        },
        {
            label: "Production Status", name: "production_status",
            options: {

                filterList: ['NOT SHIPPED'],
                filterOptions: {
                    names: ['NOT SHIPPED', ...uniqueStatusList],
                    logic: (production_status, filters) => {

                        if (filters.includes('NOT SHIPPED')) {

                            return production_status === 'SHIPPED';
                        }
                        return filters.length > 0 ? !filters.includes(production_status) : false;
                    },
                },
            },

        },
        { label: "Production Error", name: "production_error" },
        {
            label: "Shopify Order ID", name: "shopify_order_id",
            options: {
                customBodyRender: (value) => {
                    return (
                        <Button sx={{ textTransform: 'unset' }}
                            onClick={() => {
                                window.open("https://admin.shopify.com/store/influxer-merch/orders/" + value)
                            }}
                        >
                            {value}
                        </Button>
                    );
                },
            }
        },

        { label: "Date", name: "time_ordered" },
        {
            label: "Actions",
            name: "actions",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const orderName = orders[convertSortedIndexNumber(tableMeta)].order_name;
                    const productionStatus = orders[convertSortedIndexNumber(tableMeta)].production_status;
                    const orderId = orders[convertSortedIndexNumber(tableMeta)].shopify_order_id;
                    return getActionButtons(productionStatus, orderName, orderId);
                }
            },
        }
    ];

    const options = {
        rowsPerPage: 10,
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : "No Records",
            },
        },
    };

    const modalColumns = [
        { name: "id", label: "Product ID" },
        { name: "frontAsset", label: "Front Asset" },
        { name: "backAsset", label: "Back Asset" },
        { name: "productMockup", label: "Product Mockup" },
        { name: "style", label: "Style" },
        { name: "color", label: "Color" },
        { name: "size", label: "Size" },
        { name: "sku", label: "SKU" },
        { name: "title", label: "Title" },
    ];

    const lineItemModalColumns = [

        { name: "variant_title", label: "Variant Title" },
        { name: "line_quantity", label: "Variant Quantity" },
        {
            name: "shopify_product_id", label: "Shopify Product ID",
            options: {
                customBodyRender: (value) => {
                    return (
                        <Button sx={{ textTransform: 'unset' }}
                            onClick={() => {
                                window.open("https://admin.shopify.com/store/influxer-merch/products/" + value)
                            }}
                        >
                            {value}
                        </Button>
                    );
                },
            }
        },
    ]

    const modalOptions = {
        selectableRows: 'none',
        responsive: 'vertical',
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 15],
        download: false,
        print: false,
        filter: false,
    };

    return (
        <Box sx={{ width: "90%", backgroundColor: "background.paper", marginTop: "1%" }} >
            <Box display="flex" >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingRight: '5px', paddingBottom: '10px' }}>
                            <DateTimePicker
                                label="Start Date"
                                views={['month', 'year', 'day']}
                                openTo="day"
                                value={startDate}
                                onChange={(newValue) => {
                                    setStartDate(newValue);
                                    setDateChanged(true);
                                }}
                                sx={{ margin: '10px' }}
                            />
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <DateTimePicker
                                label="End Date"
                                views={['month', 'year', 'day']}
                                openTo="day"
                                value={endDate}
                                onChange={(newValue) => {
                                    setEndDate(newValue);
                                    setDateChanged(true);
                                }}
                                sx={{ margin: '10px' }}
                            />
                        </span>
                    </div>
                </LocalizationProvider>
            </Box>
            {loading && <LinearProgress />}
            <MUIDataTable
                title={"Orders List"}
                options={options}
                columns={columns}
                data={orders}
            />
            <AreYouSure
                style={{
                    backgroundColor: "background.default",
                    margin: "auto",
                    marginTop: "10vh",
                    padding: "20px",
                    width: "30vw",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRadius: "5px",
                }}
                open={isAreYouSureModalOpen}
                message={"Are you Sure Do you want to send the Order to Ninja?"}
                handleClose={() => nullifyState()}
                yes={() => {
                    submitAreYouSure();
                }}
                no={() => nullifyState()}
            />
            <Modal
                open={isTestFulfillmentModalOpen}
                onClose={() => setIsTestFulfillmentModalOpen(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    p: 4,
                    backgroundColor: "background.paper",
                    margin: "auto",
                    marginTop: "10vh",
                    width: "65%",
                    maxHeight: "70vh",
                    overflowY: "auto",
                    borderRadius: 1,
                    boxShadow: 24,
                }}>
                    <Typography id="modal-title" variant="h6" component="h2" sx={{ marginBottom: "5px" }}>
                        Test Fulfillment Results
                    </Typography>
                    <MUIDataTable
                        title={"Products"}
                        options={modalOptions}
                        columns={modalColumns}
                        data={modalContent}
                        components={{ TableToolbar(props) { return null; }, }}
                    />
                </Box>
            </Modal>
            <LineItemsViewModal
                data={lineItemData}
                columns={lineItemModalColumns}
                options={modalOptions}
                isModalOpen={isLineItemsViewOpen}
                closeModal={onLineItemsViewClose}
            />
        </Box>
    );
};

export default AllOrdersTable;
