
import {
    Typography, Accordion, AccordionDetails, AccordionSummary
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MUIDataTable from "mui-datatables";

const RunnableDataView = ({ expanded, setExpanded, title, tableData, tableColumns, tableOptions }) => {
    return <Accordion
        expanded={expanded}
        onChange={(a, state) => {
            setExpanded((p) => state);
        }}
    >
        <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
        >
            <Typography sx={{ width: "50%", flexShrink: 0 }}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <MUIDataTable
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </AccordionDetails>
    </Accordion>

}
export default RunnableDataView;