import * as React from "react";
import { runHattrickOn } from "../../../API/hattrick";
import { useUserContext } from "../../../contexts/ProviderProvider";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
  Table,
  TableContainer,
  Typography,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
  Checkbox,
  Tooltip,
} from "@mui/material";
import useNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import LazyImage from "../../common/LazyImage";

const formatDateString = (inputString) => {
  const date = new Date(inputString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };

  return date.toLocaleString("en-US", options);
};

const HattrickProductReadyTable = ({ data, refresh, currentlyRunning }) => {
  const user = useUserContext();
  const notify = useNotification();
  const navigate = useNavigate();

  if (!data) {
    return (
      <Typography>
        No products ready for this school sport combination
      </Typography>
    );
  }

  const runHattrickOnProduct = (productId, rosterId) => {
    runHattrickOn(
      {
        running: [{ type_id: productId, roster_id: rosterId }],
        type: "products",
      },
      user
    )
      .then((response) => {
        refresh();
      })
      .catch((e) => {
        //console.log(e);
        refresh();
        notify("Failed to run hattrick on products");
      });
  };

  const isRunning = (row) => {
    return (
      currentlyRunning.filter((cr) => cr.type_id === row.ProductId).length > 0
    );
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Hint</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Sku Name</TableCell>
              <TableCell>Jersey Number</TableCell>
              <TableCell>Sport</TableCell>
              <TableCell>Front</TableCell>
              <TableCell>Back</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Run</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.ProductId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {row.message && row.message !== "" && (
                    <Tooltip
                      title={
                        <>
                          <p>{formatDateString(row.timestamp)}</p>
                          <p>{row.message}</p>
                        </>
                      }
                    >
                      <PriorityHighIcon color="error" />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    sx={{ textTransform: "unset" }}
                    onClick={() =>
                      navigate("/ballpark/athletes/" + btoa(row.RosterId))
                    }
                  >
                    {row.FullName}
                  </Button>
                </TableCell>
                <TableCell>{row.SkuName}</TableCell>
                <TableCell>{row.JerseyNumber}</TableCell>
                <TableCell>{row.Sport}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ padding: "25px" }}
                >
                  <Checkbox checked={row.FrontAsset ? true : false} />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ padding: "25px" }}
                >
                  <Checkbox checked={row.BackAsset ? true : false} />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ padding: "25px" }}
                >
                  {row.Photo ? (
                    <LazyImage
                      alt={"prooduct asset"}
                      style={{ maxHeight: "25px" }}
                      src={row.Photo}
                      onClick={() => {
                        window.open(row.photo, "_blank");
                      }}
                    />
                  ) : (
                    <Typography>No Mockup</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    disabled={isRunning(row)}
                    variant="contained"
                    onClick={() =>
                      runHattrickOnProduct(row.ProductId, row.RosterId)
                    }
                  >
                    Run
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HattrickProductReadyTable;
