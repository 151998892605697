import axios from "axios";
const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const getAllProductConfigurations = (user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(BALLPARK_BACKEND_BASE_URL + "productConfigurations", {
    headers,
  });
};

export const findProductMetadataMatching = (product, signal, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(
    BALLPARK_BACKEND_BASE_URL + "getAllMatchingProductMetadatas",
    product,
    { headers, signal: signal }
  );
};

export const postProductConfiguration = (config, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(
    BALLPARK_BACKEND_BASE_URL + "productConfigurations",
    config,
    { headers }
  );
};
