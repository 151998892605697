import * as React from "react";
import { runHattrickOn } from "../../../API/hattrick";
import { useUserContext } from "../../../contexts/ProviderProvider";
import {
  Table,
  TableContainer,
  Typography,
  Paper,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
} from "@mui/material";
import useNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";

const HattrickCollectionReadyTable = ({ data, refresh, currentlyRunning }) => {
  const user = useUserContext();
  const notify = useNotification();
  const navigate = useNavigate();

  if (!data) {
    return <Typography>No Collections to create !!</Typography>;
  }

  const runHattrickOnCollection = (collectionId, rosterId) => {
    runHattrickOn(
      {
        running: [{ type_id: collectionId, roster_id: rosterId }],
        type: "collections",
      },
      user
    )
      .then((response) => {
        refresh();
      })
      .catch((e) => {
        //console.log(e);
        refresh();
        notify("Failed to start hattrick on collection");
      });
  };

  const isRunning = (row) => {
    return (
      currentlyRunning.filter((cr) => cr.type_id === row.CollectionId).length >
      0
    );
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Jersey Number</TableCell>
              <TableCell>University</TableCell>
              <TableCell>Sport</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Run</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.RosterId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Button
                    onClick={() =>
                      navigate("/ballpark/athletes/" + btoa(row.RosterId))
                    }
                  >
                    {row.FullName}
                  </Button>
                </TableCell>
                <TableCell>{row.SkuName}</TableCell>
                <TableCell>{row.University}</TableCell>
                <TableCell>{row.Sport}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ padding: "25px" }}
                >
                  <img
                    alt={"prooduct asset"}
                    style={{ maxHeight: "100px" }}
                    src={row.Photo}
                    onClick={() => {
                      window.open(row.photo, "_blank");
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    disabled={isRunning(row)}
                    variant="contained"
                    onClick={() =>
                      runHattrickOnCollection(row.CollectionId, row.RosterId)
                    }
                  >
                    Run
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HattrickCollectionReadyTable;
