import * as React from 'react';
import { useUserContext } from '../../../../contexts/ProviderProvider';
import useNotification from "../../../../hooks/useNotification";

import { CircularProgress, Typography } from "@mui/material";
import MUIDataTable from 'mui-datatables';
import { getZeroOut, getZeroOutGS } from '../../../../API/roster';

const LicensedUni = ({ addToCardArray, setLoading }) => {

    const notify = useNotification();

    const user = useUserContext();

    const [loadingData, setLoadingData] = React.useState(true);
    const [socialUniversitiesNoIG, setSocialUniversitiesNoIG] = React.useState([]);
    const [socialUniversitiesSignUp, setSocialUniversitiesSignUp] = React.useState([]);
    const [socialUniversitiesNoSales, setSocialUniversitiesNoSales] = React.useState([]);

    React.useEffect(() => {
        setLoadingData(true);
        setLoading(true);
        getZeroOutGS(user)
            .then((response) => {
                console.log(response)
                setSocialUniversitiesNoIG(response.data.obj?.SocialUniversitiesNoIG ?? []);
                setSocialUniversitiesSignUp(response.data.obj?.SocialUniversitiesSignUp ?? []);
                setSocialUniversitiesNoSales(response.data.obj?.SocialUniversitiesNoSales ?? []);
                addToCardArray({
                    id: 'Make IG Priority',
                    panel: 'Instagram',
                    count: response.data.obj?.SocialUniversitiesNoIG?.length | 0,
                });
                addToCardArray({
                    id: 'Outreach',
                    panel: 'Instagram',
                    count: response.data.obj?.SocialUniversitiesSignUp?.length | 0
                });
                addToCardArray({
                    id: 'Paid ad attempt',
                    panel: 'Instagram',
                    count: response.data.obj?.SocialUniversitiesNoSales?.length | 0
                });
                setLoadingData(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log("Error for Licensed University: ", error);
                setLoading(false);
                notify('Unable to fetch licensed university data.');
                setLoadingData(false);
            })

    }, [user, notify]);

    const columns = [
        {
            name: "schoolName",
            label: "School Name",
        },
        {
            name: "signed_athletes",
            label: "Signed Athletes",
        },
    ];

    const options = {
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: loadingData ? (
                    <CircularProgress />
                ) : (
                    "No Records"
                ),
            },
        },
    }

    return (
        <>
            <div
                style={{
                    margin: "20px 10px"
                }}>
                {socialUniversitiesNoIG ?
                    <MUIDataTable
                        title={"Licensed University + Products + players signed + no Instagram made = make Instagram Priority"}
                        data={socialUniversitiesNoIG}
                        columns={columns}
                        options={options}
                    />
                    :
                    <Typography variant="h6">Data not found for Insta account priority</Typography>
                }</div>
            <div
                style={{
                    margin: "20px 10px"
                }}>
                {socialUniversitiesSignUp ?
                    <MUIDataTable
                        title={"Licensed university + products + IG made + less than 5 sign ups = outreach"}
                        data={socialUniversitiesSignUp}
                        columns={columns}
                        options={options}
                    />
                    :
                    <Typography variant="h6">Data not found for Insta account priority</Typography>
                }
            </div >
            <div
                style={{
                    margin: "20px 10px"
                }}>
                {socialUniversitiesNoSales ?
                    <MUIDataTable
                        title={"Licensed + products + signed players + Instagram Made + at least one player posted + no sales = paid ad attempt"}
                        data={socialUniversitiesNoSales}
                        columns={columns}
                        options={options}
                    />
                    :
                    <Typography variant="h6">Data not found for Paid ad attempt</Typography>
                }
            </div >
        </>
    );

}

export default LicensedUni;