import { ButtonGroup, Button } from "@mui/material";
import * as React from "react";

const AthleteProfileProductActions = ({ tableMeta, confirmAreYouSure }) => {
  const shopify_product_id = tableMeta.rowData[9];
  const state = tableMeta.rowData[4];
  return (
    <ButtonGroup variant="contained">
      {shopify_product_id ? (
        <Button
          variant="contained"
          onClick={() => {
            window.open(
              "https://admin.shopify.com/store/influxer-merch/products/" +
              shopify_product_id,
              "_blank"
            );
          }}
        >
          View
        </Button>
      ) : (
        "N/A"
      )}
      {shopify_product_id && state !== "Product Archived" ? (
        shopify_product_id && state !== "Product Active" ? (
          <Button
            sx={{ backgroundColor: "success.main" }}
            variant="contained"
            onClick={() => {
              confirmAreYouSure(
                "ACTIVE",
                tableMeta.currentTableData[tableMeta.rowIndex].data
              );
            }}
          >
            Post
          </Button>
        ) : shopify_product_id && state !== "Product Created" ? (
          <Button
            sx={{ backgroundColor: "info.main" }}
            variant="contained"
            onClick={() => {
              confirmAreYouSure(
                "DRAFT",
                tableMeta.currentTableData[tableMeta.rowIndex].data
              );
            }}
          >
            Draft
          </Button>
        ) : (
          "PRODUCT NOT CREATED"
        )
      ) : (
        "PRODUCT ARCHIVED"
      )}
      {state !== "Product Archived" ? (
        <Button
          sx={{ backgroundColor: "error.main" }}
          variant="contained"
          onClick={() => {
            confirmAreYouSure(
              "ARCHIVE",
              tableMeta.currentTableData[tableMeta.rowIndex].data
            );
          }}
        >
          Archive
        </Button>
      ) : (
        "N/A"
      )}
    </ButtonGroup>
  );
};

export default AthleteProfileProductActions;
