
import { useCallback, useState } from "react"
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { acceptApplication, getJobUsers, getUser } from "../../../API/marketplace.js"
import { TableRow, TableCell, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ServerSideTable, { BoolData } from "../../common/ServerSideTable";
import LazyImage from "../../common/LazyImage"
import AreYouSure from "../../common/AreYouSure.jsx"
import axios from "axios";

const JobUsers = ({ job }) => {

  const [acceptJobUser, setAcceptJobUser] = useState()

  const user = useUserContext()
  const notify = useNotification()
  const navigate = useNavigate()

  const [jobUsers, setJobUsers] = useState([])
  const [refresh, setRefresh] = useState(0)

  const handlePaginationStateUpdate = useCallback((axiosOptions, page, pageSize, search, filter, ordering) => {

    if (!job || job.id === undefined) { return new Promise((resolve, reject) => { resolve(true) }) }

    return getJobUsers(user, axiosOptions, page, pageSize, ordering, search, { ...filter, jobID: job.id })
      .then(response => {
        setJobUsers(Array.isArray(response.data.obj.JobUser) ? response.data.obj.JobUser : []);
        return response
      }).catch(e => {
        if (axios.isCancel(e)) {
          // not an error
        } else {
          notify("Failed to get job users")
          return e
        }
      })

  }, [user, job, refresh])


  const handleAcceptApplication = (jobUserId) => {
    setAcceptJobUser(jobUsers.filter(f => f.id === jobUserId)[0])
  }

  const handleAcceptAreYouSure = () => {
    const id = acceptJobUser.JobUser.id
    console.log("accepting application", acceptJobUser.JobUser.id)
    setAcceptJobUser()
    acceptApplication(user, id).then(response => {
      setRefresh(p => p + 1)
    }).catch(e => {
      notify("Failed to accept athlete for deal")
      setRefresh(p => p + 1)
    })
  }


  const options = {
    renderExpandableRow: (a, b, c) => {
      console.log("Expandable row", a, b, c)
      return (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={9}
          >
            <div>{JSON.stringify(jobUsers[b.dataIndex])}</div>
          </TableCell>
        </TableRow>
      );
    }
  }

  let columns = []

  if (jobUsers && jobUsers.length > 0) {
    const shape = jobUsers[0]
    columns = Object.keys(shape)
      .filter(s => !["description"].includes(s))
      .filter(s => !["JobUser"].includes(s))
      .map(s => ({ label: capitalize(s), name: s }))
  }

  columns.unshift({ label: "Status", name: "JobUser.status", options: {} })
  columns.unshift({ label: "Accepted", name: "JobUser.accepted", options: { customBodyRender: BoolData } })

  for (let i = 0; i < columns.length; i++) {
    if (jobUsers.length === 0) { continue }
    if (columns[i].name.toLowerCase().includes("photo") || columns[i].name.toLowerCase().includes("picture")) {
      columns[i].options = {
        customBodyRender: (value) => {
          return (
            <div>
              <LazyImage src={value} style={{ height: "50px" }}></LazyImage>
            </div>
          )
        }
      }
    }

    if (typeof jobUsers[0][columns[i].name] === "boolean") {
      columns[i].options = {
        ...columns[i].options,
        customBodyRender: BoolData
      }
    }
  }

  columns.unshift({
    name: "", label: "View", options: {
      customBodyRender: (value, tableMeta) => {
        console.log("button", tableMeta)
        if (tableMeta.rowData[1]) { // if accepted
          return (
            <Button variant="contained" onClick={() => { navigate("/ballpark/messaging/" + tableMeta.rowData[3] + "/" + job.id) }}>
              Visit-{tableMeta.rowData[2]}
            </Button>
          )
        } else if(tableMeta.rowData[2] != "Requested"){
          return <Button variant="contained" onClick={() => { handleAcceptApplication(tableMeta.rowData[3]) }}> Accept </Button>
        } else {
          return <></>
        }
      }
    }
  })




  return (
    <>
      <AreYouSure
      />

      <AreYouSure
        style={{
          backgroundColor: "background.default",
          margin: "auto",
          marginTop: "10vh",
          padding: "20px",
          width: "30vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "5px",
        }}
        open={acceptJobUser ? true : false}
        yes={handleAcceptAreYouSure}
        message={`Are you sure you want to accept ${acceptJobUser?.name} for this brand deal?`}
        no={() => { setAcceptJobUser(undefined) }}
        handleClose={() => setAcceptJobUser(undefined)}
      />
      <ServerSideTable
        title={"Campaign"}
        data={jobUsers}
        columns={columns}
        tableConfig={options}
        onTableChange={() => { }}
        paginatedRequestFunc={handlePaginationStateUpdate}
        hideColumns={["ID", "Account Type", "Is Citizen", "Account Permissions", "Creation Timestamp", "Push Notification Token", "Push Notification Type", "Push Notification Endpoint", "Push Notification Badge Count", "Stripe Customer ID", "Pay Pal ID", "Pay Pal Email", "Disabled", "Venmo User Name"]}
        initialSort={{ name: "JobUser.accepted", direction: "descending" }}
      />
    </>
  )
}

const capitalize = (key) => {
  // Handle specific cases first, such as abbreviations
  const specialCases = {
    "ID": "ID",
  };

  // Split camelCase or snake_case into words
  const words = key
    .replace(/([a-z])([A-Z])/g, '$1 $2')  // Split camelCase
    .replace(/_/g, ' ')                   // Replace underscores with spaces (if any)
    .split(' ');                          // Split into an array of words

  // Capitalize the first letter of each word
  const formattedWords = words.map(word => {
    if (specialCases[word.toUpperCase()]) {
      return specialCases[word.toUpperCase()];
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back into a single string
  return formattedWords.join(' ');
}

export default JobUsers;
