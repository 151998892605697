import { useState, useEffect } from "react";
import {
  useUserContext,
  usePermissionContext
} from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import {
  getSocialAccounts,
  updateSocialAccounts,
} from "../../../API/socialAccounts";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import Can from "../../common/Can";
import {
  CircularProgress,
  LinearProgress,
  TableCell, Button
} from "@mui/material";
import { ColoredSelectModifier } from "../../common/ColoredSelectModifier";
import { EditableTable } from "../../common/EditableTable";
import { getOptionColors } from "../../../utilities/utils";
import { useNavigate } from "react-router-dom";
import useCanUser from "../../../hooks/useCanUser";
import { convertSortedIndexNumber } from "../../../utilities/utils";

function snakeToCamel(str) {
  return str.replace(/_([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
}

function camelToSnake(str) {
  return str.replace(/[A-Z]/g, function (match) {
    return "_" + match.toLowerCase();
  });
}

const SocialAccountsTable = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vaList, setVAList] = useState([]);
  const [vaColorOptions, setVAColorOptions] = useState([]);
  const [changes, setChanges] = useState({});
  const [optionColors, setOptionsColors] = useState({});
  const [accountStatusTypes, setAccountStatusType] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const user = useUserContext();
  const notify = useNotification();
  const { actions, views } = usePermissionContext();

  const [columnVisibility, setColumnVisibility] = useState({
    "university_name": true,
    "employee_id": true,
    "intern_name": true,
    "ig_account_made": true,
    "ig_handle": true,
    "ig_password": true,
    "announcement_post_created": true,
    "alias_email": true,
    "master_email": true,
    "master_password": true,
    "port_number": true
  });

  const disabled = !useCanUser(actions.ALTER, [actions.SOCIALACCOUNTS]);

  const navigate = useNavigate();

  const redirect = (handle) => {
    if (handle.length > 0) {
      window.open("http://instagram.com/" + handle + "/", "_blank");
    } else {
      notify("No Instagram Account For Athlete");
    }
  };


  const setChangesFilter = (value, index, field) => {
    if (value) {
      setChanges(prevData => ({
        ...prevData,
        [index]: {
          ...prevData[index],
          [field]: value
        }
      }));
    } else {
      setChanges((prev) => {
        const newState = { ...prev };
        if (newState[index] === undefined) return prev;
        delete newState[index][field];
        return newState;
      });
    }
  };




  const getChangeValue = (index, field) => {
    return changes[index] ? changes[index][field] : changes[index];
  };

  const handleUpdate = () => {
    setLoading(true);
    var alterations = { schoolName: accounts[selectedIndex].university_name }
    Object.keys(changes[selectedIndex]).map((key) => {
      alterations[camelToSnake(key)] = changes[selectedIndex][key];
      if (camelToSnake(key) == "employee_id") {
        alterations["employee_university_id"] = accounts[selectedIndex].employee_university_id
      }
    })

    updateSocialAccounts(
      { ...alterations },
      user
    ).then((response) => {
      notify({
        msg: "Successfully updated social accounts",
        variant: "success",
      });
      setChanges(prevData => ({
        ...prevData,
        [selectedIndex]: {}
      }));

      setLoading(false);
      setSelectedIndex(-1);
      setIsModalOpen(false);
      props.setRefresh((p) => p + 1);
    }).catch((error) => {
      notify("Failed to update social accounts");
      setLoading(false);
      props.setRefresh((p) => p + 1);
    });
  }

  const updateAccounts = (alterations) => {
    setLoading(true);

    updateSocialAccounts(
      { ...alterations },
      user
    )
      .then((response) => {
        setLoading(false);
        notify({
          msg: "Successfully updated social accounts",
          variant: "success",
        });
        props.setRefresh((p) => p + 1);
      })
      .catch((error) => {
        console.log(error);
        notify("Failed to update social accounts");
        setLoading(false);
        props.setRefresh((p) => p + 1);
      });
  }

  useEffect(() => {
    setLoading(true);
    getSocialAccounts(user)
      .then((response) => {
        setAccounts(response.data.accounts);
        const employeeList = response.data.employees.map((employee) => employee.name)
        setEmployees(employeeList);
        const vaList = response.data.vas.map(((employee) => employee.name))
        setVAList(vaList);
        setVAColorOptions(getOptionColors(vaList));
        setLoading(false);
        setOptionsColors(getOptionColors(employeeList));
        setAccountStatusType(response.data.statusTypes);
      })
      .catch((error) => {
        console.log(error);
        notify("Failed to get Social Accounts");
        setLoading(false);
      });
  }, [props.refresh, user, notify]);

  const stageAlteration = (field, value, universityName, convertDate) => {

    if (field === "universityName" || field === "university_name") {
      return;
    }
    var alteration = { schoolName: universityName }
    if (convertDate) {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      let currentDate = `${year}-${month}-${day}`;
      alteration[camelToSnake(field)] = currentDate;
      if (!value) {
        alteration[camelToSnake(field)] = null;
      }
    }
    else { alteration[camelToSnake(field)] = value; }

    updateAccounts(alteration)
  };



  const bizDevIdMap = {};
  accounts?.forEach((account) => {
    bizDevIdMap[account.employee_id] = account.name;
  });

  accounts?.forEach((account) => {
    account.employee_id = bizDevIdMap[account.employee_id];
  });

  const columns = [
    {
      name: "university_name",
      label: "University Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button sx={{ textTransform: 'unset' }}
            onClick={() =>
              navigate(`/ballpark/schools/${btoa(value)}`)
            }
          >
            {value}
          </Button>
        ),
      },
    },
    {
      name: "employee_id",
      label: "Assigned to",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <ColoredSelectModifier
            label=""
            value={getChangeValue(convertSortedIndexNumber(tableMeta), "employee_id")}
            original={value}
            options={employees}
            setValue={(value) => {
              setChangesFilter(value, convertSortedIndexNumber(tableMeta), "employee_id");
              const updateDict = { "employee_id": value, "employee_university_id": accounts[convertSortedIndexNumber(tableMeta)].employee_university_id, schoolName: tableMeta.rowData[1] }
              updateAccounts(updateDict)
            }}
            optionColors={optionColors}
            permissions={[actions.SOCIALACCOUNTS]}
          />
        ),
      },
    },
    {
      name: "intern_name",
      label: "Assigned Intern",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <ColoredSelectModifier
            label=""
            value={getChangeValue(convertSortedIndexNumber(tableMeta), "intern_name")}
            original={value}
            options={vaList}
            setValue={(value) => {
              setChangesFilter(value, convertSortedIndexNumber(tableMeta), "intern_name");
              const updateDict = { "intern_name": value, "intern_employee_university_id": accounts[convertSortedIndexNumber(tableMeta)].intern_employee_university_id, schoolName: tableMeta.rowData[1] }
              updateAccounts(updateDict)
            }}
            optionColors={vaColorOptions}
            permissions={[actions.SOCIALACCOUNTS]}
          />
        ),
      },
    },
    {
      name: "ig_account_made",
      label: "IG Account Made",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <ColoredSelectModifier
            label=""
            value={getChangeValue(convertSortedIndexNumber(tableMeta), "ig_account_made")}
            original={value}
            options={Object.keys(accountStatusTypes)}
            setValue={(value) => {
              setChangesFilter(value, convertSortedIndexNumber(tableMeta), "ig_account_made");
              stageAlteration(
                "ig_account_made",
                value,
                tableMeta.rowData[1]
              );
            }}
            optionColors={accountStatusTypes}
            permissions={[actions.SOCIALACCOUNTS]}
          />
        ),
      },
    },
    {
      name: "ig_handle", label: "IG Handle",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {

          return (
            <Button sx={{ textTransform: 'unset' }}
              onClick={() => {
                navigator.clipboard.writeText(value)
                redirect(value)
              }
              }
            >
              {value}
            </Button>
          )
        },
      }
    },
    {
      name: "ig_password", label: "IG Password",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {

          return (
            <Button sx={{ textTransform: 'unset' }}
              onClick={() => {
                navigator.clipboard.writeText(value).then(() => {
                  notify({ msg: "Password Copied", variant: "success" })
                })
              }
              }
            >
              {value}
            </Button>
          )
        },
      }
      // options: {
      //   customBodyRender: (value, tableMeta) => {
      //     const id = convertSortedIndexNumber(tableMeta) + "ig_password";
      //     if (!value) {
      //       return <div>{value}</div>
      //     }
      //     return (
      //       <div>

      //         {visiblePasswords[id] ? value : '••••••••'}
      //         {visiblePasswords[id] ?
      //           <VisibilityOffIcon
      //             sx={{ paddingLeft: "5px", paddingTop: "5px", color: "primary.main" }}
      //             fontSize="small"
      //             onClick={() => togglePasswordVisibility(id)}
      //           /> :
      //           <VisibilityIcon
      //             sx={{ paddingLeft: "5px", paddingTop: "5px", color: "primary.main" }}
      //             fontSize="small"
      //             onClick={() => togglePasswordVisibility(id)}
      //           />
      //         }
      //         {/* <Button
      //           variant="contained"
      //           size="small"
      //           onClick={() => togglePasswordVisibility(id)}
      //           style={{ marginLeft: 8 }}
      //         >
      //           {visiblePasswords[id] ? 'Hide' : 'Show'}
      //         </Button> */}
      //       </div>
      //     );
      //   }
      // }
    },
    {
      name: "initial_posts",
      label: "Initial Posts",
      options: {
        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "initialPosts",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />
          )
        }
      }
    },
    {
      name: "announcement_post_created",
      label: "Announcement Post Created",
      options: {
        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "announcementPostCreated",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />)

        },

      },
    },
    {
      name: "twitter_account_made",
      label: "Twitter Account Made",
      options: {

        customBodyRender: (value, tableMeta, updateValue) => (
          <ColoredSelectModifier
            label=""
            value={getChangeValue(convertSortedIndexNumber(tableMeta), "twitter_account_made")}
            original={value}
            options={Object.keys(accountStatusTypes)}
            setValue={(value) => {
              setChangesFilter(value, convertSortedIndexNumber(tableMeta), "twitter_account_made");
              stageAlteration(
                "twitter_account_made",
                value,
                tableMeta.rowData[1]
              );
            }}
            optionColors={accountStatusTypes}
            permissions={[actions.SOCIALACCOUNTS]}
          />
        ),
      },
    },
    {
      name: "twitter_username",
      label: "Twitter Username",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {

          return (
            <Button sx={{ textTransform: 'unset' }}
              onClick={() => {
                navigator.clipboard.writeText(value).then(() => {
                  notify({ msg: "Twitter Username Copied", variant: "success" })
                })
              }
              }
            >
              {value}
            </Button>
          )
        },
      }
    },
    {
      name: "twitter_password",
      label: "Twitter Password",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {

          return (
            <Button sx={{ textTransform: 'unset' }}
              onClick={() => {
                navigator.clipboard.writeText(value).then(() => {
                  notify({ msg: "Twitter Password Copied", variant: "success" })
                })
              }
              }
            >
              {value}
            </Button>
          )
        },
      }
    },
    {
      name: "initial_twitter_post",
      label: "Initial Twitter Posts",
      options: {

        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "initial_twitter_post",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />
          )
        }
      },
    },
    {
      name: "ig_logo_made",
      label: "IG Logo Made",
      options: {

        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "ig_logo_made",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />
          )
        }
      },
    },
    {
      name: "phone_number",
      label: "Phone Number",
      options: {

      }
    },
    {
      name: "ig_and_meta",
      label: "IG X Meta",
      options: {

        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "ig_and_meta",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />
          )
        }
      },
    },
    {
      name: "fb_and_meta",
      label: "FB X Meta",
      options: {

        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "fb_and_meta",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />
          )
        }
      },
    },
    {
      name: "fb_profile_photo",
      label: "FB Profile Photo",
      options: {

        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "fb_profile_photo",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />
          )
        }
      },
    },
    {
      name: "fb_profile_description", label: "FB Profile Description",
    },
    {
      name: "fb_bio",
      label: "FB Bio",
      options: {

        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "fb_bio",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />
          )
        }
      },
    },
    {
      name: "fb_cover_photo",
      label: "FB Cover Photo",
      options: {

        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "fb_cover_photo",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />
          )
        }
      },
    },
    {
      name: "fb_link_to_ig",
      label: "FB Link to IG",
      options: {

        customBodyRender: (value, tableMeta) => {
          const boolValue = value ? true : false
          return (<TrueFalseCheckbox
            checked={boolValue}
            interact={() =>
              stageAlteration(
                "fb_link_to_ig",
                !boolValue,
                tableMeta.rowData[1],
                true
              )
            }
            disabled={disabled}
          />
          )
        }
      },
    },

    {
      name: "alias_email", label: "Alias Gmail",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {

          return (
            <Button sx={{ textTransform: 'unset' }}
              onClick={() => {
                navigator.clipboard.writeText(value).then(() => {
                  notify({ msg: "Alias Gmail Copied", variant: "success" })
                })
              }
              }
            >
              {value}
            </Button>
          )
        },
      }
    },
    {
      name: "master_email", label: "Master Email",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {

          return (
            <Button sx={{ textTransform: 'unset' }}
              onClick={() => {
                navigator.clipboard.writeText(value).then(() => {
                  notify({ msg: "Master Email Copied", variant: "success" })
                })
              }
              }
            >
              {value}
            </Button>
          )
        },
      }
    },
    {
      name: "master_password", label: "Master Password",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {

          return (
            <Button sx={{ textTransform: 'unset' }}
              onClick={() => {
                navigator.clipboard.writeText(value).then(() => {
                  notify({ msg: "Master Password Copied", variant: "success" })
                })
              }
              }
            >
              {value}
            </Button>
          )
        },
      }
    },
    {
      name: "domain_name", label: "Domain Name",
      options: {

      }
    },
    {
      name: "portal_password", label: "Portal Password",
      options: {

      }
    },
    {
      name: "port_number", label: "Port Number",
    },
  ];


  const editOptions = {
    "employee_id": { label: "Assigned to", "type": "Skip", "options": employees, optionColors: optionColors },
    "ig_account_made": { label: "IG Account Made", "type": "Skip", "options": Object.keys(accountStatusTypes), optionColors: accountStatusTypes },
    "ig_handle": { label: "IG Handle", "type": "Text" },
    "ig_password": { label: "IG Password", "type": "Text", style: { "marginTop": "10px", "paddingBottom": "10px" }, borderColor: "primary.main" },
    "initial_posts": { label: "Initial IG Post", "type": "Skip" },
    "announcement_post_created": { label: "Announcement Post Created", "type": "Skip" },
    "ig_logo_made": { label: "IG Logo Made", "type": "Skip" },
    "phone_number": { label: "Phone Number", "type": "Text" },
    "ig_and_meta": { label: "IG X Meta", "type": "Skip" },
    "fb_and_meta": { label: "FB X Meta", "type": "Skip" },
    "twitter_account_made": { label: "Twitter Account made", "type": "Select", "options": Object.keys(accountStatusTypes), optionColors: accountStatusTypes },
    "twitter_username": { label: "Twitter Handle", "type": "Text" },
    "twitter_password": { label: "Twitter Password", "type": "Text", style: { "marginTop": "10px", "paddingBottom": "10px" } },
    "initial_twitter_post": { label: "Initial Twitter Post", "type": "Skip" },
    "fb_profile_photo": { label: "FB Profile Photo", "type": "Skip" },
    "fb_bio": { label: "FB Bio", "type": "Skip" },
    "fb_cover_photo": { label: "FB Cover Photo", "type": "Skip" },
    "fb_link_to_ig": { label: "FB Link to IG", "type": "Skip" },
    "fb_profile_description": { label: "FB Profile Description", "type": "Text" },
    "alias_email": { label: "Alias Email", "type": "Text" },
    "master_email": { label: "Master Email", "type": "Text" },
    "master_password": { label: "Master Password", "type": "Text", style: { "marginTop": "10px", "paddingBottom": "10px" } },
    "domain_name": { label: "Domain Name", "type": "Text" },
    "portal_password": { label: "Portal Password", "type": "Text" },
    "port_number": { label: "Port Number", "type": "Text" }
  }

  columns.forEach((col) => {
    col.options = col.options || {};
    col.options.display = columnVisibility[col.name] ? 'true' : 'false';
  });


  const options = {
    download: false,
    print: false,
    rowsPerPage: window.rowsPerPage,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "No Open Roster Records for that School / Sport :("
        ),
      },
    },
    onColumnViewChange: (changedColumn, action) => {
      setColumnVisibility(prevState => ({
        ...prevState,
        [changedColumn]: action === 'add'
      }));
    }
  };



  return (

    <div style={{ width: "100%" }}>
      {loading && <LinearProgress />}
      <EditableTable
        tableOptions={options}
        tableColumns={columns}
        data={accounts}
        title={"Social Accounts"}
        editOptions={editOptions}
        editTitle={"Edit Social Account"}
        tableUpdates={changes}
        setTableUpdates={setChanges}
        handleSave={handleUpdate}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        isEditable={!disabled}
      />
    </div>

  );
};

export default SocialAccountsTable;
