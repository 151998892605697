import { useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Typography,
  LinearProgress,
  CircularProgress,
  Button
} from "@mui/material";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import AreYouSure from "../../common/AreYouSure";
import {
  useUserContext,
  useSchoolContext
} from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { getTransfers, retire, takeLive, updateTransferCheckBackDate, updateTransferNotes, updateTransferReason } from "../../../API/transferPortal";
import { updateMerchRosterTransfer } from "../../../API/roster";
import SplitButton from "../../common/SplitButton";
import Notes from "./Notes";
import EditModal from "./EditModal"
import DatePicker from "../../common/DatePicker";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

const isTodayJS = (date) => {
  return dayjs(date).isSame(dayjs(), 'day')
}

const ExpandableRow = ({ data, notify, reload, user, updateReason }) => {
  const [updatingNotes, setUpdatingNotes] = useState(false)

  const rows = data.TransferPortals.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  const [checkBack, setCheckBack] = useState(dayjs(rows[0].checkBack))

  const handleDateChange = (newValue) => {
    const centralTimeDate = dayjs.tz(newValue, 'America/Chicago').startOf('day');
    setCheckBack(centralTimeDate);
  };

  useEffect(() => {
    if (!dayjs(checkBack).isValid() || !checkBack || checkBack == null || !rows[0].idTransferPortal || dayjs(rows[0].checkBack).isSame(dayjs(checkBack))) { // dont trigger a checkup on today
      console.log("no need to push update for date", checkBack, rows[0].checkBack)
      return
    }

    console.log("Updating checkback for ", rows[0].idTransferPortal, " with ", checkBack, " as ", dayjs(checkBack).utc().format(), " which is different from ", rows[0].checkBack)

    updateTransferCheckBackDate(user, rows[0].idTransferPortal, dayjs(checkBack).utc().format())
      .then(resp => {
        notify({ msg: "Successfully updated check back date", variant: "success" })
        reload()
      })
      .catch(e => {
        notify("Failed to update check back date")
        reload()
      })
  }, [checkBack])

  const columns = [
    { name: "old_university", label: "Old School", options: {} },
    { name: "reason", label: "Reason", options: {} },
    {
      name: "modified_at", label: "Date of Transfer", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          new Date(value).toLocaleDateString()
        ),
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value && <KeithCheckButtons changeReason={(reason) => updateReason(data.latestTransferId, reason)} />}</>
        }
      }
    },
    {
      name: "notes",
      label: "Has notes",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value !== "" && value !== null} disabled />
        ),
      },
    },
  ];

  const updateNotes = (id, notes) => {
    setUpdatingNotes(true)
    updateTransferNotes(user, id, notes)
      .then((response) => {
        notify({ msg: 'Updated notes', variant: 'success' });
        reload()
        setUpdatingNotes(false);
      })
      .catch((error) => {
        console.log('Error updating notes', error);
        notify('Unable to update notes');
        reload()
        setUpdatingNotes(false);
      })
  };

  const options = {
    filter: true,
    sortOrder: { name: "selected", direction: 'desc' },
    selectableRows: "none",
    selectableRowsHeader: false,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      return (null);
    },
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta, a) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            {updatingNotes && <LinearProgress />}
            <Notes
              id={rows[rowMeta.dataIndex].idTransferPortal}
              disabled={rowMeta.dataIndex}
              initialValue={rows[rowMeta.dataIndex].notes || ""}
              updateNotes={updateNotes}
            />
          </TableCell>
        </TableRow>
      );
    },
  };

  return (
    <div
      style={{
        margin: "10px 10px"
      }}>
      {dayjs(checkBack).isValid() || isTodayJS(checkBack) ?
        <DatePicker date={checkBack} setDate={handleDateChange} title="Check Back" /> :
        <Button variant="contained" onClick={() => setCheckBack(dayjs().tz('America/Chicago').startOf('day').add(14, 'day'))}>Add Check Back Date</Button>}
      <MUIDataTable
        title={"Transfer History"}
        data={rows}
        columns={columns}
        options={options}
      />
    </div >
  );
};


const TransferPortal = (props) => {
  const [submit, setSubmit] = useState(undefined);
  const [transfers, setTransfers] = useState();
  const [loadingTransfers, setLoadingTransfers] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [reload, setReload] = useState(1);
  const [updatingReason, setUpdatingReason] = useState(undefined);
  const schoolOptions = useSchoolContext().schools.map((u) => u.schoolName);

  const user = useUserContext();
  const notify = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return;
    }

    getTransfers(user)
      .then((response) => {
        const flattenedData = response.data.map((obj) => {
          const latestTransfer = obj.TransferPortals.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at))[0];

          console.log("latest transfer", latestTransfer)

          latestTransfer.action = latestTransfer?.reason.includes("Keith Check")

          return {
            ...obj,
            is_expired: latestTransfer?.is_expired,
            reason: latestTransfer?.reason,
            hasNote: latestTransfer?.notes && latestTransfer?.notes.length > 0,
            latestTransferId: latestTransfer.idTransferPortal
          };
        });
        setTransfers(flattenedData);
        setLoadingTransfers(false);
      })
      .catch((error) => {
        console.log('Error fetching transfers', error);
        notify('Unable to fetch transfers')
        setLoadingTransfers(false);
      })

  }, [user, reload, notify]);

  const handleTakeLive = (row) => {
    const athlete_id = row.rowData[6]

    // console.log('Take Live', athlete_id)

    takeLive(user, athlete_id)
      .then((response) => {
        notify({
          msg: `Took live transfer`,
          variant: "success",
        });
        setReload((p) => p + 1);
        setLoadingTransfers(false);
      })
      .catch((error) => {
        console.error(error);
        notify(`Failed to take live roster record`);
        setReload((p) => p + 1);
        setLoadingTransfers(false);
      });
  };

  const handleRetire = (row) => {

    const athlete_id = row.rowData[6]

    const athlete = transfers.filter((transfer) => transfer.idAthleteMerchInfo === athlete_id)[0]

    retire(user, athlete)
      .then((response) => {
        notify({
          msg: `Retired roster record`,
          variant: "success",
        });
        setReload((p) => p + 1);
        setLoadingTransfers(false);
      })
      .catch((error) => {
        console.error(error);
        notify(`Failed to retire roster record`);
        setReload((p) => p + 1);
        setLoadingTransfers(false);
      });
  };

  const handleEdit = (id, reason, school) => {
    var roster = transfers.filter((t) => t.idAthleteMerchInfo === id)[0]

    // console.log('Edit', roster, id, reason, school);
    setUpdatingReason(undefined);

    if (reason !== roster.reason) {
      const latestTransfer = roster.TransferPortals.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at))[0];
      updateReason(latestTransfer.idTransferPortal, reason);
    }

    if (school !== roster.university) {
      delete (roster.reason);
      delete (roster.TransferPortals);
      roster.university = school;
      updateMerchRosterTransfer(user, roster)
        .then((response) => {
          notify({
            msg: `Updated transfer`,
            variant: "success",
          });
          setReload((p) => p + 1);
          setLoadingTransfers(false);
        })
        .catch((error) => {
          console.error(error);
          notify(`Failed to update transfer`);
          setReload((p) => p + 1);
          setLoadingTransfers(false);
        });
    }
  };

  const handleSubmit = () => {

    // console.log('Submit', submit.rsn)
    setLoadingTransfers(true);

    switch (submit.rsn) {
      case "Take Live":
        handleTakeLive(submit.row);
        break;
      case "Retire":
        handleRetire(submit.row);
        break;
      default:
        console.log('Unknown submission reason, add a case');
        break;
    }

    setSubmit(undefined);
  };

  const updateReason = (id, reason) => {
    setLoadingTransfers(true)

    updateTransferReason(user, id, reason)
      .then((response) => {
        // console.log(response.data)
        notify({ msg: 'Updated reason', variant: 'success' });
        setReload((p) => p + 1);
        setLoadingTransfers(false);
      })
      .catch((error) => {
        console.log('Error updating reason', error);
        notify('Unable to update reason');
        setReload((p) => p + 1);
        setLoadingTransfers(false);
      })
  };

  const buildMessage = () => {
    return (`Are you sure you want to ${submit.rsn.toLowerCase()} ${submit.row.rowData[0]}`)
  }

  const splitButtonOptions = (currentReason) => {
    return [
      {
        text: 'Take Live',
        key: 'Take Live',
        disabled: true,
        onClick: (args) => setSubmit({ rsn: "Take Live", row: args })
      },
      {
        text: 'Edit',
        key: 'Edit',
        disabled: true,
        onClick: (args) => setUpdatingReason(args)
      },
      {
        text: 'Retire',
        key: 'Retire',
        onClick: (args) => setSubmit({ rsn: "Retire", row: args })
      },
    ].filter((o) => {
      // if the current reason is "Transfer", dont allow them to be retired, only take live and edit
      if (currentReason === "Transfer" && o.key === "Retire") {
        return false
      }
      return true
    });
  }

  /*
  * 
  * When adding columns be sure to update every occurence of rowData[x]
  * 
  */
  const columns = [
    {
      name: "full_name",
      label: "Full Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button sx={{ textTransform: 'unset' }}
            onClick={() =>
              navigate(`/ballpark/athletes/${btoa(tableMeta.rowData[6])}`)
            }
          >
            {value}
          </Button>
        ),
      },
    },
    { name: "reason", label: "Reason", options: {} },
    { name: "university", label: "School", options: {} },
    { name: "sport", label: "Sport", options: {} },
    { name: "jersey_number", label: "Jersey Number", options: {} },
    {
      name: "signed",
      label: "Signed",
      options: {
        filterList: [true],
        customFilterListOptions: {
          render: (v) => (v ? "Has Signed" : "Not Signed"),
        },
        filterOptions: {
          renderValue: (v) => (v ? "Has Signed" : "Not Signed"),
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
      },
    },
    {
      name: "idAthleteMerchInfo",
      label: "roster_id",
      options: {
        display: 'excluded'
      },
    },
    {
      name: "is_expired",
      label: "Expired",
      options: {
        display: 'excluded',
        filterList: [false],
        customFilterListOptions: {
          render: (v) => (v ? "Expired" : "Not Expired"),
        },
        filterOptions: {
          renderValue: (v) => (v ? "Expired" : "Not Expired"),
        },
      },
    },
    {
      name: "hasNote",
      label: "Has Note",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <TrueFalseCheckbox checked={value} disabled />
        }
      }
    },
    {
      name: "checkBack",
      label: "Check Back",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const transferHistory = transfers[tableMeta.currentTableData[tableMeta.rowIndex].index].TransferPortals

          transferHistory.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          })

          const lastDate = transferHistory[0].checkBack
          return <div>{lastDate}</div>
        }
      }
    },
    {
      name: "action",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <SplitButton
            options={tableMeta.rowData[5] ? splitButtonOptions(tableMeta.rowData[1]) : splitButtonOptions(tableMeta.rowData[1]).slice(1)}
            loading={loadingTransfers}
            onClickArgs={tableMeta}
          />
        ),
      }
    }
  ];

  const options = {
    filter: true,
    sortOrder: { name: "selected", direction: 'desc' },
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: rowsPerPage,
    expandableRows: true,
    onChangeRowsPerPage: (num) => setRowsPerPage(num),
    renderExpandableRow: (rowData, rowMeta, a) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandableRow
              user={user}
              data={transfers[rowMeta.dataIndex]}
              notify={notify}
              reload={() => setReload(p => p + 1)}
              updateReason={(id, reason) => { updateReason(id, reason) }}
            />
          </TableCell>
        </TableRow>
      );
    },
    textLabels: {
      body: {
        noMatch: loadingTransfers ? (
          <CircularProgress />
        ) : (
          "No transfers"
        ),
      },
    },
  };

  return (
    <div>
      <Typography variant="h1">Transfer Portal</Typography>
      {loadingTransfers && <LinearProgress />}

      {transfers &&
        <div
          style={{
            margin: "10px 10px"
          }}>
          <MUIDataTable
            title={"Athletes"}
            data={transfers}
            columns={columns}
            options={options}
          />
        </div>
      }
      {updatingReason && (<EditModal
        style={{
          backgroundColor: "background.default",
          margin: "auto",
          marginTop: "10vh",
          padding: "20px",
          width: "40vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "5px",
        }}
        object={{ "university": updatingReason.rowData[2], "id": updatingReason.rowData[6] }}
        schoolOptions={schoolOptions}
        reason={
          ['Recruit',
            'Keith Check',
            'Duplicate Signup'].includes(updatingReason.rowData[1])
        }
        open={updatingReason !== undefined}
        confirmTransfer={(id, reason, school) => {
          setLoadingTransfers(true);
          handleEdit(id, reason, school);
        }}
        close={() => {
          setUpdatingReason(undefined);
        }} />)}
      {submit && (
        <AreYouSure
          style={{
            backgroundColor: "background.paper",
            margin: "auto",
            marginTop: "10vh",
            padding: "20px",
            width: "30vw",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "5px",
          }}
          open={submit !== undefined}
          message={buildMessage()}
          yes={() => handleSubmit()}
          no={() => setSubmit(undefined)}
        />
      )}
    </div>
  );
};

const KeithCheckButtons = ({ changeReason }) => {
  return <SplitButton options={[
    { key: "Keith Check - School Contacted", text: "School Contacted", disabled: false, onClick: () => { changeReason("Keith Check - School Contacted") } },
    { key: "Keith Check - School Responded", text: "School Responded", disabled: false, onClick: () => { changeReason("Keith Check - School Responded") } },
  ]} />
}

export default TransferPortal;
