import * as React from "react";
import {
  useUserContext,
  useUserSchools,
} from "../../../contexts/ProviderProvider";
import {
  LinearProgress,
  setRef,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";

import { getMerchRosterBySchool } from "../../../API/roster";
import SocialTable from "./SocialTable";
import { getCountRosterWhereNotPostedByUniversity } from "../../../API/roster";
import useNotification from "../../../hooks/useNotification";
import { getInterns } from "../../../API/employee";
import MUIDataTable from "mui-datatables";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";

const SocialTracker = (props) => {
  const userSchools = useUserSchools();
  const user = useUserContext();
  const notify = useNotification();

  const [interns, setInterns] = React.useState({}); // map of university to boolean
  const [unPostedCount, setUnPostedCount] = React.useState({});
  const [noGraphics, setNoGraphics] = React.useState({});
  const [notAddedOnSite, setNotAddedOnSite] = React.useState({});

  const [refresh, setRefresh] = React.useState(0);

  const [loadingStateData, setLoadingStateData] = React.useState({});
  const [accordionState, setAccordionState] = React.useState({});

  const [expandedRows, setExpandedRows] = React.useState([]);
  const [expandedRowCount, setExpandedRowCount] = React.useState(0);

  const columns = [
    {
      label: "University",
      name: "university",
    },
    {
      label: "Has Intern",
      name: "hasIntern",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <TrueFalseCheckbox disabled checked={value} />
        }

      }
    },
    {
      label: "Num Not Posted",
      name: "numNotPosted",
      options: {
        sortDirection: 'desc',
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: expandedRows.includes(tableMeta.currentTableData[tableMeta.rowIndex].index) ? 'none' : 'block' }}>
              {value}
            </div>)
        }
      }
    },
    {
      label: "Num Not On Site",
      name: "numNotOnSite",
      options: { display: false },
    },
  ]


  const handleRowExpandToggle = (rowIndex) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(rowIndex)
        ? prevExpandedRows.filter((index) => index !== rowIndex)
        : [...prevExpandedRows, rowIndex]
    );
  };

  const reloadCount = (school) => {

    setUnPostedCount(() => {
      const updated = { ...unPostedCount };
      updated[school] -= 1;
      return updated
    });

  }


  React.useEffect(() => {
    if (!user) {
      return;
    }

    getInterns(user)
      .then((response) => {
        const res = response.data.interns.reduce((acc, obj) => {
          acc[obj.university] = true;
          return acc;
        }, {});
        setInterns(res);
      })
      .catch((error) => {
        notify("Error fetching interns");
      });

    getCountRosterWhereNotPostedByUniversity(user)
      .then((response) => {
        console.log("USER TOKEN", user.access);
        const result = response.data.reduce(
          (acc, obj) => {
            return {
              unPostedCount: {
                ...acc.unPostedCount,
                [obj["university"]]: obj.haveAssetNotPosted,
              },
              noGraphics: {
                ...acc.noGraphics,
                [obj["university"]]: obj.needAssetNotPosted,
              },
              noAddedOnSite: {
                ...acc.noAddedOnSite,
                [obj["university"]]: obj.needAddedOnSite,
              },
            };
          },
          { unPostedCount: {}, noGraphics: {} }
        );
        setUnPostedCount(result.unPostedCount);
        setNoGraphics(result.noGraphics);
        setNotAddedOnSite(result.noAddedOnSite);
      })
      .catch((error) => {
        notify("Failed to get unposted and needs graphics counts");
        setUnPostedCount([]);
        setNoGraphics([]);
      });
  }, [user, refresh, notify]);


  const toggleLoading = (school) => {
    setLoadingStateData((prev) => {
      const updated = { ...prev };
      updated[school] = !updated[school];
      return updated;
    });
  };

  const toggleLoadingSpecifyState = (school, isLoading) => {
    setLoadingStateData((prev) => {
      const updated = { ...prev };
      //console.log("marking" + school + " as " + isLoading);
      updated[school] = isLoading;
      return updated;
    });
  };

  const data = React.useMemo(() => {
    console.log("running memo")
    return userSchools.map(schoolName => {
      return {
        "university": schoolName,
        "hasIntern": interns[schoolName],
        "numNotPosted": unPostedCount[schoolName],
        "numNotOnSite": notAddedOnSite[schoolName],
      }
    })
  }, [userSchools, interns, unPostedCount, notAddedOnSite])


  const options = {
    rowsPerPage: 20,
    expandableRows: true,
    selectableRows: "none",
    renderExpandableRow: (rowData, rowMeta) => {

      return (
        < TableRow >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <ExpandedRow s={rowData[0]} />
          </TableCell>
        </TableRow >
      )
    },
    onRowExpansionChange: (curExpandedRows, allExpandedRows, rowsExpanded, rowsCollapsed) => {
      console.log(curExpandedRows, allExpandedRows)
      const expandedRowIndexes = allExpandedRows.map(row => row.dataIndex);
      setExpandedRows(expandedRowIndexes);
      if (expandedRowCount > allExpandedRows.length) {
        console.log("expanded")
        setRefresh(refresh + 1)
      }
      setExpandedRowCount(allExpandedRows.length)
    }
  }


  if (!user) {
    return <></>;
  }

  return (
    <div style={{ width: "95%", padding: "5% 1%" }}>
      <MUIDataTable columns={columns} data={data} options={options} />
    </div>
  );
};

const ExpandedRow = ({ s }) => {

  return (
    <div>
      <SocialTable
        school={s}
      />

    </div>)
}



function customSort(a, b, unPostedCount) {
  const countA = unPostedCount[a];
  const countB = unPostedCount[b];

  if (countA === undefined && countB === undefined) {
    return 0;
  }
  if (countA === undefined) {
    return 1;
  }
  if (countB === undefined) {
    return -1;
  }

  return countB - countA;
}


export default SocialTracker;
