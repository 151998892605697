import { useState, useEffect } from "react";

import { useUserContext } from "../../../../contexts/ProviderProvider.jsx";
import { getUnmatchedCountForUniversities } from "../../../../API/roster.js";

import { Typography } from "@mui/material";
import MUIDataTable from 'mui-datatables';
import useNotification from "../../../../hooks/useNotification.jsx";

const Matcher = ({ addToCardArray, setLoading }) => {

    const [schoolCounts, setSchoolCounts] = useState([]);

    const notify = useNotification();

    const user = useUserContext();

    useEffect(() => {
        if (!user) {
            return;
        }
        setLoading(true);
        getUnmatchedCountForUniversities(user)
            .then((response) => {
                const filteredResponse = response.data.filter((sc) => sc.licenseStatus === "Approved");
                setSchoolCounts(filteredResponse);
                setLoading(false);
                addToCardArray({
                    id: 'Matcher',
                    panel: 'Matcher',
                    count: filteredResponse.length
                })
            })
            .catch((error) => {
                console.error(error);
                notify("Failed to get Matcher");
                setLoading(false);
                setSchoolCounts([]);
            });
    }, [user, notify]);

    const columns = [
        {
            name: "AthleteCount",
            label: "Athlete Count",
        },
        {
            name: "university",
            label: "University",
        },
    ];

    const options = {
        selectableRows: 'none'
    }

    return (
        <div
            style={{
                margin: "20px 10px"
            }}>
            {schoolCounts ?
                <MUIDataTable
                    data={schoolCounts}
                    columns={columns}
                    options={options}
                />
                :
                <Typography variant="h6">Data not found for Grand Slam</Typography>
            }
        </div>
    )

}

export default Matcher;