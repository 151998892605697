import { useRoleContext, usePermissionContext } from "../contexts/ProviderProvider";

const useCanUser = (action, items) => {
  const { role } = useRoleContext()
  const { permission } = usePermissionContext();

  if (!action || !items ) {
    return false;
  }

  if (role === "Owner") {
    return true;
  }

  var can = false;
  try {
    items.forEach((item) => {
      can = can || permission[action][item];
    });
    // console.log("Can: final can", can);
  } catch (e) {
    // console.error("Can: error", e, permission, role, action, items);
    return false;
  }

  // console.log("final decision", can ? true : false)
  return can ? true : false;
};

export default useCanUser;
