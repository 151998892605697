import * as React from "react";
import * as Theme from "../contexts/ThemeContext";
import { CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { SnackbarProvider } from "notistack";
import "../App.css";

const UserContext = React.createContext();
const RoleContext = React.createContext({ role: "", setRole: () => { } });
const PermissionContext = React.createContext({
  permission: {},
  availableRoles: [],
  actions: {},
  views: {}
});
const SchoolContext = React.createContext({ schools: [], setSchool: () => { } });
const UserSchoolsContext = React.createContext([]);
const GlobalThemeContext = React.createContext({
  theme: {},
  setTheme: () => { },
  isNavOpen: true,
  setIsNavOpen: () => { }
});

export const useUserContext = () => React.useContext(UserContext);
export const useRoleContext = () => React.useContext(RoleContext);
export const usePermissionContext = () => React.useContext(PermissionContext);
export const useSchoolContext = () => React.useContext(SchoolContext);
export const useUserSchools = () => React.useContext(UserSchoolsContext);
export const useGlobalThemeContext = () => React.useContext(GlobalThemeContext);

const ContextProvider = (props) => {
  return (
    <SnackbarProvider classes={{
      containerRoot: 'customSnackbar',
    }}>
      <GlobalThemeContext.Provider
        value={{ theme: props.theme, setTheme: props.setTheme, isNavOpen: props.isNavOpen, setIsNavOpen: props.setIsNavOpen }}
      >
        <ThemeProvider
          theme={
            props.theme === "theme-light" ? Theme.lightTheme : Theme.darkTheme
          }
        >
          <CssBaseline />
          <UserContext.Provider value={props.user}>
            {props.role && props.permission && props.role !== "New Account" ? (
              <RoleContext.Provider
                value={{ role: props.role, setRole: props.setRole }}
              >
                <PermissionContext.Provider value={{
                  permission: props.permission,
                  availableRoles: props.availableRoles,
                  actions: props.actions,
                  views: props.views
                }}>
                  <SchoolContext.Provider
                    value={{
                      schools: props.schools,
                      setSchools: props.setSchools,
                    }}
                  >
                    <UserSchoolsContext.Provider value={props.userSchools}>
                      {props.children}
                    </UserSchoolsContext.Provider>
                  </SchoolContext.Provider>
                </PermissionContext.Provider>
              </RoleContext.Provider>
            ) : (
              <div
                style={{
                  width: "100%",
                  padding: "50px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    width: "25%",
                    animation: `spin 10s linear infinite`,
                  }}
                  alt=''
                  src="/rotatelogo.png"
                />
                <Typography>Please wait, verifying your identity!</Typography>
              </div>
            )}
          </UserContext.Provider>
        </ThemeProvider>
      </GlobalThemeContext.Provider>
    </SnackbarProvider>
  );
};

export default ContextProvider;
