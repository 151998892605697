import dayjs from 'dayjs';
import pako from 'pako';

export const decompressData = (response) => {
  const decompressedData = pako.ungzip(new Uint8Array(response.data.data), { to: 'string' });
  return JSON.parse(decompressedData);
}

export const formatDateTime = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const date = new Date(dateTimeString);
  return date.toLocaleString("en-US", options);
};

export const decodeURLVariable = (val) => {
  if (!val || val[0] === ":") {
    return undefined;
  }
  return atob(val);
};

export const VENDORS = ["Influxer Merch", "Gold Presidents", "Merch Fuel", "Velvet Impressions"];

export const COMPLETE_SPORTS_LIST = [
  "Swimming & Diving",
  "Lacrosse (M)",
  "Football",
  "Basketball (M)",
  "Soccer (W)",
  "Volleyball (W)",
  "Basketball (W)",
  "Tennis (W)",
  "TF and XC",
  "Beach Volleyball",
  "Soccer (M)",
  "Softball",
  "Baseball",
  "Volleyball",
  "Field Hockey (W)",
  "Lacrosse (W)",
  "Golf (M)",
  "Golf (W)",
  "Tennis (M)",
  "Gymnastics (W)",
  "Rowing (W)",
  "Equestrian",
  "Volleyball (M)",
  "Ice Hockey (M)",
  "Skiing",
  "Bowling (W)",
  "Dance",
  "Rugby (M)",
  "Ice Hockey (W)",
  "Cheer",
  "STUNT",
  "Wrestling (M)",
  "Soocer (M)",
  "Gymnastics (M)",
  "Wrestling (W)",
  "Bowling (M)",
  "Field Hockey",
  "Greco-Roman Wrestling",
  "Fencing",
  "POM",
  "Rowing (M)",
  "Beach Volleyball (W)",
  "Lacrosse",
  "Cross Country",
  "Triathlon (W)",
  "Rifle",
  "Acrobatics & Tumbling",
  "Bass Fishing",
  "Sport Shooting",
  "Weightlifting (M)",
  "Alpine Skiing",
  "Esports",
  "Weightlifting (W)",
  "Sailing (W)",
  "Fencing (W)",
  "Fencing (M)",
  "Tennnis (W)",
  "Weightlifting",
  "Water Polo (W)",
  "Swimming & Diving (M)",
  "Swimming & Diving (W)",
  "Club Tennis",
  "Table Tennis",
  "Water Polo (M)",
  "Squash",
  "Basketball",
  "Soccer",
  "Wrestling",
  "Tennis",
  "Bowling",
  "Ice Hockey",
  "Golf",
  "Synchronized Skating",
];

const generateRandomColor = () => {
  const color = "hsl(" + Math.random() * 360 + ", 100%, 90%)";
  return color
};

export const getOptionColors = (options) => {
  const colors = {};
  if (!options) return colors;
  const existingColors = new Set();
  options.forEach(key => {
    let color;
    do {
      color = generateRandomColor();
    } while (existingColors.has(color));
    existingColors.add(color);
    colors[key] = generateRandomColor();
  });
  return colors;
}

export const getDateForDatePicker = (dateValue) => {
  const newDate = new Date(dateValue);
  return dayjs(newDate.toISOString().split('T')[0])
}

export const convertSortedIndexNumber = (tableMeta) => {
  return tableMeta.currentTableData[tableMeta.rowIndex].index;
};