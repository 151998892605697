export const setLocalTheme = (themeName) => {
    localStorage.setItem('theme', themeName);
}

export const keepTheme = () => {
    const theme = localStorage.getItem('theme');
    if (theme) {
        setLocalTheme(theme);
        return;
    }

    const prefersLightTheme = window.matchMedia('(prefers-color-scheme: light)');
    if (prefersLightTheme.matches) {
        setLocalTheme('theme-light');
        return;
    }

    setLocalTheme('theme-dark');
}

export function isSmallScreen() {
    return window.innerWidth < 600;
  }
