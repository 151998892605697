import * as React from "react";
import MUIDataTable from "mui-datatables";
import { TableRow, TableCell, CircularProgress } from "@mui/material";
import ExpandableRow from "./ExpandableRow";
import { getRostersWithoutProductMockups } from "../../../API/roster";
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";

const ProductMockupUploadTable = ({
  setLoading,
  loading,
  selectedSchool,
  selectedSport,
}) => {
  const [withoutProductMockups, setWithoutProductMockups] = React.useState([]);
  const [refresh, setRefresh] = React.useState(0);

  const user = useUserContext();
  const notify = useNotification();

  React.useEffect(() => {
    if (!user || selectedSchool?.length === 0) {
      return;
    }
    setLoading(true);
    getRostersWithoutProductMockups(user, selectedSchool, selectedSport)
      .then((response) => {
        const flat = [];
        for (let i = 0; i < response.data.products.length; i++) {
          flat.push({
            ...response.data.products[i],
            ...response.data.products[i].MerchRoster,
            ...response.data.products[i].ProductMetadatum,
          });
        }
        setWithoutProductMockups(flat);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        notify("Failed to get records without assets");
        setWithoutProductMockups([]);
        setLoading(false);
      });
  }, [user, selectedSchool, selectedSport, refresh, notify, setLoading]);

  const grouped = Object.groupBy(
    withoutProductMockups ? withoutProductMockups : [],
    (p) => {
      return p.university + "~" + p.sport + "~" + p.sku_name;
    }
  );
  const columns = [
    { label: "School", name: "school" },
    { label: "Sport", name: "sport" },
    { label: "Sku", name: "sku" },
  ];

  const options = {
    rowsPerPageOptions: [5, 10, 25, 100],
    rowsPerPage: window.rowsPerPage,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta, a) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandableRow
              refresh={() => setRefresh((p) => p + 1)}
              products={grouped[rowData.join("~")]}
              schoolSportSku={rowData.join("~")}
              artOrMockup="mockup"
              productOrCollection="product"
            />
          </TableCell>
        </TableRow>
      );
    },
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "No Open Roster Records for that School / Sport :("
        ),
      },
    },
  };

  //console.log(grouped);

  const data = Object.keys(grouped).map((schoolSportSku) => {
    //console.log("key", schoolSportSku);
    const [school, sport, sku] = schoolSportSku.split("~");
    return { school, sport, sku, count: grouped[schoolSportSku].length };
  });

  return (
    <>
      <MUIDataTable options={options} columns={columns} data={data} />
    </>
  );
};

export default ProductMockupUploadTable;
