import { createTheme } from "@mui/material";


const darkThemeOptions = {
  palette: {
    mode: "dark",
    type: "dark",
    primary:
    // window.location.host.startsWith("www.dev.influxeradmin") || window.location.host.startsWith("dev.influxeradmin") || window.location.host.startsWith("localhost") ? {
    //   main: '#ff0206', // make the page red if in dev
    //   light: '#9c2929',
    //   dark: '#6b0003',
    // } :
    {
      main: "#80a097",
      light: '#9c2929',
      dark: '#6b0003',
    },
    secondary: {
      main: "#80a097",
    },
    background: {
      default: "#191919F5",
      paper: "#373737",
    },
    backgroundColor: {
      default: "#191919F5",
      paper: "#373737",
      light: "#565656",
    },
    text: {
      primary: "#d4d4d4",
    },
  },
  custom: {
    preparing: "#ecc72c",
    pending: "#b0bec5",
    started: "#4caf50",
    completed: "#126bb6",
    processing: "#4caf50",
    error: "#f44336",
  },
};

const lightThemeOptions = {
  palette: {
    mode: "light",
    type: "light",
    primary: {
      main: "#0c5998",
    },
    secondary: {
      main: "#000",
    },
    text: {
      primary: "#000000",
      secondary: "#000000"
    },
    background: {
      default: "#f9f9f9",
      paper: "#fefefe",
    },
    backgroundColor: {
      default: "#f9f9f9",
      paper: "#fafafa",
    },
    success: {
      main: "#4caf50",
      dark: "#4caf50"
    },
    error: {
      main: "#f44336",
      dark: "#f44336"
    }
  },
  custom: {
    preparing: "#ecc72c",
    pending: "#b0bec5",
    started: "#4caf50",
    completed: "#126bb6",
    processing: "#4caf50",
    error: "#f44336",
  }
};

export const darkTheme = createTheme(darkThemeOptions);
export const lightTheme = createTheme(lightThemeOptions);
