import {
  TextField,
  Autocomplete,
  Button 
 } from "@mui/material";

const MultiSelect = ({value, inputValue, options, onChange, onInputChange, label, allButton, ...props}) => {
    return (
        <Autocomplete
            value={value}
            multiple
            clearOnEscape
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                onInputChange(newInputValue);
            }}
            options={options}
            getOptionLabel={(option) => option}
            style={props.style? props.style:{ width: "90%", margin: "10px 10px", }}
            disabled={props.disabled}
            renderInput={(params) => (
                <TextField
                {...params}
                label={label}
                variant="outlined"
                style={{ width: "100%" }}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: allButton ? (
                    <>
                        <Button
                        disabled={value.includes("All")}
                        variant="contained"
                        onClick={() => {
                            onChange(["All"]);
                            onInputChange("");
                        }}
                        >
                        All
                        </Button>
                        {params.InputProps.endAdornment}
                    </>
                    ):null,
                }}
                type="text"
                />
            )}
        />);
}

export default MultiSelect