import AnalyticsIcon from '@mui/icons-material/Analytics';
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import AnalyticsDashboard from "./AnalyticsDashboard"
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => { 
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.ANALYTICS]} els={<Navigate to="/ballpark/" />}>
      <AnalyticsDashboard />
    </Can>
  );
}
export const icon = <AnalyticsIcon sx={{ color: "text.primary" }} />;
export const text = "Analytics Dashboard";
export const componentName = "Analytics Dashboard";
export const path = "analyticsDashboard";