import axios from "axios";
const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const getCollectionAndProductsByRosterId = (rosterId, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
      "allProductsAndCollectionsByRosterId?id=" +
      rosterId,
    {
      headers,
    }
  );
};
