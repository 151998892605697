import * as React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  Outlet,
  Navigate,
} from "react-router-dom";
import ContextProvider from "./contexts/ProviderProvider";
import { keepTheme, setLocalTheme } from "./utilities/themeUtilities";

import { getEmployeeRole } from "./API/employee";
import { getSchools } from "./API/school";
import { getRosterByAuthEmployeeSchool } from "./API/roster.js";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard.jsx";
import { getAuth, onIdTokenChanged } from "firebase/auth";
import ErrorPage from "./components/common/ErrorPage.jsx";
import useNotification from "./hooks/useNotification.jsx";
import { getFirestore, getDocs, collection } from "firebase/firestore";
import { app } from "./API/firebase.ts";

const db = getFirestore(app);
const App = () => {
  window.rowsPerPage = 100
  const [user, setUser] = React.useState(null);
  const [role, setRole] = React.useState("");
  const [theme, setTheme] = React.useState(localStorage.getItem("theme"));
  const [schools, setSchools] = React.useState([]);
  const [userSchools, setUserSchools] = React.useState([]);
  const [permission, setPermission] = React.useState("");
  const [availableRoles, setAvailableRoles] = React.useState([]);
  const [actions, setActions] = React.useState({});
  const [views, setViews] = React.useState({});
  const [isNavOpen, setIsNavOpen] = React.useState(true);


  const auth = getAuth();
  const notify = useNotification();

  const setThemeLocal = (theme) => {
    setLocalTheme(theme);
    setTheme(theme);
  };

  React.useEffect(() => {
    keepTheme();
  }, []);

  React.useEffect(() => {
    // Listen to changes in authentication state
    console.log("Starting auth listener");

    const unsubscribe = auth.onAuthStateChanged((updatedUser) => {
      // console.log("Auth state changed:", updatedUser);
      const fetchData = (updatedUser) => {
        if (updatedUser) {
          getEmployeeRole(updatedUser)
            .then((response) => {
              setRole(response.data.role);
              console.log(response.data.role);
            })
            .catch((e) => console.log("Failed to get role for", updatedUser));
        }
      };

      // call the function
      fetchData(updatedUser);
      setUser(updatedUser);
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth, user]);

  React.useEffect(() => {
    console.log("User value updated:", user);
  }, [user]);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onIdTokenChanged(auth, (user) => {
      console.log("Id token changed");
      if (user) {
        setUser(user);
        // User is signed in, check the token expiration
        // You can also check the expiration time in user.getIdTokenResult().expirationTime
        const now = new Date().getTime();
        const expiresIn = user.stsTokenManager.expirationTime - now;
        //console.log("Token expires in ", expiresIn);

        // Set a timeout to refresh the token before it expires
        const refreshTimeout = setInterval(() => {
          // Call a function to refresh the token
          user.getIdToken(true).then((response) => {
            setUser(auth.currentUser);
          });
        }, Math.max(0, expiresIn - 300000)); // Refresh 5 minutes before expiration

        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(refreshTimeout);
      }
    });

    // Cleanup function to unsubscribe from the observer when the component unmounts
    return () => unsubscribe();
  }, [auth, user]);

  React.useEffect(() => {
    console.log("Attempting to get schools:", user);
    if (!user || !user?.accessToken) return;
    console.log("Getting schools:", user);
    getSchools(user)
      .then((response) => {
        setSchools(response.data.schools);
      })
      .catch((e) => {
        notify("Failed to get complete school list");
      });
  }, [user, notify]);

  React.useEffect(() => {
    if (user) {
      getRosterByAuthEmployeeSchool(user)
        .then((response) => {
          const unis = response.data.school.map((s) => s.university);
          setUserSchools(unis);
        })
        .catch((error) => {
          console.error(error);
          notify("Failed to get get this users school list");
          setUserSchools([]);
        });
    }
  }, [user, notify]);

  React.useEffect(() => {
    const fetchPermissions = async () => {
      try {
        // Query Firestore for permissions based on user's role
        const querySnapShot = await getDocs(collection(db, "Admin Permissions Matrix"))
        let perm = null
        let roles = []
        let action = null
        let view = null
        querySnapShot.forEach((doc) => {
          if (doc.id.toString() === role) perm = doc.data();
          if (doc.id.toString() === "Actions") action = doc.data();
          else if (doc.id.toString() === "Views") view = doc.data();
          else roles.push(doc.id.toString());
        });
        if (perm) {
          setPermission(perm);
        } else {
          console.log('Unable to get permissions')
        }
        if (roles.length > 0) {
          setAvailableRoles(roles);
        } else {
          console.log('Unable to get all roles')
        }
        if (action) {
          setActions(action);
        } else {
          console.log('Unable to get all actions')
        }
        if (view) {
          setViews(view);
        } else {
          console.log('Unable to get all views')
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };
    if (role && db) {
      fetchPermissions();
    }
  }, [role]);

  const componentContext = require.context(
    "./components/modules",
    true,
    /index\.jsx$/
  );
  const componentModules = componentContext.keys().map(componentContext);

  const routes = componentModules.map((mod) => {
    return {
      path: mod.path,
      element: <mod.Component />,
      errorElement: <ErrorPage />,
    };
  });
  const router = createBrowserRouter([
    { path: "/", element: <Navigate to="/login" /> },
    {
      path: "/login",
      element: <Login user={user} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/ballpark",
      element: (
        <ContextProvider
          user={user}
          role={role}
          setRole={setRole}
          permission={permission}
          availableRoles={availableRoles}
          actions={actions}
          views={views}
          schools={schools}
          setSchools={setSchools}
          theme={theme}
          setTheme={setThemeLocal}
          userSchools={userSchools}
          isNavOpen={isNavOpen}
          setIsNavOpen={setIsNavOpen}
        >
          <Dashboard>
            <Outlet />
          </Dashboard>
        </ContextProvider>
      ),
      children: routes,
      errorElement: <ErrorPage />,
    },
  ]);

  return <>
    <Helmet>
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
      <meta charset="utf-8" />
      <link rel="icon" href="../public/influxer.svg" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Web site created using create-react-app" />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      <title>Influxer Admin</title>
    </Helmet>
    <RouterProvider router={router} />
  </>;
};

export default App;
