import { Divider, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import CreateProductForm from "./CreateProducts";
import ConfigureNewProduct from "./ConfigureNewProduct";

const Products = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
      style={{
        width: "80%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "column",
      }}
    >
      <Tabs value={activeTab} onChange={(e, n) => setActiveTab(n)}>
        <Tab label="Create Products" />
        <Tab label="Product Configuration" />
      </Tabs>

      <Divider />

      {activeTab === 0 && <CreateProductForm />}
      {activeTab === 1 && <ConfigureNewProduct />}
    </div>
  );
};

export default Products;
