import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import {
  useUserContext,
  useUserSchools,
} from "../../../contexts/ProviderProvider";

const ComplianceWelcome = (props) => {
  const [school, setSchool] = useState(false);

  const userSchools = useUserSchools();
  const user = useUserContext();

  useEffect(() => {
    if (!userSchools) return;
    setSchool(userSchools[0]);
  }, [userSchools]);

  return (
    <div style={{ width: "80%", padding: "5vh" }}>
      <Typography variant="h1">{school}</Typography>
      <Typography variant="h5">Welcome to the Influxer University Dashboard!</Typography>
      <Typography variant="h6"style={{ paddingTop: "2vh" }}>Getting Started</Typography>
      <Typography variant="body1">
        This is Version 1 of the Influxer University Dashboard. It’s built for you! If you have any feedback, questions, or feature requests, please let us know by contacting our team:
        <ul>
          <li>levi.norwood@influxer.com</li>
          <li>ryan.harrington@influxer.com</li>
          <li>keith@influxer.com</li>
          <li>steve.mclean@influxer.com</li>
        </ul>
        All data is from 2024 and on. If you would like any data from 2023, let us know and we will manually provide it. 
        You may also sort, filter and hide any columns as well as view Order History by clicking the arrow next to a student-athlete's name.
        <br/>
        <br/>
        Future updates will include the following:
        <ul>
          <li>Each student-athlete’s contract linked to their name</li>
          <li>Each student-athlete’s unique storefront URL so they can be easily added to your athletic website</li>
          <li>The entire roster (even if they haven’t made a sale or signed up)</li>
          <li>Payout dates & whether the student-athlete has been paid for sales in the previous quarter</li>
          <li>Much more to come!</li>
        </ul>
      </Typography>
    </div>
  );
};

export default ComplianceWelcome;
