import { Modal, Box, Typography } from "@mui/material";
import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";

export const MetadataDetailedModal = ({
  selectedProductMetadata,
  open,
  handleClose,
}) => {
  var zippedData = [];
  if (selectedProductMetadata.style) {
    const style_list = selectedProductMetadata.style.split(":");
    const color_list = selectedProductMetadata.color_reference.split(":");
    const size_list = selectedProductMetadata.size_range.split(":");
    zippedData = size_list.map((element, index) => [
      element,
      color_list[index],
      style_list[index],
    ]);
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Product Metadata Details
        </Typography>
        {Object.keys(selectedProductMetadata).map((key, index) => {
          if (
            key === "style" ||
            key === "size_range" ||
            key === "color_reference" ||
            key === "product_metadata_id"
          ) {
            return <></>;
          }
          return (
            <Typography
              key={index}
              id={`modal-modal-description-${index}`}
              sx={{ mt: 2 }}
            >
              <span style={{ color: "#868686" }}>
                {key.toUpperCase().replace("_", " ")}:-
              </span>{" "}
              {selectedProductMetadata[key]}
            </Typography>
          );
        })}
        {zippedData.length > 0 && (
          <TableContainer component={Paper} sx={{ marginTop: "10px" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Size Range</TableCell>
                  <TableCell>Color Reference</TableCell>
                  <TableCell>Style</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {zippedData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Modal>
  );
};
