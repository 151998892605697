import { useEffect, useState, useCallback } from "react"
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { getJobs } from "../../../API/marketplace.js"
import { TableRow, TableCell } from "@mui/material";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ServerSideTable from "../../common/ServerSideTable";
import axios from "axios";

const Campaigns = (props) => {

  const user = useUserContext()
  const notify = useNotification()
  const navigate = useNavigate()


  const [campaigns, setCampaigns] = useState([])

  const handlePaginationStateUpdate = useCallback((axiosOptions, page, pageSize, search, filter, ordering) => {
    return getJobs(user, axiosOptions, page, pageSize, ordering, search, filter)
      .then(response => {
        setCampaigns(response.data.obj.Job)
        return response
      }).catch(e => {
        if (axios.isCancel(e)) {
          // not an error
        } else {
          console.log("from caller error", e)
          notify("Failed to get jobs")
          return e
        }
      })
  }, [user])

  const options = {
    sortOrder: { name: "creationTimestamp", direction: "descending" },
    renderExpandableRow: (a, b, c) => {
      console.log("Expandable row", a, b, c)

      return (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={9}
          >
            <div>{campaigns[b.dataIndex].description}</div>
          </TableCell>
        </TableRow>
      );
    }
  }

  let columns = []

  if (campaigns.length > 0) {
    const shape = campaigns[0]
    columns = Object.keys(shape)
      .filter(s => !["description"].includes(s))
      .map(s => ({ label: capitalize(s), name: s }))
  }

  for (let i = 0; i < columns.length; i++) {
    console.log("column", columns[i])
    if (columns[i].name.toLowerCase().includes("photo")) {
      columns[i].options = {
        customBodyRender: (value) => {
          return (
            <div>
              <img src={value} style={{ height: "100px" }}></img>
            </div>
          )
        }
      }
    }

    if (campaigns[0][columns[i].name] === true || campaigns[0][columns[i].name] === false) {
      columns[i].options = {
        customBodyRender: (value) => {
          return (
            <div>
              <TrueFalseCheckbox checked={value} disabled={true} />
            </div>
          )
        }
      }
    }
  }

  columns.push({
    name: "", label: "View", options: {
      customBodyRender: (value, tableMeta) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              console.log("onClick", tableMeta)
              navigate("/ballpark/campaign/" + tableMeta.rowData[0])
            }}>Visit</Button>
        )
      }
    }
  })


  return (
    <div style={{ width: "80%" }}>
      <ServerSideTable
        title={"Campaign"}
        data={campaigns}
        columns={columns}
        tableConfig={options}
        onTableChange={() => { }}
        paginatedRequestFunc={handlePaginationStateUpdate}
        hideColumns={["ID", "Brand ID", "Due Date", "Creation Timestamp", "Last Update Timestamp"]}
      />
    </div >
  )
}

const capitalize = (key) => {
  // Handle specific cases first, such as abbreviations
  const specialCases = {
    "ID": "ID",
  };

  // Split camelCase or snake_case into words
  const words = key
    .replace(/([a-z])([A-Z])/g, '$1 $2')  // Split camelCase
    .replace(/_/g, ' ')                   // Replace underscores with spaces (if any)
    .split(' ');                          // Split into an array of words

  // Capitalize the first letter of each word
  const formattedWords = words.map(word => {
    if (specialCases[word.toUpperCase()]) {
      return specialCases[word.toUpperCase()];
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words back into a single string
  return formattedWords.join(' ');
}

export default Campaigns;
