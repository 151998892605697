import * as React from "react";
import { Box, Typography } from "@mui/material";
import FileDropZone from "../../common/FileDropZone";
import { getMerchRosterBySchool, uploadRosterCSV } from "../../../API/roster";
import { useUserContext, usePermissionContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import RosterTable from "./RosterTable";
import Can from "../../common/Can";
import { getSchoolByName, updateSchool, getProductMetadataBySchool } from "../../../API/school";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import AreYouSure from "../../common/AreYouSure";
import SchoolProductList from "./SchoolProductList";
import { MetadataDetailedModal } from "./ProductMetadataDetailModal";
import { decompressData } from "../../../utilities/utils";

const CreateRoster = ({ school }) => {
  const [roster, setRoster] = React.useState([]);
  const [schoolObj, setSchoolObj] = React.useState({});
  //   const [complianceOfficers, setComplianceOfficers] = React.useState();
  const [refresh, setRefresh] = React.useState(false);
  const [submit, setSubmit] = React.useState(undefined);
  const [schoolProductMetadata, setSchoolProductMetadata] = React.useState([]);
  const [selectedProductMetadata, setSelectedProductMetadata] = React.useState({});
  const [signedCount, setSignedCount] = React.useState(0)

  const user = useUserContext();
  const { actions } = usePermissionContext();
  const notify = useNotification();
  const [open, setOpen] = React.useState(false);
  const handleOpen = (index) => {
    setSelectedProductMetadata(schoolProductMetadata[index])
    setOpen(true)
  };
  const handleClose = () => {
    setSelectedProductMetadata({})
    setOpen(false);
  }

  const uploadNewRoster = (files, callback) => {
    uploadRosterCSV(files, { school }, user)
      .then((response) => {
        notify({ msg: "Successfully uploaded roster", variant: "success" });
        callback();
        setRefresh((p) => p + 1);
      })
      .catch((e) => {
        notify("Failed to upload roster");
        callback();
        setRefresh((p) => p + 1);
      });
  };

  React.useEffect(() => {
    if (!user) return;
    getSchoolByName(school, user)
      .then((response) => {
        setSchoolObj(response.data);
      })
      .catch((error) => {
        notify("Failed to get school information");
      })
    getMerchRosterBySchool(school, user)
      .then((response) => {
        const responseData = decompressData(response);
        const roster = responseData.roster
        const sortedRoster = roster.sort((a, b) => {
          if (b.signed - a.signed !== 0) {
            return b.signed - a.signed;
          }
          if (a.sport.localeCompare(b.sport) !== 0) {
            return a.sport.localeCompare(b.sport);
          }
          return a.full_name.localeCompare(b.full_name);
        });
        setRoster(sortedRoster);
      })
      .catch((error) => {
        notify("Failed to get School Roster");
      });
    getProductMetadataBySchool(school, user).then((response) => {
      console.log(response)
      setSchoolProductMetadata(response.data);
    })
      .catch((error) => {
        notify("Failed to get School ProductMetadata");
      });
  }, [school, user, notify, refresh]);

  React.useEffect(() => {
    setSignedCount(roster.filter(r => r.signed == true).length)
  }, [roster])

  const handleInternationalChange = (newStatus) => {

    const field = "allowInternational";
    const value = newStatus;
    setSubmit(undefined)
    updateSchool(school, field, value, user)
      .then((response) => {
        setSchoolObj(prevSchoolObj => ({
          ...prevSchoolObj,
          allowInternational: newStatus
        }));
        notify({ variant: "success", msg: 'Updated school international status' });
      })
      .catch((error) => {
        console.log('Error updating school international status', error);
        notify('Unable to update international status');
      });

  }

  const buildMessage = () => {
    return (`Are you sure you want to change the allow international athletes
            from ${schoolObj.allowInternational.toString().toUpperCase()} to ${(!schoolObj.allowInternational).toString().toUpperCase()}`)
  }

  return (
    <div style={{ width: "100%", paddingLeft: schoolProductMetadata.length > 0 ? "20px" : "50px", paddingRight: schoolProductMetadata.length > 0 ? "20px" : "50px" }}>
      <Typography variant="h1">{school}</Typography>
      <div>
        <div>
          <TrueFalseCheckbox
            checked={schoolObj.allowInternational}
            interact={() => setSubmit(true)}
          />
          Allow International
        </div>
        <div>There are {signedCount} athletes signed at this school.</div>
        <div style={{ width: "100%", display: "flex", gap: "10px" }}>
          <Box sx={{ width: schoolProductMetadata.length > 0 ? "90%" : "100%", backgroundColor: "background.paper" }}>
            <RosterTable roster={roster} />
          </Box>
          {schoolProductMetadata.length > 0 && <Box>
            <SchoolProductList data={schoolProductMetadata} handleOpen={handleOpen} />
          </Box>}

        </div>

        <Can action={actions.ALTER} items={[actions.CREATENEWROSTER]}>
          <FileDropZone
            sx={{ width: "100%" }}
            uploadFiles={(files, callback) => {
              uploadNewRoster(files, callback);
            }}
            text={"Add new Merch Roster records for " + school}
          />
        </Can>
      </div>
      {submit && (
        <AreYouSure
          style={{
            backgroundColor: "background.paper",
            margin: "auto",
            marginTop: "10vh",
            padding: "20px",
            width: "30vw",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "5px",
          }}
          open={submit !== undefined}
          message={buildMessage()}
          yes={() => handleInternationalChange(!schoolObj.allowInternational)}
          no={() => setSubmit(undefined)}
        />
      )}
      <MetadataDetailedModal selectedProductMetadata={selectedProductMetadata} open={open} handleClose={handleClose} />
    </div >
  );
};

export default CreateRoster;
