import { useEffect, useState } from "react";
import AssetGeneration from "./AssetGeneration";
import ConfigureEquipmentManagerTable from "./ConfigureEquipmentManagerTable";
import SocialGeneration from "./SocialGeneration";
import SchoolSportFilter from "../../common/SchoolSportFilter";

import {
  Box,
  Tab, Tabs, Button, IconButton, Typography
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import MockupGeneration from "./MockupGeneration";
import { getEquipmentManagerStatus } from "../../../API/equipmentManager";
import useNotification from "../../../hooks/useNotification";
import { useUserContext } from "../../../contexts/ProviderProvider";
import { padding } from "@mui/system";

const EquipmentManager = () => {
  const user = useUserContext();
  const notify = useNotification();
  const [assetFile, setAssetFile] = useState();
  const [assetExampleImages, setAssetExampleImages] = useState([]);
  const [assetImagesType, setAssetImagestypes] = useState("");
  const [assetLoading, setAssetLoading] = useState(false);
  const [mockupFile, setMockupFile] = useState();
  const [mockupLoading, setMockupLoading] = useState(false);
  const [mockupExampleImages, setMockupExampleImages] = useState([]);
  const [mockupImagesType, setMockupImagesType] = useState("");
  const [configLoading, setConfigLoading] = useState(false);
  const [socialFile, setSocialFile] = useState();
  const [socialLoading, setSocialLoading] = useState(false);
  const [socialExampleImages, setSocialExampleImages] = useState([]);
  const [socialImagesType, setSocialImagesType] = useState("");

  const [activeTab, setActiveTab] = useState("artFiles");
  const [assetGenerationData, setAssetGenerationData] = useState([]);
  const [mockupGenerationData, setMockupGenerationData] = useState([]);


  const updateStatus = () => {
    getEquipmentManagerStatus(user).then(res => {
      setAssetGenerationData(res.data.obj.AssetGenerationStatus);
      setMockupGenerationData(res.data.obj.MockupGenerationStatus);
    }).catch((e) => {
      console.log(e);

      notify("Failed to get Status of EquipmentManager");
    });
  }

  useEffect(() => {
    const getAlerts = () => {
      getEquipmentManagerStatus(user).then(res => {
        setAssetGenerationData(res.data.obj.AssetGenerationStatus);
        setMockupGenerationData(res.data.obj.MockupGenerationStatus);
      }).catch((e) => {
        console.log(e);
        notify("Failed to get Status of EquipmentManager");
      });
    }
    getAlerts()
    const interval = setInterval(() => getAlerts(), 10000)
    return () => {
      clearInterval(interval);
    }
  }, [])


  return (
    <Box sx={{ width: "100%", backgroundColor: "background.paper" }}>
      <div sx={{ width: "100%" }}><Button variant="outlined" onClick={updateStatus} sx={{ margin: "10px" }}><Refresh /><Typography variant="button" sx={{ padding: "3px" }}>Refresh</Typography></Button></div>
      <div sx={{
        width: "100%"
      }}>
        <Tabs value={activeTab} onChange={(e, nv) => { console.log(nv); setActiveTab(nv) }} variant="fullWidth">
          <Tab sx={{ width: "33%" }} value="artFiles" label="Art Files" />
          <Tab sx={{ width: "33%" }} value="productMockup" label="Product Mockups" />
          <Tab sx={{ width: "34%" }} value="configuration" label="Configuration" />
        </Tabs>

        {activeTab == "artFiles" ?
          <AssetGeneration file={assetFile} exampleImages={assetExampleImages} imagesType={assetImagesType} loading={assetLoading} setFile={setAssetFile} setExampleImages={setAssetExampleImages} setImagesType={setAssetImagestypes} setLoading={setAssetLoading} data={assetGenerationData} /> :
          activeTab == "productMockup" ?
            <MockupGeneration file={mockupFile} exampleImages={mockupExampleImages} imagesType={mockupImagesType} loading={mockupLoading} setFile={setMockupFile} setExampleImages={setMockupExampleImages} setImagesType={setMockupImagesType} setLoading={setMockupLoading} data={mockupGenerationData} /> :
            <ConfigureEquipmentManagerTable loading={configLoading} setLoading={setConfigLoading} />}
      </div>
    </Box >

  );
};

export default EquipmentManager;
