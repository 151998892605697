import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";
import { MenuItem, TextField, Select } from "@mui/material";
import Can from "./Can";
import { usePermissionContext } from "../../contexts/ProviderProvider";
import useCanUser from "../../hooks/useCanUser";


export const ColoredSelectModifier = ({
    original,
    value,
    setValue,
    label,
    style,
    options,
    permissions,
    optionColors,
}) => {
    const filterOriginal = (newValue) => {
        if (newValue !== original) {
            setValue(newValue);
        } else {
            setValue(undefined);
        }
    };

    const { actions } = usePermissionContext();
    const canUserAlter = useCanUser(actions.ALTER, [permissions]);
    const disabled = permissions ? !canUserAlter : false;

    const getWidth = () => {
        if (value) {
            if (value) {
                return "auto"
            }
            return "75%"
        } else {
            if (original) {
                return "auto"
            }
            return "75%"
        }
    }


    return (
        <>

            <Select
                label={label}
                value={value ? value : original}
                onChange={(e) => filterOriginal(e.target.value)}
                style={style}
                sx={{
                    alignItems: 'center',
                    backgroundColor: disabled ? "primary.main" : (optionColors[value ? value : original] ? optionColors[value ? value : original] : "primary.main"),
                    borderRadius: '9999px',
                    padding: '3px 5px',
                    marginTop: '5px',
                    marginRight: '20px',
                    marginLeft: '10px',
                    color: '#000 !important',
                    borderWidth: '0px',
                    width: getWidth(),

                }}
                disabled={disabled} >
                {
                    options.map((option, index) => (
                        <MenuItem key={index} value={option}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: optionColors[option] ? optionColors[option] : "primary.main",
                                borderRadius: '9999px',
                                padding: '10px 12px',
                                marginTop: '5px',
                                marginRight: '20px',
                                marginLeft: '10px',
                                color: '#000 !important',
                                border: value ? (option == value ? '5px double #121212' : '') : (option == original ? '5px double #121212' : ''),
                                '&.Mui-selected': {
                                    backgroundColor: optionColors[option] ? optionColors[option] : "primary.main",
                                }
                            }}>
                            {option}
                        </MenuItem>
                    ))
                }
            </Select>
            {
                value && value !== original && (
                    <>
                        <Can action={actions.ALTER} items={permissions}>
                            <Tooltip title="Revert">
                                <RefreshIcon
                                    onClick={() => setValue(original)}
                                    style={{
                                        color: "#6495ED",
                                        marginTop: "10px",
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip>

                        </Can>
                    </>
                )
            }
        </>
    );
};
