import React from 'react';
import { FormControl, Autocomplete, TextField, Chip } from '@mui/material';

const ProductFilter = ({ UniversityOptions, universities, setUniversities, SportOptions, sports, setSports, TypeOptions, types, setTypes, isSubmitting, updateType }) => {
    return (
        <>
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    multiple
                    options={UniversityOptions}
                    getOptionLabel={(option) => option}
                    value={universities}
                    onChange={(event, newValue) => setUniversities(newValue)}
                    renderInput={(params) => <TextField {...params} label="Universities" disabled={isSubmitting} />}
                    renderTags={(selectedOptions, getTagProps) =>
                        selectedOptions.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <Autocomplete
                    multiple
                    options={SportOptions}
                    getOptionLabel={(option) => option}
                    value={sports}
                    onChange={(event, newValue) => setSports(newValue)}
                    renderInput={(params) => <TextField {...params} label="Sports" disabled={isSubmitting} />}
                    renderTags={(selectedOptions, getTagProps) =>
                        selectedOptions.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                />
            </FormControl>

            <FormControl fullWidth margin="normal" required={(updateType === "Price" || updateType === "Cost")}>
                <Autocomplete
                    multiple
                    options={TypeOptions}
                    getOptionLabel={(option) => option}
                    value={types}
                    onChange={(event, newValue) => setTypes(newValue)}
                    renderInput={(params) => <TextField {...params} label="Types" disabled={isSubmitting} />}
                    renderTags={(selectedOptions, getTagProps) =>
                        selectedOptions.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                />
            </FormControl>
        </>
    );
};

export default ProductFilter;