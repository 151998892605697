import {
  Box,
  Typography,
  LinearProgress,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import * as React from "react";
import ProductMockupUploadTable from "./ProductMockupUploadTable.jsx";
import ArtFileUploadTable from "./ArtFileUploadTable.jsx";
import SchoolSportFilter from "../../common/SchoolSportFilter";
import useNotification from "../../../hooks/useNotification";
import { useUserContext } from "../../../contexts/ProviderProvider";
import { getUniversityAssetCount } from "../../../API/products.js";

const AssetUpload = (props) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [loadingProductMockups, setLoadingProductMockups] = React.useState();
  const [loadingArtFiles, setLoadingArtFiles] = React.useState();
  const [selectedSchool, setSelectedSchool] = React.useState("");
  const [selectedSport, setSelectedSport] = React.useState("");
  const [universityCount, setUniversityCount] = React.useState({});

  const notify = useNotification();
  const user = useUserContext();

  const updateUniversityCount = (results) => {
    var temp = {};
    for (var result in results) {
      const result_university = results[result]["university"];
      const mockupCount =
        results[result] !== undefined &&
        results[result]["mockupCount"] !== undefined
          ? results[result]["mockupCount"]
          : 0;
      const assetCount =
        results[result] !== undefined &&
        results[result]["assetNeededCount"] !== undefined
          ? results[result]["assetNeededCount"]
          : 0;
      temp[result_university] = [mockupCount, assetCount];
    }
    setUniversityCount(temp);
  };

  React.useEffect(() => {
    getUniversityAssetCount(user)
      .then((response) => {
        updateUniversityCount(response.data);
      })
      .catch((error) => {
        console.log(error);
        notify("Failed to get product university counts");
      });
  }, [user, notify]);

  return (
    <div style={{ width: "80%" }}>
      <Typography variant="h1">Asset Upload</Typography>
      <SchoolSportFilter
        selectedSchool={selectedSchool}
        setSelectedSchool={setSelectedSchool}
        selectedSport={selectedSport}
        setSelectedSport={setSelectedSport}
        universitiesCount={universityCount}
      />
      <Divider style={{ margin: "15px" }} />
      <Box sx={{ width: "100%", backgroundColor: "background.paper" }}>
        {(loadingArtFiles || loadingProductMockups) && <LinearProgress />}
        <Tabs value={activeTab} onChange={(e, nv) => setActiveTab(nv)}>
          <Tab label="Product Mockups" />
          <Tab label="Art Files" />
        </Tabs>
        {activeTab === 0 && (
          <ProductMockupUploadTable
            selectedSchool={selectedSchool}
            selectedSport={selectedSport}
            setSelectedSchool={setSelectedSchool}
            setSelectedSport={setSelectedSport}
            setLoading={setLoadingProductMockups}
            loading={loadingProductMockups}
          />
        )}
        {activeTab === 1 && (
          <ArtFileUploadTable
            selectedSchool={selectedSchool}
            selectedSport={selectedSport}
            setSelectedSchool={setSelectedSchool}
            setSelectedSport={setSelectedSport}
            setLoading={setLoadingArtFiles}
            loading={loadingArtFiles}
          />
        )}
      </Box>
    </div>
  );
};

export default AssetUpload;
