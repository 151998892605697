import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import Marathon from "./Marathon";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.MARATHON]} els={<Navigate to="/ballpark" />}>
      <Marathon />
    </Can>
  );
}
export const icon = <AssistWalkerIcon />;
export const text = "Marathon";
export const componentName = "Marathon";
export const path = "marathon";