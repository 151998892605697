import React from 'react'
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Header = ({ job, brand }) => {
  console.log("header", job, brand)
  return (
    <div
      style={{
        gap: "10px",
        width: "100%",
        display: "flex",
        paddingTop: "20px",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }} >
      <div style={{ margin: 0 }}>
        {job.coverPhoto ? (
          <img
            style={{
              width: "125px",
              height: "125px",
              borderRadius: "50%",
              border: job.active ? "green" : "red",
            }}
            src={job.coverPhoto}
            alt="The Profile Pic" />) : (
          <AccountCircleIcon style={{
            width: "125px",
            height: "125px",
            borderRadius: "50%",
            border: job.active ? "green" : "red",
          }}
          />
        )}
        <div style={{ margin: "auto", textAlign: "center" }}>
        </div>
      </div>

      <div>
        <span style={{ fontSize: "64px", fontWeight: "medium" }}>
          {job.title}
        </span>
        <div>
          <div style={{ marginBottom: "5px" }}>
            <span
              style={{
                opacity: "0.4",
                fontSize: "14px",
                marginLeft: "0px",
                position: "relative",
                top: "-10px",
              }}
            >
              {brand.brandName + " - " + job.category + " - " + job.numberOfApplicants + " applicants"}
            </span>
          </div>
        </div>
      </div>
      <div style={{ marginLeft: "auto" }}>
      </div>
    </div>
  );

}

export default Header;
