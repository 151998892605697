import * as React from "react";
import MUIDataTable from "mui-datatables";
import { TableRow, TableCell, CircularProgress } from "@mui/material";
import ExpandableRow from "./ExpandableRow";
import { useUserContext } from "../../../contexts/ProviderProvider";
import { getRostersWithoutArtFiles } from "../../../API/roster";
import useNotification from "../../../hooks/useNotification";

const ArtFileUploadTable = ({
  loading,
  setLoading,
  selectedSchool,
  selectedSport,
}) => {
  const [withoutArtFiles, setWithoutArtFiles] = React.useState([]);
  const [refresh, setRefresh] = React.useState(0);

  const user = useUserContext();
  const notify = useNotification();

  React.useEffect(() => {
    if (!user || selectedSchool === "") {
      return;
    }
    setLoading(true);
    getRostersWithoutArtFiles(user, selectedSchool, selectedSport)
      .then((response) => {
        //console.log(response.data.products);

        setWithoutArtFiles(response.data.products);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        notify("Failed to get records without assets");
        setWithoutArtFiles([]);
        setLoading(false);
      });
  }, [user, selectedSchool, selectedSport, refresh, notify, setLoading]);

  const grouped = {};
  withoutArtFiles.forEach((product) => {
    // group by school, sport, sku, and p.frontAsset / p.backAsset
    let groupKey =
      product.ProductMetadatum.university +
      "~" +
      product.MerchRoster.sport +
      "~" +
      product.ProductMetadatum.sku_name;
    // if the frontAsset is null it needs its own group
    if (!product.frontAsset) {
      const localKey = groupKey + "~front";
      if (!grouped[localKey]) {
        grouped[localKey] = [];
      }

      grouped[localKey].push(product);
    }

    // if the backAsset is null it needs its own group
    if (!product.backAsset) {
      const localKey = groupKey + "~back";
      if (!grouped[localKey]) {
        grouped[localKey] = [];
      }

      grouped[localKey].push(product);
    }
  });

  const columns = [
    { label: "School", name: "school" },
    { label: "Sport", name: "sport" },
    { label: "Sku", name: "sku" },
    { label: "Product Side", name: "side" },
  ];

  const options = {
    rowsPerPageOptions: [10, window.rowsPerPage],
    rowsPerPage: window.rowsPerPage,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta, a) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandableRow
              refresh={() => setRefresh((p) => p + 1)}
              products={grouped[rowData.join("~")]}
              schoolSportSku={rowData.join("~")}
              artOrMockup="art"
              productOrCollection="product"
            />
          </TableCell>
        </TableRow>
      );
    },
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "No Open Roster Records for that School / Sport :("
        ),
      },
    },
  };

  //console.log("grouped", grouped);

  const data = Object.keys(grouped).map((schoolSportSku) => {
    const [school, sport, sku, side] = schoolSportSku.split("~");
    return { school, sport, sku, side };
  });
  //console.log("data", data);

  return (
    <>
      <MUIDataTable options={options} columns={columns} data={data} />
    </>
  );
};

export default ArtFileUploadTable;
