import { useEffect, useState } from "react";
import {
  Typography,
  Autocomplete,
  LinearProgress,
  TextField,
  ListItem,
} from "@mui/material";
import { useUserContext } from "../../../contexts/ProviderProvider.jsx";
import useNotification from "../../../hooks/useNotification.jsx";
import { searchStudentAthleteByName } from "../../../API/athleteUser";

const AthleteSearch = ({ updateForm }) => {
  const notify = useNotification();
  const user = useUserContext();

  const [studentAthleteList, setStudentAthleteList] = useState([]);
  const [input, setInput] = useState("");
  const [selectedStudentAthlete, setSelectedStudentAthlete] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (
        !searchLoading &&
        input.length >= 2 &&
        studentAthleteList.length === 0 &&
        selectedStudentAthlete?.full_name !== input
      ) {
        setSearchLoading(true);
        searchStudentAthleteByName(input, user)
          .then((response) => {
            setStudentAthleteList(response.data);
            setSearchLoading(false);
          })
          .catch((error) => {
            console.error(error);
            notify("Failed to complete search");
            setStudentAthleteList([]);
            setSearchLoading(false);
          });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [
    input,
    user,
    studentAthleteList?.length,
    searchLoading,
    selectedStudentAthlete?.full_name,
    notify,
  ]);

  const fetchUsers = (event, value) => {
    if (studentAthleteList.length > 0) {
      setStudentAthleteList([]);
    }
    setInput(value);
  };

  if (!user) {
    return <></>;
  }
  return (
    <div style={{ width: "100%", paddingTop: "5vh" }}>
      <div style={{ width: "100%", margin: "auto" }}>
        <Typography>Search For Athlete (Not Required)</Typography>
        {searchLoading && <LinearProgress />}
        <Autocomplete
          options={studentAthleteList}
          onInputChange={fetchUsers}
          onChange={(e, v) => {
            setSelectedStudentAthlete(v?.athlete_id);
            updateForm(v?.athlete_id);
          }}
          getOptionLabel={(option) => option.full_name}
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option, state) => (
            <ListItem
              {...props}
              key={option.athlete_id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid white",
                backgroundColor: !option.signed
                  ? "error.main"
                  : "background.default",
              }}
            >
              <div
                style={{
                  flex: 6,
                }}
              >
                {option.sport +
                  " : " +
                  option.university +
                  " : " +
                  option.full_name}
              </div>
            </ListItem>
          )}
        />
      </div>
    </div>
  );
};

export default AthleteSearch;
