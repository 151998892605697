import * as React from "react";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import MatcherWrapper from "./MatcherWrapper";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.MATCHER]} els={<Navigate to="/ballpark" />}>
      <MatcherWrapper />
    </Can>
  );
}
export const icon = <CompareArrowsIcon />;
export const text = "Matcher";
export const componentName = "Matcher";
export const path = "matcher/:university?";
