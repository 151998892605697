import * as React from "react";
import { ListItem, ListItemButton } from "@mui/material";


const SchoolProductList = (props) => {

    return (
        <div sx={{ width: "100%" }}>
            <div style={{ marginTop: "20px", color: "#868686", opacity: "87%" }}>
                Products
            </div>
            {
                props.data.map((productMetadata, index) => {
                    return (
                        <ListItem
                            key={
                                productMetadata.sku_name
                            }
                            sx={{
                                backgroundColor:
                                    props.selectedIndex === index
                                        ? "success.dark"
                                        : "background.paper",
                                marginBottom: "5px",
                                maxWidth: "370px",
                                padding: "unset",
                                boxShadow:
                                    props.selectedIndex === index ? "0px 5px 8px darkgreen" : "unset",
                            }}
                        >
                            <ListItemButton onClick={() => { props.handleOpen(index); }}>
                                {productMetadata.sku_name}
                            </ListItemButton>
                        </ListItem>
                    )
                })
            }
        </div >
    );
};

export default SchoolProductList;
