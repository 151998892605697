import * as React from "react";
import SearchSchool from "./SearchSchool";
import { useParams } from "react-router-dom";
import { decodeURLVariable } from "../../../utilities/utils";

const MatcherWrapper = (props) => {
  const { university } = useParams();
  const school = decodeURLVariable(university);

  return (
    <>
      <SearchSchool school={school} />
    </>
  );
};
export default MatcherWrapper;
