import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Modal,
    IconButton,
    Button,
    Paper,
    Autocomplete,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const SizeEditModal = ({   user,
                           isModalOpen,
                           setIsModalOpen,
                           sizesData,
                           setSizesData,
                           locationOptions,
                           selectedSizeIndex: initialSelectedSizeIndex
                       }) => {
    const [selectedSizeIndex, setSelectedSizeIndex] = useState(initialSelectedSizeIndex);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [hasNewSize, setHasNewSize] = useState(false);

    useEffect(() => {
        // Check if there's a new size that is marked as editable
        const newSizeExists = sizesData.some((size) => size.editable);
        setHasNewSize(newSizeExists);
    }, [sizesData]);

    useEffect(() => {
        setSelectedSizeIndex(initialSelectedSizeIndex)
    }, [initialSelectedSizeIndex]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedSizes = Array.from(sizesData);
        const [removed] = reorderedSizes.splice(result.source.index, 1);
        reorderedSizes.splice(result.destination.index, 0, removed);

        setSizesData(reorderedSizes);
    };

    const handleInputChange = (key, value) => {
        if (selectedSizeIndex === null) return;

        setSizesData((prevData) => {
            const newData = [...prevData];

            // Parse 'price' and 'cost' as floats
            if (key === 'Price' || key === 'Cost') {
                newData[selectedSizeIndex][key] = parseFloat(value);
            } else {
                newData[selectedSizeIndex][key] = value;
            }

            return newData;
        });
    };
    const handleDeleteSize = (index) => {
        setSizesData((prevData) => {
            const newData = prevData.filter((_, i) => i !== index);

            // Handle selected index adjustment
            if (selectedSizeIndex !== null) {
                if (index === selectedSizeIndex) {
                    setSelectedSizeIndex(null); // Deselect if the deleted item was selected
                } else if (index < selectedSizeIndex) {
                    setSelectedSizeIndex((prevIndex) => prevIndex - 1); // Adjust index if necessary
                }
            }

            return newData;
        });
    };

    const handleSave = () => {
        if (hasNewSize) {
            setShowConfirmation(true); // Show confirmation if new sizes exist
        } else {
            setIsModalOpen(false); // Close modal if no new sizes
        }
    };

    // Handle location inventory change
    const handleLocationInventoryChange = (index, locationId, inventory) => {
        setSizesData((prevData) => {
            const newData = [...prevData];
            newData[selectedSizeIndex].locationInventory[index] = { locationId, inventory };
            return newData;
        });
    };

    const handleAddLocationInventory = () => {
        if (selectedSizeIndex === null) return;

        setSizesData((prevData) => {
            const newData = [...prevData];
            newData[selectedSizeIndex].locationInventory.push({ locationId: '', inventoryLevel: 50 });
            return newData;
        });
    };

    const handleDeleteLocationInventory = (index) => {
        if (selectedSizeIndex === null) return;

        setSizesData((prevData) => {
            const newData = [...prevData];
            newData[selectedSizeIndex].locationInventory = newData[selectedSizeIndex].locationInventory.filter((_, i) => i !== index);
            return newData;
        });
    };

    return (
        <>
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '80%',
                        overflow: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        display: 'flex',
                    }}
                >
                    {/* Left side: Drag-and-drop ordering */}
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Reorder Sizes
                        </Typography>
                        <Typography variant="h8" gutterBottom>
                            Drag to Reorder
                        </Typography>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="sizes" direction="vertical">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '8px',
                                        }}
                                    >
                                        {sizesData.map((size, index) => (
                                            <Draggable
                                                key={index}
                                                draggableId={`draggable-${index}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <Paper
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        elevation={3}
                                                        sx={{
                                                            padding: '16px',
                                                            textAlign: 'center',
                                                            position: 'relative',
                                                            backgroundColor: snapshot.isDragging
                                                                ? '#f0f0f0'
                                                                : '#fff',
                                                            cursor: 'grab',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }}
                                                        onClick={() => setSelectedSizeIndex(index)}
                                                    >
                                                        <Typography variant="subtitle1">
                                                            {size.Size || 'Unnamed'}
                                                        </Typography>
                                                        {size.editable && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleDeleteSize(index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        )}
                                                    </Paper>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Box>

                    {/* Right side: Size details editing */}
                    <Box sx={{ width: '50%', pl: 2}}>
                        {selectedSizeIndex !== null && selectedSizeIndex < sizesData.length && (
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Edit Size Details
                                </Typography>
                                <TextField
                                    label="Size Name"
                                    value={sizesData[selectedSizeIndex].Size || ''}
                                    onChange={(e) => handleInputChange('Size', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    required // Size name is required
                                    disabled={!sizesData[selectedSizeIndex].editable}
                                />
                                <TextField
                                    label="Price"
                                    type="number"
                                    value={sizesData[selectedSizeIndex].Price || ''}
                                    onChange={(e) => handleInputChange('Price', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    disabled={!sizesData[selectedSizeIndex].editable}
                                />
                                <TextField
                                    label="Cost"
                                    type="number"
                                    value={sizesData[selectedSizeIndex].Cost || ''}
                                    onChange={(e) => handleInputChange('Cost', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    disabled={!sizesData[selectedSizeIndex].editable}
                                />
                                <TextField
                                    label="Weight"
                                    type="number"
                                    value={sizesData[selectedSizeIndex].Weight || ''}
                                    onChange={(e) => handleInputChange('Weight', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    disabled={!sizesData[selectedSizeIndex].editable}
                                />
                                <TextField
                                    label="Weight Unit"
                                    value={sizesData[selectedSizeIndex].WeightUnit || ''}
                                    fullWidth
                                    margin="normal"
                                    disabled={true}
                                />
                                {/* Location-Inventory Management */}
                                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                                    Location Inventory
                                </Typography>
                                {sizesData[selectedSizeIndex].locationInventory.map((entry, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <Autocomplete
                                            options={locationOptions}
                                            value={
                                                locationOptions.find(option => option.id === entry.locationId) || null
                                            }
                                            onChange={(event, newValue) =>
                                                handleLocationInventoryChange(index, newValue ? newValue.id : '', entry.inventoryLevel)
                                            }
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Location"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            )}
                                            sx={{
                                                zIndex: 14000,
                                                flex: 1,
                                                mr: 2
                                            }}
                                        />
                                        <TextField
                                            label="Inventory"
                                            type="number"
                                            value={entry.inventoryLevel}
                                            onChange={(e) =>
                                                handleLocationInventoryChange(index, entry.locationId, e.target.value)
                                            }
                                            fullWidth
                                            margin="normal"
                                            sx={{
                                                flex: 1,
                                                mr: 2
                                            }}
                                        />
                                        <IconButton
                                            size="small"
                                            onClick={() => handleDeleteLocationInventory(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                                <Button
                                    variant="outlined"
                                    startIcon={<AddCircleIcon />}
                                    onClick={handleAddLocationInventory}
                                    sx={{ mt: 2 }}
                                >
                                    Add Inventory Location
                                </Button>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => setIsModalOpen(false)}
                                        sx={{ mr: 2 }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Modal>

            {/* Extra Confirmation Modal */}
            {showConfirmation && (
                <Modal open={showConfirmation} onClose={() => setShowConfirmation(false)} sx={{zIndex: 11000}}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                            textAlign: 'center',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Notice
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            These Change will be synced to the price/cost table which would also be used in new products creation. It will be reflected once the Variants Created on Shopify
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowConfirmation(false);
                                setIsModalOpen(false);
                            }}
                        >
                            OK
                        </Button>
                    </Box>
                </Modal>
            )}
        </>
    );
};

export default SizeEditModal;
