import { Autocomplete, ListItem, TextField } from "@mui/material";
import * as React from "react";

import { getAllSportsBySchool } from "../../API/roster";
import useNotification from "../../hooks/useNotification";
import {
  useSchoolContext,
  useUserContext,
} from "../../contexts/ProviderProvider";

const SchoolSportFilter = ({
  setSelectedSchool,
  selectedSchool,
  setSelectedSport,
  selectedSport,
  universitiesCount,
  hideCount
}) => {

  const schoolOptions = Object.keys(universitiesCount)

  const [schoolInput, setSchoolInput] = React.useState(
    selectedSchool ? selectedSchool : ""
  );
  const [sportInput, setSportInput] = React.useState(
    selectedSport ? selectedSport : ""
  );
  const [sports, setSports] = React.useState([]);

  const user = useUserContext();
  const notify = useNotification();

  const getCount = (university, index) => {
    return universitiesCount[university][index] !== undefined ? universitiesCount[university][index] : "0"
  }

  const getStringCount = (university) => {
    if (university === undefined || universitiesCount[university] === undefined) {
      return ""
    }
    const count = getCount(university, 0) + " : " + getCount(university, 1) + " : "

    return count
  }


  React.useEffect(() => {
    if (!user) {
      return;
    }
    if (selectedSchool?.length > 0) {
      getAllSportsBySchool(selectedSchool, user)
        .then((response) => {
          response.data.push("");
          setSports(response.data);
          setSelectedSport("");
        })
        .catch((error) => {
          console.error(error);
          notify("Failed to get all sports for that school");
          setSports([]);
        });
    }
  }, [selectedSchool, user]);

  //console.log(
  //   "selectedSchool:",
  //   selectedSchool,
  //   "selectedSport:",
  //   selectedSport,
  //   "schoolInput:",
  //   schoolInput,
  //   "sportInput:",
  //   sportInput
  // );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "25px",
      }}
    >
      <Autocomplete
        sx={{ width: "50%" }}
        options={[...schoolOptions, ""]}
        inputValue={schoolInput}
        onInputChange={(e, v) => {
          //console.log("school input", v);
          setSchoolInput(v);
        }}
        value={selectedSchool || ""}
        onChange={(e, v) => {
          //console.log("school change", v);
          setSelectedSchool(v);
        }}
        renderInput={(params) => <TextField {...params} label="School" />}
        renderOption={(props, option, state) => (
          <ListItem
            {...props}
            key={option}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid white",
            }}
          >
            {!hideCount && getStringCount(option)}{option}
          </ListItem>
        )}
      />
      <Autocomplete
        sx={{ width: "50%" }}
        options={sports}
        inputValue={sportInput}
        onInputChange={(e, v) => {
          setSportInput(v);
        }}
        value={selectedSport || sports[0]}
        onChange={(e, v) => {
          setSelectedSport(v);
        }}
        renderInput={(params) => <TextField {...params} label="Sport" />}
        renderOption={(props, option, state) => (
          <ListItem
            {...props}
            key={option}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid white",
            }}
          >
            {option}
          </ListItem>
        )}
      />
    </div>
  );
};

export default SchoolSportFilter;
