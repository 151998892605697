import { useState, useEffect } from "react";
import {
  Typography,
  Autocomplete,
  LinearProgress,
  TextField,
  ListItem,
} from "@mui/material";

import { useUserContext } from "../../../contexts/ProviderProvider.jsx";

import Matcher from "./Matcher.jsx";
import { getUnmatchedCountForUniversities } from "../../../API/roster.js";
import { useNavigate } from "react-router-dom";

const SearchSchool = (props) => {
  //console.log("search school props:", props);
  const [input, setInput] = useState(props.school ? props.school : "");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [schoolCounts, setSchoolCounts] = useState([]);

  const user = useUserContext();
  const navigate = useNavigate();

  const setSelectedSchoolViaUrl = (school) => {
    navigate(
      "/ballpark/matcher" +
        (school?.university ? "/" + btoa(school.university) : "")
    );
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    setSearchLoading(true);
    getUnmatchedCountForUniversities(user)
      .then((response) => {
        setSchoolCounts(response.data.filter((sc) => sc.licenseStatus === "Approved"));
        setSearchLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setSchoolCounts([]);
        setSearchLoading(false);
      });
  }, [user]);

  useEffect(() => {
    setSelectedSchool(
      schoolCounts.filter((sc) => sc.university === props.school)[0]
    );
    //console.log("setting input", props.school);
    setInput(props.school ? props.school : "");
  }, [props.school, schoolCounts]);

  if (!user) {
    return <></>;
  }

  //console.log("aaas:", input, "aas:", selectedSchool);
  return (
    <div style={{ width: "80%", paddingTop: "5vh" }}>
      <div style={{ width: "50%", margin: "auto" }}>
        <Typography>Search</Typography>
        {searchLoading && <LinearProgress />}
        <Autocomplete
          options={schoolCounts}
          value={selectedSchool}
          inputValue={input}
          onInputChange={(e, v) => setInput(v)}
          onChange={(e, v) => {
            setSelectedSchoolViaUrl(v);
          }}
          getOptionLabel={(option) =>
            option?.university ? option.university : ""
          }
          renderInput={(params) => <TextField {...params} />}
          renderOption={(props, option, state) => (
            <ListItem
              {...props}
              key={option.university}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid white",
              }}
            >
              <div>{option.AthleteCount + " : " + option.university}</div>
            </ListItem>
          )}
        />
      </div>
      {selectedSchool && <Matcher school={selectedSchool} />}
    </div>
  );
};

export default SearchSchool;
