import React, { useRef, useState, useEffect } from 'react';

const LazyImage = ({ src, alt, style, onClick }) => {
  const imgRef = useRef();
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true);
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0.1, // Start loading when 10% of the image is visible
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [imgRef]);

  var clickSrc = src

  if (src && src.includes("eagle.influxeradmin.com")) {
    clickSrc = src.replaceAll("dl=1", "dl=0");
  }

  return (
    <div ref={imgRef}>
      {inView ? (
        <img alt={alt} style={style} src={src} onClick={onClick ? onClick : (() => window.open(clickSrc, "_blank"))} />
      ) : (
        <div style={{ ...style, backgroundColor: '#f0f0f0' }} /> // Placeholder
      )}
    </div>
  );
};

export default LazyImage
