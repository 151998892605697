import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, onSnapshot, query, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../API/firebase.ts';
import { useUserContext } from '../../../contexts/ProviderProvider';
import useNotification from '../../../hooks/useNotification';
import { getBrand, getBrandByUserId, getDeliverable, getJob, getUser, updateDeliverable } from '../../../API/marketplace';
import { Typography } from '@mui/material';
import { Button } from 'react-bootstrap';
import Media from './Media';

const MessageThread = ({ jobId, athleteUserId }) => {
  const [conversation, setConversation] = useState();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(''); // New message state
  const [job, setJob] = useState()
  const [brand, setBrand] = useState()
  const [brandUser, setBrandUser] = useState()
  const [athlete, setAthlete] = useState()
  const [refresh, setRefresh] = useState(0)
  const [deliverableStatus, setDeliverableStatus] = useState({})

  const user = useUserContext();
  const notify = useNotification();

  // Load conversations
  useEffect(() => {
    const docRef = doc(db, "threads", `${jobId}_${athleteUserId}`);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        console.log("conversation", docSnap.data());
        setConversation({ ...docSnap.data() });
      } else {
        console.log("No such conversation!");
      }
    });

    // Cleanup function to unsubscribe from Firestore listener when the component unmounts
    return () => unsubscribe();
  }, [jobId, athleteUserId]);

  // Load messages for selected conversation
  useEffect(() => {
    if (conversation) {
      const q = query(collection(db, `threads/${jobId}_${athleteUserId}/messages`));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const msgs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(msgs)
        setMessages(msgs);
      });
      return () => unsubscribe()
    }
  }, [conversation, jobId, athleteUserId, refresh]);

  useEffect(() => {
    getJob(user, jobId).then(response => {
      console.log("got job", response.data.obj.brandID)
      setJob(response.data.obj)
    }).catch(e => {
      notify("Failed to get job")
    })
  }, [jobId])

  useEffect(() => {
    if (job) {
      getBrand(user, job.brandID).then(response => {
        console.log("got brand", response.data.obj)
        setBrand(response.data.obj)
      }).catch(e => {
        notify("failed to get brand")
      })
    }
  }, [job])


  useEffect(() => {
    if (brand) {
      getUser(user, brand?.user_id).then(response => {
        console.log("got user:", response.data.obj)
        setBrandUser(response.data.obj)
      }).catch(e => {
        notify("Failed to get user")
      })
    }
  }, [brand])

  useEffect(() => {
    if (athleteUserId) {
      getUser(user, athleteUserId).then(response => {
        console.log(response)
        setAthlete(response.data.obj)
      }).catch(e => {
        notify("Failed to get brand")
      })
    }
  }, [athleteUserId])

  useEffect(() => {
    if (!messages || messages.length === 0) {
      return;
    }

    console.log("deliverables-m", messages);
    const deliverables = messages.map(mess => mess.deliverableAssetID).filter(Number);

    const fetchDeliverables = async () => {
      try {
        const responses = await Promise.all(deliverables.map(id => getDeliverable(user, id)));
        const statusMap = {}
        responses.forEach(resp => {
          statusMap[resp.data.obj.DeliverableAsset.id] = resp.data.obj.DeliverableAsset.state + " - " + resp.data.obj.DeliverableAsset.reason
        })
        setDeliverableStatus(statusMap)
      } catch (error) {
        notify("Error populating deliverables")
      }
    };

    fetchDeliverables();
  }, [user, messages]);

  useEffect(() => {
    setDeliverableStatus({})
  }, [refresh])


  // Handle sending a new message
  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (newMessage.trim() !== '') {
      try {
        const messageData = {
          userID: brand?.user_id,
          text: newMessage,
          messageType: "Text", // Assuming text messages, adjust if needed
          lastUpdateTime: serverTimestamp(),
        };

        console.log("time", jobId, athleteUserId, messageData)

        // Add message to Firestore
        await addDoc(collection(db, `threads/${jobId}_${athleteUserId}/messages`), messageData);

        // Clear input field after sending
        setNewMessage('');
      } catch (error) {
        console.error("Error sending message: ", error);
        notify("Error sending message");
      }
    }
  };


  const handleDeliverableStatusUpdate = (approved, deliverableAssetID, reason) => {
    updateDeliverable(user, deliverableAssetID, jobId, athleteUserId, approved, reason).then(response => {
      setRefresh(p => p + 1)
    }).catch(e => {
      notify("Failed to update deliverables")
    })

  }

  const getNameFromId = (id) => {
    if (!id) { return "" }
    if (brand?.id === id) {
      return brand.name
    }

    if (athlete?.id === id) {
      return athlete.name
    }
    return ""
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {/* Messages for the selected conversation */}
      <Typography variant='h3'> {athlete?.name} X {brand?.name}</Typography>
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column-reverse", width: '100%', padding: '10px', overflowY: 'scroll', height: "70vh", border: "solid white 5px" }}>
        {conversation ? (
          <>
            <div>
              {messages
                .sort((a, b) => a.lastUpdateTime?.seconds - b.lastUpdateTime?.seconds)
                .map((message) => (
                  <div
                    key={message.id}
                    style={{
                      display: 'flex',
                      justifyContent: message.userID === brand?.user_id ? 'flex-end' : 'flex-start',
                      paddingBottom: '20px',
                    }}
                  >
                    <div
                      style={{
                        maxWidth: '60%',
                        padding: '10px',
                        borderRadius: '15px',
                        color: 'black',
                        backgroundColor: message.userID === brand?.user_id? '#DCF8C6' : '#F1F0F0',
                      }}
                    >
                      <strong>{getNameFromId(message.userID)}</strong>
                      {message.messageType === 'Text' && (
                        <p style={{ margin: '5px 0', color: 'black', whiteSpace: "pre-wrap" }}>{message.text}</p>
                      )}
                      {message.messageType === 'Deliverable' && (
                        <>
                          <br />
                          {deliverableStatus[message.deliverableAssetID]}
                          <Media src={message.text} style={{ maxHeight: "360", width: "100%" }} />
                          <Button onClick={() => { handleDeliverableStatusUpdate(false, message.deliverableAssetID, "Off Brief") }}>Deny - Off Brief</Button>
                          <Button onClick={() => { handleDeliverableStatusUpdate(false, message.deliverableAssetID, "Minor Changes") }}>Deny - Minor Changes</Button>
                          <Button onClick={() => { handleDeliverableStatusUpdate(true, message.deliverableAssetID, "Accept") }}>Accept</Button>
                          <Button onClick={() => { alert(message.text) }}>Copy</Button>
                        </>
                      )}
                      <br />
                      <small style={{ fontSize: '10px', color: '#888' }}>
                        {new Date(message.lastUpdateTime?.seconds * 1000).toLocaleString()}
                      </small>
                    </div>
                  </div>
                ))}
              {conversation.isTyping[athleteUserId] ? "typing..." : ""}
            </div>
          </>
        ) : (
          <p>No Conversation Found</p>
        )}
      </div>

      {/* Text box for sending messages */}
      <form onSubmit={handleSendMessage} style={{ display: 'flex', width: '100%', padding: '10px' }}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          style={{
            flexGrow: 1,
            padding: '10px',
            borderRadius: '20px',
            border: '1px solid #ccc',
            marginRight: '10px',
          }}
        />
        <button type="submit" style={{ padding: '10px 20px', borderRadius: '20px', background: '#4CAF50', color: 'white', border: 'none' }}>
          Send
        </button>
      </form>
    </div>
  );
};

export default MessageThread;


