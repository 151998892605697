import { Modal, Box, Typography, Button } from "@mui/material";
import React from "react";

const AreYouSure = ({
  open,
  oldValue,
  newValue,
  message,
  no,
  yes,
  noText,
  yesText,
  options,
  handleClose,
  style,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Are you sure?
        </Typography>
        <Typography sx={{ mt: 2 }} style={{ whiteSpace: "pre-line" }}>
          {message}
        </Typography>
        {oldValue && oldValue !== "" && (
          <Typography
            sx={{ mt: 2 }}
            style={{ color: "Red", whiteSpace: "pre-line" }}
          >
            From: {oldValue}
          </Typography>
        )}
        {newValue && newValue !== "" && (
          <Typography
            sx={{ mt: 2 }}
            style={{ color: "Green", whiteSpace: "pre-line" }}
          >
            To: {newValue}
          </Typography>
        )}
        {!options ? (
          <div style={{ display: "flex" }}>
            <Button
              style={{
                margin: "10px",
                backgroundColor: "Green",
                color: "White",
              }}
              onClick={yes}
            >
              {yesText ? yesText : "Yes"}
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "Red", color: "White" }}
              onClick={no}
            >
              {noText ? noText : "No"}
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            {options.map((o, index) => (
              <Button
                style={{
                  margin: "10px",
                  backgroundColor: o.color,
                  color: "White",
                }}
                onClick={o.onClick}
                key={o.key || index}
              >
                {o.text}
              </Button>
            ))}
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default AreYouSure;
