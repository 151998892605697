import * as React from "react";
import SocialAccountsTable from "./SocialAccountsTable";
import CreateSocialAccount from "./CreateSocialAccount";
import { Divider, Tab, Tabs } from "@mui/material";
import Overview from "./Overview";
import LicenseTracking from "./LicenseTracking";
import CommissionTable from "./ComissionTable";
import { PlayerOutreach } from "./PlayerOutreach";
import { SchoolOutreach } from "./SchoolOutreach";
import useCanUser from "../../../hooks/useCanUser";
import Can from "../../common/Can";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

const Outreach = (props) => {
    const [refresh, setRefresh] = React.useState(0);
    const { actions } = usePermissionContext();
    const tab = useCanUser(actions.VIEW, [actions.PLAYEROUTREACH]) ? "PlayerOutreach" : "SchoolOutreach";
    const [activeTab, setActiveTab] = React.useState(tab);

    return (
        <div
            style={{
                width: "90%",

                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",

            }}
        >
            <Divider sx={{ marginBottom: "15px" }} />
            <Tabs value={activeTab} onChange={(e, n) => setActiveTab(n)} variant="fullWidth" style={{ marginTop: "15px" }}>
                {useCanUser(actions.VIEW, [actions.PLAYEROUTREACH]) && <Tab label="Player Outreach" value="PlayerOutreach" />}
                {useCanUser(actions.VIEW, [actions.SCHOOLOUTREACH]) && <Tab label="School OutReach" value="SchoolOutreach" />}
            </Tabs>
            <Divider />

            {activeTab === "PlayerOutreach" ? (
                <>
                    {<PlayerOutreach />}
                </>
            ) : (activeTab == "SchoolOutreach" && <SchoolOutreach />)}
        </div>
    );
};

export default Outreach;
