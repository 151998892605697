import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react"
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { getBrand, getJob } from "../../../API/marketplace.js"
import Header from "./Header";
import JobUsers from "./JobUsers";

const Campaign = ({id}) => {
  console.log("id", id)

  const user = useUserContext()
  const notify = useNotification()

  const [campaign, setCampaign] = useState({})
  const [brand, setBrand] = useState({})

  useEffect(() => {
    getJob(user, id)
      .then(response => {
        setCampaign(response.data.obj)
      }).catch(e => {
        notify("Failed to get job")
      })
  }, [user, id])

  useEffect(() => {
    if (!campaign || !campaign.brandID) {
      return
    }
    getBrand(user, campaign.brandID)
      .then(response => {
        setBrand(response.data.obj)
      }).catch(e => {
        notify("Failed to get brand")
      })
  }, [user, campaign])

  return (
    <div style={{ width: "100%" }}>
      <Header job={campaign} brand={brand} />
      {campaign && <JobUsers job={campaign} />}
    </div>
  )

}

export default Campaign;
