import * as React from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";
import Campaigns from "./Campaigns";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.MARKETPLACE]} els={<Navigate to="/ballpark" />}>
      <Campaigns />
    </Can>
  );
}

export const icon = <DirectionsRunIcon />;
export const text = "Campaigns";
export const componentName = "Campaigns";
export const path = "campaigns";
