import { useState } from "react";

import MUIDataTable from "mui-datatables";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Button,
  CircularProgress,
} from "@mui/material";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import { matchRosterRecords } from "../../../API/roster";
import { useUserContext } from "../../../contexts/ProviderProvider";
import AreYouSure from "../../common/AreYouSure";
import useNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";

const buildAYSMessage = (action) => {
  if (action.action === "create") {
    return (
      "Are you sure you want to create a new roster spot for " +
      action.new.full_name +
      "?"
    );
  }
  if (action.action === "merge") {
    return (
      "Are you sure you want to merge " +
      action.new.full_name +
      " and " +
      action.existing.full_name +
      "? They will become one record."
    );
  }
  if (action.action === "disconnect") {
    return (
      "Are you sure you want to disconnect " +
      action.existing.full_name +
      "? This will result in two records, one empty roster spot, and one un-matched player. You can transfer the un-matched player later if you want. "
    );
  }
  if (action.action === "replace") {
    return (
      "Are you sure you want to replace " +
      action.existing.full_name +
      " with " +
      action.new.full_name +
      "? The athlete already associated with this record will be moved to the un-matched list?"
    );
  }
};

const ReassignRosterSpot = (props) => {
  return (
    <Button
      sx={{ backgroundColor: "success.dark", color: "text.primary" }}
      onClick={() => {
        props.matchRecords("replace", props.existing);
      }}
    >
      Reassign Roster Spot
    </Button>
  );
};

const AssignRosterSpot = (props) => {
  return (
    <Button
      sx={{ backgroundColor: "success.dark", color: "text.primary" }}
      onClick={() => {
        props.matchRecords("merge", props.existing);
      }}
    >
      Assign Roster Spot
    </Button>
  );
};

const UnAssign = (props) => {
  return (
    <Button
      sx={{ backgroundColor: "error.main", color: "text.primary" }}
      onClick={() => {
        props.matchRecords("disconnect", props.existing);
      }}
    >
      Un-Assign
    </Button>
  );
};

const ExpandableRow = (props) => {
  const row = props.data;
  return (
    <div style={{ display: "flex" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Sport</TableCell>
              <TableCell>Is International</TableCell>
              <TableCell>Jersey Number</TableCell>
              <TableCell>Posted On Social</TableCell>
              <TableCell>Added On Site</TableCell>
              <TableCell>Signed</TableCell>
              {row.is_matched?<TableCell>Email / Phone number</TableCell> :""}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={row.collection_id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{ padding: "25px" }}>
                {row.sport}
              </TableCell>
              <TableCell>
                <TrueFalseCheckbox checked={row.is_international} disabled />
              </TableCell>
              <TableCell>{row.jersey_number}</TableCell>
              <TableCell>
                <TrueFalseCheckbox checked={row.postedOnSocial} disabled />
              </TableCell>
              <TableCell>
                <TrueFalseCheckbox checked={row.addedOnSite} disabled />
              </TableCell>
              <TableCell>
                <TrueFalseCheckbox checked={row.signed} disabled />
              </TableCell>
              {row.is_matched?<TableCell component="th" scope="row" style={{ padding: "25px" }}>
                {row?.StudentAthlete?.User?.email}
                {"\n" + row?.StudentAthlete?.phone}
              </TableCell>:""}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const RosterEditTable = (props) => {
  const [action, setAction] = useState(undefined);
  const [rowsPerPage, setRowsPerPage] = useState(200); // default number of rowsPerPage to render

  const user = useUserContext();
  const notify = useNotification();
  const navigate = useNavigate();

  const matchRecords = (type, existing) => {
    const payload = { action: type, existing, new: props.selected };

    setAction(payload);
  };

  const actionMappings = {
    create: "add",
    merge: "assign",
    replace: "reassign",
    disconnect: "un-assign",
  };

  const takeAction = (payload) => {

    matchRosterRecords(payload, user, () => {
      props.reload();
      props.resetSelected();
    })
      .then((response) => {
        notify({
          msg: `${actionMappings[payload.action]}ed roster record`,
          variant: "success",
        });
        props.reload();
        props.resetSelected();
      })
      .catch((error) => {
        console.error(error);
        notify(`Failed to ${actionMappings[payload.action]} roster record`);
        props.reload();
        props.resetSelected();
      });
    
    setAction(undefined);
  };

  const aYSYes = () => {
      takeAction(action);
  };

  const rows = props.data
    ?.filter((d) => {
      return d?.sport === props.selected?.sport;
    })
    .filter((s) => {
      return (s.athlete_id && s.is_matched) || (!s.athlete_id && !s.is_matched);
    })
    .map((athlete) => {
      return {
        ids: athlete.idAthleteMerchInfo + " : " + athlete.athlete_id,
        athlete_name: athlete.full_name,
        data: athlete,
        assign: athlete.athlete_id ? (
          <ReassignRosterSpot existing={athlete} matchRecords={matchRecords} />
        ) : (
          <AssignRosterSpot existing={athlete} matchRecords={matchRecords} />
        ),
        unassign: athlete.athlete_id ? (
          <UnAssign existing={athlete} matchRecords={matchRecords} />
        ) : (
          <></>
        ),
      };
    })
    .sort((a, b) => (a.athlete_name || '').localeCompare(b.athlete_name || ''));

  const columns = [
    { label: "Ids", name: "ids", options: {
      display: 'excluded'
    }},
    {
      name: "athlete_name",
      label: "Athlete Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button sx={{textTransform: 'unset'}}
            onClick={() =>
              navigate(`/ballpark/athletes/${btoa(tableMeta.rowData[0])}`)
            }
          >
            {value}
          </Button>
        ),
      },
    },
    { label: "Assign", name: "assign" },
    { label: "Un Assign", name: "unassign" },
  ];
  const options = {
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: rowsPerPage,
    expandableRows: true,
    onChangeRowsPerPage: (num) => setRowsPerPage(num),
    renderExpandableRow: (rowData, rowMeta, a) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandableRow data={rows[rowMeta.dataIndex].data} />
          </TableCell>
        </TableRow>
      );
    },
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: props.loading ? (
          <CircularProgress />
        ) : (
          "No Open Roster Records for that Sport :("
        ),
      },
    },
  };

  return (
    <div>
      <MUIDataTable data={rows} options={options} columns={columns} />
      {action && (
        <AreYouSure
          style={{
            backgroundColor: "background.paper",
            margin: "auto",
            marginTop: "10vh",
            padding: "20px",
            width: "30vw",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "5px",
          }}
          open={action !== undefined}
          message={buildAYSMessage(action)}
          yes={() => aYSYes()}
          no={() => setAction(undefined)}
        />
      )}
    </div>
  );
};

export default RosterEditTable;
