import * as React from "react";
import Can from "../../common/Can";
import { usePermissionContext } from "../../../contexts/ProviderProvider";
import { Accordion, AccordionSummary, CircularProgress, Typography } from "@mui/material";
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Navigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";

// Transfer Portal for Athletes Page for individual athletes

const TransferProfile = ({ transfers }) => {

    const { views } = usePermissionContext();

    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {

    }, [transfers]);

    const columns = [
        {
            name: "old_university",
            label: "Old University",
        },
        {
            name: "reason",
            label: "Reason",
        },
        {
            name: "notes",
            label: "Notes",
        },
    ];

    const options = {
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: transfers.length < 0 ?
                    <CircularProgress />
                    : "No Records"
            },
        },
    }

    return (
        <>

            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                disabled={transfers.length > 0 ? false : true}
            >
                <AccordionSummary
                    expandIcon={transfers.length > 0 && <ArrowDownwardIcon />}
                    aria-controls="TransferPortal-content"
                    id="TransferPortal-header"
                >
                    <Typography>Transfer Portal {transfers?.length | 0}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Can action={views.VIEW} items={[views.TRANSFERPORTAL]} els={<Navigate to="/ballpark" />}>
                        <div
                            style={{
                                margin: "20px 10px"
                            }}>
                            {transfers ?
                                <MUIDataTable
                                    data={transfers}
                                    columns={columns}
                                    options={options}
                                />
                                :
                                <Typography variant="h6">Data not found for Grand Slam</Typography>
                            }
                        </div>
                    </Can>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default TransferProfile;
