import axios from "axios";

const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;

export const uploadProductAssets = (file, meta, user) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("meta", JSON.stringify(meta));

  return axios.post(BASE_HATTRICK_URL + "filesystem/upload/product", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};

export const uploadCollectionAssets = (file, meta, user) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("meta", JSON.stringify(meta));

  return axios.post(
    BASE_HATTRICK_URL + "filesystem/upload/collection",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + user.accessToken,
      },
    }
  );
};

export const uploadLicense = (file, meta, user) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("meta", JSON.stringify(meta));

  return axios.post(BASE_HATTRICK_URL + "filesystem/uploadLicense", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};

export const uploadZippedAssets = (files, meta, user) => {
  const formData = new FormData();
  //console.log(files);
  files.forEach((file) => {
    formData.append("zip", file);
  });
  formData.append("meta", JSON.stringify(meta));

  return axios.post(BASE_HATTRICK_URL + "filesystem/uploadZip", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};


export const uploadSchoolIcon = (file, meta, user) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("meta", JSON.stringify(meta));

  return axios.post(BASE_HATTRICK_URL + "filesystem/uploadSchoolIcon", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.accessToken,
    },
  });
};