import {useState, useEffect} from "react";
import Button from '@mui/material/Button';
import { Typography, LinearProgress } from "@mui/material";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import AreYouSure from "../../common/AreYouSure";

import { getBig3Players, updateBig3Players } from "../../../API/parameterStore";

import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { getRosterAndCollection } from "../../../API/roster";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";

const Big3 = (props) => {
  const [submit, setSubmit] = useState(undefined);
  const [big3, setBig3] = useState([]);
  const [big3Metadata, setBig3Metadata] = useState();
  const [loadingAthletes, setLoadingAthletes] = useState(true);

  const user = useUserContext();
  const notify = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return;
    }
    getBig3Players(user)
    .then((response) => {
        setBig3(response.data.split(','));
        getRosterAndCollection(user)
        .then((response_in) => {
            const selected = response_in.data.map((item) => {
                const isSelected = response.data.split(',').includes(item.Collection.shopify_collection_id);
                return { ...item, selected: isSelected ? 1 : 0 };
              })
            setBig3Metadata(selected);
            setLoadingAthletes(false);
        })
        .catch((error) => {
            notify('Unable to fetch Big 3 Players metadata');
            setLoadingAthletes(false);
        })
    })
    .catch((error) => {
        // console.log('Error fetching Big3 players', error);
        notify('Unable to fetch Big 3 Players');
        setLoadingAthletes(false);
    })
  }, [user, notify]);

  const handleSubmit = () => {
    if (big3.length !== 3) {
        notify('Please select exactly 3 athletes');
    }
    else {
        updateBig3Players(user, big3)
        .then((response) => {
            notify({msg:"Wrote Big 3 Players", variant:"success"})
        })
        .catch((error) => {
            // console.log(error)x
            notify("Failed to write Big 3 Players")
        })
    }
    
    setSubmit(undefined)
    
  };

  const buildMessage = () => {
    const selectedNames = big3Metadata.filter((b3) => b3.selected).map((b3) => b3.full_name).join(", ")
    return(`You want to update the Big 3 Players to ${selectedNames}?\n
    Please remember to double check on wildcat.`)
  }

  const stageAlteration = (value, rowData) => {
    const targetRosterId = rowData[4]; // Get the target roster_id
  
    // Find the object with the matching roster_id in big3Metadata
    const matchingObjectIndex = big3Metadata.findIndex(
      (obj) => obj.idAthleteMerchInfo === targetRosterId
    );
  
    if (matchingObjectIndex !== -1) {
      setBig3Metadata((prev) => {
        const accounts = [...prev];
        accounts[matchingObjectIndex]["selected"] = value;
        return accounts;
      });

      if(value){
        setBig3((prev) => {
            const updatedBig3 = [...prev, big3Metadata[matchingObjectIndex].Collection.shopify_collection_id];
            return updatedBig3;
          });
      }
      else {
        setBig3((prev) => {
            const updatedBig3 = prev.filter((id) => id !== big3Metadata[matchingObjectIndex].Collection.shopify_collection_id);
            return updatedBig3;
          });
      }
    }
  };

  const columns = [
    {
        name: "selected",
        label: "Selected",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                <TrueFalseCheckbox checked={value} 
                    interact={() => {
                        stageAlteration(
                            !value,
                            tableMeta.rowData
                        );
                    }} 
                />
            ),
        },
    },
    {
      name: "full_name",
      label: "Full Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button sx={{textTransform: 'unset'}}
            onClick={() =>
              navigate(`/ballpark/athletes/${btoa(tableMeta.rowData[4])}`)
            }
          >
            {value}
          </Button>
        ),
      },
    },
    { name: "jersey_number", label: "Jersey Number", options: {} },
    { name: "sport", label: "Sport", options: {} },
    {
      name: "idAthleteMerchInfo",
      label: "roster_id",
      options: {},
    },
  ];

  const options = {
    filter: true,
    sortOrder: {name:"selected", direction:'desc'},
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [5,10,50,100],
  };

  return (
    <div style={{ width: "80%", paddingTop: "5vh" }}>
            <Typography variant="h1">Big 3 Players</Typography>
            {loadingAthletes && <LinearProgress />}
        <div 
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            {big3Metadata && big3Metadata.filter((b3) => big3.includes(b3.Collection.shopify_collection_id)).map((big3_obj) => (
                <div key={big3_obj.Collection.collection_id} 
                style={{
                    margin: "10px 10px", 
                    flexDirection: "column",}}>
                    {big3_obj.Collection.photo !== "" ?
                        <img
                            alt="collection"
                            style={{ maxHeight: "300px", background: "White", borderRadius: "10px" }}
                            src={big3_obj.Collection.photo}
                        />:
                        <Typography variant="h4" align="center">No Photo</Typography>
                    }
                    <Typography variant="h4" align="center">{big3_obj.full_name}</Typography>
                </div>
            ))}
        </div>
        {big3Metadata && 
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        // margin: "20px 20px",
                        alignItems: "center",
                    }}>
                    <Typography variant="h6" align="center">
                        Once you've selected one or more new <b>Big 3 Players</b> save your changes.
                    </Typography>
                    <Button
                        variant="contained" 
                        color="primary" 
                        onClick={() => setSubmit(true)}
                        >
                        Save
                    </Button>
                </div>
                <div
                    style={{
                        margin: "10px 10px"
                    }}>
                    <MUIDataTable
                        title={"Athletes"}
                        data={big3Metadata}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        }
        {submit && (
            <AreYouSure
            style={{
                backgroundColor: "background.paper",
                margin: "auto",
                marginTop: "10vh",
                padding: "20px",
                width: "30vw",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "5px",
            }}
            open={submit !== undefined}
            message={buildMessage()}
            yes={() => handleSubmit()}
            no={() => setSubmit(undefined)}
            />
        )}
    </div>
  );
};

export default Big3;