import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useSchoolContext,
  useUserContext,
} from "../../../contexts/ProviderProvider";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { COMPLETE_SPORTS_LIST, VENDORS } from "../../../utilities/utils";
import {
  Autocomplete,
  Collapse,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import AthleteSearch from "./AthleteSearch";
import { createProducts } from "../../../API/products";
import useNotification from "../../../hooks/useNotification";
import {
  findProductMetadataMatching,
  getAllProductConfigurations,
} from "../../../API/productConfiguration";
import { ManufacturingData } from "./ConfigureNewProduct";
import JerseyNumberSelector from "./JerseyNumberSelector";
import MatchingSimilarProducts from "./MatchingSimilarProducts";

const buildSKU = (color, unique, type) => {
  const format = (str) => {
    console.log(
      "formatting:",
      str,
      str === undefined,
      str === null,
      str === ""
    );
    if (str && str !== "") {
      return str + " ";
    }
    if (str === undefined || str === null || str === "") {
      return "";
    }
  };
  if (color && type) {
    return `${color} ${format(unique)}${type}`;
  }
  return "";
};

const GetColorOptions = (productConfiguration, type) => {
  if (type && productConfiguration && productConfiguration[type]) {
    return Object.keys(productConfiguration[type]);
  }
  return [];
};

const GetConfiguration = (productConfiguration, type, color, onClick) => {
  const [selectedProductConfiguration, setSelectedProductConfiguration] =
    useState();
  const [hovering, setHovering] = useState();

  if (type && color) {
    if (productConfiguration[color] && productConfiguration[color][type]) {
      return (
        <div
          style={{
            display: "flex",
            direction: "row",
            gap: "1rem",
          }}
        >
          {productConfiguration[color][type].map((matching) => (
            <div
              style={{
                border:
                  selectedProductConfiguration?.product_config_id ===
                    matching.product_config_id
                    ? "solid green 2px"
                    : hovering?.product_config_id === matching.product_config_id
                      ? "solid gray 2px"
                      : "none",
              }}
              id={matching.product_config_id}
              onMouseOver={() => {
                setHovering(matching);
              }}
              onMouseLeave={() => {
                setHovering();
              }}
              onClick={() => {
                onClick(matching);
                setSelectedProductConfiguration(matching);
              }}
            >
              <ManufacturingData
                color={matching.color_reference}
                size={matching.size_range}
                style={matching.style_reference}
              />
            </div>
          ))}
        </div>
      );
    }
    return undefined;
  }
  return undefined;
};

const ErrorText = ({ message }) => {
  return <p style={{ color: "#f44336", fontSize: "0.75rem" }}>{message}</p>;
};

const filterVendors = (inputProdType) => {
  var TP = ["Coaster", "Jersey", "Blanket", "Tumbler", "Number Necklace"]
  var full = VENDORS
  if (TP.includes(inputProdType)) {
    return full.filter(ven => ven != "Influxer Merch")
  }

  return full
}

const CreateProductForm = () => {
  const universities = useSchoolContext().schools?.map((s) => s.schoolName);
  const [productConfiguration, setProductConfigurations] = useState();
  const [similarProducts, setSimilarProducts] = useState([]);
  const [typeCheck, setTypeCheck] = useState();

  const user = useUserContext();
  const notify = useNotification();

  const [loading, setLoading] = useState(false);

  const handleSubmit = (obj) => {
    let formObj = { ...obj };
    if (formObj.university === "" || formObj.university === undefined) {
      delete formObj.university;
    }
    if (formObj.athleteId === "" || formObj.athleteId === undefined) {
      delete formObj.athleteId;
    }
    if (formObj.sport === "" || formObj.sport === undefined) {
      delete formObj.sport;
    }
    if (formObj.jersey_number[0] !== -2) {
      if (formObj.jersey_number[0] === -1) {
        formObj.jersey_number[0] = "00";
      }
      formObj.jersey_number =
        formObj.jersey_number[0] + "-" + formObj.jersey_number[1];
    } else {
      delete formObj.jersey_number;
    }

    setLoading(true);
    createProducts(formObj, user)
      .then((response) => {
        setLoading(false);
        notify({ msg: "Successfully created product", variant: "success" });
      })
      .catch((err) => {
        setLoading(false);
        notify("Failed to create product");
      });
  };

  useEffect(() => {
    getAllProductConfigurations(user)
      .then((response) => {
        setProductConfigurations(response.data);
        setLoading(false);
      })
      .catch((err) => {
        notify("Failed to get product configurations");
        setLoading(false);
      });
  }, [user, notify]);

  const validationSchema = Yup.object().shape({
    skuName: Yup.string(),
    color: Yup.string().required("Color is required"),
    unique: Yup.string(),
    type: Yup.string().required("Type is required"),
    university: Yup.string(),
    sport: Yup.string().test(
      "verify its a string",
      "Your sport is invalid",
      (value) => typeof value === "string" || value instanceof String || !value
    ),
    jersey_number: Yup.array(),
    athleteId: Yup.number(),
    gender: Yup.string(),
    approvalStatus: Yup.boolean().oneOf(
      [true, false],
      "Approval Status is required"
    ),
    vendor: Yup.string().required("Vendor is required"),
    colorReference: Yup.string(),
    styleReference: Yup.string(),
    sizeRange: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      athleteId: "",
      skuName: "",
      gender: "",
      color: "",
      type: "",
      unique: "",
      university: undefined,
      sport: undefined,
      jersey_number: [-2, -1],
      athleteId: undefined,
      approvalStatus: false,
      vendor: "",
      sizeRange: "",
      styleReference: "",
      colorReference: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const controller = new AbortController();

    const config = {
      sku_name: formik.values.skuName,
      color: formik.values.color,
      type: formik.values.type,
      university: formik.values.university,
      style: formik.values.styleReference,
      color_reference: formik.values.colorReference,
      size_range: formik.values.sizeRange,
      athlete_id: formik.values.athleteId,
      sport: formik.values.sport,
      // approval_status: formik.values.approvalStatus ? 1 : null,
      vendor: formik.values.vendor,
      jersey_number: formik.values.jersey_number,
      gender: formik.values.gender,
    };

    console.log("pre", config);
    for (var field in config) {
      if (Object.prototype.hasOwnProperty.call(config, field)) {
        if (
          config[field] === undefined ||
          config[field] === null ||
          config[field] === ""
        ) {
          delete config[field];
        }
      }
    }
    if (config["jersey_number"][0] === -2) {
      delete config["jersey_number"];
    }

    console.log("post", config);

    findProductMetadataMatching(config, controller.signal, user)
      .then((resp) => {
        setSimilarProducts(resp.data);
        console.log(resp.data);
      })
      .catch((e) => {
        notify(e.response.data);
      });

    return () => {
      controller.abort();
    };
  }, [formik.values]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      {loading && <LinearProgress />}
      <form onSubmit={formik.handleSubmit}>
        <AthleteSearch
          updateForm={(athleteId) =>
            formik.setFieldValue("athleteId", athleteId)
          }
        />
        {formik.touched.athleteId && formik.errors.athleteId && (
          <div>{formik.errors.athleteId}</div>
        )}
        <Autocomplete
          style={{ paddingTop: "10px" }}
          value={formik.values.university}
          onChange={(event, newValue) => {
            formik.setFieldValue("university", newValue);
          }}
          onBlur={() => { }}
          options={[...universities, ""]}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="University"
              variant="outlined"
              style={{ width: "100%" }}
              InputProps={{
                ...params.InputProps,
              }}
              type="text"
            />
          )}
        />
        {formik.touched.university && formik.errors.university && (
          <ErrorText message={formik.errors.university} />
        )}
        <Autocomplete
          style={{ paddingTop: "10px" }}
          value={formik.values.gender}
          onChange={(event, newValue) => {
            formik.setFieldValue("gender", newValue);
          }}
          onBlur={() => { }}
          options={["Male", "Female", ""]}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Gender"
              variant="outlined"
              style={{ width: "100%" }}
              InputProps={{
                ...params.InputProps,
              }}
              type="text"
            />
          )}
        />
        {formik.touched.gender && formik.errors.gender && (
          <ErrorText message={formik.errors.gender} />
        )}
        <Autocomplete
          style={{ paddingTop: "15px" }}
          value={formik.values.sport}
          onChange={(event, newValue) => {
            formik.setFieldValue("sport", newValue);
          }}
          onBlur={formik.handleBlur}
          options={COMPLETE_SPORTS_LIST}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sports"
              variant="outlined"
              style={{ width: "100%" }}
              InputProps={{
                ...params.InputProps,
              }}
              type="text"
            />
          )}
        />
        {formik.touched.sport && formik.errors.sport && (
          <ErrorText message={formik.errors.sport} />
        )}
        <JerseyNumberSelector formik={formik} force={typeCheck} />

        <Autocomplete
          style={{ paddingTop: "15px" }}
          value={formik.values.color}
          onChange={(event, newValue) => {
            formik.setFieldValue("color", newValue);
            formik.setFieldValue(
              "skuName",
              buildSKU(newValue, formik.values.unique, formik.values.type)
            );
          }}
          onBlur={(a) => {
            formik.handleBlur(a);
            formik.setTouched({ ...formik.touched, color: true });
            formik.setFieldValue("color", a.target.value);
            formik.setFieldValue(
              "skuName",
              buildSKU(a.target.value, formik.values.unique, formik.values.type)
            );
          }}
          options={
            productConfiguration ? Object.keys(productConfiguration) : []
          }
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Color"
              variant="outlined"
              style={{ width: "100%" }}
              InputProps={{
                ...params.InputProps,
              }}
              type="text"
              error={formik.touched.color && Boolean(formik.errors.color)}
              helperText={formik.touched.color && formik.errors.color}
            />
          )}
        />
        <TextField
          id="unique"
          name="unique"
          label="Unique"
          variant="outlined"
          style={{ marginTop: "10px" }}
          fullWidth
          onChange={(e, newValue) => {
            formik.setFieldValue("unique", newValue);
            formik.setFieldValue(
              "skuName",
              buildSKU(formik.values.color, newValue, formik.values.type)
            );
          }}
          onBlur={(e, f) => {
            formik.handleBlur(e, f);
            formik.setFieldValue("unique", e.target.value);
            formik.setFieldValue(
              "skuName",
              buildSKU(formik.values.color, e.target.value, formik.values.type)
            );
          }}
          value={formik.values.unique}
          error={formik.touched.unique && Boolean(formik.errors.unique)}
          helperText={formik.touched.unique && formik.errors.unique}
        />
        <Autocomplete
          style={{ paddingTop: "15px" }}
          value={formik.values.type}
          onChange={(event, newValue) => {
            formik.setFieldValue("type", newValue);
            formik.setFieldValue(
              "skuName",
              buildSKU(formik.values.color, formik.values.unique, newValue)
            );

            setTypeCheck(false);
          }}
          onBlur={(a) => {
            formik.handleBlur(a);
            formik.setTouched({ ...formik.touched, type: true });
            formik.setFieldValue("type", a.target.value);
            formik.setFieldValue(
              "skuName",
              buildSKU(
                formik.values.color,
                formik.values.unique,
                a.target.value
              )
            );
          }}
          options={GetColorOptions(productConfiguration, formik.values.color)}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type"
              variant="outlined"
              style={{ width: "100%" }}
              InputProps={{
                ...params.InputProps,
              }}
              type="text"
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
            />
          )}
        />
        <TextField
          id="skuName"
          name="skuName"
          label="SKU Name"
          variant="outlined"
          disabled
          style={{ marginTop: "20px" }}
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.skuName}
          error={formik.touched.skuName && Boolean(formik.errors.skuName)}
          helperText={formik.touched.skuName && formik.errors.skuName}
        />
        {GetConfiguration(
          productConfiguration,
          formik.values.type,
          formik.values.color,
          (productConfiguration) => {
            formik.setFieldValue(
              "colorReference",
              productConfiguration.color_reference
            );
            formik.setFieldValue("sizeRange", productConfiguration.size_range);
            formik.setFieldValue(
              "styleReference",
              productConfiguration.style_reference
            );
            formik.setFieldTouched("sizeRange", true);
            formik.setFieldTouched("styleReference", true);
            formik.setFieldTouched("colorReference", true);
            formik.setFieldError("sizeRange", undefined);
            formik.setFieldError("styleReference", undefined);
            formik.setFieldError("colorReference", undefined);
          }
        )}
        <Autocomplete
          style={{ paddingTop: "15px" }}
          value={formik.values.vendor}
          onChange={(event, newValue) => {
            formik.setFieldValue("vendor", newValue);
          }}
          onBlur={(a) => {
            formik.handleBlur(a);
            formik.setTouched({ ...formik.touched, vendor: true });
            formik.setFieldValue("vendor", a.target.value);
          }}
          options={filterVendors(formik.values.type)}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Vendor"
              variant="outlined"
              style={{ width: "100%" }}
              InputProps={{
                ...params.InputProps,
              }}
              type="text"
              error={formik.touched.vendor && Boolean(formik.errors.vendor)}
              helperText={formik.touched.vendor && formik.errors.vendor}
            />
          )}
        />
        <Tooltip
          title="Check this box if there's a school logo or mark on this item"
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            },
          }}>
          <FormControlLabel
            style={{ marginTop: "20px" }}
            control={
              <Checkbox
                id="approvalStatus"
                name="approvalStatus"
                checked={formik.values.approvalStatus}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label="Royalty Bearing"
          />
        </Tooltip>
        <br />
        {formik.touched.approvalStatus && formik.errors.approvalStatus && (
          <ErrorText message={formik.errors.approvalStatus} />
        )}
        {formik.touched.colorReference && formik.errors.colorReference && (
          <ErrorText message={formik.errors.colorReference} />
        )}
        {formik.touched.sizeRange && formik.errors.sizeRange && (
          <ErrorText message={formik.errors.sizeRange} />
        )}
        {formik.touched.styleReference && formik.errors.styleReference && (
          <ErrorText message={formik.errors.styleReference} />
        )}
        {similarProducts.length > 0 && (
          <div style={{ margin: "10px 0px 15px 0px" }}>
            <MatchingSimilarProducts similarProducts={similarProducts} />
          </div>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
          style={{ marginRight: "1rem" }}
        >
          Submit
        </Button>
        <Typography sx={{ color: "error.main" }}>
          Warning, this will create this product for ALL athletes that the,
          school, sport, athlete, jersey number, gender filter applies to.
        </Typography>
      </form>
    </div >
  );
};

export default CreateProductForm;
