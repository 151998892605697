import HearingIcon from '@mui/icons-material/Hearing';
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import AudibleComponent from "./AudibleComponent";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
    const { views } = usePermissionContext();
    return (
        <Can action={views.VIEW} items={[views.AUDIBLE]} els={<Navigate to="/ballpark" />}>
            <AudibleComponent />
        </Can>
    );
}
export const icon = <HearingIcon sx={{ color: "text.primary" }} />;
export const text = "Audible";
export const componentName = "Audible";
export const path = "audible";