import { useState, useEffect } from "react";
import { useRoleContext } from "../../contexts/ProviderProvider";
import useCanUser from "../../hooks/useCanUser";

const Can = ({ env, children, els, action, items, notRole }) => {
  const { role } = useRoleContext();


  // if allow is undefined, and the env is configured, then we don't need to check permissions
  const can = useCanUser(action, items);
  const prelimCanCheck = (!action && !items && env) || (can && isMatchingEnv(env))

  if (role == notRole) {
    return els ? els : <></>;
  }

  if (!role || role === "") {
    return <div style={{ paddingTop: "25px" }}>loading...</div>;
  }

  if (role === "Owner") {
    return children;
  }

  // if the env is configured, then we need to check the url to make sure were in the env environment
  if (prelimCanCheck && isMatchingEnv(env) || (!action && !items && isMatchingEnv(env))) {
    return children;
  }

  return els ? els : <></>;
};

const isMatchingEnv = (env) => {
  if (env == undefined || env == null || !env) { return true }

  let currentURL = window.location.href.replace("http://", "").replace("https://", "");

  return currentURL.startsWith(env) || (env === "dev" && currentURL.startsWith("localhost"))
}

export default Can;
