import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ImageModifier } from "../../common/ImageModifier";
import { TextModifier } from "../../common/TextModifier";
import { Button, Grid, LinearProgress, Typography, Box, Checkbox, Modal, Backdrop } from "@mui/material";
import { useUserContext, usePermissionContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { insertEquipmentManagerConfig, updateEquipmentManagerConfig } from "../../../API/equipmentManager";



export const ConfigDetailedView = ({ rows, open, handleClose, metadataId, configId, action, refresh, setRefresh, selectedSku, selectedUniversity, similarConfig }) => {

    const [changes, setChanges] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const notify = useNotification();
    const user = useUserContext();
    const { views } = usePermissionContext();
    const isValidHexCode = (hexCode) => {
        const hexRegex = /^#?([0-9A-F]{3}){1,2}$/i;
        return hexRegex.test(hexCode);
    }
    const handleModalClose = () => {
        setChanges({})
        handleClose()
        setErrorMessage("")
    }
    const validateForm = () => {
        for (var key in rows) {
            if (rows[key].required) {
                if (!(rows[key].name in changes)) {
                    if (rows[key].value == "" || rows[key].value == undefined) {
                        return false
                    }
                }
                else {
                    if (changes[rows[key].name] == "") {
                        return false
                    }
                }
            }
        }
        return true
    }
    const updateConfig = () => {
        if (!validateForm()) {
            setErrorMessage("* Missing required fields")
            return
        }
        if (configId == -1 || configId == undefined) {
            setErrorMessage("Update correct config")
            return
        }
        var configDict = { "equipment_manager_config_id": configId }
        for (var key in rows) {
            configDict[rows[key].name] = changes[rows[key].name] != undefined ? changes[rows[key].name] : rows[key].value
        }
        updateEquipmentManagerConfig(user, configDict).then((response) => {
            setChanges({})
            handleClose()
            setRefresh(refresh + 1)
        }).catch((error) => {
            notify("Failed to add config")
        })
    }
    const addConfig = () => {
        if (!validateForm()) {

            setErrorMessage("* Missing required fields")
            return
        }
        if (metadataId == -1 || metadataId == undefined) {
            setErrorMessage("Update correct config")
            return
        }
        var configDict = { "metadata_id": metadataId }
        for (var key in rows) {
            configDict[rows[key].name] = changes[rows[key].name] != undefined ? changes[rows[key].name] : null
        }

        insertEquipmentManagerConfig(user, configDict).then((response) => {
            setChanges({})
            handleClose()
            setRefresh(refresh + 1)
        }).catch((error) => {
            notify("Failed to add config")
        })
    }
    const setChangesFilter = (value, field) => {
        setErrorMessage("")
        if (value || value == "") {
            setChanges((prev) => ({ ...prev, [field]: value }));
        } else {
            setChanges((prev) => {
                const newState = { ...prev };
                delete newState[field];
                return newState;
            });
        }
    };
    const getColor = (row) => {
        var colorText = changes[row.name] !== undefined ? changes[row.name] : row.value
        return colorText[0] != "#" ? "#" + colorText : colorText
    }

    const nullifyState = () => {
        setChanges({});
        setIsModalOpen(false);
    };

    const autofillData = () => {
        Object.keys(similarConfig).forEach(key => {

            if (!key.includes("verified") && !key.includes("_id")) {
                setChangesFilter(similarConfig[key], key)
            }
        })
    }

    return (
        <Modal open={open} onClose={handleModalClose} sx={{ overflow: "scroll" }}>
            <Card sx={{
                backgroundColor: "background.default",
                margin: "auto",
                marginTop: "10vh",
                padding: "20px",
                width: "50vw",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "5px",
                border: `1px solid black`,

            }}>
                <CardContent>
                    {loading && <LinearProgress />}
                    <Typography variant="h5" component="div" sx={{ paddingBottom: "10px" }}>Configure Equipment Manager</Typography>
                    <Typography variant="body1" sx={{ paddingBottom: "10px" }}>
                        University:- <span style={{ fontWeight: 'bold', color: "success.main" }}>{selectedUniversity}</span>
                    </Typography>
                    <Typography variant="body2" sx={{ paddingBottom: "10px" }}>
                        SKU Name:- <span style={{ fontWeight: 'bold', color: "success.main" }}>{selectedSku}</span>
                    </Typography>
                    {similarConfig && Object.keys(similarConfig).length > 0 && <Button color="primary" onClick={autofillData}>
                        AUTOFILL CONFIG
                    </Button>}<br />
                    {errorMessage != "" && <Typography variant="body1" sx={{ color: "error.main" }}>{errorMessage}</Typography>}
                    {rows.map((row, index) => {

                        if (row.type == "Color" || row.type == "Text") {
                            return <>
                                <TextModifier
                                    label={row.label}
                                    original={row.value}
                                    value={changes[row.name]}
                                    setValue={(value) => {
                                        setChangesFilter(value, row.name);
                                    }}
                                    style={{ marginTop: "10px" }}
                                    permissions={[views.EQMANAGER]}
                                    required={row.required}
                                />
                                {row.type == "Color" && <Box sx={{ bgcolor: getColor(row), width: '50px', height: '50px', display: 'inline-block', margin: "10px", border: isValidHexCode(getColor(row)) ? `2px solid white` : `0px` }}></Box>}

                            </>
                        } else if (row.type == "ImageText") {
                            return <>
                                <TextModifier
                                    label={row.label}
                                    original={row.value}
                                    value={changes[row.name]}
                                    setValue={(value) => {
                                        setChangesFilter(value, row.name);
                                    }}
                                    style={{ marginTop: "10px" }}
                                    permissions={[views.EQMANAGER]}
                                    required={row.required}
                                />
                                {row.type == "ImageText" && (row.value != "" || (row.name in changes && changes[row.name] != "")) && <img
                                    alt={"Wrong Image"}
                                    style={{ margin: "10px", maxHeight: "50px" }}
                                    src={row.name in changes ? changes[row.name].replace("https://www.dropbox.com", "https://dl.dropboxusercontent.com") : row.value.replace("https://www.dropbox.com", "https://dl.dropboxusercontent.com")}
                                    onClick={() => {
                                        window.open(
                                            row.value.replace("https://www.dropbox.com", "https://dl.dropboxusercontent.com"),
                                            "_blank"
                                        );
                                    }}
                                />}

                            </>
                        } else if (row.type == "Boolean") {
                            return <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {row.label}
                                </span><Checkbox
                                    label={row.label}
                                    value={changes[row.name]}
                                    defaultChecked={row.value}
                                    onChange={(e) => {
                                        setChangesFilter(e.target.checked, row.name);
                                    }}
                                />
                            </div>
                        }
                        else {
                            return <><ImageModifier
                                label={row.label}
                                original={row.name}
                                value={changes[row.label]}
                                setValue={(value) => {
                                    setChangesFilter(value, row.name);
                                }}
                                permissions={[views.EQMANAGER]}
                                style={{ marginTop: "10px" }}
                            /></>
                        }




                    }
                    )}
                    <div
                        style={{ width: "100%", display: "flex", justifyContent: "center" }}
                    >
                        <Button
                            style={{ margin: "auto", width: "20%", marginTop: "20px" }}
                            variant="contained"
                            onClick={() => { action == "Update" ? updateConfig() : addConfig() }}
                        >
                            Save
                        </Button>
                    </div>
                </CardContent>
            </Card >
        </Modal >
    );
};
