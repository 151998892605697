import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import ComplianceFinancialDashboard from "./ComplianceFinancialDashboard"
import { usePermissionContext, useRoleContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  const { role } = useRoleContext();
  return (
    <Can action={views.VIEW} items={[views.COMPLIANCEFIN]} els={<Navigate to="/ballpark/" />}>
      <ComplianceFinancialDashboard view={role != 'Compliance Admin' ? "Internal" : "External"} />
    </Can>
  );
}
export const icon = <AttachMoneyIcon />;
export const text = "Financial Dashboard";
export const componentName = "Financial Dashboard";
export const path = "financialDashboard";