import { height } from "@mui/system";
import AllOrdersTable from "./AllOrdersTable";

const OutFielder = (props) => {
  return (
    <>

      <iframe
        src="https://devapi.influxeradmin.com/public/dashboard/b744d353-b46a-4aef-adef-e66e54fcae87#theme=night"
        frameBorder="0"
        width="90%"
        height="400px"
        style={{ marginTop: "20px" }}

      ></iframe>

      <AllOrdersTable />
    </>
  )
};

export default OutFielder