import * as React from "react";
import "./toggle.css";
import { Button, ButtonGroup } from "@mui/material";
import { useGlobalThemeContext } from "../contexts/ProviderProvider";

function Toggle({ style }) {
  const { theme, setTheme } = useGlobalThemeContext();

  const changeThemeAndToggle = () => {
    if (localStorage.getItem("theme") === "theme-dark") {
      setTheme("theme-light");
    } else {
      setTheme("theme-dark");
    }
  };

  React.useEffect(() => {}, [theme]);

  const handleOnClick = () => {
    changeThemeAndToggle();
  };

  if (!localStorage.getItem("theme")) {
    return <></>;
  }

  return (
    <ButtonGroup variant="contained" sx={{ margin: "auto", ...style }}>
      <Button
        disabled={theme === "theme-dark"}
        sx={{
          background: theme === "theme-dark" ? "primary.dark" : "primary.light",
        }}
        onClick={handleOnClick}
      >
        Dark
      </Button>
      <Button disabled={theme === "theme-light"} onClick={handleOnClick}>
        Light
      </Button>
    </ButtonGroup>
  );
}

export default Toggle;
