import {
    Box,
    Tab, Tabs,
} from "@mui/material";
import TaskDetailedTable from "./TaskDetailedTable";


const TaskUpdateView = ({ activeTab, setTab, loading, setAssetLoading, data, taskType }) => {


    return (
        <div>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={activeTab} onChange={(e, nv) => { console.log(nv); setTab(nv) }} variant="fullWidth">
                    <Tab sx={{ width: "34%" }} value="Running" label="Running" />
                    <Tab sx={{ width: "33%" }} value="Success" label="Success" />
                    <Tab sx={{ width: "33%" }} value="Errors" label="Errors" />
                </Tabs>
            </Box>
            {activeTab == "Success" ?
                <TaskDetailedTable loading={loading} setLoading={setAssetLoading} data={data.SuccessTasks === undefined ? [] : data.SuccessTasks} taskType={taskType} /> :
                activeTab == "Errors" ?
                    <TaskDetailedTable loading={loading} setLoading={setAssetLoading} data={data.ErrorTasks === undefined ? [] : data.ErrorTasks} taskType={taskType} /> :
                    <TaskDetailedTable loading={loading} setLoading={setAssetLoading} data={data.RunningTasks === undefined ? [] : data.RunningTasks} taskType={taskType} />}
        </div>
    )
}
export default TaskUpdateView;


