import MUIDataTable from "mui-datatables";
import { useState } from "react";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import { getMerchRosterBySchool, updateMerchRoster } from "../../../API/roster";
import { useUserContext } from "../../../contexts/ProviderProvider";
import InstagramLink from "../../common/InstagramLink";
import useNotification from "../../../hooks/useNotification";
import { Button, LinearProgress, Typography } from "@mui/material";
import { getSocialMediaGraphics } from "../../../API/roster";
import { isSmallScreen } from "../../../utilities/themeUtilities.js";
import * as React from "react";
import { decompressData } from "../../../utilities/utils.js";

const SocialTable = (props) => {
  const { school } = props;

  const isMobile = isSmallScreen();

  const user = useUserContext();
  const notify = useNotification();
  const [playerGraphics, setPlayerGraphics] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [unPostedCount, setUnPostedCount] = useState(0);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    setIsLoading(true);
    getMerchRosterBySchool(school, user)
      .then((response) => {
        const responseData = decompressData(response);
        setData(responseData.roster)
        // in response.data.roster count number of posted on social is false and added on site is true
        let count = 0;
        responseData.roster.forEach((player) => {
          if (player.postedOnSocial === false && player.addedOnSite === true) {
            count++;
          }
        });
        setUnPostedCount(count);

        setIsLoading(false);
      })
      .catch((e) => {
        notify("Failed to get athletes for school");
        setIsLoading(false);
      });

  }, [refresh, user]);


  const hasSMG = (playerId) => {
    const player = data.filter(
      (p) => p.idAthleteMerchInfo === playerId
    )[0];
    if (player.postedOnSocial === null) {
      return false;
    }
    return true;
  };

  const hasGraphics = (playerId) => {
    const player = data.filter(
      (p) => p.idAthleteMerchInfo === playerId
    )[0];
    if (
      player.university in playerGraphics &&
      playerId in playerGraphics[player.university]
    ) {
      if (
        Object.keys(playerGraphics[player.university][playerId]).length === 0
      ) {
        return false;
      } else if (playerGraphics[player.university][playerId]["url"] === "") {
        return false;
      }
      return true;
    }
    return false;
  };

  const getGraphics = (playerId) => {
    const player = data.filter(
      (p) => p.idAthleteMerchInfo === playerId
    )[0];
    if (
      player.university in playerGraphics &&
      playerId in playerGraphics[player.university] &&
      "url" in playerGraphics[player.university][playerId]
    ) {
      return playerGraphics[player.university][playerId]["url"];
    }
    return "";
  };

  // const getDownloadGraphics = (playerId) => {
  //   console.log(playerGraphics, playerId);
  //   const player = props?.data.filter(
  //     (p) => p.idAthleteMerchInfo === playerId
  //   )[0];
  //   if (
  //     player.university in playerGraphics &&
  //     playerId in playerGraphics[player.university] &&
  //     "downloadURL" in playerGraphics[player.university][playerId]
  //   ) {
  //     return playerGraphics[player.university][playerId]["downloadURL"];
  //   }
  //   return "";
  // };

  const getPlayerPhoto = (playerId) => {
    const player = data.filter(
      (p) => p.idAthleteMerchInfo === playerId
    )[0];
    getSocialMediaGraphics(
      user,
      player.university,
      player.sport,
      player.full_name
    )
      .then((response) => {
        setPlayerGraphics((prevState) => ({
          ...prevState,
          [player.university]: {
            ...prevState[player.university],
            [player.idAthleteMerchInfo]: response.data,
          },
        }));
      })
      .catch((error) => {
        console.log(error);
        notify(
          "Player Social Media not yet created, Please contact biz dev team"
        );
      });
  };

  const updateSocialPostStatus = (athlete, index) => {
    //console.log("starting loading");
    setIsLoading(true);
    updateMerchRoster({ ...athlete, postedOnSocial: true, employee_id: user.uid }, user, false)
      .then((response) => {

        setRefresh(refresh + 1);
        setIsLoading(false);
      })
      .catch((error) => {
        notify("Failed to update the athlete");
        console.error(error);
        setIsLoading(false);
      });
  };

  const convertSortedIndexNumber = (tableMeta) => {
    return tableMeta.currentTableData[tableMeta.rowIndex].index;
  };

  const columns = [
    {
      label: "Sport",
      name: "sport",
      options: { sx: { display: { xs: "none", sm: "none" } } },
    },
    { label: "Name", name: "full_name" },
    {
      label: "Has Posted",
      name: "postedOnSocial",
      options: {
        display: isMobile ? "false" : "true",
        filterList: [false],
        customFilterListOptions: {
          render: (v) =>
            v === null ? "No Social Graphics" : v ? "Has Posted" : "Not Posted",
        },
        filterOptions: {
          renderValue: (v) =>
            v === null ? "No Social Graphics" : v ? "Has Posted" : "Not Posted",
        },
        customBodyRender: (value, tableMeta, updateValue) => {

          return (
            <Button
              // checked={value}
              disabled={!value ? false : true}
              variant={!value ? "contained" : "outlined"}
              onClick={() => {

                updateSocialPostStatus(data[convertSortedIndexNumber(tableMeta)], convertSortedIndexNumber(tableMeta));
              }}
            >
              {" "}
              {!value ? "Post Athlete" : "Posted"}
            </Button>
          )
        },
      },
    },
    {
      label: "Has Added on Site",
      name: "addedOnSite",
      options: {
        display: "false",
        filterList: [true],
        customFilterListOptions: {
          render: (v) =>
            v === null || v === false ? "Not Added on Site" : "Added on Site",
        },
        filterOptions: {
          render: (v) =>
            v === null || v === false ? "Not Added on Site" : "Added on Site",
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
        sx: { display: { xs: "none", sm: "none" } },
      },
    },
    {
      label: "Has Signed",
      name: "signed",
      options: {
        display: isMobile ? "false" : "true",
        filterList: [true],
        customFilterListOptions: {
          render: (v) => (v ? "Has Signed" : "Not Signed"),
        },
        filterOptions: {
          renderValue: (v) => (v ? "Has Signed" : "Not Signed"),
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
        sx: { display: { xs: "none", sm: "none" } },
      },
    },
    {
      label: "Instagram",
      name: "idAthleteMerchInfo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <InstagramLink
            id={value}
            style={{ height: "30px" }}
            variant={"merchRoster"}
          />
        ),
      },
    },
    {
      label: "Social Graphics",
      name: "idAthleteMerchInfo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          hasSMG(value) ? (
            hasGraphics(value) ? (
              <img
                src={getGraphics(value)}
                style={{ maxHeight: "50px" }}
                alt="social graphics"
                onClick={() => {
                  window.open(getGraphics(value), "_blank");
                }}
              />
            ) : (
              <Button onClick={() => getPlayerPhoto(value)}>
                Get Graphics
              </Button>
            )
          ) : (
            <Typography>No Social Media Graphics</Typography>
          ),
      },
    },
  ];

  const options = {
    rowsPerPage: window.rowsPerPage,
    scrollX: true, // Enable horizontal scrolling
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: "No Records",
      },
    },
    responsive: "scroll",
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      {/* <Typography>Number of Players need to be posted: {unPostedCount}</Typography> */}
      <MUIDataTable columns={columns} options={options} data={data} title={"Number of Players need to be posted: " + unPostedCount.toString()} />
    </>
  )
};

export default SocialTable;
