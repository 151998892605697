import { useEffect, useState } from "react";
import { CircularProgress, Typography, Button, Card, CardContent, TableRow, TableCell, Checkbox, setRef, LinearProgress } from "@mui/material";
import MUIDataTable from "mui-datatables";
import {
    useSchoolContext,
    useUserContext,
} from "../../../contexts/ProviderProvider.jsx";
import { useNavigate } from "react-router-dom";
import { getEquipmentManagerConfig } from "../../../API/equipmentManager";
import useNotification from "../../../hooks/useNotification.jsx";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox.jsx";
import { ConfigDetailedView } from "./ConfigDetailedView.jsx";
import { convertSortedIndexNumber } from "../../../utilities/utils.js";
import { decompressData } from "../../../utilities/utils.js";

const ConfigureEquipmentManagerTable = ({ loading, setLoading }) => {
    const user = useUserContext();
    const [emConfigurations, setEMConfigurations] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState([])
    const [selectedMetadataId, setSelectedMetadataId] = useState(-1)
    const [selectedConfigId, setSelectedConfigId] = useState(-1)
    const [selectedAction, setSelectedAction] = useState("")
    const [selectedSku, setSelectedSku] = useState("")
    const [selectedUniversity, setSelectedUniversity] = useState("")
    const [similarConfig, setSimilarConfig] = useState({})

    const notify = useNotification();
    const handleClose = (() => {
        setSelectedAction("")
        setSelectedMetadataId(-1)
        setSelectedConfigId(-1)
        setSelectedRow([])
        setSimilarConfig({});
        setOpen(false);
        setSelectedSku("")
        setSelectedUniversity("")
    })
    useEffect(() => {
        setLoading(true)
        getEquipmentManagerConfig(user)
            .then((response) => {
                setLoading(false)
                const responseData = decompressData(response);
                setEMConfigurations(responseData.missingConfigRecords);
            })
            .catch((error) => {
                setLoading(false)
                console.error(error);
                notify("Failed to get equipment manager configurations");
            })

    }, [refresh, user]);
    const configOptions = {
        "back_text_font_color": { "label": "Text Font Color", "type": "Color", "required": true, "name": "back_text_font_color" },
        "back_jersey_number_font_color": { "label": "Jersey Number Font Color", "type": "Color", "required": false, "name": "back_jersey_number_font_color" },
        "back_number_outline_color": { "label": "Number Outline Color", "type": "Color", "required": false, "name": "back_number_outline_color" },
        "back_icon_link": { "label": "Icon Link", "type": "ImageText", "required": false, "name": "back_icon_link" },
        "back_text_font_link": { "label": "Last Name Font", "type": "Text", "required": true, "name": "back_text_font_link" },
        "back_jersey_font_link": { "label": "Jersey Number Font", "type": "Text", "required": false, "name": "back_jersey_font_link" },
        "back_verified": { "label": "Back Asset Verified", "type": "Boolean", "required": false, "name": "back_verified" },
        "mockup_template_link": { "label": "Template File Link", "type": "ImageText", "required": true, "name": "mockup_template_link" },
        "mockup_verified": { "label": "Mockup Verified", "type": "Boolean", "required": false, "name": "mockup_verified" },
    }
    var buildAssetRow = (row, metadataId, sku_name, university) => {
        setSelectedSku(sku_name)
        setSelectedUniversity(university)
        setSelectedMetadataId(metadataId)

        if (row.length == 0) {
            setSelectedRow([
                { "label": "Last Name Font Color", "type": "Color", "value": "", "required": true, "name": "back_text_font_color" },
                { "label": "Jersey Number Font Color", "type": "Color", "value": "", "required": false, "name": "back_jersey_number_font_color" },
                { "label": "Number Outline Color", "type": "Color", "value": "", "required": false, "name": "back_number_outline_color" },
                { "label": "Icon Link", "type": "ImageText", "value": "", "required": false, "name": "back_icon_link" },
                { "label": "Last Name Font", "type": "Text", "value": "", "required": true, "name": "back_text_font_link" },
                { "label": "Jersey Number Font", "type": "Text", "value": "", "required": false, "name": "back_jersey_font_link" },
                { "label": "Back Asset Verified", "type": "Boolean", "value": false, "name": "back_verified" },
                { "label": "Template File Link", "type": "ImageText", "value": "", "required": true, "name": "mockup_template_link" },
                { "label": "Mockup Verified", "type": "Boolean", "value": false, "name": "mockup_verified" },
            ])
        } else {
            const configRow = row[0]
            setSelectedConfigId(configRow.equipment_manager_config_id)
            var tempSelectedRow = []
            for (var key in configRow) {
                if (key == "equipment_manager_config_id" || key == "metadata_id")
                    continue
                tempSelectedRow.push({ "name": configOptions[key]["name"], "label": configOptions[key]["label"], "type": configOptions[key]["type"], "value": configRow[key] == null ? "" : configRow[key], "required": configOptions[key]["required"] })
            }
            setSelectedRow(tempSelectedRow)
        }
    }


    const options = {

        rowsPerPage: window.rowsPerPage,
        enableNestedDataAccess: ".",
        expandableRows: true,
        isRowExpandable: (dataIndex) => {
            return emConfigurations[dataIndex]["EquipmentManagerConfigs"].length != 0;
        },
        expandableRowsHeader: false,
        selectableRows: "none",
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <TableRow>
                    <TableCell colSpan={9} >
                        <Card>
                            <CardContent >
                                <Typography id="modal-modal-title" variant="h5" component="h2">
                                    Config Detailed view
                                </Typography>

                                {Object.keys(emConfigurations[rowMeta.dataIndex]["EquipmentManagerConfigs"][0]).map((key, index) => {

                                    return (
                                        <Typography
                                            key={index}
                                            id={`modal-modal-description-${index}`}
                                            sx={{ mt: 2 }}
                                        >
                                            <span style={{ color: "#868686" }}>
                                                {key.toUpperCase().replace("_", " ")}:-
                                            </span>{" "}
                                            {key.toUpperCase().includes("VERIFIED") ?
                                                emConfigurations[rowMeta.dataIndex]["EquipmentManagerConfigs"][0][key] ? "TRUE" : "FALSE"
                                                : emConfigurations[rowMeta.dataIndex]["EquipmentManagerConfigs"][0][key]}

                                        </Typography>
                                    );
                                })}
                            </CardContent>
                        </Card>
                    </TableCell>
                </TableRow >
            );
        },

    };

    const columns = [
        {
            label: "Product Count",
            name: "product_count",
        },
        {
            label: "Sku Name",
            name: "sku_name",
        },
        {
            label: "University",
            name: "university",
        },
        {
            label: "Related Athlete",
            name: "athlete_id",
            options: {
                customBodyRender: (value) => {
                    return <div >{value === null || value === "" ? "ALL" : value}</div>
                }
            }
        },
        {
            label: "Sport",
            name: "sport",
            options: {
                customBodyRender: (value) => {
                    return <div >{value === null || value === "" ? "ALL" : value}</div>;
                }
            }
        },
        {
            label: "Approval Status",
            name: "approval_status",
            options: {
                customFilterListOptions: {
                    render: (v) => (v == "Approved" ? "TRUE" : "FALSE"),
                },
                filterOptions: {
                    renderValue: (v) => (v == "Approved" ? "TRUE" : "FALSE"),
                },
                customBodyRender: (value, tableMeta) => {
                    return <TrueFalseCheckbox checked={value == "Approved"} disabled={true} />
                },
            }
        },
        {
            label: "Jersey number",
            name: "jersey_number",
            options: {
                customBodyRender: (value) => {
                    return <div >{value === null || value === "" ? "ALL" : value}</div>
                }
            }
        },
        {
            label: "Gender",
            name: "gender",
            options: {
                customBodyRender: (value) => {
                    return <div >{value === null || value === "" ? "ALL" : value}</div>
                }
            }
        },
        {
            label: "Has Configuration",
            name: "has_configuration",
            filter: true,
            customFilterListOptions: {
                render: (v) => (v === "TRUE" ? "MISSING CONFIGURATION" : "HAS CONFIGURATION"),
            },
            filterOptions: {
                renderValue: (v) => (v === "TRUE" ? "MISSING CONFIGURATION" : "HAS CONFIGURATION"),
            },
            options: {
                customBodyRender: (value, tableMeta) => {
                    return emConfigurations[convertSortedIndexNumber(tableMeta)]["EquipmentManagerConfigs"].length != 0 ? "TRUE" : "FALSE";
                }
            }
        },
        {
            label: "Configure",
            name: "configure",

            options: {
                customBodyRender: (value, tableMeta) => {
                    const hasConfig = emConfigurations[convertSortedIndexNumber(tableMeta)]["EquipmentManagerConfigs"].length != 0;
                    return (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 6, bgcolor: hasConfig ? "warning.light" : "success.main" }}
                            onClick={(a) => { console.log(emConfigurations[convertSortedIndexNumber(tableMeta)]); setSimilarConfig(emConfigurations[convertSortedIndexNumber(tableMeta)].similar_config); setSelectedAction(hasConfig ? "Update" : "Add"); buildAssetRow(emConfigurations[convertSortedIndexNumber(tableMeta)].EquipmentManagerConfigs, emConfigurations[convertSortedIndexNumber(tableMeta)].product_metadata_id, emConfigurations[convertSortedIndexNumber(tableMeta)].sku_name, emConfigurations[convertSortedIndexNumber(tableMeta)].university); setOpen(true) }}
                        >
                            {hasConfig ? "Modify Config" : "Add Config"}
                        </Button>
                    );
                }
            },
        },
    ];

    return (

        <div style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
            <Typography variant="h3">Equipment Manager Configuration</Typography>
            {loading && <LinearProgress />}
            <MUIDataTable
                style={{ width: "90%" }}
                data={emConfigurations}
                columns={columns}
                options={options}
            />
            <ConfigDetailedView rows={selectedRow} open={open} handleClose={handleClose} metadataId={selectedMetadataId} configId={selectedConfigId} action={selectedAction} refresh={refresh} setRefresh={setRefresh} selectedSku={selectedSku} selectedUniversity={selectedUniversity} similarConfig={similarConfig} />


        </div>
    );
};

export default ConfigureEquipmentManagerTable;
