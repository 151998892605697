import * as React from "react";
import FileDropZone from "./FileDropZone";
import Can from "./Can";
import { usePermissionContext } from "../../contexts/ProviderProvider";
import noimage from "../../images/noasset.jpeg";
import { Checkbox, Typography } from "@mui/material";

export const ImageModifier = ({
  label,
  style,
  original,
  value,
  shared,
  toggleShared,
  setValue,
  permissions,
}) => {
  const handleImageChange = (files, callback) => {
    setValue(files[0]);
    callback();
  };

  const { actions } = usePermissionContext();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {value || original ? (
        <img
          alt={"photo of " + label}
          style={{ maxHeight: "100px" }}
          src={value ? URL.createObjectURL(value) : original}
          onClick={() => {
            window.open(
              value ? URL.createObjectURL(value) : original,
              "_blank"
            );
          }}
        />
      ) : (
        <div>no image</div>
      )}
      <Can
        action={actions.ALTER}
        items={permissions}
      >
        <FileDropZone
          single
          sx={{
            width: "70%",
          }}
          text={"Drag and drop " + label}
          uploadFiles={(files, callback) => {
            handleImageChange(files, callback);
          }}
        />
        {toggleShared && (
          <>
            <Checkbox
              checked={shared}
              onClick={() => {
                toggleShared(!shared);
              }}
            />
            <Typography>Is Shared</Typography>
          </>
        )}
      </Can>
    </div>
  );
};
