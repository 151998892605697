import { Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";

const FileDropZone = ({ uploadFiles, sx, text }) => {
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    setLoading(true);

    const files = e.dataTransfer.files;

    const reformattedStruct = [];

    for (let i = 0; i < files.length; i++) {
      //console.log("File Name:", files[i].name);
      reformattedStruct.push(files[i]);
    }
    uploadFiles(reformattedStruct, () => setLoading(false));
  };

  return (
    <Box
      sx={{
        width: "125px",
        height: "75px",
        padding: "5px",
        border: "2px dashed #aaa",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        textAlign: "center",
        backgroundColor: dragging ? "#eee" : "transparent",
        ...sx,
      }}
      onDragEnter={handleDragEnter}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {loading ? <CircularProgress /> : text ? text : "Drag & Drop Files"}
    </Box>
  );
};

export default FileDropZone;
