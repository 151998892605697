import React from "react"
import { useParams } from "react-router-dom"

const BrandProfile = ({}) => {

  const {id} = useParams()
  return (
  <>
      {JSON.stringify(id)}
  </>
  )
}

export default BrandProfile
