import * as React from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";
import BrandProfile from "./BrandProfile";


export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.ATHLETES]} els={<Navigate to="/ballpark" />}>
      <BrandProfile />
    </Can>
  );
}

export const icon = <DirectionsRunIcon />;
export const text = "Brand";
export const componentName = "Marketplace";
export const display = false
export const path = "marketplace";
