import * as React from "react";
import { Typography } from "@mui/material";
import myImage from './mark.png';
import { useGlobalThemeContext } from '../../../contexts/ProviderProvider';


const InfluxerWelcome = (props) => {

  const { theme, setTheme } = useGlobalThemeContext();

  const [url, setUrl] = React.useState('');
  const [key, setKey] = React.useState(0);

  React.useEffect(() => {
    if (theme == 'theme-dark') {
      setUrl("https://devapi.influxeradmin.com/public/dashboard/ae97c7ff-cf1a-4056-b529-987247def34f#theme=night");
      setKey(key + 1);
    } else {
      setUrl("https://devapi.influxeradmin.com/public/dashboard/ae97c7ff-cf1a-4056-b529-987247def34f");
      setKey(key + 1);
    }
  }, [theme])

  return (
    <>
      <iframe
        style={{
          position: "relative",
          top: "0",
          left: "0",
          width: "100%",
          height: "2500px",
        }}
        // src="https://54.89.1.194:3000/public/dashboard/ae97c7ff-cf1a-4056-b529-987247def34f#theme=night"
        key={key}
        src={url}
      // frameBorder="0"
      // allowtransparency
      ></iframe>
    </>
  )
};

export default InfluxerWelcome