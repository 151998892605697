import { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { getSMGErrors } from "../../../API/equipmentManager";
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";

const MediaDayError = () => {
    const user = useUserContext();
    const notify = useNotification();
    const [sMGErrors, setSMGErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            name: "roster_id",
            label: "Roster ID",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "full_name",
            label: "Full Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "university",
            label: "University",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "error_message",
            label: "Error Message",
            options: {
                filter: true,
                sort: true,
            },
        },
    ]

    useEffect(() => {
        setIsLoading(true);
        getSMGErrors(user)
            .then((res) => {
                setSMGErrors(res.data.obj ? res.data.obj : []);
            })
            .catch((err) => {
                notify("Failed to fetch SMG Errors");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div style={{ width: "100%", marginTop: "10px", paddingLeft: "20px", paddingRight: "20px" }}>
            <MUIDataTable
                data={sMGErrors}
                columns={columns}
                options={{
                    rowsPerPage: window.rowsPerPage,
                    scrollX: true,
                    selectableRows: "none",
                }}
            />
        </div>
    )
}

export default MediaDayError;