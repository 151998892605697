import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
}));

const StyledAuthor = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const StyledDate = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
}));

const StyledNote = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const StyledRoute = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
}));

const Change = ({ change }) => {
    return (
        <StyledCard>
            <CardContent>
                <StyledAuthor variant="h6">{change.author}</StyledAuthor>
                <StyledDate variant="body2">{new Date(change.created).toLocaleString()}</StyledDate>
                <StyledNote variant="body1">{change.note}</StyledNote>
                <StyledRoute variant="body2">{change.route}</StyledRoute>
            </CardContent>
        </StyledCard>
    );
};

export default Change;