import * as React from "react";
import { Autocomplete, TextField, Button } from "@mui/material";
import { useSchoolContext } from "../../contexts/ProviderProvider";

const SingleSchoolSelector = ({ setSelectedSchool, selectedSchool }) => {
    const [schoolInput, setSchoolInput] = React.useState("");
    const { schools } = useSchoolContext();

    const handleAddSchool = (school) => {
        setSelectedSchool(school);
    };

    return (
        <Autocomplete
            value={selectedSchool}
            onChange={(event, newValue) => {
                handleAddSchool(newValue);
            }}
            inputValue={schoolInput}
            onInputChange={(event, newInputValue) => {
                setSchoolInput(newInputValue);
            }}
            options={schools
                .map((school) => school.schoolName)
                .filter((s) => selectedSchool != s)}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="School List"
                    variant="outlined"
                    style={{ width: "100%" }}
                    InputProps={{
                        ...params.InputProps,
                    }}
                    type="text"
                />
            )}
        />
    );
};

export default SingleSchoolSelector;
