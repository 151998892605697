import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotesIcon from "@mui/icons-material/Notes";

import { useRoleContext, usePermissionContext } from "../../contexts/ProviderProvider";
import Can from "./Can";
import Logo from "../../images/Influxer-Logo.jpeg";

const ENV = process.env.REACT_APP_ADMIN_ENV;
if (!ENV) {
  console.error(
    "you need to configure REACT_APP_ADMIN_ENV in environment variables[dev or prod]"
  );
}

const Navbar = (props) => {
  const { role, setRole } = useRoleContext();
  const { availableRoles } = usePermissionContext();

  let navigate = useNavigate();

  return (
    <AppBar
      top="0"
      variant="dense"
      style={{ position: "sticky", top: "0", zIndex: "9990" }}
    >
      <Toolbar sx={{ backgroundColor: "primary.main" }}>
        <Grid container item style={{ height: "40px", display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "nowrap" }}>
          <IconButton onClick={props.openNav} sx={{ paddingRight: "10px" }}>
            <MenuIcon sx={{ color: "text.primary", fontSize: "40px" }} />
          </IconButton>
          <button
            style={{
              padding: "unset",
              maxHeight: "auto",
              maxWidth: "auto",
              height: "40px",
              border: "unset",
              background: "none"
            }}
            onClick={() => navigate("/ballpark")}
          >
            <img
              src="/icon_ballpark-svg.png"
              style={{
                maxHeight: "25px",
                width: "auto",
                paddingLeft: "5px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center"
              }}
            />
          </button>
        </Grid>
        <Can env={ENV} else={<Typography>{role}</Typography>}>
          <Grid item lg={5} xs={5}>
            <Select
              fullWidth
              id="role-select"
              value={role}
              key={role}
              onChange={(e) => setRole(e.target.value)}
              xs={{ width: "100%" }}
            >
              {availableRoles && Object.keys(availableRoles).map((role) => (
                <MenuItem key={availableRoles[role]} value={availableRoles[role]}>
                  {availableRoles[role]}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Can>
        <Can notRole={"Compliance Admin"}>
          <IconButton onClick={props.openNotes} sx={{ paddingRight: "10px" }}>
            <NotesIcon />
          </IconButton>
        </Can>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
