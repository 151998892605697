import { useState, useEffect } from "react";
import {
  Typography,
  LinearProgress,
  CircularProgress,
  TableRow,
  TableCell,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { getOrderHistoryBySchool } from "../../../API/compliance";
import {
  useUserContext,
  useUserSchools,
  useSchoolContext
} from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import MUIDataTable from "mui-datatables";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import MultiSelect from "../../common/MultiSelect";
import DateRangePicker from '../../common/DateRangePicker';
import { decompressData } from "../../../utilities/utils";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import sample_data from './sample_data.json'

dayjs.extend(utc);
dayjs.extend(timezone);


const ExpandableRow = (props) => {
  const rows = props.data.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  const columns = [
    {
      label: "Title", name: "title", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value.replace(props.school, '').replace(/-[^-]*-/, '-')}</div>
        ),
      }
    },
    { label: "Price", name: "price", options: {} },
    { label: "Quantity", name: "quantity", options: {} },
    {
      label: "Date", name: "date", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          new Date(value).toLocaleDateString()
        ),
      }
    },
    {
      name: "royaltyBearing",
      label: "Royalty Bearing",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value !== "" && value !== null && value} disabled />
        ),
      },
    },
  ]

  const options = {
    filter: true,
    sortOrder: { name: "date", direction: 'desc' },
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: 10,
    // customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
    //   return ( null );
    // },
  };

  return (
    <div
      style={{
        margin: "10px 10px"
      }}>
      <MUIDataTable
        title={"Order History"}
        data={rows}
        columns={columns}
        options={options}
        components={{ TableToolbar(props) { return null; }, }}
      />
    </div>
  );
};

const BusinessTotalsDashboard = ({ totals }) => {
  return (
    <Grid container spacing={1}>
      {totals.map((total, index) => (
        <Grid item xs={12} sm={4} md={4} lg={2} key={index}>
          <Card>
            <CardContent>
              <Typography variant="h7" gutterBottom style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {total.title}
              </Typography>
              <Typography variant="h5">
                {total.value}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const ComplianceFinancialDashboard = (props) => {
  const [loadingData, setLoadingData] = useState(true);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [complianceTotals, setComplianceTotals] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [schoolInput, setSchoolInput] = useState("");
  const today = new Date();
  const startOfPreviousQuarter = new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3) - 3, 1);

  const [startDate, setStartDate] = useState(dayjs.tz(startOfPreviousQuarter.toISOString().split('T')[0], 'America/Chicago').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs.tz(today.toISOString().split('T')[0], 'America/Chicago').endOf('day'));

  const userSchools = useUserSchools();
  const { schools } = useSchoolContext();
  const user = useUserContext();
  const notify = useNotification();

  const TIMEOUT_DURATION = 10000;

  useEffect(() => {
    let timeoutId;

    if (!user || !userSchools) {
      timeoutId = setTimeout(() => {
        window.location.reload(); // Refresh the page
      }, TIMEOUT_DURATION);
      return () => clearTimeout(timeoutId);
    }

    setSelectedSchools([userSchools[0]]);

    clearTimeout(timeoutId);
  }, [user, userSchools]);

  useEffect(() => {
    if (!selectedSchools || selectedSchools.length < 1) { return }
    setLoadingData(true);
    if( selectedSchools[0] === 'Influxer School Of Gains') {   
        setTimeout(() => {
          const responseData = sample_data;
          
          setComplianceData(responseData.data);
          setComplianceTotals(responseData.totals);
          setLoadingData(false);
      }, 2000);
    } else {
      getOrderHistoryBySchool(user, selectedSchools, startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss'), endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss'), props.view)  // full inclusive range
        .then((response) => {
          const responseData = decompressData(response);

          setComplianceData(responseData.data);
          setComplianceTotals(responseData.totals);
          setLoadingData(false);
        })
        .catch((error) => {
          console.log(error);
          notify('Failed to get compliance data');
          setLoadingData(false);
        });
    }
  }, [user, selectedSchools, startDate, endDate, props.view]);

  // Transaction Fees (0.85%)	University Royalty Fee	Service Fee (4%)	Transfer Fee (2%)	Gross profit 	Player's profit (60%)
  const columns = props.view === "Internal" ? [
    {
      label: "Athlete", name: "name", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Shopify Collection ID", name: "shopify_collection_id", options: {
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    { label: "University", name: "university", options: { display: selectedSchools && (selectedSchools.length > 1 || selectedSchools[0] === "All"), sortThirdClickReset: true } },
    { label: "Sport", name: "sport", options: { display: false } },
    { label: "State", name: "state", options: { display: false } },
    { label: "Division", name: "division", options: { display: false } },
    { label: "Conference", name: "conference", options: { display: false } },
    { label: "Gender", name: "gender", options: { display: false } },
    { label: "Sub Division", name: "subDivision", options: { display: false } },
    {
      label: "Total Quantity Sold",
      name: "quantity_sum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Orders",
      name: "order_sum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Sales",
      name: "sales_sum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Cost of Goods",
      name: "cogs",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transaction Fees", name: "trans_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "University Royalty Fees", name: "roy_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Service Fees", name: "serv_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transfer Fees", name: "transfer_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Gross (Net) Profit", name: "net", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Player Profit", name: "play_prof", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    { label: 'Line Items', name: 'line_items', options: { display: false, filter: false } }
  ] :
    // External columns (one school)
    [
      {
        label: "Athlete", name: "name", options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{value}</div>
          ),
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "Total Quantity Sold",
        name: "quantity_sum",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "Total Orders",
        name: "order_sum",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "Total Sales",
        name: "sales_sum",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          sortCompare: (order) => {
            return (a, b) => {
              const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
              const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

              if (isNaN(val1)) return -1;
              if (isNaN(val2)) return 1;

              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "Cost of Goods",
        name: "cogs",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          sortCompare: (order) => {
            return (a, b) => {
              const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
              const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

              if (isNaN(val1)) return -1;
              if (isNaN(val2)) return 1;

              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "Transaction Fees", name: "trans_fee", options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          sortCompare: (order) => {
            return (a, b) => {
              const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
              const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

              if (isNaN(val1)) return -1;
              if (isNaN(val2)) return 1;

              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "University Royalty Fees", name: "roy_fee", options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          sortCompare: (order) => {
            return (a, b) => {
              const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
              const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

              if (isNaN(val1)) return -1;
              if (isNaN(val2)) return 1;

              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "Service Fees", name: "serv_fee", options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          sortCompare: (order) => {
            return (a, b) => {
              const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
              const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

              if (isNaN(val1)) return -1;
              if (isNaN(val2)) return 1;

              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "Transfer Fees", name: "transfer_fee", options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          sortCompare: (order) => {
            return (a, b) => {
              const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
              const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

              if (isNaN(val1)) return -1;
              if (isNaN(val2)) return 1;

              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "Gross (Net) Profit", name: "net", options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          sortCompare: (order) => {
            return (a, b) => {
              const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
              const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

              if (isNaN(val1)) return -1;
              if (isNaN(val2)) return 1;

              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          filter: false,
          sortThirdClickReset: true
        }
      },
      {
        label: "Player Profit", name: "play_prof", options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              textAlign: "center",
            }}>{value}</div>
          ),
          customHeadLabelRender: (columnMeta) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{columnMeta.label}</div>
          ),
          sortCompare: (order) => {
            return (a, b) => {
              const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
              const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

              if (isNaN(val1)) return -1;
              if (isNaN(val2)) return 1;

              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          filter: false,
          sortThirdClickReset: true
        }
      },
      { label: 'Line Items', name: 'line_items', options: { display: false, filter: false } }
    ];

  const options = {
    filter: true,
    // sortOrder: {name:"selected", direction:'desc'},
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: rowsPerPage,
    expandableRows: true,
    onChangeRowsPerPage: (num) => setRowsPerPage(num),
    renderExpandableRow: (rowData, rowMeta, a) => {
      const colSpan = 7;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandableRow
              user={user}
              data={complianceData[rowMeta.dataIndex].player_trans}
              school={complianceData[rowMeta.dataIndex].university}
              notify={notify} />
          </TableCell>
        </TableRow>
      );
    },
    textLabels: {
      body: {
        noMatch: loadingData ? (
          <CircularProgress />
        ) : (
          "No Records"
        ),
      },
    },
  };

  return (
    <div style={{ width: "90%", paddingTop: "5vh" }}>
      {selectedSchools && <Typography variant="h1">{selectedSchools.length > 1 ? selectedSchools.sort()[0] + ` and ${selectedSchools.length - 1} more` : selectedSchools}</Typography>}
      {loadingData && <LinearProgress />}
      {complianceTotals &&
        <div
          style={{
            margin: "10px 10px",
          }}>
          <BusinessTotalsDashboard
            totals={complianceTotals}
          />
        </div>}
      <div style={{
        margin: "10px 10px",
        // justifyContent: "center",
        width: "90%",
        // paddingLeft: "10px"
      }}>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
      {userSchools.length > 1 &&
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
          <MultiSelect
            value={selectedSchools}
            inputValue={schoolInput}
            options={schools
              .map((school) => school.schoolName)
              .filter((s) => !schoolInput.includes(s))}
            onChange={setSelectedSchools}
            onInputChange={setSchoolInput}
            label={"School List"}
            allButton
          />
        </div>
      }
      {complianceData &&
        <div
          style={{
            margin: "20px 10px"
          }}>
          <MUIDataTable
            title={"Performance"}
            data={complianceData}
            columns={columns}
            options={options}
          />
        </div>
      }
    </div>
  )
};

export default ComplianceFinancialDashboard