import * as React from "react";
import BugReportIcon from '@mui/icons-material/BugReport';
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";
import TestSuite from "./TestSuite";

export const Component = () => {
  const { views } = usePermissionContext();

  return (
    <Can action={views.VIEW} items={[views.TESTSUITE]} els={<Navigate to="/ballpark" />} env={"dev"}>
      <TestSuite />
    </Can>
  );
}
export const icon = <BugReportIcon />;
export const text = "Test Suite";
export const componentName = "TestSuite";
export const path = "testSuite";
