import * as React from "react";
import GrandSlam from "./GrandSlam";
import { useParams } from "react-router-dom";
import { decodeURLVariable } from "../../../utilities/utils";

const GrandSlamWrapper = (props) => {
  var { university, sport } = useParams();
  university = decodeURLVariable(university);
  sport = decodeURLVariable(sport);
  return (
    <>
      <GrandSlam school={university} sport={sport} />
    </>
  );
};
export default GrandSlamWrapper;
