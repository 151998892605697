import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { useParams } from "react-router-dom";
import Conversation from "./Conversation";

export const Component = () => {
  const { userId, jobId } = useParams()

  return (
    <Conversation athleteUserId={userId} jobId={jobId} />
  );
}

export const icon = <DirectionsRunIcon />;
export const text = "Messaging";
export const componentName = "Messaging";
export const path = "messaging/:userId/:jobId";
export const display = false
