import * as React from "react";
import SchoolIcon from "@mui/icons-material/School";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import SchoolWrapper from "./SchoolWrapper";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.SCHOOLS]} els={<Navigate to="/ballpark" />}>
      <SchoolWrapper />
    </Can>
  );
}
export const icon = <SchoolIcon />;
export const text = "Schools";
export const componentName = "Schools";
export const path = "schools/:university?";
