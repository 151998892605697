import { Grid } from '@mui/material'
import Campaign from "../Campaign/Campaign"
import { useState } from 'react'
import JobDetails from './JobDetails'
import MessageThread from './MessageThread'

const Conversation = ({ jobId, athleteUserId }) => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} sm={12}>
        {jobId && (<Campaign id={jobId} />)}
      </Grid>
      <Grid item lg={6} sm={12}>
        <MessageThread jobId={jobId} athleteUserId={athleteUserId} />
      </Grid>
    </Grid>
  )
}

export default Conversation
