import { useState, useEffect } from 'react'
import { LinearProgress, CircularProgress, Button } from '@mui/material';
import { EditableTable } from '../../common/EditableTable';
import TrueFalseCheckbox from '../../common/TrueFalseCheckbox';
import { getSchoolOutreach, putUniversityOutreach } from '../../../API/socialAccounts';
import { useUserContext } from '../../../contexts/ProviderProvider';
import useNotification from '../../../hooks/useNotification';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ColoredSelectModifier } from '../../common/ColoredSelectModifier';
import { getOptionColors, getDateForDatePicker } from '../../../utilities/utils';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { usePermissionContext } from '../../../contexts/ProviderProvider';
import { useNavigate } from 'react-router-dom';
import useCanUser from '../../../hooks/useCanUser';
import { convertSortedIndexNumber } from '../../../utilities/utils';

export const SchoolOutreach = () => {

    const user = useUserContext();
    const notify = useNotification();
    const { actions } = usePermissionContext();
    const disabled = !useCanUser(actions.ALTER, [actions.SCHOOLOUTREACH]);
    const [universityOutreach, setUniversityOutreach] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [changes, setChanges] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [refresh, setRefresh] = useState(0);
    const [optionColors, setOptionColors] = useState({});

    const navigate = useNavigate();

    const getChangeValue = (index, field) => {
        const field_string = index.toString() + "_" + field;
        return changes[field_string];
    };

    const setChangesFilter = (value, index, field) => {

        const field_string = index.toString() + "_" + field;
        if (value) {
            setChanges((prev) => ({ ...prev, [field_string]: value }));
        } else {
            setChanges((prev) => {
                const newState = { ...prev };
                delete newState[field_string];
                return newState;
            });
        }
    };

    const getTodaysDate = () => {
        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let currentDate = `${year}-${month}-${day}`;
        return currentDate

    }


    const updateUniversityOutreach = (alteration) => {
        setLoading(true);
        putUniversityOutreach(alteration, user)
            .then((response) => {
                setLoading(false);
                notify({
                    msg: "Successfully updated player outreach",
                    variant: "success",
                });
                setRefresh(refresh + 1);
            })
            .catch((error) => {
                console.log(error);
                notify("Failed to update player outreach");
                setLoading(false);
            });
    }

    const stageAlteration = (field, value, outreach_id, convertDate) => {

        if (field === "university_outreach_id") {
            return;
        }

        var alteration = { university_outreach_id: outreach_id }

        if (convertDate) {
            const date = new Date();

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            let currentDate = `${year}-${month}-${day}`;
            alteration[field] = currentDate;
            if (!value) {
                alteration[field] = null;
            }
        }
        else {
            alteration[field] = value;
        }
        updateUniversityOutreach(alteration);
    };


    const handleUpdate = () => {
        var alterations = { university_outreach_id: universityOutreach[selectedIndex].university_outreach_id }
        Object.keys(changes[selectedIndex]).map((key) => {
            if (key == "outreached") {
                var outreached_date = getTodaysDate()
                if (!changes[selectedIndex][key]) {
                    outreached_date = null
                }
                alterations["outreached_date"] = outreached_date;
            }
            alterations[key] = changes[selectedIndex][key];
        })

        putUniversityOutreach(alterations, user)
            .then((response) => {
                setLoading(false);
                notify({
                    msg: "Successfully updated player outreach",
                    variant: "success",
                });
                setIsModalOpen(false);
                setRefresh(refresh + 1);

            })
            .catch((error) => {
                console.log(error);
                notify("Failed to update player outreach");
                setLoading(false);
            });
    }

    useEffect(() => {
        setLoading(true);

        getSchoolOutreach(user)
            .then((response) => {

                setLoading(false);
                setUniversityOutreach(response.data.universityOutreach);
                setEmployees(response.data.employees);
                setOptionColors(getOptionColors(response.data.employees));
                // notify({
                //     msg: "Successfully retrieved player outreach",
                //     variant: "success",
                // });
            })
            .catch((error) => {
                console.log(error);
                notify("Failed to retrieve player outreach");
                setLoading(false);
            });

    }, [refresh, user, notify]);



    // edit options for Editable table
    const editOptions = {
        "assigned_to": { label: "Assigned To", "type": "Select", "options": employees },
        "outreached": { label: "Outreached", "type": "Checkbox" },
        "outreached_date": { label: "Outreached Date", "type": "Date" },
        "contact_responded": { label: "Contact Responded", "type": "Checkbox", disabled: true },
        "contact_name": { label: "School's Contact Name", "type": "Text", permissions: [actions.SCHOOLOUTREACH] },
        "contact_info": { label: "School's Contact Info", "type": "Text", permissions: [actions.SCHOOLOUTREACH] },
        "notes": { label: "Notes", "type": "Text", style: { marginTop: "10px", width: "600px" }, permissions: [actions.SCHOOLOUTREACH] },
    };

    // Columns for Editable table
    const columns = [
        {
            name: "university",
            label: "University",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Button sx={{ textTransform: 'unset' }}
                        onClick={() =>
                            navigate(`/ballpark/schools/${btoa(value)}`)
                        }
                    >
                        {value}
                    </Button>
                ),
            },
        },
        {
            name: "license_status", label: "License Status"
        },
        {
            name: "assigned_to", label: "Assigned To", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <ColoredSelectModifier
                            label=""
                            value={getChangeValue(convertSortedIndexNumber(tableMeta), "assigned_to")}
                            original={value}
                            options={employees}
                            setValue={(value) => {
                                stageAlteration(
                                    "assigned_to",
                                    value,
                                    universityOutreach[convertSortedIndexNumber(tableMeta)].university_outreach_id
                                )
                                setChangesFilter(value, convertSortedIndexNumber(tableMeta), "assigned_to");
                            }}
                            optionColors={optionColors}
                            permissions={[actions.SCHOOLOUTREACH]}
                        />


                    )
                },
            },
        },
        {
            name: "outreached", label: "Outreached", options: {
                customBodyRender: (value, tableMeta) => (
                    <TrueFalseCheckbox
                        checked={value}
                        interact={() => {
                            stageAlteration(
                                "outreached",
                                !value,
                                universityOutreach[convertSortedIndexNumber(tableMeta)].university_outreach_id
                            )
                            var outreached_date = getTodaysDate()
                            if (value) {
                                outreached_date = null
                            }
                            stageAlteration(
                                "outreached_date",
                                outreached_date,
                                universityOutreach[convertSortedIndexNumber(tableMeta)].university_outreach_id
                            )
                        }
                        }
                        disabled={disabled}
                    />
                ),
            },
        },
        {
            name: "outreached_date", label: "Outreached Date",
            // options: {
            //     customBodyRender: (value, tableMeta) => (
            //         <LocalizationProvider dateAdapter={AdapterDayjs}>
            //             <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '75%' }}>

            //                 <DateTimePicker
            //                     views={['month', 'year', 'day']}
            //                     openTo="day"
            //                     value={getDateForDatePicker(value)}
            //                     onAccept={(newValue) => {
            //                         stageAlteration(
            //                             "outreached_date",
            //                             newValue,
            //                             universityOutreach[convertSortedIndexNumber(tableMeta)].university_outreach_id
            //                         )
            //                     }}
            //                 />
            //             </div>
            //         </LocalizationProvider>

            //     ),
            // },
        },
        {
            name: "contact_responded", label: "Is Contact Responded", options: {

                customBodyRender: (value, tableMeta) => {

                    return (<TrueFalseCheckbox
                        checked={value}
                        interact={() =>
                            stageAlteration(
                                "contact_responded",
                                !value,
                                universityOutreach[convertSortedIndexNumber(tableMeta)].university_outreach_id,
                                true
                            )
                        }
                        disabled={disabled}
                    />)
                }

            },
        },
        { name: "contact_name", label: "School Contact Name" },
        { name: "contact_info", label: "School Contact Info" },
        { name: "notes", label: "Notes" },
    ];



    const options = {
        rowsPerPage: window.rowsPerPage,
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress />
                ) : (
                    "No Open Roster Records for that School :("
                ),
            },
        }
    };

    return (
        <div style={{ marginTop: "10px" }}>

            {loading && <LinearProgress />}
            <EditableTable
                tableOptions={options}
                tableColumns={columns}
                data={universityOutreach}
                title={"University Outreach"}
                editOptions={editOptions}
                editTitle={"Edit Social Account"}
                tableUpdates={changes}
                setTableUpdates={setChanges}
                handleSave={handleUpdate}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                isEditable={!disabled}
            />

        </div>
    )
}