import GroupsIcon from '@mui/icons-material/Groups';
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import Big3 from "./Big3";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.BIG3]} els={<Navigate to="/ballpark" />}>
      <Big3 />
    </Can>
  );
}
export const icon = <GroupsIcon />;
export const text = "Big 3 Players";
export const componentName = "Big3";
export const path = "big3";