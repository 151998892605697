import React from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import EmployeeTable from "./EmployeeTable.jsx";
import { useUserContext } from "../../../contexts/ProviderProvider.jsx";
import NewEmployee from "./NewEmployee.jsx";

const Employees = (props) => {
  const [refresh, setRefresh] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState("Internal");

  const user = useUserContext();

  if (!user) {
    return <></>;
  }
  return (
    <div style={{ 
      width: "90%",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      flexDirection: "column",
      }}>
      <Tabs value={activeTab} onChange={(event, newValue) => {setActiveTab(newValue)}}>
        <Tab label="Internal" value="Internal" />
        <Tab label="External" value="External" />
      </Tabs>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{ width: "100%" }}
        gap={5}
      >
        <Grid item lg={12} xs={12} style={{ paddingTop: "5vh", width: "100%" }}>
          {activeTab === "Internal" && <EmployeeTable
            reloadFlag={refresh}
            reload={() => setRefresh((p) => p + 1)}
            internal={true}
          />}
          {activeTab === "External" && <EmployeeTable
            reloadFlag={refresh}
            reload={() => setRefresh((p) => p + 1)}
            internal={false}
          />}
        </Grid>
        <Grid item lg={12} xs={12} style={{ paddingTop: "2vh" }}>
          <NewEmployee reload={() => setRefresh((p) => p + 1)} tab={activeTab}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default Employees;
