import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ErrorAlert from "./ErrorMessage";
import { useNavigate } from "react-router-dom";
import { signInWithGoogle, signInWithEmailPass, forgotPassword } from "../../API/firebase.ts";
import { styled } from '@mui/material/styles';
import { TextField} from '@mui/material';

const FormContainer = styled('form')({
  width: '100%',
  maxWidth: 300,
  marginTop: theme => theme.spacing(1),
});

const SubmitButton = styled(Button)({
  margin: theme => theme.spacing(3, 0, 2),
});

export default function GoogleSignIn() {
  let navigate = useNavigate();

  const [loginBool, setLoginBool] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [forgotPass, setForgotPass] = React.useState(0);

  const handleLoginEmailPass = async (event) => {
    event.preventDefault();
    const userLogin = await signInWithEmailPass(username,password);
    if (!userLogin) {
      setLoginBool(false);
      setErrorMessage("Hmmm...it seems like your auth token is incorrect");
    } else {
      if (userLogin.error) {
        setLoginBool(false);
        setErrorMessage(userLogin.message);
        //console.log("user login error", loginBool, errorMessage);
      } else {
        setLoginBool(true);
        setErrorMessage("");
        loginBool && navigate("/ballPark/")
      }
    }
  };

  React.useEffect(() => {
    if(forgotPass !== 2) {return}
      const timer = setTimeout(() => {
        setForgotPass(0);
      }, 5000);
      return () => clearTimeout(timer);
    }, [forgotPass]);

  const handleForgotPassword = async (event) => {
      setForgotPass(2);
      event.preventDefault();
      const pass = await forgotPassword(username);
      setErrorMessage(pass.message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userLogin = await signInWithGoogle();
    if (!userLogin) {
      setLoginBool(false);
      setErrorMessage("Hmmm...it seems like your auth token is incorrect");
    } else {
      if (userLogin.error) {
        setLoginBool(false);
        setErrorMessage("Please login with your influxer account");
        //console.log("user login error", loginBool, errorMessage);
      } else {
        setLoginBool((prevBool) => true);
        setErrorMessage("");
        console.log("user login error", loginBool, errorMessage);
        loginBool && navigate("/ballpark");
      }
    }
  };

  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          width: "90%",
          borderRadius: "30px",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          color="black"
          style={{ marginTop: "24px" }}
        >
          Welcome to Ballpark
        </Typography>
        <Avatar sx={{ mt: 2, bgcolor: "error.main" }}>
          <LockOutlinedIcon />
        </Avatar>

        {errorMessage !== "" && <ErrorAlert message={errorMessage} />}

          <FormContainer onSubmit={handleLoginEmailPass}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <SubmitButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Login
            </SubmitButton>
          </FormContainer>
          <Button color="primary" onClick={handleForgotPassword}>
            Forgot / Reset Password
          </Button>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 6, bgcolor: "success.main" }}
          >
            Employee Login
          </Button>
        </Box>
      </Box>
      {/* {forgotPass && <></>} */}
    </Container>
  );
}
