import { useState, useEffect } from 'react'
import { useUserContext } from '../../../contexts/ProviderProvider'
import { Button, MenuItem, Select, TextField, Typography, listItemSecondaryActionClasses } from '@mui/material'
import { createTestUser } from '../../../API/testSuite'
import useNotification from "../../../hooks/useNotification";

const CaptureField = (props) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "min-content", }}>
            {props.placeholder}
            <TextField style={{ width: "400px", margin: "10px 10px" }} {...props} />
        </div>
    )
}

const TestSuite = ({ }) => {

    const user = useUserContext()
    const notify = useNotification()

    const testUniversityList = ["Bree", "Weathertop", "Rivendell", "Moria", "Lorien", "Rauros", "Isengard", "Edoras", "Osgiliath", "Barad Dur"]
    const testSportList = ['Swimming & Diving', 'Lacrosse (M)', 'Football', 'Basketball (M)', 'Soccer (W)', 'Volleyball (W)', 'Basketball (W)', 'Tennis (W)', 'TF and XC', 'Beach Volleyball', 'Soccer (M)', 'Softball', 'Baseball', 'Field Hockey (W)', 'Lacrosse (W)', 'Golf (M)', 'Golf (W)', 'Tennis (M)', 'Gymnastics (W)', 'Rowing (W)', 'Equestrian', 'Volleyball (M)', 'Ice Hockey (M)', 'Skiing', 'Bowling (W)', 'Dance', 'Ice Hockey (W)', 'Cheer', 'STUNT', 'Wrestling (M)', 'Gymnastics (M)', 'Bowling (M)', 'Rugby (M)', 'Greco-Roman Wrestling', 'Fencing', 'POM', 'Wrestling (W)', 'Rowing (M)', 'Triathlon (W)', 'Rifle', 'Acrobatics & Tumbling', 'Table Tennis', 'Other', 'Water Polo (M)', 'Water Polo (W)', 'eSports', 'Bass Fishing', 'Weightlifting', 'Rodeo', 'Sailing', 'Rugby (W)', 'Synchronized Skating', 'Alpine Skiing', 'Sprint Football', 'Cycling', 'Squash (M)', 'Squash (W)', 'Artistic Swimming', 'Field Hockey (M)', 'Triathlon (M)', 'Nordic Skiing', 'Crew', 'Riding', 'Waterski', 'Sport Shooting (W)']

    const [first, setFirst] = useState(user.displayName.split(" ")[0])
    const [last, setLast] = useState(user.displayName.split(" ")[1])
    const [password, setPassword] = useState("asdfasdf")
    const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("testSuite.phoneNumber"))
    const [email, setEmail] = useState(user.email.replace("@", `+${new Date().getTime()}@`))
    const [university, setUniversity] = useState(testUniversityList[0])
    const [sport, setSport] = useState("Football")
    const [jerseyNumber, setJerseyNumber] = useState("00")

    const createAccount = (accountType) => {
        const meta = { startingState: accountType, email, firstName: first, lastName: last, phoneNumber, university, genderedSport: sport, jerseyNumber }

        createTestUser(meta, user)
            .then(resp => {
                console.log(resp)
            }).catch(e => {
                console.log("failed", e)
                notify(e)
            })

        setEmail(user.email.replace("@", `+${new Date().getTime()}@`))
    }

    useEffect(() => { localStorage.setItem("testSuite.phoneNumber", phoneNumber) }, [phoneNumber])

    return (

        <div style={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <br />
            <Typography variant="h5" style={{ width: "min-content", whiteSpace: "nowrap" }}>The fields influence how your athlete is created</Typography>
            <br />
            <CaptureField value={first} placeholder="First Name" onChange={(e) => setFirst(e.target.value)} />
            <CaptureField value={last} placeholder="Last Name" onChange={(e) => setLast(e.target.value)} />
            <CaptureField value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            <Typography variant="overline" color={"yellow"}>The "+number" is so that you can create multiple accounts and all the emails will still end up going to you</Typography>
            <CaptureField value={phoneNumber} placeholder="Phone Number" onChange={(e) => setPhoneNumber(e.target.value)} />
            <CaptureField value={jerseyNumber} placeholder="Jersey Number" onChange={(e) => setJerseyNumber(e.target.value)} />
            <div style={{ display: "flex", flexDirection: "column" }}>
                University
                <Select
                    id="demo-simple-select"
                    value={university}
                    label="University"
                    onChange={(e) => setUniversity(e.target.value)}
                    style={{ width: "400px" }}
                >
                    {testUniversityList.map(u => {
                        return <MenuItem value={u}>{u}</MenuItem>
                    })}

                </Select>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                Sports
                <Select
                    id="demo-simple-select"
                    value={sport}
                    label="Sport"
                    onChange={(e) => setSport(e.target.value)}
                    style={{ width: "400px" }}
                >
                    {testSportList.map(u => {
                        return <MenuItem value={u}>{u}</MenuItem>
                    })}
                </Select>
            </div>

            {(first && last && password && phoneNumber && email) ? (
                <>
                    <Button style={{ width: "100%", margin: "25px 50px 0px 50px" }} variant='contained' onClick={() => { createAccount("HappyPath") }}>Create New Happy Path Account</Button>
                    <Button style={{ width: "100%", margin: "25px 50px 0px 50px" }} variant='contained' onClick={() => { createAccount("OldAppOnly") }}>Create New Old App Only Account</Button>
                    <Button style={{ width: "100%", margin: "25px 50px 0px 50px" }} variant='contained' onClick={() => { createAccount("NewAppOnly") }}>Create New New App Only Account</Button>
                    <Button style={{ width: "100%", margin: "25px 50px 0px 50px" }} variant='contained' onClick={() => { createAccount("InTransfer") }}>Create New In Transfer State Account</Button>
                </>
            ) : <></>}
        </div >
    )
}

export default TestSuite