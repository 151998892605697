import React, { useState } from 'react';
import {Modal, Box, Typography, Button, Chip, TextField, Paper} from '@mui/material';
import ReactQuill from "react-quill";

const groupByType = (array) => {
    return array.reduce((result, currentValue) => {
        const type = currentValue.type;
        if (!result[type]) {
            result[type] = [];
        }
        result[type].push(currentValue);
        return result;
    }, {});
};

const ValueUpdateSummaryModal = ({ isModalOpen, setIsModalOpen, filteredProducts, noProductsFound, handleConfirmUpdate, handleDescriptionConfirmUpdate, updateType, universities, sports, types, isSubmitting, specificOptions }) => {
    const [expandedProductType, setExpandedProductType] = useState(null);
    const [note, setNote] = useState("");

    const handleTypeClick = (type) => {
        setExpandedProductType(expandedProductType === type ? null : type);
    };

    const handleConfirm = () => {
        if (updateType === "Description") {
            handleDescriptionConfirmUpdate(note)
        }else{
            handleConfirmUpdate(note);
        }
        setNote("")
    };

    const groupedProducts = groupByType(filteredProducts);

    return (
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} sx={{ zIndex: 11000 }}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2
            }}>
                <Typography variant="h6" gutterBottom>Confirm Bulk {updateType} Update</Typography>
                <Box sx={{ mb: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px', backgroundColor: "primary.main" }}>
                    <Typography variant="body1" gutterBottom><strong>Filter Options: {universities.join(', ')}, {sports.join(', ')}, {types.join(', ')}</strong></Typography>
                    <Typography variant="body2">Updating <strong>{filteredProducts.length}</strong> products.</Typography>
                </Box>

                <Typography variant="body1" gutterBottom>The following products will be updated:</Typography>
                <Box sx={{ maxHeight: '500px', overflowY: 'auto', margin: '16px 0', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}>
                    {noProductsFound ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Typography variant="body1" color="textSecondary" sx={{ backgroundColor: 'primary.main', padding: '16px', borderRadius: '4px' }}>
                                No products found. Check Your Filter Options!
                            </Typography>
                        </Box>
                    ) : (
                        Object.entries(groupedProducts).map(([type, products]) => {
                            const specificOptionContent = () => {
                                if (updateType === "Description") {
                                    return specificOptions[type]?.description ? (
                                        <Box component="div" sx={{ marginBottom: '16px' }}>
                                            <Typography variant="body1" gutterBottom>New Description:</Typography>
                                            <Paper sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px', maxHeight: '100px', overflow: 'auto' }}>
                                                <ReactQuill value={specificOptions[type].description} readOnly={true} theme="bubble" />
                                            </Paper>
                                        </Box>
                                    ) : (
                                        <Typography>New Description: N/A</Typography>
                                    );
                                } else {
                                    if (specificOptions[type]) {
                                        return Object.entries(specificOptions[type]).map(([size, value]) => (
                                            <Chip key={size} label={`${size}: ${value}`} sx={{ margin: '2px' }} />
                                        ));
                                    } else {
                                        return <Chip label="N/A" />;
                                    }
                                }
                            };
                            return (
                                <Box key={type} sx={{ mb: 2, borderBottom: '1px solid grey', pb: 2 }}>
                                    <Box
                                        sx={{
                                            cursor: 'pointer',
                                            padding: '12px',
                                            border: '1px solid #ddd',
                                            borderRadius: '8px',
                                            backgroundColor: expandedProductType === type ? 'primary.main' : 'background.paper',
                                            '&:hover': {
                                                backgroundColor: 'primary.main',
                                            },
                                            mb: 1
                                        }}
                                        onClick={() => handleTypeClick(type)}
                                    >
                                        <Typography variant="h6"><strong>Type:</strong> {type}</Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 1 }}>
                                            {specificOptionContent()}
                                        </Box>
                                    </Box>
                                    {expandedProductType === type && (
                                        <Box sx={{ ml: 2 }}>
                                            {products.map(product => (
                                                <Box key={product.ShopifyProductID} sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px', borderBottom: '1px solid grey' }}>
                                                    <Box>
                                                        <Typography><strong>SKU:</strong> {product.Sku_name}</Typography>
                                                        <Typography><strong>Sport:</strong> {product.sport}</Typography>
                                                        <Typography><strong>University:</strong> {product.university}</Typography>
                                                        <Typography><strong>Athlete's Name:</strong> {product.full_name}</Typography>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                </Box>
                            );
                        })
                    )}
                </Box>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Add a Note To This Bulk Operation"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        multiline
                        rows={4}
                        variant="outlined"
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', mt: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleConfirm} disabled={isSubmitting}>Confirm</Button>
                    <Button variant="outlined" color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ValueUpdateSummaryModal;