import * as React from "react";
import MUIDataTable from "mui-datatables";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const RosterTable = ({ roster }) => {
  const navigate = useNavigate();
  const columns = [
    {
      name: "full_name",
      label: "Full Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta)
          return (
            <Button sx={{ textTransform: 'unset' }}
              onClick={() =>
                navigate(`/ballpark/athletes/${btoa(tableMeta.rowData[8])}`)
              }
            >
              {value}
            </Button>
          )
        }
      },
    },
    { name: "jersey_number", label: "Jersey Number", options: {} },
    { name: "sport", label: "Sport", options: {} },
    {
      name: "is_international",
      label: "Is International",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
      },
    },
    {
      name: "signed",
      label: "Signed",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
      },
    },
    {
      name: "is_matched",
      label: "Matched",
      options: {
        filter: true,
        filterList: [true],
        customFilterListOptions: {
          render: (v) => (v !== null ? "Matched" : ""),
        },
        filterOptions: {
          render: (v) => (v !== null ? "Matched" : ""),
        },
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
      },
    },
    {
      name: "addedOnSite",
      label: "On Shopify",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
      },
    },
    {
      name: "postedOnSocial",
      label: "Posted on Social",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
      },
    },
    {
      name: "idAthleteMerchInfo",
      label: "roster_id",
      options: {},
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: window.rowsPerPage,
    selectableRows: "none",
  };

  return (
    <div>
      <MUIDataTable
        title={"Roster"}
        data={roster}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default RosterTable;
