import axios from "axios";

const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;

export async function getSchools(preference, submitError) {
  //if products then bool will be true
  //console.log(BASE_URL);

  return axios
    .get(BASE_HATTRICK_URL + "universities/" + preference)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      submitError(error.message + (error.data ? ":" + error.data : ""));
      return {
        universities: [],
        productExecution: [],
        collectionExecution: [],
      }; // Return an empty array in case of an error
    });
  /* data looks like
    {
      "universities": [
          "University of Florida",
          "University Of Idaho",
          "Austin Peay State University"
      ],
      "productExecution": [],
      "collectionExecution": []
    }
  */
}
export async function runCollections(schoolNames, setLoadingPage, submitError) {
  setLoadingPage(true);
  setLoadingPage(true);
  let schoolDict = { University: schoolNames };

  return axios
    .post(BASE_HATTRICK_URL + "collection", schoolDict)
    .then((response) => {
      // console.log("received after start", response.data);
      setLoadingPage(false);
      return response.data;
    })
    .catch((error) => {
      setLoadingPage(false);
      // console.log("error:", error);
      if (error.response.data.blockedUniversities.length > 0) {
        submitError(
          "These schools:" +
            error.response.data.blockedUniversities.join(",") +
            " were already running"
        );
      } else {
        submitError(
          error.message +
            ":" +
            error.code +
            (error.config.data ? " -- \r\n" + error.config.data : "")
        );
      }
      return [];
    });
}
export async function runProducts(schoolNames, setLoadingPage, submitError) {
  setLoadingPage(true);
  let schoolDict = { University: schoolNames };

  return axios
    .post(BASE_HATTRICK_URL + "product", schoolDict)
    .then((response) => {
      //console.log("received after start", response.data);
      setLoadingPage(false);
      return response.data;
    })
    .catch((error) => {
      setLoadingPage(false);
      //console.log("error:", error);
      if (error.response.data.blockedUniversities.length > 0) {
        submitError(
          "These schools:" +
            error.response.data.blockedUniversities.join(",") +
            " were already running"
        );
      } else {
        submitError(
          error.message +
            ":" +
            error.code +
            (error.config.data ? " -- \r\n" + error.config.data : "")
        );
      }
      return [];
    });
}
export const getProductsReady = (school, sport, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BASE_HATTRICK_URL +
      "hattrick/ready/products?" +
      (school ? "uni=" + btoa(school) : "") +
      (sport ? "&sport=" + btoa(sport) : ""),
    { headers }
  );
};
export const getCollectionsReady = (school, sport, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(
    BASE_HATTRICK_URL +
      "hattrick/ready/collections?" +
      (school ? "uni=" + btoa(school) : "") +
      (sport ? "&sport=" + btoa(sport) : ""),
    { headers }
  );
};
export const runHattrickOn = (records, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(BASE_HATTRICK_URL + "hattrick/run", records, { headers });
}; 

export const stopHattrick = (user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.post(BASE_HATTRICK_URL + "hattrick/stop", null, { headers });
};

export const revokeLicense = (schoolName, user) => {
  return axios.post(
    BASE_HATTRICK_URL + "filesystem/revokeLicense",
    { school: schoolName },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
    }
  );
};

export const getCurrentlyRunning = (user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(BASE_HATTRICK_URL + "hattrick/running", { headers });
};

export const markAsArchived = (payload, user, refresh) => {
  return axios.post(
    BASE_HATTRICK_URL + "products/archive",
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
    }
  );
};
export const updateProductStatus = (productIds, status, user) => {
  var product_status = status.toLowerCase();
  return axios.post(
    BASE_HATTRICK_URL + "products/updateProductStatus",
    { shopify_product_ids: productIds, product_status: product_status },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
    }
  );
};

export const updateMetadata = (updated, user) => {
  return axios.post(
    BASE_HATTRICK_URL + "products/updateMetadata",
    {
      ...updated,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
    }
  );
};

export const updateVendor = (productId, oldVendor, newVendor, user) => {
  return axios.post(
    BASE_HATTRICK_URL + "products/vendor",
    {
      shopify_product_id: productId,
      old_vendor: oldVendor,
      new_vendor: newVendor,
    },
    {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.accessToken,
    }
  );
};
export const getVendors = (user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };
  return axios.get(BASE_HATTRICK_URL + "products/vendors", { headers });
};
