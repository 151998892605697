import React, {useEffect, useState} from 'react';
import {
    FormControl,
    TextField,
    Autocomplete,
    Button,
    Box,
    Typography,
    Paper,
    IconButton,
    MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    fetchVariantsCreationSummary,
    fetchSizesForType,
    fetchLocations,
    } from '../../../../API/audible';
import SizeEditModal from './SizeEditModal';

const VariantsCreation = ({
                              TypeOptions,
                              setVariantsCreationType,
                              notify,
                              user,
                              setProductSizeData,
                              setIsModalOpen,
                              clearState,
                          }) => {
    const [selectedType, setSelectedType] = useState('');
    const [sizesData, setSizesData] = useState([]); // To store size-related values: Now we have SizeName, Price, Cost, Weight, WeightUnit (not editiable), InventoryLevel, and Editable(for values we fetched from price and cost map, it is False)
    const [variantProducts, setVariantProducts] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpenState] = useState(false);
    const [weightUnit, setWeightUnit] = useState('');
    const [locationInventoryPairs, setLocationInventoryPairs] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedSizeIndex, setSelectedSizeIndex] = useState(null);


    useEffect(() => {
        setSelectedType('');
        setSizesData([]);
        setVariantProducts([]);
        setWeightUnit('');
        setLocationInventoryPairs([])
        setSelectedSizeIndex(null)
    }, [user, clearState]); // clear state is passed in by the parent component Audible.

    // Need to handle type selection change
    const handleTypeChange = async (event, newValue) => {
        setSelectedType(newValue);
        setVariantsCreationType(newValue)

        // Fetch sizes for the selected type
        if (newValue) {
            try {
                const response = await fetchSizesForType(user, newValue);
                const sizes = response.data.obj[newValue] || [];
                const unit = sizes.length > 0 ? sizes[0].WeightUnit : '';
                setWeightUnit(unit);
                setSizesData(
                    sizes.map((size) => ({
                        ...size,
                        editable: false, // Existing sizes are not editable
                        locationInventory: [],
                    }))
                );
            } catch (error) {
                console.error('Error fetching sizes:', error);
                notify('Failed to fetch sizes');
            }
        }
    };

    const handleAddSize = () => {
        // Add a new size with default values and an editable flag
        setSizesData((prevData) => [
            ...prevData,
            { Size: '', Price: 0, Cost: 0, Weight: 0, WeightUnit: weightUnit, locationInventory: locationInventoryPairs, editable: true },
        ]);
    };

    const handleFetchVariants = async () => {
        if (!selectedType) {
            notify('Please select a product type.');
            return;
        }

        if (locationInventoryPairs.length === 0) {
            notify('Please add at least one location-inventory pair.');
            return;
        }

        setIsSubmitting(true);
        setVariantProducts([]); // Clear previous results

        try {
            const response = await fetchVariantsCreationSummary(user, selectedType);
            const allProducts = response.data.obj['Products to be Processed'] || [];

            // Filter out products with null shopify_product_id
            const filteredProducts = allProducts.filter(
                (product) => product.shopify_product_id !== null
            );

            // Calculate the number of filtered-out products
            const filteredOutCount = allProducts.length - filteredProducts.length;
            if (filteredOutCount !== 0) {
                // and notify the user if there is any
                notify(`Filtered out ${filteredOutCount} products without Shopify ID`);
            }

            setVariantProducts(filteredProducts);
            // Prepare the product-size data for final submission
            const productSizeData = {};
            filteredProducts.forEach((product) => {
                productSizeData[product.shopify_product_id] = sizesData;
            });
            setProductSizeData(productSizeData);
            setIsModalOpen(true); // Open confirmation modal
        } catch (error) {
            console.error('Error fetching variants:', error);
            notify('Failed to fetch variants');
        } finally {
            setIsSubmitting(false);
        }
    };

    const fetchLocationOptions = async () => {
        try {
            const response = await fetchLocations(user);
            const locations = response.data.obj.Locations || [];
            setLocationOptions(locations);
        } catch (error) {
            console.error('Error fetching locations:', error);
            notify('Failed to fetch locations');
        }
    };

    useEffect(() => {
        fetchLocationOptions();
    }, [user]);

    // Here need to handle location-inventory change for all sizes
    const handleGlobalLocationInventoryChange = (field, value, index = null) => {
        setLocationInventoryPairs((prevPairs) => {
            const newPairs = [...prevPairs];

            if (index !== null) {
                newPairs[index][field] = value;
            } else {
                newPairs.push({ locationId: '', inventoryLevel: 50 });
            }

            return newPairs;
        });

        setSizesData((prevData) => {
            const updatedData = prevData.map((size) => ({
                ...size,
                locationInventory: [...locationInventoryPairs],
            }));
            return updatedData;
        });
    };

    const handleAddGlobalLocationInventoryPair = () => {
        handleGlobalLocationInventoryChange();
    };

    const handleRemoveGlobalLocationInventoryPair = (index) => {
        setLocationInventoryPairs((prevPairs) => {
            const newPairs = prevPairs.filter((_, i) => i !== index);
            setSizesData((prevData) => {
                const updatedData = prevData.map((size) => ({
                    ...size,
                    locationInventory: newPairs,
                }));
                return updatedData;
            });
            return newPairs;
        });
    };

    return (
        <Box sx={{ marginTop: '16px' }}>
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    options={TypeOptions}
                    getOptionLabel={(option) => option}
                    value={selectedType}
                    onChange={handleTypeChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Product Type" />
                    )}
                />
            </FormControl>
            {sizesData.length > 0 && (
                <>
                    <Box sx={{ marginTop: '16px' }}>
                        <Typography variant="h6">Sizes</Typography>
                        <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                            The displayed sizes are the ones currently in our system, not on Shopify. Starting this operation will sync these sizes to Shopify. We will create new variants for each size and reorder the variants based on your input. However, we wont delete any existing variants.
                            You can also add a new size that is not defined in our system yet.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '16px',
                                justifyContent: 'flex-start',
                            }}
                        >
                            {sizesData.map((size, index) => (
                                <Paper
                                    key={index}
                                    elevation={3}
                                    sx={{
                                        padding: '16px',
                                        textAlign: 'center',
                                        position: 'relative',
                                        width: '140px',
                                    }}
                                    onClick={() => {
                                        setSelectedSizeIndex(index);
                                        setIsModalOpenState(true);
                                    }}
                                >
                                    <Typography variant="subtitle1">{size.Size || 'Unnamed'}</Typography>
                                    <Typography variant="body2">Price: {size.Price}</Typography>
                                    <Typography variant="body2">Cost: {size.Cost}</Typography>
                                    <Typography variant="body2">Weight: {size.Weight}</Typography>
                                </Paper>
                            ))}
                            <Paper
                                elevation={3}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '16px',
                                    position: 'center',
                                    width: '140px',
                                    height: '140px',
                                }}
                            >
                                <IconButton color="secondary" onClick={handleAddSize}>
                                    <AddIcon />
                                </IconButton>
                            </Paper>
                        </Box>
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6">Global Location-Inventory Settings</Typography>
                        <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                            Configure location and inventory levels for all sizes. This configuration will only affect the new Variants we are adding on Shopify and will not change existing variants' inventory configuration.
                        </Typography>
                        {locationInventoryPairs.map((pair, index) => (
                            <Paper
                                key={index}
                                elevation={2}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '16px',
                                    mb: 2,
                                }}
                            >
                                <TextField
                                    select
                                    label="Location"
                                    value={pair.locationId}
                                    onChange={(e) => handleGlobalLocationInventoryChange('locationId', e.target.value, index)}
                                    fullWidth
                                    margin="normal"
                                >
                                    {locationOptions.map((location) => (
                                        <MenuItem key={location.id} value={location.id}>
                                            {location.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    label="Inventory Level"
                                    type="number"
                                    value={pair.inventoryLevel}
                                    onChange={(e) => handleGlobalLocationInventoryChange('inventoryLevel', e.target.value, index)}
                                    fullWidth
                                    margin="normal"
                                    sx={{ ml: 2 }}
                                />
                                <IconButton
                                    color="secondary"
                                    onClick={() => handleRemoveGlobalLocationInventoryPair(index)}
                                    sx={{ ml: 2 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Paper>
                        ))}
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAddGlobalLocationInventoryPair}
                            sx={{ mt: 2, display: 'flex', alignItems: 'center'}}
                        >
                            Add Location-Inventory Pair
                        </Button>
                    </Box>
                        <Box sx={{ mt: 2 }}>
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    color="primary"*/}
                            {/*    onClick={() => setIsModalOpenState(true)} // Open the edit modal*/}
                            {/*    sx={{ mt: 2, mr: 2 }}*/}
                            {/*>*/}
                            {/*    Edit Sizes*/}
                            {/*</Button>*/}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleFetchVariants}
                                disabled={isSubmitting}
                                sx={{ mt: 2 }}
                            >
                                Start Bulk Operation
                            </Button>
                        </Box>
                    </Box>
                </>
            )}

            <SizeEditModal
                user = {user}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpenState}
                sizesData={sizesData}
                setSizesData={setSizesData}
                locationOptions = {locationOptions}
                selectedSizeIndex={selectedSizeIndex}
            />
        </Box>
    );
};

export default VariantsCreation;