import { useState, useEffect } from "react";
import Can from "../../common/Can";
import FileDropZone from "../../common/FileDropZone";
import AreYouSure from "../../common/AreYouSure";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Modal,
  Typography,
  TextField, Grid, ButtonGroup, Select, MenuItem
} from "@mui/material";
import { useUserContext, usePermissionContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { getBizDevs } from "../../../API/employee";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PercentIcon from '@mui/icons-material/Percent';
import InputAdornment from "@mui/material/InputAdornment";
import { convertSortedIndexNumber } from "../../../utilities/utils";

const LicenseInteractor = ({ tableMeta, uploadLicenses, revokeLicense, openModal, schoolData }) => {
  const [tryRevokeLicense, setTryRevokeLicense] = useState();
  const [uploadLicenseContext, setUploadLicenseContext] = useState();
  const [bdrOptions, setBdrOptions] = useState();
  const [bdrOptionsLoading, setBdrOptionsLoading] = useState(false);
  const [licensingCompanies, setLicensingCompanies] = useState([]);
  const [licensingCompany, setLicensingCompany] = useState("");
  const [licensingCompanyInput, setLicensingCompanyInput] = useState("");
  const [division, setDivision] = useState(schoolData.division);
  const [subDivision, setSubDivision] = useState(schoolData.subDivision);
  const [conference, setConference] = useState(schoolData.conference);
  const [url, setUrl] = useState('');
  const [royaltyRate, setRoyaltyRate] = useState('');
  const [schoolColors, setSchoolColors] = useState({});
  const user = useUserContext();
  const notify = useNotification();
  const { actions } = usePermissionContext();

  useEffect(() => {
    if (!bdrOptions && uploadLicenseContext) {
      setBdrOptionsLoading(true);
      getBizDevs(user)
        .then((resp) => {
          setBdrOptions(resp.data.bizDevs);
          setLicensingCompanies(resp.data.licensingCompanies)
          setBdrOptionsLoading(false);
        })
        .catch((e) => {
          setBdrOptionsLoading(false);
          notify("Failed to get BDR options:" + e);
        });
    }
  }, [uploadLicenseContext, bdrOptions, notify, user]);

  const getColor = (colorText) => {
    if (!colorText) return ""
    return colorText[0] != "#" ? "#" + colorText : colorText
  }

  const handleRoyatlyRateChange = (event) => {
    const newValue = event.target.value;

    if (newValue === "" || (/^-?\d*\.?\d*$/.test(newValue) && parseFloat(newValue) < 100)) {
      setRoyaltyRate(newValue);
    }
  };

  const isValidHexCode = (hexCode) => {
    const hexRegex = /^#?([0-9A-F]{3}){1,2}$/i;
    return hexRegex.test(hexCode);
  }

  const resetState = () => {
    setUrl('');
    setLicensingCompany('');
    setLicensingCompanyInput('');
    setSchoolColors({});
    setUploadLicenseContext();
  }


  const upload = () => {
    uploadLicenses(
      tableMeta.rowData[0],
      uploadLicenseContext.account_manager_employee_id,
      uploadLicenseContext.employee_id,
      uploadLicenseContext.files,
      uploadLicenseContext.callback,
      url,
      royaltyRate,
      licensingCompany == "Other" ? licensingCompanyInput : licensingCompany,
      uploadLicenseContext.schoolIcon,
      getSchoolColors(),
      division,
      subDivision,
      conference
    );
    resetState();
  };

  const getSchoolColors = () => {
    const values = Object.values(schoolColors);

    const transformedValues = values.join(", ");
    return transformedValues.replace("#", "")
  }

  const interactable = () => {
    if (tableMeta.rowData[1] !== "Approved") {
      return (
        <Can action={actions.ALTER} items={[actions.LICENSESTATUS]}>
          <FileDropZone
            uploadFiles={(files, callback) => {
              setUploadLicenseContext({ files: files[0] })
              console.log(files)
              callback();
            }

            }
            text={"Drop License PDF"}
          />
        </Can>
      );
    } else if (tableMeta.rowData[1] === "Approved") {
      return (
        <Can action={actions.ALTER} items={[actions.LICENSESTATUS]}>
          <ButtonGroup>
            <Button
              variant="contained"
              sx={{ backgroundColor: "primary.main" }}
              onClick={() => openModal(convertSortedIndexNumber(tableMeta))}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "error.main" }}
              onClick={() => setTryRevokeLicense(tableMeta.rowData[0])}
            >
              Revoke
            </Button>

          </ButtonGroup>

        </Can>
      );
    }
  };

  return (
    <>
      {interactable()}
      <AreYouSure
        style={{
          backgroundColor: "background.paper",
          margin: "auto",
          marginTop: "10vh",
          padding: "20px",
          width: "30vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "5px",
        }}
        open={tryRevokeLicense !== undefined}
        message={`Are you sure you want to revoke the license to ${tryRevokeLicense}?`}
        yes={() => {
          revokeLicense(tryRevokeLicense);
          setTryRevokeLicense();
        }}
        no={() => setTryRevokeLicense(undefined)}
      />
      {uploadLicenseContext && (
        <Modal open={uploadLicenseContext} sx={{ overflow: "scroll" }}>
          <Box
            sx={{
              backgroundColor: "background.paper",
              margin: "auto",
              marginTop: "10vh",
              padding: "20px",
              width: "50vw",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "5px",

            }}
          >
            <center>
              <Typography variant={"h6"} paddingBottom={"10px"}>
                Please select the assigned Business Development Reps
              </Typography>
            </center>
            {/* <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Select</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bdrOptionsLoading && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {bdrOptions &&
                    bdrOptions
                      .filter((o) => o.role === "Business Dev")
                      .map((o) => (
                        <TableRow key={o.employee_id}>
                          <TableCell>
                            <Checkbox
                              checked={
                                o.employee_id ===
                                uploadLicenseContext.employee_id
                              }
                              onChange={(a, newState) => {
                                setUploadLicenseContext((p) => ({
                                  ...p,
                                  employee_id: newState
                                    ? o.employee_id
                                    : undefined,
                                }));
                              }}
                            />
                          </TableCell>
                          <TableCell>{o.name}</TableCell>
                          <TableCell>{o.role}</TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>*/}
            <div style={{ padding: "5px" }} />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Select</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bdrOptionsLoading && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {bdrOptions &&
                    bdrOptions
                      .filter(
                        (o) =>
                          o.role === "Business Development Account Manager"
                      )
                      .map((o) => (
                        <TableRow key={o.employee_id}>
                          <TableCell>
                            <Checkbox
                              checked={
                                o.employee_id ===
                                uploadLicenseContext.account_manager_employee_id
                              }
                              onClick={() => {
                                setUploadLicenseContext((p) => ({
                                  ...p,
                                  account_manager_employee_id: o.employee_id,
                                }));
                              }}
                            />
                          </TableCell>
                          <TableCell>{o.name}</TableCell>
                          <TableCell>{o.role}</TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Licensing Company Selector */}
            <Grid container spacing={3} sx={{ paddingTop: "10px" }}>
              <Grid item xs={licensingCompany === 'Other' ? 6 : 12}>
                <TextField
                  label="Licensing Company"
                  style={{ marginTop: "20px" }}
                  value={licensingCompany}
                  onChange={(event) => {
                    setLicensingCompany(event.target.value)
                    setLicensingCompanyInput("")
                  }}
                  select
                  fullWidth
                >
                  {licensingCompanies.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {licensingCompany == "Other" &&
                <Grid item xs={6}>
                  <TextField
                    helperText="Input Licensing Company"
                    style={{
                      marginTop: "20px"

                    }}
                    label={<div >Enter Licensing Company</div>}
                    defaultValue={''}
                    onChange={(e) => setLicensingCompanyInput(e.target.value)}
                    fullWidth
                  />
                </Grid>
              }
            </Grid>

            {/* Division */}
            <TextField
              helperText="School's Division"
              style={{
                minWidth: "100%",
                paddingTop: "20px",
                marginTop: "10px"
              }}
              label={<div style={{ paddingTop: "20px" }}>Division</div>}
              defaultValue={schoolData["division"]}
              onChange={(e) => setDivision(e.target.value)}
              fullWidth
            />

            <TextField
              helperText="School's SubDivision"
              style={{
                minWidth: "100%",
                paddingTop: "20px",
                marginTop: "10px"
              }}
              label={<div style={{ paddingTop: "20px" }}>Sub Division</div>}
              defaultValue={schoolData["subDivision"]}
              onChange={(e) => setSubDivision(e.target.value)}
              fullWidth
            />

            <TextField
              helperText="School's Conference"
              style={{
                minWidth: "100%",
                paddingTop: "20px",
                marginTop: "10px"
              }}
              label={<div style={{ paddingTop: "20px" }}>Conference</div>}
              defaultValue={schoolData["conference"]}
              onChange={(e) => setConference(e.target.value)}
              fullWidth
            />



            {/* School URL */}
            <TextField
              helperText="URL to the School's Athletic Page"
              style={{
                minWidth: "100%",
                paddingTop: "20px",
                marginTop: "10px"
              }}
              label={<div style={{ paddingTop: "20px" }}>Athletic URL</div>}
              defaultValue={''}
              onChange={(e) => setUrl(e.target.value)}
              fullWidth
            />

            {/* School royalty rate */}
            <TextField
              helperText="School's Royalty Rate"
              style={{
                minWidth: "100%",
                paddingTop: "20px",
                marginTop: "10px"
              }}
              label={<div style={{ paddingTop: "20px" }}>Royalty Rate</div>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <PercentIcon />
                  </InputAdornment>
                ),
              }}
              defaultValue={''}
              value={royaltyRate}
              onChange={(e) => handleRoyatlyRateChange(e)}
            />

            {/* School Colors */}
            <Box sx={{
              border: "1px solid #4E4E4E", padding: "10px", margin: "10px",
            }}>
              <center><Typography>School Colors</Typography></center>
              <Grid container spacing={2} sx={{ paddingTop: "10px" }}>

                <Grid item xs={4}>
                  <TextField
                    label="Primary Color"
                    style={{ marginTop: "10px" }}
                    onChange={(e) => setSchoolColors({ ...schoolColors, ["primary"]: e.target.value })}
                  />
                  {<Box sx={{ bgcolor: getColor(schoolColors["primary"]), width: '50px', height: '50px', display: 'inline-block', margin: "10px", border: isValidHexCode(getColor(schoolColors["primary"])) ? `2px solid white` : `0px` }}></Box>}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Secondary Color"
                    style={{ marginTop: "10px" }}
                    onChange={(e) => setSchoolColors({ ...schoolColors, ["secondary"]: e.target.value })}
                  />
                  {<Box sx={{ bgcolor: getColor(schoolColors["secondary"]), width: '50px', height: '50px', display: 'inline-block', margin: "10px", border: isValidHexCode(getColor(schoolColors["secondary"])) ? `2px solid white` : `0px` }}></Box>}</Grid>

                <Grid item xs={4}>
                  <TextField
                    label="Ternary Color"
                    style={{ marginTop: "10px" }}
                    onChange={(e) => setSchoolColors({ ...schoolColors, ["ternary"]: e.target.value })}
                  />
                  {<Box sx={{ bgcolor: getColor(schoolColors["ternary"]), width: '50px', height: '50px', display: 'inline-block', margin: "10px", border: isValidHexCode(getColor(schoolColors["ternary"])) ? `2px solid white` : `0px` }}></Box>}
                </Grid>
              </Grid>
            </Box>

            {/* School Icon Uploader */}
            {uploadLicenseContext["schoolIcon"] && (
              <img
                alt={"photo of School Icon"}
                style={{ maxHeight: "100px" }}
                src={URL.createObjectURL(uploadLicenseContext["schoolIcon"])}
                onClick={() => {
                  window.open(
                    URL.createObjectURL(uploadLicenseContext["schoolIcon"]),
                    "_blank"
                  );
                }}
              />
            )}
            <FileDropZone
              single
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                width: "100%",
                maxHeight: "50px",
              }}
              text={"Drag and Drop School Icon"}
              uploadFiles={(files, callback) => {
                setUploadLicenseContext({ ...uploadLicenseContext, schoolIcon: files[0] })
                callback();
              }
              }
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                paddingTop: "10px",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                color="success"
                disabled={
                  uploadLicenseContext.account_manager_employee_id === undefined || url === '' || royaltyRate == '' || !schoolColors || licensingCompany == ''
                }
                onClick={upload}
              >
                Upload!
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setUploadLicenseContext();
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default LicenseInteractor;
