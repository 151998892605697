import * as React from "react";
import BadgeIcon from "@mui/icons-material/Badge";
import Can from "../../common/Can";
import Employees from "./Employees";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.EMPLOYEES]} els={<Navigate to="/ballpark" />}>
      <Employees />
    </Can>
  );
}
export const icon = <BadgeIcon />;
export const text = "Employees";
export const componentName = "Employees";
export const path = "employees";
