import * as React from "react";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import EquipmentManager from "./EquipmentManager";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.EQMANAGER]} els={<Navigate to="/ballpark" />}>
      <EquipmentManager />
    </Can>
  );
}
export const icon = <HomeRepairServiceIcon />;
export const text = "Equipment Manager";
export const componentName = "Equipment Manager";
export const path = "equipmentmanager";