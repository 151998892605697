import * as React from "react";
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import TransferPortal from "./TransferPortal";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.TRANSFERPORTAL]} els={<Navigate to="/ballpark" />}>
      <div style={{ width: "80%", paddingTop: "5vh" }}>
        <TransferPortal />
      </div>
    </Can>
  );
}
export const icon = <TransferWithinAStationIcon />;
export const text = "Transfer Portal";
export const componentName = "TransferPortal";
export const path = "transfer";
