import MediaDaySuccess from "./MediaDaySuccess";
import MediaDayError from "./MediaDayError";
import { useEffect, useState } from "react";
import {
    Box,
    Tab, Tabs, Button, IconButton, Typography
} from "@mui/material";

const MediaDay = () => {

    const [activeTab, setActiveTab] = useState("success");


    return (
        <Box sx={{ width: "100%", backgroundColor: "background.paper" }}>
            <div sx={{
                width: "100%"
            }}>
                <Tabs value={activeTab} onChange={(e, nv) => { console.log(nv); setActiveTab(nv) }} variant="fullWidth">
                    <Tab sx={{ width: "33%" }} value="success" label="Success" />
                    <Tab sx={{
                        width: "33%", '&.Mui-selected': {
                            color: '#f44336',
                        },
                    }} value="error" label="Error" />
                </Tabs>

                {activeTab == "success" ?
                    <MediaDaySuccess /> :
                    activeTab == "error" ?
                        <MediaDayError /> : <></>}
            </div>
        </Box >

    );
}

export default MediaDay;