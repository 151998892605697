import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ImageModifier } from "../../../common/ImageModifier";
import { TextModifier } from "../../../common/TextModifier";
import { SelectModifier } from "../../../common/SelectModifier";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { VENDORS, formatDateTime } from "../../../../utilities/utils";
import { updateMetadata, updateVendor } from "../../../../API/hattrick";
import { uploadProductAssets } from "../../../../API/filesystem";
import AreYouSure from "../../../common/AreYouSure";
import {
  useUserContext,
  usePermissionContext
} from "../../../../contexts/ProviderProvider";
import useNotification from "../../../../hooks/useNotification";

export const DetailedRowView = ({ row, refresh, merchRoster }) => {
  const flatRow = { ...row.ProductMetadatum, ...row };

  const [changes, setChanges] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log(row, merchRoster)

  const user = useUserContext();
  const notify = useNotification();
  const { actions } = usePermissionContext();

  const setChangesFilter = (value, field) => {
    console.log(value, field);
    if (value) {
      setChanges((prev) => ({ ...prev, [field]: value }));
    } else {
      setChanges((prev) => {
        const newState = { ...prev };
        delete newState[field];
        return newState;
      });
    }
  };

  const nullifyState = () => {
    setChanges({});
    setIsModalOpen(false);
  };

  const submitAreYouSure = async () => {
    // start all the api calls, gather the promises, resolve them all at once, handle the errors
    setLoading(true);
    const promises = [];

    if (changes.vendor) {
      promises.push(
        updateVendor(flatRow.product_id, flatRow.vendor, changes.vendor, user)
      );
    }

    // the images will rely on these changes in the db, need to finish this before them
    // const response = 
    await updateMetadata(
      {
        productId: flatRow.product_id,
        sku_name: changes.sku_name,
        type: changes.type,
        color: changes.color,
      },
      user
    );

    //console.log(response.status);

    const imageMetadata = {
      ...flatRow,
      ...merchRoster,
      sku_name: changes.sku_name,
      productOrCollection: undefined,
      artOrMockup: undefined,
      sku_name: flatRow.sku_name,
      product_id: flatRow.product_id,
    };

    if (changes.photo) {
      imageMetadata["productOrCollection"] = "product";
      imageMetadata["artOrMockup"] = "mockup";
      promises.push(
        uploadProductAssets(
          changes.photo,
          { ...imageMetadata, isShared: changes.photoShared },
          user
        )
      );
    }
    if (changes.frontAsset) {
      imageMetadata["productOrCollection"] = "product";
      imageMetadata["artOrMockup"] = "art-front";
      promises.push(
        uploadProductAssets(
          changes.frontAsset,
          { ...imageMetadata, isShared: changes.frontShared },
          user
        )
      );
    }
    if (changes.backAsset) {
      imageMetadata["productOrCollection"] = "product";
      imageMetadata["artOrMockup"] = "art-back";
      promises.push(
        uploadProductAssets(
          changes.backAsset,
          { ...imageMetadata, isShared: false },
          user
        )
      );
    }

    console.log(promises);
    Promise.all(promises)
      .then((promises) => {
        console.log(promises);
        setLoading(false);
        refresh();
        notify({ variant: "success", msg: "Update Successful" });
      })
      .catch((err) => {
        setLoading(false);
        refresh();
        notify("Failed to update product");
      });
  };

  const getAreYouSureMessage = () => {
    return (
      "Are you sure you want to change the value from " +
      JSON.stringify(flatRow) +
      " to " +
      JSON.stringify(changes)
    );
  };

  //console.log("detailed row:", flatRow);
  //console.log("changes", changes);

  return (
    <Card>
      <CardContent>
        {loading && <LinearProgress />}
        <Typography style={{ marginTop: "10px", marginBottom: "10px" }}>
          Created at: {formatDateTime(flatRow.modified_at)}
        </Typography>
        <SelectModifier
          label="Vendor"
          style={{ marginTop: "10px" }}
          original={flatRow.vendor}
          value={changes?.vendor}
          setValue={(value) => {
            setChangesFilter(value, "vendor");
          }}
          options={VENDORS}
          permissions={[actions.VENDOR]}
          disabled={row.state === "Product Active" || "Product Archived"}
        />
        <TextModifier
          label="Type"
          original={flatRow.type}
          value={changes?.type}
          setValue={(value) => {
            setChangesFilter(value, "type");
          }}
          style={{ marginTop: "10px" }}
          permissions={[actions.TYPE]}
          disabled={row.state === "Product Active" || "Product Archived"}
        />
        <TextModifier
          label="Color"
          style={{ marginTop: "10px" }}
          original={flatRow.color}
          value={changes?.color}
          setValue={(value) => {
            setChangesFilter(value, "color");
          }}
          permissions={[actions.COLOR]}
          disabled={row.state === "Product Active" || "Product Archived"}
        />
        <TextModifier
          label="Sku Name"
          style={{ marginTop: "10px" }}
          original={flatRow.sku_name}
          value={changes?.sku_name}
          setValue={(value) => {
            setChangesFilter(value, "sku_name");
          }}
          permissions={[actions.SKU]}
          disabled={row.state === "Product Active" || "Product Archived"}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ width: "100%", paddingTop: "15px" }}
        >
          <Grid item lg={4} xs={12}>
            <ImageModifier
              label="Product Mockup"
              style={{ marginTop: "10px" }}
              original={flatRow.photo}
              value={changes?.photo}
              shared={changes.photoShared}
              // toggleShared={(value) => {
              //   setChangesFilter(value, "photoShared");
              // }}
              setValue={(value) => {
                setChangesFilter(value, "photo");
              }}
              permissions={[actions.SKU]}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <ImageModifier
              label="Front Asset"
              style={{ marginTop: "10px" }}
              original={flatRow.frontAsset}
              value={changes?.frontAsset}
              setValue={(value) => {
                setChangesFilter(value, "frontAsset");
              }}
              shared={changes.frontShared}
              toggleShared={(value) => {
                setChangesFilter(value, "frontShared");
              }}
              permissions={[actions.SKU]}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <ImageModifier
              label="Back Asset"
              style={{ marginTop: "10px" }}
              original={flatRow.backAsset}
              value={changes?.backAsset}
              shared={changes.backShared}
              // toggleShared={(value) => {
              //   setChangesFilter(value, "backShared");
              // }}
              setValue={(value) => {
                setChangesFilter(value, "backAsset");
              }}
              permissions={[actions.SKU]}
            />
          </Grid>
        </Grid>
        {Object.keys(changes).length > 0 && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              style={{ margin: "auto", width: "20%", marginTop: "20px" }}
              variant="contained"
              onClick={() => setIsModalOpen(true)}
            >
              Save
            </Button>
          </div>
        )}
      </CardContent>
      <AreYouSure
        style={{
          backgroundColor: "background.default",
          margin: "auto",
          marginTop: "10vh",
          padding: "20px",
          width: "30vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "5px",
        }}
        open={isModalOpen}
        message={getAreYouSureMessage()}
        handleClose={() => nullifyState()}
        yes={() => {
          submitAreYouSure();
          nullifyState();
        }}
        no={() => nullifyState()}
      />
    </Card>
  );
};
