import React from 'react';
import { Box, Button, Modal, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MUIDataTable from "mui-datatables";
import { useGlobalThemeContext } from '../../../contexts/ProviderProvider';

// MarathonTable component to display the modal with selected orders for a marathon process.
const MarathonTable = ({ open, handleClose, runMarathon, data, columns, options }) => {
    const { isNavOpen } = useGlobalThemeContext();

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isNavOpen ? '65%' : '75%',
                maxHeight: '80vh',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                m: 2
            }}>
                {/* Header section with title and close button */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                        Selected Orders for Marathon {isNavOpen}
                    </Typography>
                    <IconButton onClick={handleClose} sx={{ mb: 2 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                {/* Table container with hidden scrollbar */}
                <Box sx={{
                    flexGrow: 1,
                    overflowY: 'scroll',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    mb: 2
                }}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Box>
                {/* Run Marathon button */}
                <Button
                    onClick={runMarathon}
                    fullWidth
                    variant="contained"
                    sx={{ bgcolor: 'success.main', color: 'white', padding: 2, ':hover': { bgcolor: 'success.dark' } }}
                >
                    Run Marathon
                </Button>
            </Box>
        </Modal>
    );
}

export default MarathonTable;
