import axios from "axios";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react"
import TrueFalseCheckbox from "./TrueFalseCheckbox";

const ServerSideTable = ({ data, columns, tableConfig, onTableChange, paginatedRequestFunc, style, title, hideColumns, initialSort }) => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [ordering, setOrdering] = useState({ col: undefined, direction: undefined })
  const [search, setSearch] = useState()
  const [filter, setFilter] = useState({}) //[{"columnName", "value"}]
  const [total, setTotal] = useState(0)
  const [filterOptions, setFilterOptions] = useState({})

  useEffect(() => {
    console.log("paginated request:", page, pageSize, ordering, search, filter, paginatedRequestFunc);

    if (typeof paginatedRequestFunc !== "function") {
      console.log("paginated request func is not func:", typeof paginatedRequestFunc, paginatedRequestFunc);
      return;
    }

    // Step 1: Create a cancel token
    const source = axios.CancelToken.source();

    // Step 2: Make the request with the cancel token
    paginatedRequestFunc({ cancelToken: source.token }, page, pageSize, search, filter, ordering)
      .then(response => {
        setTotal(response.data.obj.TotalSize);
        setFilterOptions(response.data.obj.Options);
        return response;
      })
      .catch(e => {
        if (axios.isCancel(e)) {
          console.log("Request canceled", e.message);
        } else {
          console.log(e);  // Handle other errors
          return e;
        }
      });

    // Step 3: Cleanup function to cancel the request if the effect is re-run or component unmounts
    return () => {
      console.log("Canceling previous request...");
      source.cancel("Operation canceled due to new request or component unmount");
    };

  }, [page, pageSize, search, filter, ordering, paginatedRequestFunc]);


  const options = {
    sortOrder: initialSort,
    serverSide: true,
    page: page,
    enableNestedDataAccess: ".",
    count: total,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [5, 10, 25, 50],
    scrollX: true,
    selectableRows: false,
    expandableRows: true,
    onTableChange: (action, tableState) => {
      console.log("onTableChange", action, tableState)

      // if you supply default sort, always make sure the internal sort state is up to date
      const sortColumnName = tableState.sortOrder?.name?.split(".")[tableState.sortOrder.name?.split(".").length - 1]
      const sortDirection = tableState.sortOrder.direction.includes("desc") ? "desc" : tableState.sortOrder.direction.includes("asc") ? "asc" : undefined;
      console.log("sortColumnName", sortColumnName)
      console.log("sortDirection", sortDirection)
      console.log("sortOrdering", ordering)
      if (ordering?.col !== sortColumnName || ordering.direction !== sortDirection) {
        setOrdering({ col: sortColumnName, direction: sortDirection })
      }

      if (action == "changePage") {
        setPage(tableState.page)
      }
      if (action == "search") {
        setSearch(tableState.searchText)
      }
      if (action == "filterChange") {
        const filter = {}
        tableState.filterList.forEach((fl, i) => {
          filter[tableState.columns[i].name.split(".")[tableState.columns[i].name.split(".").length - 1]] = fl[0]
        })

        setFilter(filter)
      }
      onTableChange && onTableChange(action, tableState)
    },
    onChangeRowsPerPage: (newRows) => {
      setPageSize(newRows)
    },

    ...tableConfig
  }

  if (Object.keys(filterOptions).length > 0) {
    columns.forEach(col => {
      col.options = col.options ? col.options : {}
      col.options.filterOptions = col.options.filterOptions ? col.options.filterOptions : {}

      col.options.filterOptions = {
        names: filterOptions[col.name],
        colName: col.name,
        sourceData: filterOptions,
        ...col.options.filterOptions
      }
    })
  }

  for (let i = 0; i < columns.length; i++) {
    if (Array.isArray(hideColumns) && hideColumns.includes(columns[i].label)) {
      columns[i].options = { ...columns[i].options, display: false }
    }
  }

  console.log("before rendering pagination table", title, data, options, columns)

  return (
    <div style={{ width: "100%" }}>
      {data && (
        <MUIDataTable
          title={title}
          style={{ width: "100%", ...style }}
          data={data}
          options={options}
          columns={columns}
        />
      )}
    </div>
  )
}

export const BoolData = (value) => {
  return (
    <div>
      <TrueFalseCheckbox checked={value} disabled={true} />
    </div>
  )
}

export default ServerSideTable




