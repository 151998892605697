import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {
  useSchoolContext,
  useUserContext,
} from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import { updateEmployees } from "../../../API/employee";

const CustomList = ({
  title,
  items,
  filter,
  numberOfChecked,
  handleToggleAll,
  handleToggle,
  checked,
}) => {
  const [filtered, setFiltered] = React.useState([]);

  React.useEffect(() => {
    const f = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].toLowerCase().includes(filter.toLowerCase())) {
        f.push(items[i]);
      }
    }

    setFiltered(f);
  }, [filter, items]);

  return (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 600,
          height: 500,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items
          .filter(
            (i) =>
              filter === "" ||
              (i && filter && i.toLowerCase().includes(filter.toLowerCase()))
          )
          .map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;
            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value} />
              </ListItem>
            );
          })}
      </List>
    </Card>
  );
};

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function notIn(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

// set subtraction, a - b
function minus(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

const UniversityShuttle = ({ employee, schools, reload }) => {
  const globalSchoolList = useSchoolContext().schools;
  const user = useUserContext();
  const notify = useNotification();
  const [shuttleSearch, setShuttleSearch] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [checked, setChecked] = React.useState([]);
  const [assigned, setAssigned] = React.useState(
    schools.map((school) => school.university)
  );

  const notAssigned = React.useMemo(() => {
    return notIn(
      globalSchoolList.map((school) => school.schoolName),
      assigned
    );
  }, [assigned, useSchoolContext().schools]);

  const [disjointSuperset, setDisjointSuperset] = React.useState(notAssigned);

  const disJointSupersetChecked = intersection(checked, disjointSuperset);
  const assignedChecked = intersection(checked, assigned);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setAssigned(assigned.concat(disJointSupersetChecked).sort());
    const sortedDisjointSuperset = not(
      disjointSuperset,
      disJointSupersetChecked
    ).sort();
    setDisjointSuperset(sortedDisjointSuperset);
    setChecked(not(checked, disJointSupersetChecked));
  };

  const handleCheckedLeft = () => {
    setDisjointSuperset(disjointSuperset.concat(assignedChecked).sort());
    setAssigned(not(assigned, assignedChecked).sort());
    setChecked(not(checked, assignedChecked));
  };

  const handleAllDisjointSuperset = () => {
    setDisjointSuperset(disjointSuperset.concat(assigned).sort());
    setAssigned([]);
  };

  const handleAllAssigned = () => {
    setAssigned(assigned.concat(disjointSuperset).sort());
    setDisjointSuperset([]);
  };

  const saveEmployeeAssignments = () => {
    setLoading(true);
    const originalAssignedSchoolNames = schools.map((s) => s.university);

    // determine which schools are in assigned and not in schools
    const newSchoolAssociation = minus(assigned, originalAssignedSchoolNames);

    // determine which schools are in schools and not in assigned
    const destroyedAssociations = minus(originalAssignedSchoolNames, assigned);

    updateEmployees(
      {
        newSchool: newSchoolAssociation,
        destroyedSchool: destroyedAssociations,
        employeeId: employee,
      },
      user
    )
      .then(() => {
        setLoading(false);
        reload();
        notify({
          msg: "Successfully updated employee's school associations",
          variant: "success",
        });
      })
      .catch((err) => {
        //console.log(err);
        reload();
        setLoading(false);
        notify("Failed to update employee's school associations");
      });
  };

  return (
    <div style={{ paddingTop: "10px" }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={12}>
          <center>
            <TextField
              label="search"
              style={{ paddingBottom: "10px" }}
              value={shuttleSearch}
              onChange={(e) => {
                console.log("set search:", e.target.value);
                setShuttleSearch(e.target.value);
              }}
            />
          </center>
        </Grid>
        <Grid item lg={5}>
          <CustomList
            items={disjointSuperset}
            title={"Available Universities"}
            filter={shuttleSearch}
            numberOfChecked={numberOfChecked}
            handleToggleAll={handleToggleAll}
            handleToggle={handleToggle}
            checked={checked}
          />
        </Grid>
        <Grid item lg={1}>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllAssigned}
              disabled={disjointSuperset.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={disJointSupersetChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={assignedChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllDisjointSuperset}
              disabled={assigned.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={5}>
          <CustomList
            items={assigned}
            title={"Assigned Universities"}
            filter={shuttleSearch}
            numberOfChecked={numberOfChecked}
            handleToggleAll={handleToggleAll}
            handleToggle={handleToggle}
            checked={checked}
          />
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            style={{ marginTop: "10px" }}
            disabled={loading}
            variant="contained"
            onClick={saveEmployeeAssignments}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default UniversityShuttle;
