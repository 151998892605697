import axios from "axios";

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const getAthleteUserById = (id, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  return axios.get(BALLPARK_BACKEND_BASE_URL + "getUserById?id=" + id, {
    headers,
  })
};

export const searchStudentAthleteByName = (name, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.accessToken,
  };

  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "searchForStudentAthlete?search=" + name,
    {
      headers,
    }
  );
};
