import {useState} from "react";
import { Box } from "@mui/material";
import UnMatchedTable from "./UnMatchedTable";
import RosterEditTable from "./RosterEditTable";

const MatchingTables = (props) => {
  const [selectedAthlete, setSelectedAthlete] = useState(0);

  return (
    <div style={{ width: "100%", display: "flex", gap: "10px" }}>
      <Box sx={{ width: "80%", backgroundColor: "background.paper" }}>
        <RosterEditTable
          data={props.rosterList}
          selected={props.unmatched[selectedAthlete]}
          loading={props.loadingUnMatched}
          reload={props.reload}
          resetSelected={() => {
            setSelectedAthlete(0);
          }}
        />
      </Box>
      <Box>
        <UnMatchedTable
          data={props.unmatched}
          selectedIndex={selectedAthlete}
          select={(index) => setSelectedAthlete(index)}
          reload={props.reload}
          resetSelected={() => {
            setSelectedAthlete(0);
          }}
          loading={props.loadingUnMatched}
        />
      </Box>
    </div>
  );
};

export default MatchingTables;
