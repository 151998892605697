import * as React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import SocialTracker from "./SocialTracker";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";
import CreateSocialIntern from "./CreateSocialIntern";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <>
      <Can action={views.VIEW} items={[views.SOCIALTRACKER]} els={<Navigate to="/ballpark" />}>
        <SocialTracker />
      </Can>
      <Can action={views.VIEW} items={[views.CREATESOCIALINTERN]}>
        <CreateSocialIntern />
      </Can>
    </>
  );
}
export const icon = <InstagramIcon />;
export const text = "Social Tracker";
export const componentName = "SocialTracker";
export const path = "social";
