import * as React from "react";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import Products from "./Products";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.GRANDSLAM]} els={<Navigate to="/ballpark" />}>
      <Products />
    </Can>
  );
}
export const icon = (
  <PrecisionManufacturingIcon />
);
export const text = "Products";
export const componentName = "Product Maker";
export const path = "products";
