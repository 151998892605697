import { TextField, Button, Box } from "@mui/material";
import { useState } from "react";
import Can from "../../common/Can";
import { useUserContext, usePermissionContext } from "../../../contexts/ProviderProvider";
import { createSchool } from "../../../API/school";
import useNotification from "../../../hooks/useNotification.jsx";

const NewSchool = ({ reload }) => {
  const [loading, setLoading] = useState(false);
  const [newSchool, setNewSchool] = useState({
    schoolName: "",
    division: "NCAA",
    subDivision: "Division I",
    conference: "",
    state: "TX",
    licenseStataus: undefined,
    address: "",
    url:""
  });

  const user = useUserContext();
  const notify = useNotification();
  const { actions } = usePermissionContext();

  const handleCreate = () => {
    setLoading(true);
    createSchool(newSchool, user)
      .then((response) => {
        setLoading(false);
        reload();
      })
      .catch((e) => {
        setLoading(false);
        notify("Failed to create school");
        reload();
      });
  };

  const updateField = (field, value) => {
    setNewSchool((prev) => {
      const updated = { ...prev };
      updated[field] = value;
      return updated;
    });
  };

  return (
    <Can action={actions.ALTER} items={[actions.CREATENEWSCHOOL]}>
      <Box
        sx={{
          backgroundColor: "background.paper",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingTop: "10px",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Create New School
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignContent: "column",
            width: "100%",
            padding: "20px",
            paddingTop: "10px",
          }}
        >
          <TextField
            fullWidth
            style={{ width: "30vw" }}
            label={"School Name"}
            value={newSchool.schoolName}
            onChange={(e) => {
              updateField("schoolName", e.target.value);
            }}
          />
          <TextField
            fullWidth
            style={{ width: "30vw" }}
            label={"Division"}
            value={newSchool.division}
            onChange={(e) => {
              updateField("division", e.target.value);
            }}
          />
          <TextField
            fullWidth
            style={{ width: "30vw" }}
            label={"Sub Division"}
            value={newSchool.subDivision}
            onChange={(e) => {
              updateField("subDivision", e.target.value);
            }}
          />
          <TextField
            fullWidth
            style={{ width: "30vw" }}
            label={"Conference"}
            value={newSchool.conference}
            onChange={(e) => {
              updateField("conference", e.target.value);
            }}
          />
          <TextField
            fullWidth
            style={{ width: "30vw" }}
            label={"State"}
            value={newSchool.state}
            onChange={(e) => {
              updateField("state", e.target.value);
            }}
          />
          <TextField
            fullWidth
            style={{ width: "30vw" }}
            label={"Athletic URL"}
            value={newSchool.url}
            onChange={(e) => {
              updateField("url", e.target.value);
            }}
          />
          <div style={{ margin: "auto" }}>
            <Button style={{}} variant="contained" onClick={handleCreate}>
              Create
            </Button>
          </div>
        </div>
      </Box>
    </Can>
  );
};
export default NewSchool;
