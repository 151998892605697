import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    Grid,
    Tooltip,
    TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

// Confirmation/ Summary Modal
const VariantsCreationSummaryModal = ({
                                          isModalOpen,
                                          setIsModalOpen,
                                          variantProducts,
                                          handleConfirmUpdate,
                                          isSubmitting
                                      }) => {
    const [note, setNote] = useState("");
    const [productCount, setProductCount] = useState(0);
    const [sizesData, setSizesData] = useState([]);

    useEffect(() => {
        // Calculate the number of products impacted
        const filteredProducts = Object.entries(variantProducts).filter(
            ([productId, sizes]) => productId !== 'null' // Exclude null product IDs
        );

        const impactedProductsCount = filteredProducts.length;
        setProductCount(impactedProductsCount);

        // Extract size data from the first available product
        if (filteredProducts.length > 0) {
            const firstProductId = filteredProducts[0][0]; // Use the first valid product ID
            const firstProductSizes = filteredProducts[0][1]; // Get the sizes array for the first product

            if (firstProductSizes) {
                console.log('first product', firstProductSizes);
                setSizesData(firstProductSizes);
            }
        }
    }, [variantProducts]);

    const handleConfirm = () => {
        handleConfirmUpdate(note);
        setNote("");
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            sx={{
                zIndex: 11000
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    zIndex: 11000
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Variants Creation Summary
                </Typography>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <Card sx={{ bgcolor: "primary.main", color: 'white' }}>
                            <CardContent>
                                <Typography variant="body1">
                                    {productCount} products will be impacted.
                                    <Tooltip title="Number of products that will be impacted by this operation" sx={{ zIndex: 12000 }}>
                                        <InfoIcon />
                                    </Tooltip>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Sizes Data for Selected Type</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {sizesData && sizesData.length > 0 && (
                            <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                                <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
                                    {sizesData.map((size, index) => (
                                        <Grid item key={index} sx={{ minWidth: '250px' }}>
                                            <Card variant="outlined" sx={{ p: 2 }}>
                                                <Typography><strong>Size:</strong> {size.Size}</Typography>
                                                <Typography><strong>Price:</strong> {size.Price}</Typography>
                                                <Typography><strong>Cost:</strong> {size.Cost}</Typography>
                                                <Typography><strong>Weight:</strong> {size.Weight}</Typography>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Box sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Add a Note To This Bulk Operation"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        multiline
                        rows={4}
                        variant="outlined"
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', mt: 2 }}>
                    <Tooltip
                        title={productCount === 0 ? "No products selected for processing." : ""}
                        arrow
                        sx={{ zIndex: 22000 }}
                    >
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleConfirm}
                                disabled={isSubmitting || productCount === 0}
                            >
                                Confirm
                            </Button>
                        </span>
                    </Tooltip>
                    <Button variant="outlined" color="secondary" onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default VariantsCreationSummaryModal;