import { useState, useEffect } from "react";
import { useRoleContext } from "../../../contexts/ProviderProvider";
import ComplianceWelcome from "./ComplianceWelcome";
import InfluxerWelcome from "./InfluxerWelcome"
import { Timeline } from 'react-twitter-widgets';
import { Tabs, Tab } from "@mui/material";
import ZeroOutMetaData from "./ZeroOut/ZeroOutMetaData";


export const Welcome = () => {
  const { role } = useRoleContext();

  const [activeTab, setActiveTab] = useState(0)
  const [componentToRender, setComponentToRender] = useState();

  useEffect(() => {
    if (role) {
      switch (role) {
        // Only add in roles for external people
        case 'Compliance Admin':
          setComponentToRender(<ComplianceWelcome />);
          break
        default:
          setComponentToRender(<>
            <Tabs value={activeTab} onChange={(e, nv) => setActiveTab(nv)}>
              <Tab label="Metrics" />
              <Tab label="Keiths Thoughts" />
              <Tab label="Zero Out" />
            </Tabs>
            {activeTab === 0 && (
              <>
                <InfluxerWelcome />
              </>
            )}
            {activeTab === 1 && (
              <>
                <KeithTwitterTimeline />
              </>
            )}
            {activeTab === 2 && (
              <>
                <ZeroOutMetaData />
              </>
            )}
          </>
          );
      }
    }
  }, [role, activeTab]);

  return (
    <div style={{ width: '100%' }}>
      {componentToRender}
    </div>
  );
};

const KeithTwitterTimeline = () => {
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const updateContainerWidth = () => {
      const container = document.getElementById('timeline-container');
      if (container) {
        setContainerWidth(container.offsetWidth);
        console.log("found", container.offsetWidth)
      }
    };

    updateContainerWidth(); // Update height initially

    window.addEventListener('resize', updateContainerWidth); // Update height on resize

    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  console.log("container height", containerWidth)

  return (
    <div id="timeline-container" style={{ width: "100vw", height: "100vh" }}>
      <Timeline
        dataSource={{
          sourceType: "profile",
          screenName: "mrkeithmiller"
        }}
        options={{ width: "80vw", height: "700px", theme: 'dark' }}
      />
    </div>
  );
}

export { Welcome as Component };

export const icon = <div>👋</div>;
export const text = "Welcome";
export const componentName = "Welcome";
export const path = "";
