import { 
    Modal, 
    Box, 
    Typography, 
    Button, 
    ListItem,
    TextField,
    Autocomplete, } from "@mui/material";
import React, { useState } from "react";

const Transfer = ({
  open,
  message,
  handleClose,
  schoolOptions,
  object,
  style,
  close,
  confirmTransfer
}) => {

    const [school, setSchool] = useState(object['university']);

    const transferOptions = [
        { 
            text: 'Transfer', 
            key: 'Transfer',
            color: 'primary', 
            disabled: school === object['university'],
            onClick: () => confirmTransfer('Transfer', school)
        },
        { 
            text: 'Graduate',
            key: 'Graduate',
            color: 'primary',
            disabled: school !== object['university'],
            onClick: () => confirmTransfer('Graduate', school) 
        },
        { 
            text: 'Un-Sign', 
            key: 'Un-Sign',
            color: 'primary',
            disabled: school !== object['university'],
            onClick: () => confirmTransfer('Un-Sign', school) 
        },
        { 
            text: 'Lost',  
            key: 'Lost',
            color: 'primary',
            disabled: school !== object['university'], 
            onClick: () => confirmTransfer('Lost', school) 
        },
        { 
            text: 'Take Down',  
            key: 'Take Down',
            color: 'primary',
            disabled: school !== object['university'],
            onClick: () => confirmTransfer('Take Down', school) 
        },
        {
            text: 'Cancel',
            key: 'Cancel',
            color: 'error',
            onClick: close
        }
    ];

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Transfer Portal
        </Typography>
        <Typography sx={{ mt: 2 }} style={{ whiteSpace: "pre-line" }}>
          {message ? message: "Please select what kind of transfer to initiate.\n" 
          + "Only change the school if the athlete is transfering to a new school."}
        </Typography>

        <Autocomplete
            fullWidth
            sx={{ maxWidth: "100%", width: "90%", padding: "10px", xs: 12, lg: 12 }}
            options={schoolOptions}
            value={
              school
            }
            onChange={(e, v) => setSchool(v)}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option, state) => (
              <ListItem
                {...props}
                key={option}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid white",
                }}
              >
                <div>{option}</div>
              </ListItem>
            )}
          />

        <div style={{ display: "flex",
                flexDirection: "row",
                justifyContent: "center", }}>
            {transferOptions.map((o, index) => (
                <Button
                variant="contained"
                color={o.color}
                style={{
                    margin: "5px",
                }}
                onClick={() => {
                    o.onClick();
                    setSchool(object['university'])
                }}
                disabled={o.disabled}
                key={o.key || index}
                >
                {o.text}
                </Button>
            ))}
        </div>
      </Box>
    </Modal>
  );
};

export default Transfer;
