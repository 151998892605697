import React, { useEffect, useState, useContext } from 'react';
import {
    Container,
    Typography,
    CircularProgress,
    Button,
    Box,
    Paper,
    Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { WebSocketContext } from "../../../common/webSocket";
import { handleCancelPendingOp, fetchAllBulkOperations } from "../../../../API/audible";
import useNotification from "../../../../hooks/useNotification";
import MUIDataTable from 'mui-datatables';
import OperationDetailsModal from './OperationDetailsModal';
import BulkOperationModal from "./BulkOperationModal";

const QueueBox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    width: '100%',
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

const ButtonGroup = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginLeft: 'auto', // were pushing buttons to the right
}));

const Status = styled('div')(({ theme, status }) => ({
    backgroundColor: theme.custom[status.toLowerCase()],
    color: status === 'STARTED' ? '#fff' : 'inherit',
}));

const OperationsTable = ({ user, refreshTrigger, setRefreshTrigger }) => {
    const ws = useContext(WebSocketContext);
    const [operations, setOperations] = useState([]);
    const [allOperations, setAllOperations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [selectedOperation, setSelectedOperation] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [progress, setProgress] = useState({ currentProcessedCount: 0, totalProductCount: 1 });
    const [isBulkOperationModalOpen, setIsBulkOperationModalOpen] = useState(false);

    const notify = useNotification();

    useEffect(() => {
        if (ws) {
            ws.onmessage = (event) => {
                const message = JSON.parse(event.data);
                if (message.type === 'bulkOperationStatus') {
                    // console.log('WebSocket message received in OperationsQueue:', message.data);
                    // Handle bulkOperationStatus message
                    fetchOperations();
                } else if (message.type === 'bulkOperationPreparingProgress') {
                    // console.log('WebSocket progress message received:', message.data);
                    // Parse the message.data string manually
                    const progressData = parseProgressData(message.data);
                    setProgress({
                        currentProcessedCount: progressData.currentProcessedCount,
                        totalProductCount: progressData.totalProductCount
                    });
                }
            };
        }
    }, [ws]);

    const parseProgressData = (data) => {
        const progressData = {};
        data.split(',').forEach(part => {
            const [key, value] = part.split(':').map(item => item.trim());
            progressData[key] = parseInt(value, 10);
        });
        return progressData;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 30000); // Update half a minute

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        fetchOperations();
    }, [refreshTrigger]);

    const CancelOperation = (TaskID, event) => {
        event.stopPropagation(); // Prevent the modal from opening
        handleCancelPendingOp(TaskID, user)
            .then(() => {
                notify("Operation successfully cancelled");
                setRefreshTrigger((prev) => !prev);
            })
            .catch((error) => handleCancelError(error));
    };

    const handleCancelError = (error) => {
        if (error.response) {
            if (error.response.status === 409) {
                notify("Oops, the operation has been started!");
            } else {
                notify(`An error occurred while revoking operation: ${error.response.data.Err}`);
            }
        } else if (error.request) {
            notify('No response received from the server');
        } else {
            notify(error.message);
        }
        fetchOperations();
    };

    const fetchOperations = () => {
        setLoading(true);
        fetchAllBulkOperations(user)
            .then((response) => {
                const fetchedOperations = response.data.obj["All Bulk Operations"];
                // Filter out child operations
                const parentOperations = fetchedOperations.filter(op => !op.ParentTaskID);
                const sortedOperations = parentOperations.sort((a, b) => new Date(b.OperationPostedTime) - new Date(a.OperationPostedTime));
                setOperations(sortedOperations);
                setAllOperations(fetchedOperations)
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching operations:', error);
                setLoading(false);
            });
    };

    const handleRowClick = (rowData) => {
        const taskId = rowData[0];
        const operation = operations.find(op => op.TaskID === taskId);
        setSelectedOperation(operation);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedOperation(null);
    };

    const getStatusClass = (status) => (
        <Status status={status}>{status}</Status>
    );

    const calculateTimeDifference = (startTime) => {
        const start = new Date(startTime);
        const diffMs = currentTime - start;
        if (diffMs < 0) return `0 minute(s) ago`; // due to milliseconds differences during status changes

        const diffMins = Math.floor(diffMs / 60000);
        const diffHours = Math.floor(diffMins / 60);
        const diffDays = Math.floor(diffHours / 24);

        const mins = diffMins % 60;
        const hours = diffHours % 24;
        const days = diffDays;

        let timeString = `${mins} minute(s) ago`;
        if (hours > 0) {
            timeString = `${hours} hour(s), ${timeString}`;
        }
        if (days > 0) {
            timeString = `${days} day(s), ${timeString}`;
        }

        return timeString;
    };

    const getStatusAndTime = (operation) => {
        const { OperationStatus, OperationPostedTime, OperationPreparedTime, OperationStartTime, OperationEndTime } = operation;
        let timeString;

        if (OperationStatus === 'PENDING') {
            timeString = `${calculateTimeDifference(OperationPostedTime)}`;
        } else if (OperationStatus === 'PREPARING') {
            timeString = `${calculateTimeDifference(OperationPreparedTime)}`;
        } else if (OperationStatus === 'RUNNING' || OperationStatus === 'STARTED') {
            timeString = `${calculateTimeDifference(OperationStartTime)}`;
        } else if (OperationStatus === 'COMPLETED') {
            timeString = `At ${new Date(OperationEndTime).toLocaleString()}`;
        } else {
            timeString = 'Unknown';
        }

        return (
            <Tooltip title={timeString}>
                <Box display="flex" alignItems="center">
                    <AccessTimeIcon />
                    <Typography variant="body2" style={{ marginLeft: 4 }}>
                        {timeString}
                    </Typography>
                </Box>
            </Tooltip>
        );
    };

    const columns = [
        { name: 'TaskID', label: 'Task ID' },
        { name: 'OperationAction', label: 'Operation Action' },
        { name: 'UserName', label: 'UserName' },
        { name: 'OperationStatus',
            label: 'Status',
            options: {
                customBodyRender: (value) => getStatusClass(value)
            }
        },
        {
            name: 'StatusAndTime',
            label: 'Time',
            options: {
                customBodyRender: (value, tableMeta) => (
                    getStatusAndTime(operations[tableMeta.rowIndex])
                )
            }
        },
        {
            name: 'actions',
            label: 'Actions',
            options: {
                customBodyRender: (value, tableMeta) => (
                    (operations[tableMeta.rowIndex].OperationStatus === 'PENDING' || operations[tableMeta.rowIndex].OperationStatus === 'PREPARING') && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(event) => CancelOperation(operations[tableMeta.rowIndex].TaskID, event)}
                        >
                            Cancel
                        </Button>
                    )
                )
            }
        },
    ];

    const options = {
        selectableRows: 'none',
        pagination: true,
        rowsPerPageOptions: [25, 50, 100],
        rowsPerPage: 100,
        onRowClick: handleRowClick,
    };

    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container>
            <QueueBox>
                <Header>
                    <Typography variant="h6">
                        Operations Queue
                    </Typography>
                    <ButtonGroup>
                        <Button variant="contained" color="primary" onClick={fetchOperations}>
                            Refresh
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setIsBulkOperationModalOpen(true)}
                        >
                            Check Bulk Operation
                        </Button>
                    </ButtonGroup>

                    <BulkOperationModal
                        isOpen={isBulkOperationModalOpen}
                        onClose={() => setIsBulkOperationModalOpen(false)}
                        user={user}
                        notify={notify}
                    />
                </Header>
                <Paper style={{ width: '100%' }}>
                    <MUIDataTable
                        title={"Operations Queue"}
                        data={operations}
                        columns={columns}
                        options={options}
                    />
                </Paper>
            </QueueBox>
            <OperationDetailsModal
                open={isModalOpen}
                onClose={handleCloseModal}
                operation={selectedOperation}
                progress={progress}
                operations={allOperations} // Pass all operations to the modal
            />
        </Container>
    );
};

export default OperationsTable;