import * as React from "react";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import OutFielder from "./OutFielder";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => { 
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.OUTFIELDER]} els={<Navigate to="/ballpark" />}>
      <OutFielder />
    </Can>
  );
}
export const icon = <HomeRepairServiceIcon sx={{ color: "text.primary" }} />;
export const text = "OutFielder";
export const componentName = "OutFielder";
export const path = "outfielder";