import {useState, useEffect} from "react";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";
import AreYouSure from "../../common/AreYouSure";

import { verifyPath, startMarathon } from "../../../API/marathon";

import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";

const Marathon = (props) => {
  const [side, setSide] = useState("Both");
  const [dropboxPath, setDropboxPath] = useState('');
  const [submit, setSubmit] = useState(undefined);

  const user = useUserContext();
  const notify = useNotification();

  useEffect(() => {
    if (!user) {
      return;
    }
  }, [user]);

  const handleOptionChange = (event) => {
    setSide(event.target.value);
  };

  const handleTextChange = (event) => {
    setDropboxPath(event.target.value);
  };

  const handleSubmit = () => {
    setSubmit(undefined)
    verifyPath(dropboxPath, user)
      .then((response) => {
        if (response.success){

          notify({msg:`Verified path: ${dropboxPath}`, variant:'success'});
          startMarathon(dropboxPath, side, user)
          .then((response) => {
            notify({msg:`Started marathon for path: ${dropboxPath}`, variant:'success'});
            setDropboxPath('');
          })
          .catch((error) => {
            console.error(error);
            notify(`Failed to start marathon for path ${dropboxPath}`);
          });
          return
        }

        notify(`Failed to verify path ${dropboxPath}`);
        return
      })
      .catch((error) => {
        console.error(error);
        notify(`Failed to verify path ${dropboxPath}`);
      });
  };

  const buildMessage = () => {
    // Notify on empty input string
    if (dropboxPath === '') {
      notify('Empty dropbox link')
      setSubmit(undefined);
    }
    return(`Is this the right link: ${dropboxPath}`)
  }

  return (
    <div style={{ width: "80%", paddingTop: "5vh" }}>
      <div style={{ width: "50%", margin: "auto" }}>
        <Typography variant="h1">Marathon</Typography>
        <div>
          <TextField
            label="Enter Dropbox Link"
            variant="outlined"
            value={dropboxPath}
            onChange={handleTextChange}
            style={{ width: "92%", margin: "auto" }}
          />
        </div>
        <div>
          <RadioGroup style={{ flexDirection: 'row' }} value={side} onChange={handleOptionChange}>
            <FormControlLabel value="Both" defaultChecked control={<Radio />} label="Both" />
            <FormControlLabel value="Front" control={<Radio />} label="Front" />
            <FormControlLabel value="Back" control={<Radio />} label="Back" />
          </RadioGroup>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            // endIcon={<SendIcon />}
            onClick={() => setSubmit(true)}
          >
            Run Marathon
          </Button>
        </div>
      </div>
      {submit && (
        <AreYouSure
          style={{
            backgroundColor: "background.paper",
            margin: "auto",
            marginTop: "10vh",
            padding: "20px",
            width: "30vw",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "5px",
          }}
          open={submit !== undefined}
          message={buildMessage()}
          yes={() => handleSubmit()}
          no={() => setSubmit(undefined)}
        />
      )}
    </div>
  );
};

export default Marathon;