import { useState, useEffect } from 'react'
import SingleSchoolSelector from '../../common/SingleSchoolSelector';
import { LinearProgress, CircularProgress, Button } from '@mui/material';
import { EditableTable } from '../../common/EditableTable';
import TrueFalseCheckbox from '../../common/TrueFalseCheckbox';
import { getPlayerOutreach, putPlayerOutreach } from '../../../API/socialAccounts';
import { useUserContext } from '../../../contexts/ProviderProvider';
import useNotification from '../../../hooks/useNotification';
import { usePermissionContext } from '../../../contexts/ProviderProvider';
import { useNavigate } from 'react-router-dom';
import useCanUser from '../../../hooks/useCanUser';
import { convertSortedIndexNumber } from '../../../utilities/utils';


export const PlayerOutreach = () => {

    const user = useUserContext();
    const notify = useNotification();
    const { actions } = usePermissionContext();
    const disabled = !useCanUser(actions.ALTER, [actions.PLAYEROUTREACH]);
    const [selectedSchool, setSelectedSchool] = useState("");
    const [playerOutreach, setPlayerOutreach] = useState([]);
    const [loading, setLoading] = useState(false);
    const [changes, setChanges] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [refresh, setRefresh] = useState(0);
    const navigate = useNavigate();


    const updatePlayerOutreach = (alteration) => {
        setLoading(true);
        putPlayerOutreach(alteration, user)
            .then((response) => {
                setLoading(false);
                notify({
                    msg: "Successfully updated player outreach",
                    variant: "success",
                });
                setRefresh(refresh + 1);
            })
            .catch((error) => {
                console.log(error);
                notify("Failed to update player outreach");
                setLoading(false);
            });
    }

    const stageAlteration = (field, value, outreach_id, convertDate) => {

        if (field === "player_outreach_id") {
            return;
        }
        var alteration = { player_outreach_id: outreach_id }

        if (convertDate) {
            const date = new Date();

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            let currentDate = `${year}-${month}-${day}`;
            alteration[field] = currentDate;
            if (!value) {
                alteration[field] = null;
            }
        }
        else {
            alteration[field] = value;
        }
        updatePlayerOutreach(alteration);
    };


    const handleUpdate = () => {
        var alterations = { player_outreach_id: playerOutreach[selectedIndex].player_outreach_id }
        Object.keys(changes[selectedIndex]).map((key) => {
            alterations[key] = changes[selectedIndex][key];
        })
        putPlayerOutreach(alterations, user)
            .then((response) => {
                setLoading(false);
                notify({
                    msg: "Successfully updated player outreach",
                    variant: "success",
                });
                setRefresh(refresh + 1);
                setIsModalOpen(false);
            })
            .catch((error) => {
                console.log(error);
                notify("Failed to update player outreach");
                setLoading(false);
            });
    }

    useEffect(() => {
        setLoading(true);

        getPlayerOutreach(selectedSchool, user)
            .then((response) => {
                setLoading(false);
                setPlayerOutreach(response.data.playerOutreach);
                // notify({
                //     msg: "Successfully retrieved player outreach",
                //     variant: "success",
                // });
            })
            .catch((error) => {
                console.log(error);
                notify("Failed to retrieve player outreach");
                setLoading(false);
            });

    }, [refresh, user, notify, selectedSchool]);


    // Getting Player Outreach data
    const getPlayerOutreachData = () => {
        setLoading(true);

        getPlayerOutreach(selectedSchool, user)
            .then((response) => {
                setLoading(false);
                notify({
                    msg: "Successfully retrieved player outreach",
                    variant: "success",
                });
            })
            .catch((error) => {
                console.log(error);
                notify("Failed to retrieve player outreach");
                setLoading(false);
            });
    }

    // edit options for Editable table
    const editOptions = {
        "signed": { label: "Signed", "type": "Checkbox", disabled: true },
        "following": { label: "Following", "type": "Checkbox", disabled: true },
        "initial_message": { label: "Initial Message Sent", "type": "Checkbox", disabled: true },
        "follow_up_message": { label: "Follow Up Message", "type": "Checkbox", disabled: true },
        "received_response": { label: "Received Response", "type": "Checkbox", disabled: true },
        "notes": { label: "Notes", "type": "Text", style: { marginTop: "10px", width: "600px" }, permissions: [actions.PLAYEROUTREACH] },
    };

    // Columns for Editable table
    const columns = [
        { name: "jersey_number", label: "Jersey Number" },
        {
            name: "full_name",
            label: "Full Name",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <Button sx={{ textTransform: 'unset' }}
                            onClick={() =>
                                navigate(`/ballpark/athletes/${btoa(playerOutreach[convertSortedIndexNumber(tableMeta)].roster_id)}`)
                            }
                        >
                            {value}
                        </Button>
                    )
                },
            },
        },
        { name: "sport", label: "Sport" },
        {
            name: "signed", label: "Signed", options: {
                filter: true,
                filterList: ["Not Signed"],  // default filter value
                customFilterListOptions: {
                    render: (v) => (v === "Not Signed" ? "Not Signed" : "Signed"),
                },
                filterOptions: {
                    names: ["Not Signed", "Signed"],
                    logic: (value, filters) => {
                        if (filters.includes("Not Signed") && (value === false || value === null)) {
                            return false;
                        }
                        if (filters.includes("Signed") && value === true) {
                            return false;
                        }
                        return true;
                    },

                },
                customBodyRender: (value, tableMeta) => (
                    <TrueFalseCheckbox
                        checked={value}
                        disabled={true}
                    />
                ),
            },
        },
        {
            name: "following", label: "Following", options: {

                customBodyRender: (value, tableMeta) => (
                    <TrueFalseCheckbox
                        checked={value}
                        interact={() =>
                            stageAlteration(
                                "following",
                                !value,
                                playerOutreach[convertSortedIndexNumber(tableMeta)].player_outreach_id,
                                true
                            )
                        }
                        disabled={disabled}
                    />
                ),
            },
        },
        {
            name: "initial_message", label: "Initial Message Sent", options: {
                customBodyRender: (value, tableMeta) => (
                    <TrueFalseCheckbox
                        checked={value}
                        interact={() =>
                            stageAlteration(
                                "initial_message",
                                !value,
                                playerOutreach[convertSortedIndexNumber(tableMeta)].player_outreach_id,
                                true
                            )
                        }
                        disabled={disabled}
                    />
                ),
            },
        },
        {
            name: "follow_up_message", label: "Follow Up Message", options: {
                customBodyRender: (value, tableMeta) => (
                    <TrueFalseCheckbox
                        checked={value}
                        interact={() =>
                            stageAlteration(
                                "follow_up_message",
                                !value,
                                playerOutreach[convertSortedIndexNumber(tableMeta)].player_outreach_id,
                                true
                            )
                        }
                        disabled={disabled}
                    />
                ),
            },
        },
        {
            name: "received_response", label: "Received Response", options: {
                customBodyRender: (value, tableMeta) => (
                    <TrueFalseCheckbox
                        checked={value}
                        interact={() =>
                            stageAlteration(
                                "received_response",
                                !value,
                                playerOutreach[convertSortedIndexNumber(tableMeta)].player_outreach_id,
                                true
                            )
                        }
                        disabled={disabled}
                    />
                ),
            },
        },
        { name: "notes", label: "Notes" },
    ];



    const options = {
        rowsPerPage: window.rowsPerPage,
        selectableRows: "none",
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress />
                ) : (
                    "No Open Roster Records for that School :("
                ),
            },
        }
    };

    return (
        <div style={{ marginTop: "10px" }}>
            <div style={{ marginBottom: "10px" }}>
                <SingleSchoolSelector
                    selectedSchool={selectedSchool}
                    setSelectedSchool={setSelectedSchool}
                />
            </div>
            {loading && <LinearProgress />}
            <EditableTable
                tableOptions={options}
                tableColumns={columns}
                data={playerOutreach}
                title={selectedSchool ? "Player Outreach" + " - " + selectedSchool : "Player Outreach"}
                editOptions={editOptions}
                editTitle={"Edit Social Account"}
                tableUpdates={changes}
                setTableUpdates={setChanges}
                handleSave={handleUpdate}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                isEditable={!disabled}
            />

        </div>
    )
}