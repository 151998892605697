import ListAltIcon from '@mui/icons-material/ListAlt';
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import ComplianceRosterDashboard from "./ComplianceRosterDashboard"
import { usePermissionContext } from "../../../contexts/ProviderProvider";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.COMPLIANCEROS]} els={<Navigate to="/ballpark/" />}>
      <ComplianceRosterDashboard />
    </Can>
  );
}
export const icon = <ListAltIcon />;
export const text = "Roster Management Dashboard";
export const componentName = "Roster Management Dashboard";
export const path = "rosterManagementDashboard";