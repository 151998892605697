import * as React from "react";
import { Checkbox } from "@mui/material";

const TrueFalseCheckbox = (props) => {
  const isChecked = (val) => {
    if (
      !val ||
      val === 0 ||
      val === "" ||
      val === "Not Created" ||
      val === "Created"
    ) {
      return false;
    } else if (String(val).toLowerCase() === "false") {
      return false;
    }
    return true;
  };
  return (
    <Checkbox
      style={props.style}
      onChange={props.interact}
      disabled={props.disabled}
      checked={isChecked(props.checked)}
    />
  );
};

export default TrueFalseCheckbox;
