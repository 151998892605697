import React from 'react';
import { Button } from '@mui/material';
import { generateAuditCSV } from '../../../../API/audible';
import { useUserContext } from "../../../../contexts/ProviderProvider";
import useNotification from "../../../../hooks/useNotification";

const DownloadCSVButton = ({ taskID, completionTime }) => {
    const user = useUserContext();
    const notify = useNotification();

    const handleDownload = async () => {
        try {
            await generateAuditCSV(user, taskID);
            notify({ msg: "CSV file downloaded successfully", variant: "success" });
        } catch (error) {
            console.error('Error downloading CSV file:', error);
            notify({ msg: "Failed to download CSV file", variant: "error" });
        }
    };

    // Convert completionTime string to Date object
    const completionDate = new Date(completionTime);
    const currentDate = new Date();

    // Calculate if 7 days have passed since completionTime
    const isDisabled = (currentDate - completionDate) >= (7 * 24 * 60 * 60 * 1000);

    return (
        <Button variant="contained" color="primary" onClick={handleDownload} disabled={isDisabled}>
            DOWNLOAD Report
        </Button>
    );
};

export default DownloadCSVButton;