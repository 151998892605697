import { useState } from "react";
import { Checkbox, FormControlLabel, Slider } from "@mui/material";

const JerseyNumberSelector = ({ formik, force }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", paddingTop: "15px" }}>
      <FormControlLabel
        control={
          <Checkbox
            label={"Use Jersey Number?"}
            style={{ marginLeft: "3rem", marginRight: "0rem" }}
            checked={force || !formik.values.jersey_number.includes(-2)}
            disabled={force}
            onClick={() => {
                if (force || !formik.values.jersey_number.includes(-2)) {
                  formik.setFieldValue("jersey_number", [-2, -1]);
                } else {
                  formik.setFieldValue("jersey_number", [-1, 99]);
                }
            }}
          />
        }
        label="Use Jersey Number"
      />
      <Slider
        disabled={!(force || !formik.values.jersey_number.includes(-2))}
        ariaLabel={"Jersey Number Range"}
        value={formik.values.jersey_number}
        onChange={(e, b) => {
          formik.setFieldValue("jersey_number", b);
        }}
        onBlur={formik.handleBlur}
        valueLabelDisplay="auto"
        step={1}
        max={99}
        min={-1}
        marks={[
          { value: -1, label: "00" },
          { value: 0, label: 0 },
          { value: 10, label: 10 },
          { value: 20, label: 20 },
          { value: 30, label: 30 },
          { value: 40, label: 40 },
          { value: 50, label: 50 },
          { value: 60, label: 60 },
          { value: 70, label: 70 },
          { value: 80, label: 80 },
          { value: 90, label: 90 },
          { value: 99, label: 99 },
        ]}
      />
    </div>
  );
};

export default JerseyNumberSelector;
