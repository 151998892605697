import { 
    Modal, 
    Box, 
    Typography, 
    Button } from "@mui/material";

const Transfer = ({
  open,
  message,
  handleClose,
  style,
  close,
  confirmTransfer
}) => {

    const transferOptions = [
        { 
            text: 'Recruit', 
            key: 'Recruit',
            color: 'primary',
            onClick: () => confirmTransfer('Recruit')
        },
        { 
            text: 'Keith Check', 
            key: 'Keith Check',
            color: 'primary',
            onClick: () => confirmTransfer('Keith Check') 
        },
        { 
          text: 'Duplicate Signup', 
          key: 'Duplicate Signup',
          color: 'primary',
          onClick: () => confirmTransfer('Duplicate Signup') 
        },
        {
            text: 'Cancel',
            key: 'Cancel',
            color: 'error',
            onClick: close
        }
    ];

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Transfer Portal
        </Typography>
        <Typography sx={{ mt: 2 }} style={{ whiteSpace: "pre-line" }}>
          {message ? message: "Please select what kind of transfer to initiate."}
        </Typography>

        <div style={{ display: "flex",
                flexDirection: "row",
                justifyContent: "center", }}>
            {transferOptions.map((o, index) => (
                <Button
                variant="contained"
                color={o.color}
                style={{
                    margin: "5px",
                }}
                onClick={() => o.onClick()}
                key={o.key || index}
                >
                {o.text}
                </Button>
            ))}
        </div>
      </Box>
    </Modal>
  );
};

export default Transfer;
