import * as React from "react";
import SocialAccountsTable from "./SocialAccountsTable";
import CreateSocialAccount from "./CreateSocialAccount";
import { Divider, Tab, Tabs } from "@mui/material";
import Overview from "./Overview";
import Outreach from "./Outreach";
import LicenseTracking from "./LicenseTracking";
import CommissionTable from "./ComissionTable";
import useCanUser from "../../../hooks/useCanUser";
import { usePermissionContext } from "../../../contexts/ProviderProvider";

const BizDev = (props) => {
  const [refresh, setRefresh] = React.useState(0);
  const { actions } = usePermissionContext();
  const canSocialAccounts = useCanUser(actions.VIEW, [actions.SOCIALACCOUNTS])
  const canOutreach = useCanUser(actions.VIEW, [actions.PLAYEROUTREACH, actions.SCHOOLOUTREACH])
  const initialTab = canSocialAccounts ? "SocialAccounts" : canOutreach ? "Outreach" : "Overview";
  const [activeTab, setActiveTab] = React.useState(initialTab);


  return (
    <div
      style={{
        width: "95%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Tabs value={activeTab} onChange={(e, n) => setActiveTab(n)}>
        {useCanUser(actions.VIEW, [actions.PLAYEROUTREACH, actions.SCHOOLOUTREACH]) && <Tab label="Outreach" value="Outreach" />}
        {useCanUser(actions.VIEW, [actions.SOCIALACCOUNTS]) && <Tab label="Social Accounts" value="SocialAccounts" />}
        <Tab label="Overview" value="Overview" />
        {/* <Tab label="License Tracking" /> <Tab label="Commission Tracking" /> */}
      </Tabs>

      {activeTab === "Outreach" && (
        <>
          <Outreach />
        </>
      )}
      {activeTab === "SocialAccounts" && (
        <>
          <SocialAccountsTable refresh={refresh} setRefresh={setRefresh} />
        </>
      )}
      {activeTab === "Overview" && <Overview />}
      {/* {activeTab === 2 && <LicenseTracking />}
      {activeTab === 3 && <CommissionTable />} */}
    </div>
  );
};

export default BizDev;
