import * as React from "react";
import { Autocomplete, TextField, Button } from "@mui/material";
import { useSchoolContext } from "../../contexts/ProviderProvider";

const SchoolSelector = ({ setSelectedSchools, selectedSchools, singleSelect }) => {
  const [schoolInput, setSchoolInput] = React.useState("");
  const { schools } = useSchoolContext();

  const handleAddSchool = (schools) => {
    if (!selectedSchools.includes("All")) {
      setSelectedSchools(schools);
    }

    if (schools.includes("All")) {
      setSelectedSchools(["All"]);
    }

    if (schools.length < selectedSchools.length) {
      setSelectedSchools(schools);
    }
  };

  return (
    <Autocomplete
      multiple={singleSelect ? singleSelect : true}
      value={selectedSchools}
      onChange={(event, newValue) => {
        handleAddSchool(newValue);
      }}
      inputValue={schoolInput}
      onInputChange={(event, newInputValue) => {
        setSchoolInput(newInputValue);
      }}
      options={schools
        .map((school) => school.schoolName)
        .filter((s) => !selectedSchools.includes(s))}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          label="School List"
          variant="outlined"
          style={{ width: "100%" }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <Button
                  disabled={schools.includes("All")}
                  variant="contained"
                  onClick={() => {
                    setSelectedSchools(["All"]);
                    setSchoolInput("");
                  }}
                >
                  All
                </Button>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          type="text"
        />
      )}
    />
  );
};

export default SchoolSelector;
