import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useState } from "react";

const snakeToCapitalizedWord = (str) => {
  return str
    .replace(/_([a-z])/g, function (match, letter) {
      return ` ${letter.toUpperCase()}`;
    })
    .replace(/^([a-z])/g, function (match, letter) {
      return letter.toUpperCase();
    });
};

const MatchingSimilarProducts = ({ similarProducts }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      onChange={(a, state) => {
        setExpanded((p) => state);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {`${similarProducts.length} matching products`}{" "}
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(similarProducts[0]).map((col) => (
                  <TableCell>{snakeToCapitalizedWord(col)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {similarProducts.map((row) => {
                return (
                  <TableRow>
                    {Object.keys(row).map((col) => {
                      return <TableCell>{row[col]}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default MatchingSimilarProducts;
