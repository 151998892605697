import MUIDataTable from "mui-datatables";
import * as React from "react";
import { CircularProgress } from "@mui/material";
import { getRosterAndMerchForSchool } from "../../../API/roster.js";
import { useUserContext } from "../../../contexts/ProviderProvider.jsx";
import NewProduct from "../Schools/NewProduct.jsx";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox.jsx";
import useNotification from "../../../hooks/useNotification.jsx";

const Roster = (props) => {
  const [rosterLoading, setRosterLoading] = React.useState(false);
  const [roster, setRoster] = React.useState([]);
  const [newProduct, setNewProduct] = React.useState(0);
  const user = useUserContext();
  const notify = useNotification();

  React.useEffect(() => {
    if (!user) {
      return;
    }
    setRosterLoading(true);
    getRosterAndMerchForSchool(props.school, user)
      .then((response) => {
        setRoster(response.data.products);
      })
      .catch((error) => {
        notify("Failed to get roster with all merch for school");
        console.error(error);
        setRosterLoading(false);
      });
  }, [props.school, user, newProduct]);

  let products = new Set();
  if (roster.length > 0) {
    roster.forEach((athlete) => {
      athlete.Products.forEach((product) => {
        products.add(product.sku_name);
        athlete[product.sku_name] = product.state;
      });
    });
  }

  const columns = [
    { label: "Jersey #", name: "jersey_number" },
    { label: "First", name: "first_name" },
    { label: "Last", name: "last_name" },
    { label: "Full", name: "full_name" },
    { label: "Sport", name: "sport" },
    { label: "Uni", name: "university" },
    {
      label: "International",
      name: "is_international",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
      },
    },
    {
      label: "signed",
      name: "signed",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value} disabled />
        ),
      },
    },
  ];

  if (roster && products) {
    products.forEach((product) =>
      columns.push({
        name: product,
        label: product,
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const color =
              value === "Asset Ready"
                ? "#f57c00"
                : value === "Product Created"
                  ? "#385e3c"
                  : "#d32f2f";
            return <div style={{ color: color }}>{value}</div>;
          },
        },
      })
    );
  }

  [
    {
      label: "on site",
      name: "addedOnSite",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox disabled checked={value} />
        ),
      },
    },
    {
      label: "Collection Status",
      name: "Collection",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <TrueFalseCheckbox disabled checked={value?.state} d={tableMeta} />
          );
        },
      },
    },
  ].forEach((col) => columns.push(col));

  return (
    <div style={{ width: "90%" }}>
      <div style={{ fontSize: "64px", fontWeight: "medium" }}>
        {props.school}
      </div>
      <MUIDataTable
        options={{
          rowsPerPage: window.rowsPerPage,
          // responsive: "scrollMaxHeight", // Add this line for vertical scrolling if needed
          scrollX: true, // Enable horizontal scrolling
          selectableRows: "none",
          textLabels: {
            body: {
              noMatch: rosterLoading ? <CircularProgress /> : "No Records",
            },
          },
        }}
        columns={columns}
        data={roster}
      />

      {/* <NewProduct
        university={props.school}
        reload={() => {
          setNewProduct((prev) => prev + 1);
        }}
      /> */}
    </div>
  );
};

export default Roster;
