import axios from "axios"

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;
const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;

export const getOrderData = (startDate, endDate, user) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
    };
    const queryString = 'start_data=' + startDate + '&end_data=' + endDate
    return axios.get(BASE_HATTRICK_URL + "orders/get?" + queryString, { headers });
}


export const getAllOrders = (startDate, endDate, user) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
    };
    const queryString = 'start_data=' + startDate + '&end_data=' + endDate
    return axios.get(BASE_HATTRICK_URL + "orders/getAllOrders?" + queryString, { headers });
}

export const updateOutfielderOrderStatus = (order_name, user) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
    };
    return axios.post(BASE_HATTRICK_URL + "orders/submit", { order_name }, { headers });
}

export const TestfulfillmentStatus = (orderId, user) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
    };
    const queryString = 'orderId=' + orderId
    return axios.get(BASE_HATTRICK_URL + "orders/fulfilled?" + queryString, { headers });
}

export const getOrderStatus = (orderName, user) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
    };
    const queryString = 'Id=' + btoa(orderName)
    return axios.get(BASE_HATTRICK_URL + "orders/status?" + queryString, { headers });
}


export const exportOrderCSV = (order_ids, startDate, endDate, user) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
    };
    const queryString = 'start_data=' + startDate + '&end_data=' + endDate
    return axios.post(BASE_HATTRICK_URL + "orders/export?" + queryString, { order_ids }, { headers });
}