import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const Notes = ({ id, initialValue, updateNotes, disabled }) => {
  const [notes, setNotes] = useState(initialValue || '');
  const [isModified, setIsModified] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const characterLimit = 500;

  useEffect(() => {
    setIsModified(notes !== initialValue);
    setOpenWarning(notes.length > characterLimit);
  }, [notes, initialValue, characterLimit]);

  const handleInputChange = (event) => {
    if (notes.length > characterLimit) {
        console.log('Over character limit')
    }
    setNotes(event.target.value);
    setIsModified(true);
    
  };

  const handleSaveNotes = async () => {
    if (isModified) {
        updateNotes(id, notes);
        setIsModified(false);
    }
  };

  return (
    <div>
      <TextField
        label="Notes"
        multiline
        disabled={disabled!==0}
        fullWidth
        value={notes}
        onChange={handleInputChange}
        variant="outlined"
        error={notes.length > characterLimit}
      />
      {isModified && notes.length <= characterLimit && (
        <div
            style={{
                margin: "10px 10px"
            }}>
            <Button variant="contained" color="primary" onClick={handleSaveNotes}>
            Save Notes
            </Button>
        </div>
      )}
      {notes.length > characterLimit && (
        <div style={{ color: 'red' }}>
          Character limit exceeded ({notes.length}/{characterLimit})
        </div>
      )}
    </div>
  );
};

export default Notes;
