import { Typography, LinearProgress } from "@mui/material";
import ProductsTable from "./ProductsTable";

const EditableProductsTable = ({
  loading,
  setLoading,
  products,
  merchRoster,
  refresh,
}) => {
  return (
    <>
      <Typography
        variant="h5"
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          paddingBottom: "10px",
        }}
      >
        Products
      </Typography>
      {loading && <LinearProgress />}
      {!loading && (
        <ProductsTable
          setLoading={setLoading}
          data={products}
          merchRoster={merchRoster}
          refresh={refresh}
        />
      )}
    </>
  );
};

export default EditableProductsTable;
