import React, { useState } from "react";
import {
  useSchoolContext,
  useUserContext,
  usePermissionContext
} from "../../../contexts/ProviderProvider";
import {
  Autocomplete,
  Grid,
  TextField,
  Button,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { configureEmployee } from "../../../API/employee";
import useNotification from "../../../hooks/useNotification";
import Can from "../../common/Can.jsx";
import SchoolSelector from "../../common/SchoolSelector.jsx";

const NewEmployee = ({ reload, tab }) => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("Social Intern");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [roleInput, setRoleInput] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useUserContext();
  const notify = useNotification();
  const { schools } = useSchoolContext();
  const { availableRoles, actions } = usePermissionContext();

  const submit = () => {
    const schoolList = selectedSchools.includes("All")
      ? schools.map((s) => s.schoolName)
      : selectedSchools;

    setLoading(true);

    if (tab === "External"){ 
      if (!pass){
        notify('Please enter a password');
        setLoading(false);
        return
      }
    }
    configureEmployee(
      { role, email, name, pass, associatedSchools: schoolList },
      user
    )
      .then((promises) => {
        //console.log(promises);
        notify({ msg: "Successfully configured user", variant: "success" });
        setName("");
        setEmail("");
        setRole("");
        setPass("");
        setSelectedSchools([]);
        setLoading(false);
        reload();
      })
      .catch((err) => {
        notify("Failed to configure user with error: " + err);
        setLoading(false);
      });
  };

  if (!schools || schools.length === 0) return <></>;

  return (
    <Can action={actions.ALTER} items={[actions.CONFIGUREEMPLOYEE]}>
      <Paper sx={{ padding: "20px" }}>
        <Grid container spacing={2} direction={"row"}>
          <Grid item lg={12} xs={6}>
            <Typography sx={{ paddingBottom: "5px" }} variant="h5">
            {tab === "External" ? "New External Employee": "New Internal Employee"}
            </Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              style={{ width: "100%" }}
              label={"Name"}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              style={{ width: "100%" }}
              label={"Email"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
          {tab === "External" && 
          <Grid item lg={6} xs={12}>
            <TextField
              style={{ width: "100%" }}
              label={"Password"}
              value={pass}
              onChange={(e) => {
                setPass(e.target.value);
              }}
            />
          </Grid>}
          <Grid item lg={6} xs={12}>
            <Autocomplete
              value={role}
              onChange={(event, newValue) => {
                setRole(newValue);
              }}
              inputValue={roleInput}
              onInputChange={(event, newInputValue) => {
                setRoleInput(newInputValue);
              }}
              options={Object.keys(availableRoles).map((key) => availableRoles[key])}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  type="text"
                />
              )}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <SchoolSelector
              selectedSchools={selectedSchools}
              setSelectedSchools={setSelectedSchools}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" onClick={submit}>
                  Configure New User
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Can>
  );
};

export default NewEmployee;
