import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    Divider,
    Paper,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Grid
} from '@mui/material';

import DownloadCSVButton from "../ProductAudit/DownloadCSVButton";

const OperationDetailsModal = ({ open, onClose, operation, progress, operations }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedChildOperation, setSelectedChildOperation] = useState(null);

    const isValidDate = (date) => {
        return !isNaN(Date.parse(date));
    };

    const renderProgressBar = () => {
        if (progress && operation && operation.OperationStatus === 'PREPARING') {
            const { currentProcessedCount, totalProductCount } = progress;
            const progressValue = (currentProcessedCount / totalProductCount) * 100;
            return (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress variant="determinate" value={progressValue} />
                    <Typography variant="body2" color="textSecondary">{`${currentProcessedCount} / ${totalProductCount}`}</Typography>
                </Box>
            );
        }
        return null;
    };

    const countWords = (str) => {
        return str.trim().split(/\s+/).length;
    };

    const renderInputFilters = () => {
        if (operation && operation.InputFilters) {
            const words = countWords(operation.InputFilters);
            if (words > 50) {
                return (
                    <Box sx={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
                        <Typography variant="body1" gutterBottom>
                            <strong>Input Filters:</strong>
                        </Typography>
                        <Box sx={{ maxHeight: isExpanded ? 'none' : 100, overflow: 'hidden', position: 'relative' }}>
                            <Typography variant="body1" gutterBottom>
                                {operation.InputFilters}
                            </Typography>
                            {!isExpanded && (
                                <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '20px', background: 'linear-gradient(to top, white, rgba(255, 255, 255, 0))' }} />
                            )}
                        </Box>
                        {!isExpanded && (
                            <Typography variant="body2" color="primary">
                                Click to expand
                            </Typography>
                        )}
                    </Box>
                );
            }
            return (
                <Typography variant="body1" gutterBottom>
                    <strong>Input Filters:</strong> {operation.InputFilters}
                </Typography>
            );
        }
        return null;
    };
    const renderChildOperations = () => {
        const childOperations = operations.filter(op => op.ParentTaskID === operation.TaskID);
        if (childOperations.length === 0) {
            return <Typography variant="body2">No child operations found.</Typography>;
        }

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Task ID</strong></TableCell>
                        <TableCell><strong>Action</strong></TableCell>
                        <TableCell><strong>Status</strong></TableCell>
                        <TableCell><strong>Start Time</strong></TableCell>
                        <TableCell><strong>End Time</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {childOperations.map((child) => (
                        <TableRow
                            key={child.TaskID}
                            onClick={() => setSelectedChildOperation(child)} // Set the selected child operation
                            sx={{ cursor: 'pointer', backgroundColor: selectedChildOperation?.TaskID === child.TaskID ? '#f0f0f0' : 'inherit' }} // Highlight selected row
                        >
                            <TableCell>{child.TaskID}</TableCell>
                            <TableCell>{child.OperationAction}</TableCell>
                            <TableCell>{child.OperationStatus}</TableCell>
                            <TableCell>
                                {isValidDate(child.OperationStartTime)
                                    ? new Date(child.OperationStartTime).toLocaleString()
                                    : 'N/A'}
                            </TableCell>
                            <TableCell>
                                {isValidDate(child.OperationEndTime)
                                    ? new Date(child.OperationEndTime).toLocaleString()
                                    : 'N/A'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    };

    const renderChildOperationDetails = () => {
        if (!selectedChildOperation) {
            return <Typography variant="body2">Select a child operation to view details.</Typography>;
        }

        return (
            <Box component={Paper} elevation={2} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Child Operation {selectedChildOperation.TaskID} is <strong>{selectedChildOperation.OperationStatus}</strong>
                </Typography>
                {selectedChildOperation.OperationStatus === 'ERROR' && (
                    <Typography variant="h6" gutterBottom sx={{ color: 'red', fontWeight: 'bold' }}>
                        {selectedChildOperation.OperationError}
                    </Typography>
                )}
                <Typography variant="body1" gutterBottom>
                    <strong>Task ID:</strong> {selectedChildOperation.TaskID}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Shopify Operation ID:</strong> {selectedChildOperation.ShopifyOperationID}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>User Notes:</strong> {selectedChildOperation.UserNotes}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Operation Posted Time:</strong> {isValidDate(selectedChildOperation.OperationPostedTime) ? new Date(selectedChildOperation.OperationPostedTime).toLocaleString() : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Operation Prepared Time:</strong> {isValidDate(selectedChildOperation.OperationPreparedTime) ? new Date(selectedChildOperation.OperationPreparedTime).toLocaleString() : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Operation Start Time:</strong> {isValidDate(selectedChildOperation.OperationStartTime) ? new Date(selectedChildOperation.OperationStartTime).toLocaleString() : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Operation End Time:</strong> {isValidDate(selectedChildOperation.OperationEndTime) ? new Date(selectedChildOperation.OperationEndTime).toLocaleString() : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Operation Action:</strong> {selectedChildOperation.OperationAction}
                </Typography>
            </Box>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                    Operation Details
                </Typography>
            </DialogTitle>
            {operation && (
                <DialogContent dividers sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                            <Box component={Paper} elevation={2} sx={{ p: 2 }}> {/* 70% of the window size and allow scrolling */}
                                <Typography variant="h6" gutterBottom>
                                    Operation {operation.TaskID} is <strong>{operation.OperationStatus}</strong>
                                </Typography>
                                {operation.OperationStatus === 'ERROR' && (
                                    <Typography variant="h6" gutterBottom sx={{ color: 'red', fontWeight: 'bold' }}>
                                        {operation.OperationError}
                                    </Typography>
                                )}
                                {renderProgressBar()}
                                <Typography variant="subtitle1" gutterBottom>
                                    Initiated by <strong>{operation.UserName}</strong> at {new Date(operation.OperationPostedTime).toLocaleString()}
                                </Typography>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="body1" gutterBottom>
                                    <strong>Task ID:</strong> {operation.TaskID}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Shopify Operation ID:</strong> {operation.ShopifyOperationID}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>User Notes:</strong> {operation.UserNotes}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Operation Posted Time:</strong> {isValidDate(operation.OperationPostedTime) ? new Date(operation.OperationPostedTime).toLocaleString() : 'N/A'}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Operation Prepared Time:</strong> {isValidDate(operation.OperationPreparedTime) ? new Date(operation.OperationPreparedTime).toLocaleString() : 'N/A'}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Operation Start Time:</strong> {isValidDate(operation.OperationStartTime) ? new Date(operation.OperationStartTime).toLocaleString() : 'N/A'}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Operation End Time:</strong> {isValidDate(operation.OperationEndTime) ? new Date(operation.OperationEndTime).toLocaleString() : 'N/A'}
                                </Typography>
                                {renderInputFilters()}
                                <Typography variant="body1" gutterBottom>
                                    <strong>Operation Status:</strong> {operation.OperationStatus}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <strong>Operation Action:</strong> {operation.OperationAction}
                                </Typography>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="h6" gutterBottom>
                                    Child Operations
                                </Typography>
                                {renderChildOperations()}
                            </Box>
                        </Grid>
                        {/* Sidebar for Selected Child Operation Details */}
                        <Grid item xs={12} md={5}>
                            <Box component={Paper} elevation={2} sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Child Operation Details
                                </Typography>
                                {renderChildOperationDetails()}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            )}
            <DialogActions>
                {operation && operation.OperationAction === "Product Audit Query" && operation.OperationStatus === "COMPLETED" && (
                    <DownloadCSVButton
                        taskID={operation.TaskID}
                        completionTime={operation.OperationEndTime}
                    />
                )}
                <Button onClick={onClose} color="primary" variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OperationDetailsModal;