import {useState, useEffect} from "react";
import { 
  Typography, 
  LinearProgress, 
  CircularProgress,
  TableRow,
  TableCell,
  Grid, 
  Card,
  Button, 
  CardContent,
  TextField,
  Autocomplete 
 } from "@mui/material";
import { getMerchRosterBySchool } from "../../../API/roster";
import { 
  useUserContext,
  useUserSchools,
  useSchoolContext
} from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import MUIDataTable from "mui-datatables";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import MultiSelect from "../../common/MultiSelect";
import ScoutTeam from "../../common/ScoutTeam";
import { decompressData } from "../../../utilities/utils";
import sample_data from './sample_data.json'

 const ExpandableRow = (props) => {
  console.log('Expand', props.data);
  const rows = props.data;
  
  const columns = [
    { label: "Email", name: "StudentAthlete", options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <span>
          {value && value.User && value.User.email!==null ? value.User.email : 'No Email'}
        </span>
      ),
    }},
    { label: "Phone Number", name: "StudentAthlete", options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <span>
          {value && value.phone ? value.phone : 'No Phone number'}
        </span>
      ),
    }},
    { label: "International", name: "is_international", options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <TrueFalseCheckbox checked={value!=="" && value!==null && value} disabled />
      ),
    }},
    { label: "Jersey Number", name: "jersey_number", options: {} }
  ]

  const options = {
    selectableRows: "none",
    selectableRowsHeader: false,
    expandableRowsHeader: false,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {return ( null )},
  };

  console.log('Table row', rows, columns, options)

  return (
    <div
        style={{
            margin: "10px 10px"
        }}>
        <MUIDataTable
            data={[rows]}
            columns={columns}
            options={options}
            components={{ TableToolbar(props) { return null; }, }}
        />
    </div>
  );
};

const BusinessTotalsDashboard = ({ totals }) => {
  return (
    <Grid container spacing={1}>
      {totals.map((total, index) => (
        <Grid item xs={12} sm={4} md={4} lg={2} key={index}>
          <Card>
            <CardContent>
              <Typography variant="h7" gutterBottom style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {total.title}
              </Typography>
              <Typography variant="h5">
                {total.value}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const ComplianceRosterDashboard = (props) => {
    const [loadingData, setLoadingData] = useState(true);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const [complianceData, setComplianceData] = useState([]);
    const [complianceTotals, setComplianceTotals] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [schoolInput, setSchoolInput] = useState("");
    const [refresh, setRefresh] = useState(0);

    const userSchools = useUserSchools();
    const { schools } = useSchoolContext();
    const user = useUserContext();
    const notify = useNotification();

    const TIMEOUT_DURATION = 10000;

    useEffect(() => {
      let timeoutId;

      if (!user || !userSchools) {
        timeoutId = setTimeout(() => {
          window.location.reload(); // Refresh the page
        }, TIMEOUT_DURATION);
        return () => clearTimeout(timeoutId);
      }

      setSelectedSchools([userSchools[0]]);

      clearTimeout(timeoutId);
    }, [user, userSchools]);

    useEffect(() => {
      if (!selectedSchools) {return}
      setLoadingData(true)
      if( selectedSchools[0] === 'Influxer School Of Gains') {   
        setTimeout(() => {
          const responseData = sample_data;
          
          const flattenedData = responseData.roster.map((obj) => {
            const handle = obj?.Collection?.handle ? 'https://influxermerch.com/athletes/'+obj?.Collection?.handle  : null;
            const signed = obj.signed ? true : false;
            return {
              ...obj,
              signed: signed,
              store_link: handle
            };
          });
          setComplianceData(flattenedData);
          setComplianceTotals(responseData.totals);
          setLoadingData(false);
        }, 2000);
      } else {
        getMerchRosterBySchool(selectedSchools, user)
          .then((response) => {
            const responseData = decompressData(response);

            const flattenedData = responseData.roster.map((obj) => {
              const handle = obj?.Collection?.handle ? 'https://influxermerch.com/athletes/'+obj?.Collection?.handle  : null;
              const signed = obj.signed ? true : false;
              return {
                ...obj,
                signed: signed,
                store_link: handle
              };
            });
            setComplianceData(flattenedData);
            setComplianceTotals(responseData.totals);
            setLoadingData(false);
          })
          .catch((error) => {
            console.log(error);
            notify('Failed to get compliance data');
            setLoadingData(false);
          });
      }
    }, [user, selectedSchools, refresh]);

    // Transaction Fees (0.85%)	University Royalty Fee	Service Fee (4%)	Transfer Fee (2%)	Gross profit 	Player's profit (60%)
    const columns = [
      { label: "Athlete", name: "full_name", options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            <div style={{
              whiteSpace: "nowrap",
            }}>{value}</div>
          ),
          sortThirdClickReset: true,
          sortCompare: (order) => {
            return (a, b) => {
              const val1 = a.data.split(" ").pop();
              const val2 = b.data.split(" ").pop();
          
              if (!isNaN(val1)) return -1;
              if (!isNaN(val2)) return 1; 
          
              if (val1 < val2) return order === "asc" ? -1 : 1;
              if (val1 > val2) return order === "asc" ? 1 : -1;
              return 0;
            };
          },
          filter:false
        }
      },
      {
        name: "review",
        label: "Needs Review",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                <TrueFalseCheckbox checked={value!=="" && value!==null && value} disabled />
            ),
            customFilterListOptions: {
              render: (v) => (v ? "Needs Review" : "Reviewed"),
            },
            filterOptions: {
              renderValue: (v) => (v ? "Needs Review" : "Reviewed"),
            },
            sortDescFirst: true,
            sortThirdClickReset: true,
        },
      },
      {
        name: "signed",
        label: "Signed",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => (
                <TrueFalseCheckbox checked={value!=="" && value!==null && value} disabled />
            ),
            customFilterListOptions: {
              render: (v) => (v ? "Signed" : "Unsigned"),
            },
            filterOptions: {
              renderValue: (v) => (v ? "Signed" : "Unsigned"),
            },
            sortDescFirst: true,
            sortThirdClickReset: true,
        },
      },
      { label: "Sport", name: "sport", options: {sortThirdClickReset: true,}},
      { label: "University", name: "university", options: {
        display:selectedSchools && (selectedSchools.length > 1 || selectedSchools[0] === "All"),
        filter:selectedSchools && (selectedSchools.length > 1 || selectedSchools[0] === "All"), 
        sortThirdClickReset:true}},
      { label: "Store Link", name: "store_link", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button sx={{textTransform: 'unset'}}
            disabled={value==null}
            onClick={() =>{
              window.open(value, '_blank');
            }}
          >
            External Link
          </Button>
        ),
        sort: false
      }}
    ];

    const options = {
      filter: true,
      sortOrder: {name:"review", direction:'desc'},
      selectableRows: "none",
      selectableRowsHeader: false,
      rowsPerPageOptions: [5, 10, 25, 100, 200],
      rowsPerPage: rowsPerPage,
      expandableRows: true,
      onChangeRowsPerPage: (num) => setRowsPerPage(num),
      renderExpandableRow: (rowData, rowMeta, a) => {
        const colSpan = 7;
        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <ExpandableRow
                data={complianceData[rowMeta.dataIndex]} />
            </TableCell>
          </TableRow>
        );
      },
      textLabels: {
          body: {
            noMatch: loadingData ? (
              <CircularProgress />
            ) : (
              "No Records"
            ),
          },
        },
    };
    
    return (
        <div style={{ width: "90%", paddingTop: "5vh" }}>
            {selectedSchools && <Typography variant="h1">{selectedSchools.length >1 ? selectedSchools.sort()[0] + ` and ${selectedSchools.length - 1} more` : selectedSchools}</Typography>}
            {loadingData && <LinearProgress />}
            {complianceTotals &&
            <div
                style={{
                    margin: "10px 10px",
                }}>
                <BusinessTotalsDashboard
                  totals={complianceTotals}
                />
              </div>}
            {userSchools.length > 1 &&
            <>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
                <MultiSelect
                    value={selectedSchools}
                    inputValue={schoolInput}
                    options={schools
                      .map((school) => school.schoolName)
                      .filter((s) => !schoolInput.includes(s))}
                    onChange={setSelectedSchools}
                    onInputChange={setSchoolInput}
                    label={"School List"}
                    allButton
                />
              </div>
              {user && <ScoutTeam
                scoutData={selectedSchools}
                refresh={() => {
                  setRefresh((v)=>v+1);
                }}
                user={user}
              />}
            </>
            }
            {complianceData && 
              <div
                  style={{
                      margin: "20px 10px"
                  }}>
                  <MUIDataTable
                      title={"Athletes"}
                      data={complianceData}
                      columns={columns}
                      options={options}
                  />
              </div>
            }
        </div>
    )
};

export default ComplianceRosterDashboard