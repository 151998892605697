import * as React from "react";
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import BizDev from "./BizDev";

export const Component = () => {
  const { views } = usePermissionContext();
  return (
    <Can action={views.VIEW} items={[views.BUSINESSDEVELOPMENT]} els={<Navigate to="/ballpark" />}>
      <BizDev />
    </Can>
  );
}
export const icon = <DomainAddIcon />;
export const text = "Biz Dev";
export const componentName = "BizDev";
export const path = "bizdev";
