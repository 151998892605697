import * as React from "react";
import Schools from "./Schools";
import CreateRoster from "./CreateRoster";
import { useParams } from "react-router-dom";
import { decodeURLVariable } from "../../../utilities/utils";

const SchoolWrapper = () => {
  const { university } = useParams();
  const school = decodeURLVariable(university);

  return (
    <>{school?.length > 0 ? <CreateRoster school={school} /> : <Schools />}</>
  );
};

export default SchoolWrapper;
