import React, { createContext, useEffect, useState } from 'react';
import { useUserContext } from "../../contexts/ProviderProvider";

export const WebSocketContext = createContext(null);

const RECONNECT_INTERVAL = 5000; // 5 seconds
const HEARTBEAT_INTERVAL = 30000; // 30 seconds

// https://alexboots.medium.com/using-react-context-with-socket-io-3b7205c86a6d
// https://legacy.reactjs.org/docs/context.html

export const WebSocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);
    let heartbeatInterval = null;
    let reconnectTimeout = null;
    const user = useUserContext();

    useEffect(() => {
        const connectWebSocket = () => {

            const baseUrl = process.env.REACT_APP_HATTRICK_URL.replace(/^http/, 'ws');
            const websocketUrl = `${baseUrl}bulkOpWS/ws`;

            const socket = new WebSocket(websocketUrl);

            socket.onopen = () => {
                console.log("WebSocket Connected");
                const encryptedToken = btoa(encodeURIComponent(user.accessToken));
                socket.send(JSON.stringify({ type: 'auth', token: encryptedToken }));

                heartbeatInterval = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send(JSON.stringify({ type: 'heartbeat' }));
                    }
                }, HEARTBEAT_INTERVAL);
            };

            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
                if (message.type === 'auth' && message.status === 'failed') {
                    console.log("Authentication failed, closing connection");
                    socket.close();
                }
            };

            socket.onclose = (event) => {
                console.log("WebSocket Closed", event);
                if (heartbeatInterval) {
                    clearInterval(heartbeatInterval);
                }
                if (reconnectTimeout) {
                    clearTimeout(reconnectTimeout);
                }
                reconnectTimeout = setTimeout(connectWebSocket, RECONNECT_INTERVAL);
            };

            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
                if (heartbeatInterval) {
                    clearInterval(heartbeatInterval);
                }
                if (reconnectTimeout) {
                    clearTimeout(reconnectTimeout);
                }
                reconnectTimeout = setTimeout(connectWebSocket, RECONNECT_INTERVAL);
            };

            setWs(socket);
        };

        connectWebSocket();

        return () => {
            if (heartbeatInterval) {
                clearInterval(heartbeatInterval);
            }
            if (reconnectTimeout) {
                clearTimeout(reconnectTimeout);
            }
            if (ws) {
                ws.close();
            }
        };
    }, [user.accessToken]);

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    );
};