import * as React from "react";
import MUIDataTable from "mui-datatables";
import { getEmployees, changeEmployeeRole } from "../../../API/employee";
import {
  Typography,
  Select,
  CircularProgress,
  LinearProgress,
  MenuItem,
  TableRow,
  TableCell,
} from "@mui/material";
import AreYouSure from "../../common/AreYouSure.jsx";

import { useUserContext, usePermissionContext } from "../../../contexts/ProviderProvider.jsx";
import Can from "../../common/Can";
import useNotification from "../../../hooks/useNotification.jsx";
import UniversityShuttle from "./UniversityShuttle.jsx";

const EmployeeTable = (props) => {
  const [employees, setEmployees] = React.useState([]);
  const [change, setChange] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  const user = useUserContext();
  const { availableRoles, actions } = usePermissionContext();
  const notify = useNotification();

  React.useEffect(() => {
    if (!user) {
      return;
    }
    getEmployees(user)
      .then((response) => {
        setEmployees(response.data.employees.filter(e => props.internal ? e.email.includes('@influxer.com') : !e.email.includes('@influxer.com')));
      })
      .catch((error) => {
        console.error(error);
        notify("Failed to get employees");
      });
  }, [user, notify, props.reloadFlag]);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    if (!change) {
      // its been unset, refresh the page
      getEmployees(user)
        .then((response) => {
          setEmployees(response.data.employees.filter(e => props.internal ? e.email.includes('@influxer.com') : !e.email.includes('@influxer.com')));
        })
        .catch((error) => {
          console.error(error);
          notify("Failed to get employees");
        });
    }
  }, [change, user, notify]);

  const updateEmployeeList = (tableMeta, newVal) => {
    setEmployees((prev) => {
      const newTD = JSON.parse(JSON.stringify(prev));
      newTD[convertSortedIndexNumber(tableMeta)].role = newVal;
      setLoading(false);
      return newTD;
    });
  };

  const convertSortedIndexNumber = (tableMeta) => {
    return tableMeta.currentTableData[tableMeta.rowIndex].index;
  };

  const updateRole = (e, tableMeta) => {
    setChange({
      action: () => {
        setLoading(true);
        changeEmployeeRole(user, tableMeta.rowData[2], e.target.value)
          .then((response) => {
            updateEmployeeList(tableMeta, e.target.value);
            setLoading(false);
          })
          .catch((error) => {
            notify("Failed to change employee role");
            console.error(error);
          });
      },
      message:
        "Are you sure you want to update " +
        tableMeta.rowData[0] +
        " to be a " +
        e.target.value +
        "?",
    });
  };

  const columns = [
    { label: "Name", name: "name", options: {} },
    {
      label: "Role",
      name: "role",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Can
                action={actions.ALTER}
                items={[actions.ROLES]}
                else={<Typography>{value}</Typography>}
              >
                <Select
                  id="role-select"
                  value={value}
                  key={value}
                  onChange={(e) => updateRole(e, tableMeta)}
                >
                  {Object.keys(availableRoles).map((role) => (
                    <MenuItem key={availableRoles[role]} value={availableRoles[role]}>
                      {availableRoles[role]}
                    </MenuItem>
                  ))}
                </Select>
              </Can>
            </>
          );
        },
      },
    },
    { label: "Email", name: "email", options: {} },
    { label: "Employee Id", name: "employee_id", options: { display: false } },
    {
      name: "EmployeeUniversities",
      options: {
        display: false,
      },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      {loading && <LinearProgress />}
      <MUIDataTable
        title={"Employee List"}
        style={{ width: "100%" }}
        data={employees}
        options={{
          rowsPerPage: window.rowsPerPage,
          selectableRows: "none",
          expandableRows: true,
          renderExpandableRow: (rowData, rowMeta) => {
            return (
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={9}
                >
                  <UniversityShuttle
                    employee={rowData[rowData.length - 2]}
                    schools={rowData[rowData.length - 1]}
                    reload={props.reload}
                  />
                </TableCell>
              </TableRow>
            );
          },
          textLabels: {
            body: {
              noMatch: <CircularProgress />,
            },
          },
        }}
        columns={columns}
      />
      <AreYouSure
        style={{
          backgroundColor: "background.default",
          margin: "auto",
          marginTop: "10vh",
          padding: "20px",
          width: "30vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "5px",
        }}
        open={change ? true : false}
        message={change?.message}
        handleClose={() => setChange(undefined)}
        yes={() => {
          change.action();
          setChange(undefined);
        }}
        no={() => setChange(undefined)}
      />
    </div>
  );
};

export default EmployeeTable;
