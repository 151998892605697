import * as React from "react";
import { useGlobalThemeContext, usePermissionContext } from '../../../../contexts/ProviderProvider';

import GrandSlam from "./GrandSlam";
import Matcher from "./Matcher";
import Can from "../../../common/Can";
import { Navigate } from "react-router-dom";
import LicensedUni from "./LicensedUni";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ConfigureEquipmentZeroOut from "./ConfigureEquipmentZeroOut";
import TransferPortalZeroOut from "./TransferPortalZeroOut";
import { Card, CardContent, Grid, LinearProgress, Link, Typography } from "@mui/material";

const ZeroOutMetaData = (props) => {

    const { views } = usePermissionContext();

    const { theme, setTheme } = useGlobalThemeContext();

    const [url, setUrl] = React.useState('');
    const [key, setKey] = React.useState(0);

    const [cardArray, setCardArray] = React.useState([]);
    const [expanded, setExpanded] = React.useState({});
    const [loadingStates, setLoadingStates] = React.useState({});

    const accordionRefs = React.useRef({});

    const setLoading = (panel, isLoading) => {
        setLoadingStates((prev) => ({ ...prev, [panel]: isLoading }));
    }

    const addToCardArray = (value) => {
        setCardArray((prevArray) => {
            const index = prevArray.findIndex(item => item.id === value.id)

            if (index !== -1) {
                const updatedArray = [...prevArray];
                updatedArray[index].count = value.count;
                return updatedArray;
            } else {
                return [...prevArray, value];
            }
        });
    }

    const CardDashboard = () => {
        return (
            <Grid container spacing={1}>
                {cardArray.map(({ id, count, panel }, index) => (
                    <Grid item xs={12} sm={4} md={4} lg={2} key={index}>
                        <Card>
                            <Link
                                onClick={() => handleCardClick(panel)}
                                underline="none"
                            >
                                <CardContent>
                                    <Typography variant="h7" gutterBottom style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                        {id}
                                    </Typography>
                                    <Typography variant="h5">
                                        {count}
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        );
    }

    const handleCardClick = (id) => {
        setExpanded(prevState => {

            const newState = {
                ...prevState,
                [id]: !prevState[id]
            }
            if (!prevState[id]) {
                setTimeout(() => {
                    accordionRefs.current[id]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    })
                }, 100);
            }

            return newState;
        });
    }

    React.useEffect(() => {
        if (theme === 'theme-dark') {
            setUrl("https://devapi.influxeradmin.com/public/dashboard/9b09095b-67c4-4616-bcc5-771ce082d703#theme=night");
        } else {
            setUrl("https://devapi.influxeradmin.com/public/dashboard/9b09095b-67c4-4616-bcc5-771ce082d703");
        }
        setKey(key + 1);
    }, [theme])

    return (
        <>
            {
                Object.values(loadingStates).filter((e) => e).length > 0 &&
                <LinearProgress />
            }
            <div style={{
                margin: "20px 10px"
            }}>
                {cardArray &&
                    <CardDashboard />
                }
            </div>

            <div style={{
                margin: "20px 10px"
            }}>
                <Accordion
                    expanded={!!expanded['GrandSlam']}
                    onChange={() => handleCardClick('GrandSlam')}
                    ref={el => accordionRefs.current['GrandSlam'] = el}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="GrandSlam-content"
                        id="GrandSlam-header"
                    >
                        <Typography>Grand Slam</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GrandSlam addToCardArray={addToCardArray} setLoading={(isLoading) => setLoading('GrandSlam', isLoading)} />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={!!expanded['EquipMangConfig']}
                    onChange={() => handleCardClick('EquipMangConfig')}
                    ref={el => accordionRefs.current['EquipMangConfig'] = el}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="EquipMangConfig-content"
                        id="EquipMangConfig-header"
                    >
                        <Typography>Equipment Manager Configuration</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ConfigureEquipmentZeroOut addToCardArray={addToCardArray} setLoading={(isLoading) => setLoading('EquipMangConfig', isLoading)} />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={!!expanded['Matcher']}
                    onChange={() => handleCardClick('Matcher')}
                    ref={el => accordionRefs.current['Matcher'] = el}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="Matcher-content"
                        id="Matcher-header"
                    >
                        <Typography>Matcher</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Matcher addToCardArray={addToCardArray} setLoading={(isLoading) => setLoading('Matcher', isLoading)} />
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={!!expanded['TransferPortal']}
                    onChange={() => handleCardClick('TransferPortal')}
                    ref={el => accordionRefs.current['TransferPortal'] = el}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="TransferPortal-content"
                        id="TransferPortal-header"
                    >
                        <Typography>Transfer Portal</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Can action={views.VIEW} items={[views.TRANSFERPORTAL]} els={<Navigate to="/ballpark" />}>
                            <TransferPortalZeroOut addToCardArray={addToCardArray} setLoading={(isLoading) => setLoading('TransferPortal', isLoading)} />
                        </Can>
                    </AccordionDetails>
                </Accordion>


                <Accordion
                    expanded={!!expanded['Instagram']}
                    onChange={() => handleCardClick('Instagram')}
                    ref={el => accordionRefs.current['Instagram'] = el}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="Instagram-content"
                        id="Instagram-header"
                    >
                        <Typography>Licensed University + IG Made/No IG Made</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <LicensedUni addToCardArray={addToCardArray} setLoading={(isLoading) => setLoading('Instagram', isLoading)} />
                    </AccordionDetails>
                </Accordion>
            </div>

            <div style={{
                margin: "20px 10px"
            }}>
                <iframe
                    style={{
                        position: "relative",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "2500px",
                    }}
                    key={key}
                    src={url}
                ></iframe>
            </div>
        </>
    )
};

export default ZeroOutMetaData