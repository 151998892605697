import { Modal, Box, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";


const LineItemsViewModal = ({ data, columns, options, isModalOpen, closeModal }) => {

    return (
        <Modal
            open={isModalOpen}
            onClose={() => closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                p: 4,
                backgroundColor: "background.paper",
                margin: "auto",
                marginTop: "10vh",
                width: "65%",
                maxHeight: "70vh",
                overflowY: "auto",
                borderRadius: 1,
                boxShadow: 24,
            }}>
                <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                    Order's Line Items
                </Typography>
                <MUIDataTable
                    options={options}
                    columns={columns}
                    data={data}
                />
            </Box>
        </Modal>
    )
};

export default LineItemsViewModal;


