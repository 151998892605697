import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Tooltip,
    TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

const ArchiveSummaryModal = ({ isModalOpen, setIsModalOpen, summaryDetails, handleConfirmUpdate, isSubmitting }) => {
    const [expandedProducts, setExpandedProducts] = useState([]);
    const { ProductsWithShopifyID, ProductsWithoutShopifyID, MetadataIdsWithoutAnyShopifyID } = summaryDetails;
    const [note, setNote] = useState("");

    const handleProductClick = (productId) => {
        setExpandedProducts((prevExpandedProducts) =>
            prevExpandedProducts.includes(productId)
                ? prevExpandedProducts.filter((id) => id !== productId)
                : [...prevExpandedProducts, productId]
        );
    };

    const handleConfirm = () => {
        handleConfirmUpdate(note);
        setNote("")
    };


    return (
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}
               sx = {{
                   zIndex: 11000
               }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    zIndex: 11000
                }}
            >
                <Typography variant="h6" gutterBottom>Archive Summary</Typography>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ bgcolor: "primary.main"}}>
                            <CardContent>
                                <Typography variant="body1">
                                    {ProductsWithShopifyID ? `${ProductsWithShopifyID.length} products will be archived.` : 'No products to archive.'}
                                    <Tooltip title="Products will be archived on Shopify and in our database" sx={{zIndex: 12000}}>
                                        <ArchiveIcon color="text.primary" sx={{ ml: 1, zIndex: 12000 }} />
                                    </Tooltip>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ bgcolor: "primary.main"}}>
                            <CardContent>
                                <Typography variant="body1">
                                    {ProductsWithoutShopifyID ? `${ProductsWithoutShopifyID.length} products might be deleted.` : 'No products to delete.'}
                                    <Tooltip title="Products might be deleted because no products have been made on Shopify" sx={{zIndex: 12000}}>
                                        <DeleteIcon color="text.primary" sx={{ ml: 1, zIndex: 12000 }} />
                                    </Tooltip>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ bgcolor: "primary.main"}}>
                            <CardContent>
                                <Typography variant="body1">
                                    {MetadataIdsWithoutAnyShopifyID ? `${MetadataIdsWithoutAnyShopifyID.length} metadata entries might be deleted.` : 'No metadata entries to delete.'}
                                    <Tooltip title="No related products under this metadata entry have been made on Shopify" sx={{zIndex: 12000}}>
                                        <InfoIcon color="text.primary" sx={{ ml: 1, zIndex: 12000 }} />
                                    </Tooltip>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{ProductsWithShopifyID ? `${ProductsWithShopifyID.length} Products to be Archived` : 'No products to archive'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {ProductsWithShopifyID && ProductsWithShopifyID.length > 0 && (
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                                {ProductsWithShopifyID.map((product) => (
                                    <Box key={product.ShopifyProductID} sx={{ mb: 2, p: 2, border: '1px solid grey', borderRadius: 2 }}>
                                        <Typography><strong>SKU:</strong> {product.Sku_name}</Typography>
                                        <Typography><strong>University:</strong> {product.university}</Typography>
                                        <Typography><strong>Sport:</strong> {product.sport}</Typography>
                                        <Button variant="text" onClick={() => handleProductClick(product.ShopifyProductID)}>
                                            {expandedProducts.includes(product.ShopifyProductID) ? 'Hide Details' : 'Show Details'}
                                        </Button>
                                        {expandedProducts.includes(product.ShopifyProductID) && (
                                            <Box sx={{ mt: 2 }}>
                                                <Typography><strong>Product ID:</strong> {product.ProductID}</Typography>
                                                <Typography><strong>Metadata ID:</strong> {product.MetadataID}</Typography>
                                                <Typography><strong>Full Name:</strong> {product.full_name}</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{ProductsWithoutShopifyID ? `${ProductsWithoutShopifyID.length} Products to be Deleted` : 'No products to delete'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {ProductsWithoutShopifyID && ProductsWithoutShopifyID.length > 0 && (
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                                {ProductsWithoutShopifyID.map((product) => (
                                    <Box key={product.ProductID} sx={{ mb: 2, p: 2, border: '1px solid grey', borderRadius: 2 }}>
                                        <Typography><strong>SKU:</strong> {product.Sku_name}</Typography>
                                        <Typography><strong>University:</strong> {product.university}</Typography>
                                        <Typography><strong>Sport:</strong> {product.sport}</Typography>
                                        <Button variant="text" onClick={() => handleProductClick(product.ProductID)}>
                                            {expandedProducts.includes(product.ProductID) ? 'Hide Details' : 'Show Details'}
                                        </Button>
                                        {expandedProducts.includes(product.ProductID) && (
                                            <Box sx={{ mt: 2 }}>
                                                <Typography><strong>Product ID:</strong> {product.ProductID}</Typography>
                                                <Typography><strong>Metadata ID:</strong> {product.MetadataID}</Typography>
                                                <Typography><strong>Full Name:</strong> {product.full_name}</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{MetadataIdsWithoutAnyShopifyID ? `${MetadataIdsWithoutAnyShopifyID.length} Metadata Entries to be Deleted` : 'No metadata entries to delete'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {MetadataIdsWithoutAnyShopifyID && MetadataIdsWithoutAnyShopifyID.length > 0 && (
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                                {MetadataIdsWithoutAnyShopifyID.map((metadataId) => (
                                    <Box key={metadataId} sx={{ mb: 2, p: 2, border: '1px solid grey', borderRadius: 2 }}>
                                        <Typography><strong>Metadata ID:</strong> {metadataId}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Add a Note To This Bulk Operation"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        multiline
                        rows={4}
                        variant="outlined"
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', mt: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleConfirm} disabled={isSubmitting}>Confirm</Button>
                    <Button variant="outlined" color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ArchiveSummaryModal;