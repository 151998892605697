import Navbar from "../components/common/Navbar";
import GoogleSignIn from "../components/common/GoogleSignIn";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Login = ({ user, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("login checking user for redirect:", user);
    if (user?.accessToken) { // user?.email.endsWith("influxer.com") &&  user?.email.verified
      navigate("/ballpark");
    }
  }, [user, navigate]);

  return (
    <div style={{ width: "100%" }}>
      <Navbar />
      <GoogleSignIn />
    </div>
  );
};

export default Login;
