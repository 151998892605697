import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Link } from '@mui/material';
import { fetchBulkOperationData } from "../../../../API/audible";

const BulkOperationModal = ({ isOpen, onClose, user, notify }) => {
    // Local state management for the modal
    const [operationId, setOperationId] = useState('');
    const [operationData, setOperationData] = useState(null);
    const [loading, setLoading] = useState(false);

    // Function to fetch bulk operation data
    const handleFetchOperationData = async () => {
        setLoading(true);
        try {
            const response = await fetchBulkOperationData(user, operationId);
            setOperationData(response.data.obj["status"]);
        } catch (error) {
            console.error("Error fetching bulk operation data:", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{
                width: '550px', // Increased width by 40%
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 2, // Gap between elements for better spacing
            }}>
                <Typography variant="h6" gutterBottom>
                    Enter Shopify Bulk Operation ID
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="gid://shopify/BulkOperation/"
                    value={operationId}
                    onChange={(e) => setOperationId(e.target.value)}
                    margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFetchOperationData}
                    disabled={loading}
                >
                    Fetch Operation Data
                </Button>
                {loading ? (
                    <Typography variant="body2">Loading...</Typography>
                ) : (
                    operationData && (
                        <Box sx={{ textAlign: 'left', maxHeight: '200px', overflowY: 'auto', mt: 2 }}>
                            <Typography variant="subtitle1">Operation Data:</Typography>
                            <pre>{JSON.stringify(operationData, null, 2)}</pre>
                            {operationData.url && (
                                <Typography variant="body2" sx={{ mt: 2 }}>
                                    <Link href={operationData.url} target="_blank" rel="noopener noreferrer">
                                        Download Feedback File
                                    </Link>
                                </Typography>
                            )}
                        </Box>
                    )
                )}
            </Box>
        </Modal>
    );
};

export default BulkOperationModal;