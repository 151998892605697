import { Card, CardContent, LinearProgress, Modal, Typography, Checkbox, Button } from "@mui/material";
import { TextModifier } from "./TextModifier";
import { SelectModifier } from "./SelectModifier";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getDateForDatePicker } from "../../utilities/utils";


export const EditableModal = ({ options, data, title, saveChanges, open, handleModalClose, loading, selectedIndex, editedData, setEditedData, isEditable }) => {

    const handleChange = (key, value) => {
        setEditedData(prevData => ({
            ...prevData,
            [selectedIndex]: {
                ...prevData[selectedIndex],
                [key]: value
            }
        }));
    };

    const transformString = (str) => {
        return str.replace(/_/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase())
            .replace(/\s+/g, '');
    };


    return (
        <Modal open={open} onClose={handleModalClose} sx={{ overflow: "scroll" }}>
            <Card sx={{
                backgroundColor: "background.default",
                margin: "auto",
                marginTop: "10vh",
                padding: "20px",
                width: "50vw",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "5px",
                border: `1px solid black`,

            }}>
                <CardContent>
                    {loading && <LinearProgress />}

                    <Typography variant="h5" component="div" sx={{ paddingBottom: "10px" }}>{title}</Typography>

                    {Object.keys(data).map(key => {
                        var rowValue = editedData[selectedIndex] && editedData[selectedIndex][key] !== undefined ? editedData[selectedIndex][key] : data[key]
                        const rowOption = options[key]
                        if (rowOption && rowOption.type == "Date") {
                            rowValue = getDateForDatePicker(rowValue)
                        }

                        if (!rowOption || !rowOption.type) {

                            return <Typography variant="body1" sx={{ paddingBottom: "10px" }}>
                                {transformString(key)}:- <span style={{ fontWeight: 'bold', color: "success.main" }}>{rowValue}</span>
                            </Typography>

                        }
                        else {
                            if (rowOption.type == "Text") {
                                return <>
                                    <TextModifier
                                        label={rowOption.label ? rowOption.label : transformString(key)}
                                        original={data[key]}
                                        value={rowValue}
                                        setValue={(value) => {
                                            handleChange(key, value);
                                        }}
                                        style={rowOption.style ? rowOption.style : { marginTop: "10px" }}
                                        permissions={rowOption.permissions}
                                        required={rowOption.required ? rowOption.required : false}
                                        borderColor={rowOption.borderColor}
                                    />
                                </>
                            } else if (rowOption.type == "Select") {
                                return <>

                                    <SelectModifier
                                        label={rowOption.label ? rowOption.label : transformString(key)}
                                        style={rowOption.style ? rowOption.style : { marginTop: "10px" }}
                                        original={data[key]}
                                        value={rowValue}
                                        setValue={(value) => {
                                            handleChange(key, value);
                                        }}
                                        options={rowOption.options ? rowOption.options : []}
                                        optionColors={rowOption.optionColors ? rowOption.optionColors : {}}
                                        permissions={rowOption.permissions}
                                    />

                                </>
                            } else if (rowOption.type == "Checkbox") {
                                return <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "15px",
                                            fontWeight: "bold",
                                            marginTop: "10px"
                                        }}
                                    >
                                        {rowOption.label ? rowOption.label : transformString(key)} :-
                                    </span>
                                    <Checkbox
                                        label={key}
                                        style={rowOption.style ? rowOption.style : { marginTop: "10px" }}
                                        defaultChecked={data[key]}
                                        value={rowValue}
                                        disabled={rowOption.disabled ? rowOption.disabled : false}
                                        onChange={(e) => {
                                            handleChange(key, e.target.checked);
                                        }}
                                    />
                                </div>
                            } else if (rowOption.type == "Date") {
                                return <>
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <span
                                            style={{
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                paddingRight: '5px',
                                                paddingBottom: '10px',

                                            }}
                                        >
                                            {rowOption.label ? rowOption.label : transformString(key)} :-
                                        </span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <span style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                paddingRight: '5px',
                                                paddingBottom: '10px'
                                            }}>
                                                <DateTimePicker
                                                    label={""}
                                                    views={rowOption.dateViews ?? ['month', 'year', 'day']}
                                                    openTo="day"
                                                    value={rowValue}
                                                    onChange={(newValue) => handleChange(key, newValue)}
                                                    sx={{ margin: '10px' }}
                                                />
                                            </span>
                                        </LocalizationProvider>
                                    </div>
                                </>

                            } else if (rowOption.type == "Img") {
                                <img
                                    src={rowValue} />
                            }
                            else if (rowOption.type == "Skip") {
                                return
                            }
                            else {
                                return <Typography variant="body1" sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                    {transformString(key)}:- <span style={{ fontWeight: 'bold', color: "success.main" }}>{rowValue}</span>
                                </Typography>
                            }

                        }
                    }
                    )}
                    <div
                        style={{ width: "100%", display: "flex", justifyContent: "center" }}
                    >
                        <Button
                            style={{ margin: "auto", width: "20%", marginTop: "20px" }}
                            variant="contained"
                            onClick={() => { saveChanges(editedData) }}
                            disabled={!isEditable}
                        >
                            Save
                        </Button>
                    </div>
                </CardContent>
            </Card >
        </Modal >
    );
}