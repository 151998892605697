import { useState } from "react";
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Button
} from "@mui/material";
import InstagramLink from "../../common/InstagramLink";
import { useNavigate } from "react-router-dom";
import AreYouSure from "../../common/AreYouSure";
import useNotification from "../../../hooks/useNotification";
import { createTransfer } from "../../../API/transferPortal";
import { useUserContext } from "../../../contexts/ProviderProvider";
import Transfer from "./TransferModal";
import { matchRosterRecords } from "../../../API/roster";
import ScoutTeam from "../../common/ScoutTeam";

const UnMatchedTable = (props) => {
  const navigate = useNavigate();
  const user = useUserContext();
  const notify = useNotification();
  const [action, setAction] = useState(undefined);
  const [transfer, setTransfer] = useState(undefined);

  const getGoogleLink = (university, sport) => {
    if (university === undefined && sport === undefined) {
      return ""
    }
    var search_link = "https://www.google.com/search?q=" + university
    if (sport.includes("(M)")) {
      search_link += "+" + "Mens" + "+" + sport.replace("(M)", "") + "+roster"
    }
    else if (sport.includes("(W)")) {
      search_link += "+" + "Womens" + "+" + sport.replace("(W)", "") + "+roster"
    }
    else if (sport.includes("&")) {
      search_link += "+" + sport.replace("&", "and") + "+roster"
    }
    else {
      search_link += "+" + sport + "+roster"
    }
    search_link = search_link.replace(new RegExp(' ', 'g'), "+")
    return search_link
  }

  const buildAYSMessage = (action) => {
    if (action.action === "create") {
      return (
        "Are you sure you want to create a new roster spot for " +
        action.new.full_name +
        "?"
      );
    }
  };

  const matchRecords = (type, existing) => {
    const payload = { action: type, existing, new: props.data[props.selectedIndex] };

    setAction(payload);
  };

  const sendToTransferPortal = (reason) => {
    const athlete = props.data[props.selectedIndex];
    console.log('Transfer athlete', athlete, reason);

    createTransfer(
      user,
      athlete.idAthleteMerchInfo,
      athlete.university,
      reason
    )
      .then((response) => {
        // console.log('Successful transfer initiated',response);
        notify({ msg: response.data.Response, variant: "success" })
        props.reload();
        props.resetSelected();
      })
      .catch((error) => {
        // console.log(error);
        notify("Failed to complete transfer");
        props.reload();
        props.resetSelected();
      })
    setTransfer(undefined)
  }

  const actionMappings = {
    create: "add",
    merge: "assign",
    replace: "reassign",
    disconnect: "un-assign",
  };

  const takeAction = (payload) => {

    matchRosterRecords(payload, user, () => {
      props.reload();
      props.resetSelected();
    })
      .then((response) => {
        notify({
          msg: `${actionMappings[payload.action]}ed roster record`,
          variant: "success",
        });
        props.reload();
        props.resetSelected();
      })
      .catch((error) => {
        console.error(error);
        notify(`Failed to ${actionMappings[payload.action]} roster record`);
        props.reload();
        props.resetSelected();
      });

    setAction(undefined);
  };

  const aYSYes = () => {
    takeAction(action);
  };

  return (
    <div sx={{ width: "100%" }}>
      <div style={{ color: "#868686", opacity: "87%" }}>
        Selected Athlete Meta-Data
      </div>
      <Box
        sx={{
          padding: "5px",
          backgroundColor: "background.paper",
          marginTop: "10px",
          maxWidth: "370px",
        }}
      >
        <div style={{ color: "#888888" }}>Time signed up:</div>
        <div style={{ marginLeft: "100px" }}>
          {
            props.data[props.selectedIndex]?.StudentAthlete?.User
              ?.creationTimestamp
          }
        </div>
        <div style={{ color: "#888888" }}>Contract Signed At:</div>
        <div style={{ marginLeft: "100px" }}>
          {props.data[props.selectedIndex]?.signed_at}
        </div>
        <div style={{ color: "#888888" }}>Venmo:</div>
        <div style={{ marginLeft: "100px" }}>
          {props.data[props.selectedIndex]?.StudentAthlete?.User?.venmoUserName}
        </div>
        <div style={{ color: "#888888" }}>Instagram:</div>
        <div style={{ marginLeft: "100px" }}>
          <InstagramLink
            style={{ width: "30px" }}
            id={props.data[props.selectedIndex]?.StudentAthlete?.instagramData}
          />
        </div>
        <div style={{ color: "#888888" }}>Is International</div>
        <div style={{ marginLeft: "100px" }}>
          {props.data[props.selectedIndex]?.is_international ? "Yes" : "No"}
        </div>
        <div style={{ color: "#888888" }}>Signed:</div>
        <div style={{ marginLeft: "100px" }}>
          {props.data[props.selectedIndex]?.signed ? "Yes" : "No"}
        </div>
        <div style={{ color: "#888888" }}>Email:</div>
        <div style={{ marginLeft: "100px", overflowWrap: "break-word" }}>
          {props.data[props.selectedIndex]?.StudentAthlete?.User?.email}
        </div>
        <div style={{ color: "#888888" }}>Phone:</div>
        <div style={{ marginLeft: "100px" }}>
          {props.data[props.selectedIndex]?.StudentAthlete?.phone}
        </div>
        <div style={{ color: "#888888" }}>Sport:</div>
        <div style={{ marginLeft: "100px" }}>
          <a style={{ "color": "#80a097", "textDecoration": "none" }} href={getGoogleLink(props.data[props.selectedIndex]?.university, props.data[props.selectedIndex]?.sport)} target="_blank" rel="noopener noreferrer">{props.data[props.selectedIndex]?.sport}</a>
        </div>
        {/* <div style={{ color: "#888888" }}>Edit info:</div> */}
        <div style={{ marginLeft: "100px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigate("/ballpark/athletes/" + btoa(props.data[props.selectedIndex]?.idAthleteMerchInfo))
            }
          >
            Edit Info
          </Button>
        </div>
      </Box >
      {user && <ScoutTeam
              scoutData={props.data[props.selectedIndex]?.university}
              refresh={props.reload}
              user={user}
            />}
      <div style={{ marginTop: "20px", color: "#868686", opacity: "87%" }}>
        Un-Matched Athletes
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Button
          disabled={props.loading}
          sx={{ backgroundColor: "success.dark", color: "text.primary" }}
          onClick={() => matchRecords("create")}
        >
          Add to Roster
        </Button>
        <Button
          disabled={props.loading}
          sx={{ backgroundColor: "error.main", color: "text.primary" }}
          onClick={() => setTransfer(true)}
        >
          Send to Transfer Portal
        </Button>
      </div>
      {
        props.data.slice(0, 10).map((athlete, index) => (
          <ListItem
            key={
              athlete.sport +
              ":" +
              athlete.full_name +
              ":" +
              athlete.jersey_number
            }
            sx={{
              backgroundColor:
                props.selectedIndex === index
                  ? "success.dark"
                  : "background.paper",
              marginBottom: "5px",
              maxWidth: "370px",
              padding: "unset",
              boxShadow:
                props.selectedIndex === index ? "0px 5px 8px darkgreen" : "unset",
            }}
          >
            <ListItemButton onClick={() => props.select(index)}>
              <ListItemAvatar>
                <Avatar src={athlete.StudentAthlete?.User?.profilePicture} />
              </ListItemAvatar>
              {(athlete.jersey_number ? athlete.jersey_number : " _ ") +
                " : " +
                athlete.full_name +
                " " +
                athlete.sport}
            </ListItemButton>
          </ListItem>
        ))
      }
      {transfer && <Transfer
        style={{
          backgroundColor: "background.default",
          margin: "auto",
          marginTop: "10vh",
          padding: "20px",
          width: "40vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "5px",
        }}
        open={transfer !== undefined}
        confirmTransfer={(reason) => {
          sendToTransferPortal(reason);
        }}
        close={() => {
          setTransfer(undefined);
        }} />}
      {action && (
        <AreYouSure
          style={{
            backgroundColor: "background.paper",
            margin: "auto",
            marginTop: "10vh",
            padding: "20px",
            width: "30vw",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "5px",
          }}
          open={action !== undefined}
          message={buildAYSMessage(action)}
          yes={() => aYSYes()}
          no={() => setAction(undefined)}
        />
      )}
    </div >
  );
};

export default UnMatchedTable;
