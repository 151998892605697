import { useState, useEffect } from "react";
import {
  Typography,
  LinearProgress,
  CircularProgress,
  TableRow,
  TableCell,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Autocomplete
} from "@mui/material";
import { getOrderHistoryBySchool } from "../../../API/compliance";
import { getAnalyticsOptions } from "../../../API/roster";
import {
  useUserContext,
} from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import MUIDataTable from "mui-datatables";
import TrueFalseCheckbox from "../../common/TrueFalseCheckbox";
import MultiSelect from "../../common/MultiSelect";
import DateRangePicker from '../../common/DateRangePicker';
import { decompressData } from '../../../utilities/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const ExpandableRow = (props) => {
  return (
    <div
      style={{
        margin: "10px 10px"
      }}>
      <MUIDataTable
        title={props.title}
        data={props.data}
        columns={props.columns}
        options={props.options}
        components={{ TableToolbar(props) { return null; }, }}
      />
    </div>
  );
};

const BusinessTotalsDashboard = ({ totals }) => {
  console.log(totals)
  return (
    <Grid container spacing={1}>
      {totals
        .filter(total => total != null && total != undefined)
        .map((total, index) => (
          <Grid item xs={12} sm={4} md={4} lg={2} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h7" gutterBottom style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {total.title}
                </Typography>
                <Typography variant="h5">
                  {total.value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

const AnalyticsDashboard = (props) => {
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [complianceData, setComplianceData] = useState([]);
  const [complianceTotals, setComplianceTotals] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [schools, setSchools] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [schoolInput, setSchoolInput] = useState("");

  const [sports, setSports] = useState([]);
  const [sportInput, setSportInput] = useState("");
  const [selectedSports, setSelectedSports] = useState([]);

  const [showByValue, setShowByValue] = useState("Player");
  const [showByInput, setShowByInput] = useState("Player");

  const [genders, setGenders] = useState([]);
  const [selectedGender, setSelectedGender] = useState("");
  const [genderInput, setGenderInput] = useState("");

  const [skus, setSkus] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [skusInput, setSkusInput] = useState("");

  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [typesInput, setTypesInput] = useState("");

  const [states, setStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [statesInput, setStatesInput] = useState("");

  const [conferences, setConferences] = useState([]);
  const [selectedConferences, setSelectedConferences] = useState([]);
  const [conferencesInput, setConferencesInput] = useState("");

  const [divisions, setDivisions] = useState([]);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [divisionsInput, setDivisionsInput] = useState("");

  const [licensingCompanies, setLicensingCompanies] = useState([]);
  const [selectedLicensingCompanies, setSelectedLicensingCompanies] = useState([]);
  const [licensingCompaniesInput, setLicensingCompaniesInput] = useState("");

  const [colors, setColors] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [colorsInput, setColorsInput] = useState("");

  const today = new Date();
  const startOfPreviousQuarter = new Date(today.getFullYear(), today.getMonth() - (today.getMonth() % 3) - 3, 1);
  
  const [startDate, setStartDate] = useState(dayjs.tz(startOfPreviousQuarter.toISOString().split('T')[0], 'America/Chicago').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs.tz(today.toISOString().split('T')[0], 'America/Chicago').endOf('day'));

  const [sendQuery, setSendQuery] = useState(0);
  const [filterQuery, setFilterQuery] = useState(0);

  const user = useUserContext();
  const notify = useNotification();

  // load dynamic options lists based on changes in selectors
  useEffect(() => {
    if (!user) { return }
    const analyticsData = {
      schools: selectedSchools,
      sports: selectedSports,
      gender: selectedGender,
      sku_name: selectedSkus,
      type: selectedTypes,
      conference: selectedConferences,
      division: selectedDivisions,
      state: selectedStates,
      licensing_company: selectedLicensingCompanies,
      color: selectedColors
    }

    getAnalyticsOptions(analyticsData, user)
      .then((response) => {
        setSports(response.data.sports)
        setConferences(response.data.conferences)
        setGenders(response.data.genders)
        setSkus(response.data.skus)
        setTypes(response.data.types)
        setSchools(response.data.schools)
        setDivisions(response.data.divisions)
        setStates(response.data.states)
        setLicensingCompanies(response.data.licensing_companies)
        setColors(response.data.colors)
        setLoadingOptions(false)
      })
      .catch((response) => {
        notify('Failed to get sports for selected schools.')
        setLoadingOptions(false)
      })
  }, [user, filterQuery]);

  useEffect(() => {
    if (!user || sendQuery == 0) { return }

    const analyticsData = {
      schools: selectedSchools,
      sports: selectedSports,
      gender: selectedGender,
      sku_name: selectedSkus,
      type: selectedTypes,
      conference: selectedConferences,
      division: selectedDivisions,
      state: selectedStates,
      licensing_company: selectedLicensingCompanies,
      color: selectedColors,
      groupBy: showByValue
    }
    getOrderHistoryBySchool(user, analyticsData, startDate.startOf('day'), endDate.endOf('day'), "Internal")  // full inclusive range
      .then((response) => {
        const responseData = decompressData(response);
        console.log('response from getOrderHistory.')
        console.log(responseData)

        setComplianceData(responseData.data);
        setComplianceTotals(responseData.totals);
        setLoadingQuery(false);
      })
      .catch((error) => {
        notify('Failed to get compliance data');
        setLoadingQuery(false);
      });
  }, [user, sendQuery, startDate, endDate]);


  const handleFilterQueryClick = () => {
    console.log('Filter query options')
    setLoadingOptions(true)
    setFilterQuery(filterQuery + 1);
  };

  const handleSendQueryClick = () => {
    console.log('Send query as written')
    setLoadingQuery(true)
    setSendQuery(sendQuery + 1);
  };

  const handleExportAffinity = () => {
    const convertToCSV = (objArray) => {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';

      const headers = Object.keys(array[0]);
      str += headers.join(',') + '\r\n';
  
      for (let i = 0; i < array.length; i++) {
        str += headers.map(header => array[i][header]).join(',') + '\r\n';
      }
      return str;
    };

    console.log("Export Affinity Data");
    setLoadingExport(true);
    const mergedList = complianceData.flatMap(record => record.player_trans);
    const csvData = new Blob([convertToCSV(mergedList)], { type: 'text/csv;charset=utf-8;' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `Affinity Export ${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoadingExport(false);
  }

  // Transaction Fees (0.85%)	University Royalty Fee	Service Fee (4%)	Transfer Fee (2%)	Gross profit 	Player's profit (60%)
  const playerColumns = [
    {
      label: "Athlete", name: "name", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Shopify Collection ID", name: "shopify_collection_id", options: {
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    { label: "University", name: "university", options: { display: selectedSchools && (selectedSchools.length > 1 || selectedSchools[0] === "All" && showByValue !== "University"), sortThirdClickReset: true } },
    { label: "Sport", name: "sport", options: { display: false } },
    { label: "State", name: "state", options: { display: false } },
    { label: "Division", name: "division", options: { display: false } },
    { label: "Conference", name: "conference", options: { display: false } },
    { label: "Gender", name: "gender", options: { display: false } },
    { label: "Sub Division", name: "subDivision", options: { display: false } },
    {
      label: "Total Quantity Sold",
      name: "quantity_sum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Orders",
      name: "order_sum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Sales",
      name: "sales_sum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Cost of Goods",
      name: "cogs",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transaction Fees", name: "trans_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "University Royalty Fees", name: "roy_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Service Fees", name: "serv_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transfer Fees", name: "transfer_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Gross (Net) Profit", name: "net", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Player Profit", name: "play_prof", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    { label: 'Line Items', name: 'line_items', options: { display: false, filter: false } }
  ]

  const playerOptions = {
    filter: true,
    // sortOrder: {name:"selected", direction:'desc'},
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: rowsPerPage,
    expandableRows: true,
    onChangeRowsPerPage: (num) => setRowsPerPage(num),
    renderExpandableRow: (rowData, rowMeta, a) => {
      const expInd = complianceData[rowMeta.dataIndex]?.expIndex;

      const colSpan = 7;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandableRow
              user={user}
              data={showByValue === "Player" ? complianceData[rowMeta.dataIndex].player_trans.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
              }) : complianceData[expInd].data[rowMeta.dataIndex].player_trans.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
              })
              }
              school={complianceData[rowMeta.dataIndex].university}
              title={"OrderHistory"}
              columns={playerExpandableColumns}
              options={playerExpandableOptions}
              notify={notify} />
          </TableCell>
        </TableRow>
      );
    },
    textLabels: {
      body: {
        noMatch: loadingQuery ? (
          <CircularProgress />
        ) : (
          "No Records"
        ),
      },
    },
  };

  const playerExpandableColumns = [
    {
      label: "Title", name: "title", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value.replace(props.school, '').replace(/-[^-]*-/, '-')}</div>
        ),
      }
    },
    { label: "Price", name: "price", options: {} },
    { label: "Quantity", name: "quantity", options: {} },
    {
      label: "Date", name: "date", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          new Date(value).toLocaleDateString()
        ),
      }
    },
    {
      name: "royaltyBearing",
      label: "Royalty Bearing",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value !== "" && value !== null && value} disabled />
        ),
      },
    },
  ]

  const playerExpandableOptions = {
    filter: true,
    sortOrder: { name: "date", direction: 'desc' },
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: 10,
    // customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
    //   return ( null );
    // },
  };

  const universityColumns = [
    { label: "University", name: "university", options: { sortThirdClickReset: true } },
    { label: "Rank", name: "rank", options: { sortThirdClickReset: true } },
    { label: "State", name: "state", options: { sortThirdClickReset: true } },
    { label: "Division", name: "division", options: { sortThirdClickReset: true } },
    { label: "Conference", name: "conference", options: { sortThirdClickReset: true } },
    {
      label: "Sub Division", name: "subDivision", options: {
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Quantity Sold",
      name: "totalQuantitySum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Orders",
      name: "totalOrderSum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Sales",
      name: "totalSalesSum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "University Royalty Fees", name: "totalRoyFee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Gross (Net) Profit", name: "totalGrossProf", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Player Profit", name: "totalPlayProf", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    { label: 'Line Items', name: 'line_items', options: { display: false, filter: false } }
  ]

  const universityOptions = {
    filter: true,
    // sortOrder: {name:"selected", direction:'desc'},
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: rowsPerPage,
    expandableRows: true,
    onChangeRowsPerPage: (num) => setRowsPerPage(num),
    renderExpandableRow: (rowData, rowMeta, a) => {
      const colSpan = 7;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandableRow
              user={user}
              data={complianceData[rowMeta.dataIndex].data}
              school={complianceData[rowMeta.dataIndex].university}
              columns={playerColumns}
              options={playerOptions}
              notify={notify} />
          </TableCell>
        </TableRow>
      );
    },
    textLabels: {
      body: {
        noMatch: loadingQuery ? (
          <CircularProgress />
        ) : (
          "No Records"
        ),
      },
    },
  };

  const prodColumns = [
    {
      label: "Product", name: "type", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Quantity Sold",
      name: "quantity_sum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Orders",
      name: "order_sum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Sales",
      name: "sales_sum",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Cost of Goods",
      name: "cogs",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transaction Fees", name: "trans_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "University Royalty Fees", name: "roy_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Service Fees", name: "serv_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transfer Fees", name: "transfer_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Gross (Net) Profit", name: "net", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Player Profit", name: "play_prof", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    { label: 'Line Items', name: 'line_items', options: { display: false, filter: false } }
  ]

  const prodOptions = {
    filter: true,
    // sortOrder: {name:"selected", direction:'desc'},
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: rowsPerPage,
    expandableRows: true,
    onChangeRowsPerPage: (num) => setRowsPerPage(num),
    renderExpandableRow: (rowData, rowMeta, a) => {
      const colSpan = 7;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandableRow
              user={user}
              data={complianceData[rowMeta.dataIndex].player_trans}
              columns={prodExpandableColumns}
              options={prodExpandableOptions}
              title={'Order History'}
              notify={notify} />
          </TableCell>
        </TableRow>
      );
    },
    textLabels: {
      body: {
        noMatch: loadingQuery ? (
          <CircularProgress />
        ) : (
          "No Records"
        ),
      },
    },
  };

  const prodExpandableColumns = [
    {
      label: "Athlete", name: "name", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Shopify Collection ID", name: "shopify_collection_id", options: {
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Shopify Product ID", name: "shopify_product_id", options: {
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Title", name: "title", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value.replace(props.school, '').replace(/-[^-]*-/, '-')}</div>
        ),
      }
    },
    { label: "Price", name: "price", options: {} },
    { label: "Quantity", name: "quantity", options: {} },
    { label: "Sport", name: "sport", options: { display: false } },
    { label: "Gender", name: "gender", options: { display: false } },
    {
      label: "Date", name: "date", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          new Date(value).toLocaleDateString()
        ),
      }
    },
    {
      name: "royaltyBearing",
      label: "Royalty Bearing",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <TrueFalseCheckbox checked={value !== "" && value !== null && value} disabled />
        ),
      },
    },
    {
      label: "Player Profit", name: "play_prof", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    { label: 'Line Items', name: 'line_items', options: { display: false, filter: false } }
  ]

  const prodExpandableOptions = {
    filter: true,
    sortOrder: { name: "date", direction: 'desc' },
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: 10,
    // customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
    //   return ( null );
    // },
  };

  const skuColumns = [
    {
      label: "Sku Name", name: "sku_name", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    { label: "University", name: "university", options: { sortThirdClickReset: true } },
    {
      label: "Total Quantity Sold", name: "quantity_sum", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Orders", name: "order_sum", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Sales", name: "sales_sum", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Cost of Goods", name: "cogs", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transaction Fees", name: "trans_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "University Royalty Fees", name: "roy_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Service Fees", name: "serv_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transfer Fees", name: "transfer_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Gross (Net) Profit", name: "net", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Player Profit", name: "play_prof", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
  ]

  const skuOptions = {
    filter: true,
    sortOrder: { name: "University", direction: 'desc' },
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: rowsPerPage,
    expandableRows: true,
    onChangeRowsPerPage: (num) => setRowsPerPage(num),
    renderExpandableRow: (rowData, rowMeta, a) => {
      const colSpan = 7;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <ExpandableRow
              user={user}
              data={complianceData[rowMeta.dataIndex].player_trans}
              columns={skuExpandableColumns}
              options={skuExpandableOptions}
              title={'Order History'}
              notify={notify} />
          </TableCell>
        </TableRow>
      );
    },
    textLabels: {
      body: {
        noMatch: loadingQuery ? (
          <CircularProgress />
        ) : (
          "No Records"
        ),
      },
    },
  };

  const skuExpandableColumns = [
    {
      label: "Licensor", name: "Licensor", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Invoice Id", name: "Invoice Id", options: {
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Date", name: "Date", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          new Date(value).toLocaleDateString()
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Item Description", name: "Item Description", options: {
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Affinity UPI", name: "Affinity UPI", options: {
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    { label: "Affinity Distribution Channel", name: "Affinity Distribution Channel", options: {} },
    { label: "Retailer", name: "Retailer", options: {} },
    { label: "Customer", name: "Customer", options: {} },
    { label: "Insignia", name: "Insignia", options: {} },
    { label: "Shipping Country", name: "Shipping Country", options: {} },
    { label: "Shipping Postal Code", name: "Shipping Postal Code", options: {} },
    { label: "Number of Units", name: "Number of Units", options: {} },
    { label: "Price Per Unit", name: "Price Per Unit", options: {} },
    { label: "Gross Sales", name: "Gross Sales", options: {} },
    { label: "Royalties", name: "Royalties", options: {} }
  ]

  const skuExpandableOptions = {
    filter: true,
    sortOrder: { name: "Date", direction: 'desc' },
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: 10,
    // customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
    //   return ( null );
    // },
  };

  const sportColumns = [
    {
      label: "Sport", name: "sport", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{value}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Quantity Sold", name: "quantity_sum", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Orders", name: "order_sum", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Total Sales", name: "sales_sum", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Cost of Goods", name: "cogs", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transaction Fees", name: "trans_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "University Royalty Fees", name: "roy_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Service Fees", name: "serv_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Transfer Fees", name: "transfer_fee", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Gross (Net) Profit", name: "net", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
    {
      label: "Player Profit", name: "play_prof", options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{
            textAlign: "center",
          }}>{value}</div>
        ),
        customHeadLabelRender: (columnMeta) => (
          <div style={{
            whiteSpace: "nowrap",
          }}>{columnMeta.label}</div>
        ),
        sortCompare: (order) => {
          return (a, b) => {
            const val1 = parseFloat(a.data.replace('$', '').replace(',', '').trim());
            const val2 = parseFloat(b.data.replace('$', '').replace(',', '').trim());

            if (isNaN(val1)) return -1;
            if (isNaN(val2)) return 1;

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        filter: false,
        sortThirdClickReset: true
      }
    },
  ]
  const sportOptions = {
    filter: true,
    sortOrder: { name: "Sport", direction: 'desc' },
    selectableRows: "none",
    selectableRowsHeader: false,
    rowsPerPageOptions: [5, 10, 25, 100, 200],
    rowsPerPage: rowsPerPage,
    expandableRows: false,
    onChangeRowsPerPage: (num) => setRowsPerPage(num),
    textLabels: {
      body: {
        noMatch: loadingQuery ? (
          <CircularProgress />
        ) : (
          "No Records"
        ),
      },
    },
  }

  const showByOptions = [
    "University",
    "Player",
    "Product Type",
    "Sku Name",
    "Sport"
  ]

  const tableConfigurations = {
    "Player": {
      columns: playerColumns,
      options: playerOptions,
      title: "Player Sales Data"
    },
    "University": {
      columns: universityColumns,
      options: universityOptions,
      title: "University Sales Data"
    },
    "Product Type": {
      columns: prodColumns,
      options: prodOptions,
      title: "Product Type Sales Data"
    },
    "Sku Name": {
      columns: skuColumns,
      options: skuOptions,
      title: "Sku Names Sales Data"
    },
    "Sport": {
      columns: sportColumns,
      options: sportOptions,
      title: "Sport Sales Data"
    }
  };

  return (
    <div style={{ width: "90%", paddingTop: "5vh" }}>
      <Typography variant="h1">Analytics</Typography>
      {(loadingQuery || loadingOptions) && <LinearProgress />}
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
        {schools.length > 0 &&
          <MultiSelect
            value={selectedSchools}
            inputValue={schoolInput}
            options={schools}
            onChange={setSelectedSchools}
            onInputChange={setSchoolInput}
            label={"School List"}
            style={{ width: "30%", margin: "10px 10px" }}
            allButton
          />
        }
        {sports.length > 0 &&
          <MultiSelect
            value={selectedSports}
            inputValue={sportInput}
            options={sports}
            onChange={setSelectedSports}
            onInputChange={setSportInput}
            label={"Sport List"}
            style={{ width: "30%", margin: "10px 10px" }}
            allButton
          />
        }
        {genders.length > 0 &&
          <Autocomplete
            value={selectedGender}
            onChange={(event, newValue) => {
              setSelectedGender(newValue);
            }}
            inputValue={genderInput}
            onInputChange={(event, newInputValue) => {
              setGenderInput(newInputValue);
            }}
            options={genders}
            style={{ width: "30%", margin: "10px 10px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Gender"}
                variant="outlined"
                style={{ width: "100%" }}
                InputProps={{
                  ...params.InputProps,
                }}
                type="text"
              />
            )}
          />
        }
        {skus.length >= 1 &&
          <MultiSelect
            value={selectedSkus}
            inputValue={skusInput}
            options={skus}
            onChange={setSelectedSkus}
            onInputChange={setSkusInput}
            label={"Sku"}
            style={{ width: "30%", margin: "10px 10px" }}
            allButton
          />
        }
        {types.length >= 1 &&
          <MultiSelect
            value={selectedTypes}
            inputValue={typesInput}
            options={types}
            onChange={setSelectedTypes}
            onInputChange={setTypesInput}
            label={"Type"}
            style={{ width: "30%", margin: "10px 10px" }}
            allButton
          />
        }
        {states.length >= 1 &&
          <MultiSelect
            value={selectedStates}
            inputValue={statesInput}
            options={states}
            onChange={setSelectedStates}
            onInputChange={setStatesInput}
            label={"State"}
            style={{ width: "30%", margin: "10px 10px" }}
            allButton
          />
        }
        {conferences.length >= 1 &&
          <MultiSelect
            value={selectedConferences}
            inputValue={conferencesInput}
            options={conferences}
            onChange={setSelectedConferences}
            onInputChange={setConferencesInput}
            label={"Conference"}
            style={{ width: "30%", margin: "10px 10px" }}
            allButton
          />
        }
        {licensingCompanies.length >= 1 &&
          <MultiSelect
            value={selectedLicensingCompanies}
            inputValue={licensingCompaniesInput}
            options={licensingCompanies}
            onChange={setSelectedLicensingCompanies}
            onInputChange={setLicensingCompaniesInput}
            label={"Licensing Company"}
            style={{ width: "30%", margin: "10px 10px" }}
            allButton
          />
        }
        {divisions.length >= 1 &&
          <MultiSelect
            value={selectedDivisions}
            inputValue={divisionsInput}
            options={divisions}
            onChange={setSelectedDivisions}
            onInputChange={setDivisionsInput}
            label={"Division"}
            style={{ width: "30%", margin: "10px 10px" }}
            allButton
          />
        }
        {colors.length >= 1 &&
          <MultiSelect
            value={selectedColors}
            inputValue={colorsInput}
            options={colors}
            onChange={setSelectedColors}
            onInputChange={setColorsInput}
            label={"Color"}
            style={{ width: "30%", margin: "10px 10px" }}
            allButton
          />
        }
      </div>
      <div style={{
        margin: "10px 10px",
        // justifyContent: "center",
        width: "90%",
        // paddingLeft: "10px"
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <Autocomplete
          value={showByValue}
          onChange={(event, newValue) => {
            setShowByValue(newValue);
          }}
          inputValue={showByInput}
          onInputChange={(event, newInputValue) => {
            setShowByInput(newInputValue);
          }}
          options={showByOptions}
          style={{ width: "20%", margin: "30px 10px", }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Show By"}
              variant="outlined"
              style={{ width: "100%" }}
              InputProps={{
                ...params.InputProps,
              }}
              type="text"
            />
          )}
        />
      </div>
      <div style={{
        margin: "10px",
        justifyContent: "flex-start", // Align items to the left
        width: "100%", // Full width of the parent
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap', // Allow wrapping if buttons don't fit
        gap: '10px', // Space between buttons
      }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilterQueryClick}
          style={{
            margin: "5px", // Space around each button
            flex: '0 1 auto', // Prevent buttons from stretching
            whiteSpace: 'nowrap', // Prevent text from wrapping inside buttons
          }}
          disabled={loadingOptions}
        >
          {loadingOptions ? (
                  <>
                      Filter Options
                      <CircularProgress size={24} color="inherit" />
                  </>
              ) : (
                  'Filter Options'
              )}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendQueryClick}
          style={{
            margin: "5px", // Space around each button
            flex: '0 1 auto', // Prevent buttons from stretching
            whiteSpace: 'nowrap', // Prevent text from wrapping inside buttons
          }}
          disabled={loadingQuery}
        >
          {loadingQuery ? (
                  <>
                      Send Query
                      <CircularProgress size={24} color="inherit" />
                  </>
              ) : (
                  'Send Query'
              )}
        </Button>
        {showByValue === "Sku Name" &&
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportAffinity}
          style={{
            margin: "5px", // Space around each button
            flex: '0 1 auto', // Prevent buttons from stretching
            whiteSpace: 'nowrap', // Prevent text from wrapping inside buttons
          }}
          disabled={complianceData.length <1 || loadingExport}
        >
          {loadingExport ? (
                  <>
                    Export Affinity Data
                    <CircularProgress size={24} color="inherit" />
                  </>
              ) : (
                  'Export Affinity Data'
              )}
        </Button>}
      </div>
      {complianceData &&
        <div
          style={{
            margin: "20px 10px"
          }}>
          {showByValue ?
            <MUIDataTable
              title={tableConfigurations[showByValue].title}
              data={complianceData}
              columns={tableConfigurations[showByValue].columns}
              options={tableConfigurations[showByValue].options}
            />
            :
            <Typography variant="h6">Please select a Show By Value</Typography>
          }
        </div>
      }
      {complianceTotals &&
        <div
          style={{
            margin: "10px 10px",
          }}>
          <BusinessTotalsDashboard
            totals={complianceTotals}
          />
        </div>}
    </div>
  )
};

export default AnalyticsDashboard