import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';

export default function errorAlert(props){
    return(
        <Stack sx={{ width: '50%' }} spacing={2}>
          <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
            <strong>{props.message}</strong>
          </Alert>
        </Stack>
    )
}