import * as React from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Can from "../../common/Can";
import { Navigate, useParams } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";
import Campaign from "./Campaign";

export const Component = () => {
  const { id } = useParams()
  return (
    <Campaign id={id} />
  );
}

export const icon = <DirectionsRunIcon />;
export const text = "Campaign";
export const componentName = "Campaign";
export const path = "campaign/:id";
export const display = false
