import { useState, useEffect } from "react";
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import {
    getRunnableMockups,
    runAllMockupGeneration, stopMockupGeneration
} from "../../../API/equipmentManager";
import {
    Button, Divider
} from "@mui/material";
import TaskUpdateView from "./TaskUpdateView";
import RunnableDataView from "./RunnableDataView";
import SchoolSportFilter from "../../common/SchoolSportFilter";
import AreYouSure from "../../common/AreYouSure";
import StopIcon from '@mui/icons-material/Stop';

const MockupGeneration = ({ file, exampleImages, imagesType, loading, setFile, setExampleImages, setImagesType, setLoading, data }) => {
    const user = useUserContext();
    const notify = useNotification();
    const [activeTab, setActiveTab] = useState("Running");
    const [selectedSchool, setSelectedSchool] = useState("");
    const [selectedSport, setSelectedSport] = useState("");
    const [universityCount, setUniversityCount] = useState({});
    const [expanded, setExpanded] = useState(false);
    const [runnableAssetData, setRunnableAssetData] = useState([]);
    const [selectedAssetData, setSelectedAssetdata] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const setUniversities = (data) => {
        let universityCount = {}
        data.forEach(asset => {
            if (asset.university in universityCount) {
                universityCount[asset.university][0] += 1
            }
            else {
                universityCount[asset.university] = [0, 0]
            }
        })
        setUniversityCount(universityCount)
    }

    const changeSelectedData = (data, school, sport) => {

        let selectedData = []
        if (school === "" && sport === "") {
            selectedData = data
        }
        else {
            selectedData = data.filter(asset => {
                if (school === "") {
                    return asset.sport === sport
                } else if (sport === "") {
                    return asset.university === school
                }
                return (asset.university === school && asset.sport === sport)
            })
        }
        setSelectedAssetdata(selectedData)

    }

    const changeUniversity = (university) => {
        const temp_university = university == null || university == undefined ? "" : university
        setSelectedSchool(temp_university)
        changeSelectedData(runnableAssetData, temp_university, selectedSport)
    }

    const changeSport = (sport) => {
        const temp_sport = sport == null || sport == undefined ? "" : sport
        setSelectedSport(temp_sport)
        changeSelectedData(runnableAssetData, selectedSchool, temp_sport)
    }
    useEffect(() => {
        const getAlerts = () => {
            getRunnableMockups(user).then(res => {

                setRunnableAssetData(res.data.result);
                setUniversities(res.data.result)
                changeSelectedData(res.data.result, selectedSchool, selectedSport)
                if (res.data.result.length > 0) {
                    setTableColumns(Object.keys(res.data.result[0]))
                }
            }).catch((e) => {
                console.log(e);
                notify("Failed to get Status of EquipmentManager");
            });
        }
        getAlerts()
        const interval = setInterval(() => getAlerts(), 60000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    const runAll = () => {
        const university = selectedSchool === undefined || selectedSchool === null ? "" : selectedSchool
        const sport = selectedSport === undefined || selectedSport === null ? "" : selectedSport
        const payload = { "University": university, "Sport": sport }
        runAllMockupGeneration(payload, user).then(() => {
            notify({ msg: "Successfully Started Execution", variant: "success" })
        }).catch(() => {
            notify({ msg: "Failed to run all asset generation", variant: "error" })
        })
    }

    const stopMockups = () => {
        stopMockupGeneration(user).then(() => {
            getRunnableMockups(user).then(res => {
                setRunnableAssetData(res.data.result);
                setUniversities(res.data.result)
                changeSelectedData(res.data.result, selectedSchool, selectedSport)
                if (res.data.result.length > 0) {
                    setTableColumns(Object.keys(res.data.result[0]))
                }
            }).catch((e) => {
                console.log(e);
                notify("Failed to get Status of EquipmentManager");
            });
            notify({ msg: "Successfully STOPPED Mockup Generation Execution", variant: "success" })
        }).catch(() => {
            notify({ msg: "Failed to stop asset generation", variant: "error" })
        })
    }

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
            }}
        >
            <Divider variant="fullWidth" />

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginBottom: "20px"
            }}>
                <Button variant="outlined" color="error" onClick={(() => setIsModalOpen(true))} sx={{ margin: "10px" }}><StopIcon />Stop Slammin'</Button>
            </div>


            <div style={{
                paddingLeft: "10%",
                width: "90%",
            }}>
                <SchoolSportFilter
                    selectedSchool={selectedSchool}
                    setSelectedSchool={changeUniversity}
                    selectedSport={selectedSport}
                    setSelectedSport={changeSport}
                    universitiesCount={universityCount}
                    hideCount={true}
                />
                <RunnableDataView expanded={expanded} setExpanded={setExpanded} title={"Runnable Assets"} tableData={selectedAssetData} tableColumns={tableColumns} tableOptions={{}} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
            }}>

                <Button sx={{ width: "70%", height: "20px", padding: "20px", marginRight: "15%", marginLeft: "15%", marginTop: "30px" }} variant={"contained"} onClick={runAll}>
                    Run All Mockup Generation
                </Button>
            </div>

            <br />
            <Divider style={{ margin: "20px" }} />
            <TaskUpdateView activeTab={activeTab} setTab={setActiveTab} loading={loading} setAssetLoading={setLoading} data={data} taskType="Mockup" />

            <AreYouSure
                style={{
                    backgroundColor: "background.default",
                    margin: "auto",
                    marginTop: "10vh",
                    padding: "20px",
                    width: "30vw",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRadius: "5px",
                }}
                open={isModalOpen}
                message={"Do you want to Stop Mockup Generation"}
                handleClose={() => setIsModalOpen(false)}
                yes={() => {
                    stopMockups();
                    setIsModalOpen(false);
                }}
                no={() => setIsModalOpen(false)}
            />
        </div>
    )
}
export default MockupGeneration;