import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    Grid,
    Tooltip,
    TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

import useNotification from "../../../../hooks/useNotification";


const ProductAuditSummaryModal = ({ isModalOpen, setIsModalOpen, selectedCollections, handleConfirmUpdate, isSubmitting }) => {
    const [expandedProducts, setExpandedProducts] = useState([]);
    const [note, setNote] = useState("");
    const [validCollections, setValidCollections] = useState([]);
    const [invalidCollections, setInvalidCollections] = useState([]);

    const notify = useNotification();

    useEffect(() => {
        const collectionsWithID = selectedCollections.filter(collection => collection.ShopifyCollectionId !== null);
        const collectionsWithoutID = selectedCollections.filter(collection => collection.ShopifyCollectionId === null);

        setValidCollections(collectionsWithID);
        setInvalidCollections(collectionsWithoutID);

        if (collectionsWithoutID.length > 0) {
            notify("Some collections do not have a Shopify Collection ID and will be excluded from the audit.");
        }
    }, [selectedCollections, notify]);

    const handleProductClick = (collectionId) => {
        setExpandedProducts((prevExpandedProducts) =>
            prevExpandedProducts.includes(collectionId)
                ? prevExpandedProducts.filter((id) => id !== collectionId)
                : [...prevExpandedProducts, collectionId]
        );
    };

    const handleConfirm = () => {
        handleConfirmUpdate(note);
        setNote("");
    };

    return (
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}
               sx={{
                   zIndex: 11000
               }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    zIndex: 11000
                }}
            >
                <Typography variant="h6" gutterBottom>Product Audit Summary</Typography>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ bgcolor: "primary.main" }}>
                            <CardContent>
                                <Typography variant="body1">
                                    {validCollections.length} collections selected for auditing.
                                    <Tooltip title="Collections selected for auditing" sx={{ zIndex: 12000 }}>
                                    </Tooltip>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ bgcolor: "primary.main" }}>
                            <CardContent>
                                <Typography variant="body1">
                                    {invalidCollections.length} collections without Shopify Collection ID.
                                    <Tooltip title="Collections without Shopify Collection ID will be excluded" sx={{ zIndex: 12000 }}>
                                    </Tooltip>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{validCollections.length} Collections Selected for Auditing</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {validCollections && validCollections.length > 0 && (
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                                {validCollections.map((collection) => (
                                    <Box key={collection.CollectionId} sx={{ mb: 2, p: 2, border: '1px solid grey', borderRadius: 2 }}>
                                        <Typography><strong>Full Name:</strong> {collection.FullName}</Typography>
                                        <Typography><strong>University:</strong> {collection.University}</Typography>
                                        <Typography><strong>Sport:</strong> {collection.Sport}</Typography>
                                        <Button variant="text" onClick={() => handleProductClick(collection.CollectionId)}>
                                            {expandedProducts.includes(collection.CollectionId) ? 'Hide Details' : 'Show Details'}
                                        </Button>
                                        {expandedProducts.includes(collection.CollectionId) && (
                                            <Box sx={{ mt: 2 }}>
                                                <Typography><strong>Collection ID:</strong> {collection.CollectionId}</Typography>
                                                <Typography><strong>Roster ID:</strong> {collection.RosterId}</Typography>
                                                <Typography><strong>Shopify Collection ID:</strong> {collection.ShopifyCollectionId}</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{invalidCollections.length} Collections Without Shopify Collection ID (Not Included in the Audit Report)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {invalidCollections && invalidCollections.length > 0 && (
                            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                                {invalidCollections.map((collection) => (
                                    <Box key={collection.CollectionId} sx={{ mb: 2, p: 2, border: '1px solid grey', borderRadius: 2 }}>
                                        <Typography><strong>Full Name:</strong> {collection.FullName}</Typography>
                                        <Typography><strong>University:</strong> {collection.University}</Typography>
                                        <Typography><strong>Sport:</strong> {collection.Sport}</Typography>
                                        <Button variant="text" onClick={() => handleProductClick(collection.CollectionId)}>
                                            {expandedProducts.includes(collection.CollectionId) ? 'Hide Details' : 'Show Details'}
                                        </Button>
                                        {expandedProducts.includes(collection.CollectionId) && (
                                            <Box sx={{ mt: 2 }}>
                                                <Typography><strong>Collection ID:</strong> {collection.CollectionId}</Typography>
                                                <Typography><strong>Roster ID:</strong> {collection.RosterId}</Typography>
                                                <Typography><strong>Shopify Collection ID:</strong> {collection.ShopifyCollectionId}</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Box sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Add a Note To This Bulk Operation"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        multiline
                        rows={4}
                        variant="outlined"
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', mt: 2 }}>
                    <Tooltip title={validCollections.length === 0 ? "No valid collections selected for auditing." : ""} arrow sx={{ zIndex: 22000 }}>
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleConfirm}
                                disabled={isSubmitting || validCollections.length === 0}
                            >
                                Confirm
                            </Button>
                        </span>
                    </Tooltip>
                    <Button variant="outlined" color="secondary" onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ProductAuditSummaryModal;