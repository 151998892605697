import { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
  Paper,
  TableBody,
  Checkbox,
} from "@mui/material";
import {
  uploadProductAssets,
  uploadZippedAssets,
} from "../../../API/filesystem";
import { useUserContext } from "../../../contexts/ProviderProvider";
import useNotification from "../../../hooks/useNotification";
import FileDropZone from "../../common/FileDropZone";

const IsFileUploadShared = ({ row, shared, setShared, uploadFiles }) => {
  return (
    <>
      <FileDropZone
        uploadFiles={(files, callback) => uploadFiles(files, row, callback)}
      />
      <Checkbox checked={shared} onClick={() => setShared(!shared)} />
      Is Shared
    </>
  );
};

const ExpandableRow = ({
  products,
  schoolSportSku,
  refresh,
  artOrMockup,
  productOrCollection,
}) => {
  const [zipShared, setZipShared] = useState(false);
  const user = useUserContext();
  const notify = useNotification();

  const uploadFiles = (files, product, callback) => {
    const l = schoolSportSku.split("~");
    const side = l.length >= 4 ? l[3] : undefined;
    uploadProductAssets(
      files[0],
      {
        ...product,
        ...product.ProductMetadatum,
        ...product.MerchRoster,
        product_id: product.product_id,
        artOrMockup:
          artOrMockup === "mockup" ? artOrMockup : artOrMockup + "-" + side,
        productOrCollection: productOrCollection,
        isShared: zipShared,
      },
      user
    )
      .then((response) => {
        refresh();
        callback();
      })
      .catch((error) => {
        notify("Failed to upload :(");
        console.error(error);
        refresh();
        callback();
      });
  };

  const uploadZip = (files, schoolSportSku, callback) => {
    const [school, sport, sku, side] = schoolSportSku.split("~");
    //console.log(school, sport, sku, side, files);
    uploadZippedAssets(
      files,
      {
        school,
        sport,
        sku,
        side,
        artOrMockup:
          artOrMockup === "mockup" ? artOrMockup : artOrMockup + "-" + side,
        isShared: zipShared,
      },
      user
    )
      .then((response) => {
        notify({
          msg: "Successfully processed zip file, please verify the results",
          variant: "warn",
        });
        callback();
        refresh();
      })
      .catch((error) => {
        console.log(error);
        notify("Failed to upload zip");
        callback();
        refresh();
      });
  };

  return (
    <>
      <Box
        sx={{
          paddingBottom: "15px",
          backgroundColor: "background.light",
        }}
      >
        <FileDropZone
          multiple
          sx={{
            width: "100%",
          }}
          text="Drop Zip: Filenames must be Jersey # / Last Name .png"
          uploadFiles={(files, callback) => {
            //console.log(callback);
            uploadZip(files, schoolSportSku, callback);
          }}
        />
        <Checkbox
          checked={zipShared}
          onClick={() => setZipShared(!zipShared)}
        />
        Is Shared Asset
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Upload</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((row) => {
              const athlete = row.MerchRoster || {};
              return (
                <TableRow
                  key={athlete.full_name}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell>{athlete.full_name}</TableCell>
                  <TableCell>
                    <IsFileUploadShared
                      row={row}
                      uploadFiles={uploadFiles}
                      shared={zipShared}
                      setShared={setZipShared}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExpandableRow;
