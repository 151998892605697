import * as React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CurrentlyRunning = (props) => {
  const [isOpen, setIsOpen] = React.useState([]);
  const { currentlyRunning, products, collections } = props;

  //console.log("currently running", currentlyRunning, products, collections);

  const convertIds = (run, productCollectionDict) => {
    const mapping =
      productCollectionDict[run.roster_id.toString() + run.type_id.toString()];
    if (mapping) {
      run.full_name = mapping.full_name;
      run.sku_name = mapping.sku_name;
    }
    return run;
  };

  const productCollectionDict = {};
  collections?.forEach((c) => {
    productCollectionDict[c.RosterId.toString() + c.CollectionId.toString()] = {
      full_name: c.FullName,
      sku_name: "",
    };
  });

  products?.forEach((p) => {
    productCollectionDict[p.RosterId.toString() + p.ProductId.toString()] = {
      full_name: p.FullName,
      sku_name: ":" + p.SkuName,
    };
  });

  const grouped = {};
  currentlyRunning.forEach((run) => {
    if (grouped[run.type]) {
      // do nothing
    } else {
      grouped[run.type] = [];
    }

    grouped[run.type].push(convertIds(run, productCollectionDict));
  });

  if (Object.keys(grouped).length === 0) {
    return (
      <Box
        sx={{
          width: "fit-content",
          margin: "auto",
          backgroundColor: "background.paper",
          marginBottom: "10px",
        }}
      >
        <Typography
          style={{ margin: "auto", width: "fit-content", padding: "5px" }}
        >
          Nothing running
        </Typography>
      </Box>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        borderRadius: "4px",
        paddingBottom: "10px",
      }}
    >
      {Object.keys(grouped).map((type, i) => (
        <>
          <Typography fontSize={"20px"} sx={{ width: "100%" }}>
            {"Running " + cap(type)}
          </Typography>
          <Box
            key={type}
            sx={{
              backgroundColor: "background.paper",
              padding: "15px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              height: isOpen[i] ? "auto" : "70px",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {grouped[type].map((running) => (
                <Box
                  key={running.type_id}
                  sx={{
                    borderRadius: "4px",
                    backgroundColor: "warning.light",
                    color: "warning.contrastText",
                    padding: "5px",
                    margin: "5px",
                    fontSize: "12px",
                    height: "30px",
                  }}
                >
                  {running.full_name ?
                  running.full_name + running.sku_name :
                  "New Item"
                }
                </Box>
              ))}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <IconButton
                onClick={() => {
                  setIsOpen((p) => {
                    const updated = [...p];
                    updated[i] = p[i] ? false : true;
                    return updated;
                  });
                }}
              >
                {isOpen[i] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </Box>
          </Box>
        </>
      ))}
    </div>
  );
};

const cap = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export default CurrentlyRunning;
