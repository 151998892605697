const Media = ({ src, style }) => {
  if (src.endsWith(".png") || src.endsWith(".jpg") || src.endsWith(".jpeg")) {
    return <img src={src} style={style} />
  } else {
    return(
      <video controls style={style}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    )
  }
}

export default Media
