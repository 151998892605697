// DateRangePicker.jsx
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * DateRangePicker component renders two date pickers for selecting start and end dates.
 * @param {object} props - The properties passed to the component.
 * @param {any} props.startDate - The value of the start date.
 * @param {function} props.setStartDate - The function to set the start date.
 * @param {any} props.endDate - The value of the end date.
 * @param {function} props.setEndDate - The function to set the end date.
 * @param {title} props.title - The string value to set the title above the picker
 */
const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate, title, ...props }) => {
  const handleStartDateChange = (newValue) => {
    const centralTimeDate = dayjs.tz(newValue,'America/Chicago').startOf('day');
    setStartDate(centralTimeDate);
  };

  const handleEndDateChange = (newValue) => {
    const centralTimeDate = dayjs.tz(newValue, 'America/Chicago').endOf('day');
    setEndDate(centralTimeDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column' }}>
        <Typography id="date-range-picker" gutterBottom>
          {props.title ? props.title : "Date Range"}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingRight: '5px', paddingBottom: '10px' }}>
            <DateTimePicker
              label="Start Date"
              views={['month', 'year', 'day']}
              openTo="day"
              value={startDate}
              onChange={handleStartDateChange}
              style={{ padding: '5px' }}
            />
          </span>
          <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <DateTimePicker
              label="End Date"
              views={['month', 'year', 'day']}
              openTo="day"
              value={endDate}
              onChange={handleEndDateChange}
              style={{ padding: '5px' }}
            />
          </span>
        </div>
      </div>
    </LocalizationProvider>
  );
};

DateRangePicker.propTypes = {
  startDate: PropTypes.any.isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.any.isRequired,
  setEndDate: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default DateRangePicker;
