import { useState, useEffect } from "react";

import MUIDataTable from "mui-datatables";
import {

    IconButton,
    Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { EditableModal } from "./EditableModal";
import { convertSortedIndexNumber } from "../../utilities/utils";


export const EditableTable = ({ tableColumns, tableOptions, data, title, editOptions, editTitle, tableUpdates, setTableUpdates, handleSave, isModalOpen, setIsModalOpen, selectedIndex, setSelectedIndex, isEditable }) => {
    const [selectedRowData, setSelectedRowData] = useState({});
    const handleEdit = (rowData, index) => {
        const modalData = {};
        rowData.forEach((item, index) => {
            if (index == 0) {
                return
            }
            modalData[columns[index].name] = item;
        });
        setSelectedRowData(modalData);
        setSelectedIndex(index);
        setIsModalOpen(true);

    }

    const columns = [
        {
            name: "action",
            label: "Action",
            options: {
                customBodyRender: (value, tableMeta) => (
                    <Tooltip title="Edit">
                        <IconButton onClick={() => handleEdit(tableMeta.rowData, convertSortedIndexNumber(tableMeta))}>
                            <EditIcon />
                        </IconButton >
                    </Tooltip >
                ),
            },
        },
        ...tableColumns];
    const options = {
        ...tableOptions, setTableProps: () => ({
            style: {
                textAlign: 'center'
            }
        })
    };


    return (
        <div sx={{
            display: "flex",
            justifyContent: "center"
        }}>

            <MUIDataTable
                options={options}
                columns={columns}
                data={data}
                title={title}
            />


            <EditableModal
                open={isModalOpen}
                options={editOptions}
                data={selectedRowData}
                title={editTitle ? editTitle : "Edit Modal"}
                selectedIndex={selectedIndex}
                editedData={tableUpdates}
                setEditedData={setTableUpdates}
                saveChanges={handleSave}
                handleModalClose={() => { setIsModalOpen(false); setSelectedRowData({}); setSelectedIndex(-1) }}
                isEditable={isEditable}
            />
        </div >
    )
}