import * as React from 'react';
import { useUserContext } from '../../../../contexts/ProviderProvider';
import useNotification from "../../../../hooks/useNotification";

import { Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import MUIDataTable from 'mui-datatables';
import { getTransfers } from '../../../../API/transferPortal';

const TransferCardDashboard = ({ transfersCards }) => {
    return (
        <Grid container spacing={1}>
            {transfersCards.map(({ reason, count }) => (
                <Grid item xs={12} sm={4} md={4} lg={2} key={reason}>
                    <Card>
                        <CardContent>
                            <Typography variant="h7" gutterBottom style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {reason}
                            </Typography>
                            <Typography variant="h5">
                                {count}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

const TransferPortalZeroOut = ({ addToCardArray, setLoading }) => {

    const [transfers, setTransfers] = React.useState([]);
    const [transfersCards, setTransfersCards] = React.useState([]);

    const [loadingData, setLoadingData] = React.useState(true)

    const notify = useNotification();

    const user = useUserContext();


    React.useEffect(() => {
        if (!user) {
            return;
        }
        setLoading(true);
        getTransfers(user)
            .then((response) => {
                console.log(response)
                const cards_obj = {};
                const flattenedData = response.data.map((obj) => {
                    const latestTransfer = obj.TransferPortals.sort((a, b) => new Date(b.modified_at) - new Date(a.modified_at))[0];
                    const reason = latestTransfer?.reason;
                    if (cards_obj[reason]) cards_obj[reason]++;
                    else cards_obj[reason] = 1;
                    return {
                        ...obj,
                        is_expired: latestTransfer?.is_expired,
                        reason: reason,
                    };
                });

                const cards = Object.entries(cards_obj).map(([key, value]) => {
                    return {
                        reason: key,
                        count: value
                    }
                });

                setTransfers(flattenedData);
                addToCardArray({
                    id: 'Transfer Portal',
                    panel: 'TransferPortal',
                    count: flattenedData.length
                });
                setTransfersCards(cards);
                setLoadingData(false);
                setLoading(false);
            })
            .catch((error) => {
                console.log('Error fetching transfers', error);
                setLoading(false);
                notify('Unable to fetch Transfer Portal');
                setLoadingData(false);
            })

    }, [user, notify]);

    const columns = [
        {
            name: "full_name",
            label: "Full Name",
        },
        {
            name: "reason",
            label: "Reason",
        },
        {
            name: "university",
            label: "University",
        },
    ];

    const options = {
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: loadingData ? (
                    <CircularProgress />
                ) : (
                    "No Records"
                ),
            },
        },
    }

    return (
        <>
            <div
                style={{
                    margin: "20px 10px"
                }}>
                {transfersCards &&
                    <TransferCardDashboard transfersCards={transfersCards} />
                }
                {transfers ?
                    <div
                        style={{
                            margin: "10px 10px"
                        }}>

                        <MUIDataTable
                            title={"Transfer Portal"}
                            data={transfers}
                            columns={columns}
                            options={options}
                        />
                    </div>
                    :
                    <Typography variant="h6">Data not found for Grand Slam</Typography>
                }
            </div>
        </>
    );
}

export default TransferPortalZeroOut