import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";
import { MenuItem, TextField } from "@mui/material";
import Can from "./Can";
import { usePermissionContext } from "../../contexts/ProviderProvider";
import useCanUser from "../../hooks/useCanUser";

export const SelectModifier = ({
  original,
  value,
  setValue,
  label,
  style,
  options,
  permissions,
  disabled
}) => {
  const filterOriginal = (newValue) => {
    if (newValue !== original) {
      setValue(newValue);
    } else {
      setValue(undefined);
    }
  };

  const { actions } = usePermissionContext();
  const canUserAlter = useCanUser(actions.ALTER, [permissions]);
  const canDisabled = permissions ? !canUserAlter : false;

  return (
    <>
      <TextField
        label={label}
        value={value ? value : original}
        onChange={(e) => filterOriginal(e.target.value)}
        style={style}
        sx={{ width: "75%" }}
        disabled={disabled || canDisabled}
        select
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {value && value !== original && (
        <>
          <Tooltip title="Revert">
            <RefreshIcon
              onClick={() => setValue(original)}
              style={{
                color: "#6495ED",
                marginTop: "10px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
          {/* <Tooltip title="Submit">
              <CheckIcon
                onClick={() => {
                  props.confirmAreYouSure(
                    props.confirm_type,
                    props.product,
                    props.text,
                    editedText
                  );
                }}
                style={{ color: "green", marginTop: "10px", cursor: "pointer" }}
              />
            </Tooltip> */}
        </>
      )}
    </>
  );
};
