import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * DateRangePicker component renders a date picker for selecting a date.
 * @param {object} props - The properties passed to the component.
 * @param {any} props.date - The value of the date.
 * @param {function} props.setDate - The function to set the date.
 * @param {string} props.title - The string value to set the title above the picker
 */
const DatePicker = ({ date, setDate, title, ...props }) => {
    const handleDateChange = (newValue) => {
        if (newValue && newValue.isValid()) {
            const centralTimeDate = newValue.tz('America/Chicago').startOf('day');
            setDate(centralTimeDate);
        } else {
            setDate(null); // Handle invalid date appropriately
        }
    };

    const today = dayjs().tz('America/Chicago').startOf('day');
    const usingDate = date && dayjs.isDayjs(date) && date.isValid() ? date : today;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column' }}>
                <Typography id="date-range-picker" gutterBottom>
                    {title ? title : "Date"}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingRight: '5px', paddingBottom: '10px' }}>
                        <DateTimePicker
                            label="Date"
                            views={['month', 'year', 'day']}
                            openTo="day"
                            value={usingDate}
                            onChange={handleDateChange}
                            style={{ padding: '5px' }}
                        />
                    </span>
                </div>
            </div>
        </LocalizationProvider>
    );
};

DatePicker.propTypes = {
    date: PropTypes.any.isRequired,
    setDate: PropTypes.func.isRequired,
    title: PropTypes.string
};

export default DatePicker;
