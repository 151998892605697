import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import Can from "../../common/Can";
import { Navigate } from "react-router-dom";
import { usePermissionContext } from "../../../contexts/ProviderProvider";
import MediaDay from "./MediaDay";

export const Component = () => {
    const { views } = usePermissionContext();
    return (
        <Can action={views.VIEW} items={[views.MEDIADAY]} els={<Navigate to="/ballpark" />}>
            <MediaDay />
        </Can>
    );
}
export const icon = <DomainVerificationIcon />;
export const text = "Media Day";
export const componentName = "MediaDay";
export const path = "mediaday";